/**
 * Created by br0wn on 10/25/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import URI from '../../../../config/uri.js'
import {l} from'../../../i18n/translator.js';
import AppLayout from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx';
import {UserList} from '../../common/user/userList.jsx';
import * as PageActions from '../action/list';


export class UserListPage extends React.Component {
    componentWillMount() {
        const {actions} = this.props;
        actions.init();
    }

    render() {
        const {auth, pageState, actions} = this.props;
        const {deleting, data} = pageState;
        const userList = data.userList || [];

        return (
            <AppLayout>
                <LoadedPage pageState={pageState}>

                    {/* Title and breadcrumbs */}
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-10">
                            <h2>{l('Users')}</h2>

                            <ol className="breadcrumb">
                                <li>
                                    <Link to={URI.DASHBOARD}>{l('Dashboard')}</Link>
                                </li>
                                <li className="active">
                                    <strong>{l('Users')}</strong>
                                </li>
                            </ol>
                        </div>
                    </div>

                    {/* Page Content */}
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="ibox">
                            <div className="ibox-content">

                                <div className="row m-b-lg">
                                    <div className="col-xs-2">
                                        <Link to={URI.USER_CREATE} className="btn btn-info">
                                            Create user
                                        </Link>
                                    </div>
                                </div>

                                {userList.length ? (
                                    <UserList
                                        auth={auth}
                                        users={userList}
                                        updating={deleting}
                                        onDeleteUser={actions.deleteUser}
                                    />
                                ) : (
                                    <p>{l('No users found. Create a user by clicking "Create user" button.')}</p>
                                )}

                            </div>
                        </div>
                    </div>
                </LoadedPage>
            </AppLayout>
        )
    }
}


export default connect(
    state => ({
        i18n: state.i18n,
        auth: state.auth,
        pageState: state.page.userList
    }),
    dispatch => ({
        actions: {
            init: () => dispatch(PageActions.init()),
            deleteUser: (user) => dispatch(PageActions.deleteUser(user))
        }
    })
)(UserListPage);
