import {l} from '../../../../i18n/translator';
import {warn} from '../../../../../lib/logger';
import {
    fetchAnalysisCriteriaList,
    fetchBrokers,
    fetchInsurances,
    fetchInsuranceTypes,
    fetchOfferRequest,
    fetchOfferRequestContact,
    fetchOfferRequestOffers
} from '../../../../api/sdk-action';
import ChatActions from '../action/chat';
import {calculateInsuranceOfferStatistics} from '../helper';
import TYPES from './type';
import {isUserOfType} from '../../../../auth/helper';
import {CompanyTypes} from '../../../../../config/domain/user';
import {CriteriaTypes} from '../../../../../config/domain/analysis';


export const init = (offerRequestId) => {
    return (dispatch, getState) => {
        const isBroker = isUserOfType(getState().auth, CompanyTypes.BROKER);

        dispatch({
            type: TYPES.PAGE_LOAD_START
        });

        dispatch(
            fetchOfferRequest(offerRequestId)
        ).then(offerRequest => {
            dispatch(dataLoaded('offerRequest', offerRequest));

            return Promise.all([
                dispatch(fetchInsurances(offerRequest.insuranceTypeId)),
                dispatch(fetchInsuranceTypes()),
                dispatch(fetchBrokers()),
                dispatch(fetchOfferRequestOffers(offerRequest.id)),
                dispatch(loadContact(offerRequest)),
                isBroker ? dispatch(fetchAnalysisCriteriaList({insuranceType: offerRequest.insuranceTypeId, type: CriteriaTypes.UTILITY})) : undefined,
            ]).then(([insurances, insuranceTypes, brokers, offerList, contact, analysisCriteria]) => {
                dispatch(dataLoaded('insuranceTypes', insuranceTypes));
                dispatch(dataLoaded('insurances', insurances));
                dispatch(dataLoaded('brokers', brokers));
                dispatch(dataLoaded('offerList', offerList));
                dispatch(dataLoaded('contact', contact));
                dispatch(dataLoaded('analysisCriteria', analysisCriteria));

                // update statistics
                dispatch(updateStatistics({offerRequest, offerList, insurances}));

                // dispatch page loaded (somehow we need to delay it to not throw an error)
                dispatch(pageLoadEnd({isLoaded: true}))
            }).then(() => {
                dispatch(ChatActions.loadThreadList());
            }).catch(error => {
                warn("Error while fetching offer resources!", error);
                let errors = [];

                if (error.requestError) {
                    errors.push(l("Unknown response received from server"));
                } else {
                    errors.push(l("Unknown error occurred"));
                }

                dispatch(pageLoadEnd({errors: errors}));
            });
        }).catch(error => {
            warn("Error while loading offer!", error);

            if (error.notFound || error.forbidden) {
                return dispatch(pageLoadEnd({notFound: true}));
            }

            let errors = [];

            if (error.requestError) {
                errors.push(l("Unknown response received from server"));
            } else {
                errors.push(l("Unknown error occurred"));
            }

            dispatch(pageLoadEnd({errors}));
        });
    };
};

export const updateStatistics = ({offerRequest, offerList, insurances}) => (dispatch) => {
    let statistics = calculateInsuranceOfferStatistics({offerRequest, offers: offerList, insurances});
    dispatch(dataLoaded('statistics', statistics));
};

export const loadContact = (offerRequest) => {
    return (dispatch) => {

        if (offerRequest.contact != null) {
            return Promise.resolve(offerRequest.contact);
        }

        return dispatch(
            fetchOfferRequestContact(offerRequest.id)
        );
    };
};

export const cleanup = () => {
    return {
        type: TYPES.PAGE_RESET
    };
};

export const dataLoaded = (name, values) => {
    return {
        type: TYPES.DATA_LOADED,
        data: {[name]: values}
    };
};

export const pageLoadEnd = (params = {}) => ({
    type: TYPES.PAGE_LOAD_END,
    ...params
});
