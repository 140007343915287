import swal from 'sweetalert';
import {reset, SubmissionError} from 'redux-form';
import API from '../../../config/api/routes';
import {warn} from '../../../lib/logger';
import {handleApiValidationErrorResponse, handleErrorResponse} from '../../../lib/http';
import {updateQueryParameter} from '../../../lib/url';
import {deleteRequest, jsonGetRequest, jsonPostRequest, postRequest} from '../../api/request';
import {makeAuthApiRequest} from '../../api/action';
import {createAction} from '../../action';
import {mapFormValuesToUser} from '../../helper/mapping/user.js';
import {l} from '../../i18n/translator';
import {buildErrors} from '../common/form/helper';
import {apiPostUser} from '../../api/sdk-action';

/*
 * ACTION TYPES
 */
export const TYPES = {
    DATA: 'page.profile.users.data',
    DATA_LOADED: 'page.profile.users.data_loaded',
    DATA_UPDATE_SINGLE: 'page.profile.users.data_update_single',

    PAGE_LOAD_START: 'page.profile.users.load_start',
    PAGE_LOAD_END: 'page.profile.users.load_end',
    PAGE_RESET: 'page.profile.users.reset',

    USERS_LOADED: 'page.profile.users.loaded',
    DELETE_USER_START: 'page.profile.users.delete_start',
    DELETE_USER_END: 'page.profile.users.delete_end',

    GENERATE_API_KEY_END: 'page.profile.api_key.generate_end',
    DELETE_API_KEY_START: 'page.profile.api_key.delete_start',
    DELETE_API_KEY_END: 'page.profile.api_key.delete_end',
    UPDATE_API_KEY_MODAL: 'page.profile.api_key.modal.update',
    UPDATE_API_KEY_END: 'page.profile.api_key.update_end',

    UPDATE_USER_EDIT_MODAL: 'page.profile.users.edit.modal.update',

    UPDATE_MOBILE: 'page.profile.mobile.update'
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    DATA_LOADED: TYPES.DATA_LOADED,
    PAGE_RESET: TYPES.PAGE_RESET
};


export const fetchUsers = (all = false) => (dispatch) => dispatch(
    makeAuthApiRequest(
        all ? updateQueryParameter(API.USERS, 'all', 'true') : API.USERS,
        jsonGetRequest()
    )
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    return Promise.reject({requestError: response});
}));

export const fetchUser = (userId) => (dispatch) => dispatch(
    makeAuthApiRequest(API.USER.replace(':id', userId), jsonGetRequest())
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    return Promise.reject({requestError: response});
}));

export const fetchInsuranceTypes = () => (dispatch) => dispatch(
    makeAuthApiRequest(API.INSURANCE_TYPES, jsonGetRequest())
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    return Promise.reject({requestError: response});
}));

export const apiDeleteUser = (userId) => {
    return (dispatch) => {
        return dispatch(makeAuthApiRequest(API.USER_ENABLE.replace(':id', userId), deleteRequest())
        ).catch(e => handleErrorResponse(e, response => {
            return Promise.reject({requestError: response});
        }));
    };
};

export const apiEnableUser = (userId) => {
    return (dispatch) => {
        return dispatch(makeAuthApiRequest(API.USER_ENABLE.replace(':id', userId), postRequest())
        ).catch(e => handleErrorResponse(e, response => {
            return Promise.reject({requestError: response});
        }));
    };
};

export const apiUpdatePassword = (oldPassword, newPassword) => {
    return (dispatch) => {
        let data = {
            oldPassword: oldPassword,
            password: newPassword
        };
        return dispatch(makeAuthApiRequest(API.AUTH.NEW_PASSWORD, jsonPostRequest({
                body: JSON.stringify(data)
            }))
        ).catch(handleApiValidationErrorResponse);
    };
};

export const apiverifyMobile = (mobile, mobileToken) => {
    return (dispatch) => {
        let data = {mobile, mobileToken};
        return dispatch(makeAuthApiRequest(API.AUTH.VERIFY_MOBILE, jsonPostRequest({
                body: JSON.stringify(data)
            }))
        ).catch(handleApiValidationErrorResponse);
    };
};

export const createUser = () => (dispatch) => {
    dispatch(showUserEditForm());
};

export const editUser = (user) => (dispatch) => {
    dispatch(showUserEditForm(user));
};

export const showUserEditForm = (user = {}) => (dispatch) => {
    dispatch({type: TYPES.UPDATE_USER_EDIT_MODAL, user, show: true});
};

export const closeUserEditForm = () => (dispatch) => {
    dispatch({type: TYPES.UPDATE_USER_EDIT_MODAL, user: {}, show: false});
};

export const updateUserDataSingle = (user) => (dispatch) => {
    dispatch({type: TYPES.DATA_UPDATE_SINGLE, user});
};

export const usersLoaded = (users) => createAction(
    TYPES.USERS_LOADED, {users}
);

export const submitUser = (data, dispatch) => {
    let userValues = mapFormValuesToUser(data);
    // we currently removed the username field from the form because we always want it to be the same for now
    userValues.username = userValues.email;
    return dispatch(apiPostUser(userValues)
    ).then((user) => {
        dispatch(updateUserDataSingle(user));
        return dispatch(closeUserEditForm());
    }).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};

export const deleteUser = (user) => {
    return (dispatch) => {
        swal({
            title: l('Are you sure?'),
            type: 'warning',
            showCancelButton: true
        }, () => {
            dispatch({type: TYPES.DELETE_USER_START, id: user.id});

            return dispatch(
                apiDeleteUser(user.id)
            ).then(
                () => dispatch({type: TYPES.DELETE_USER_END, id: user.id})
            ).catch(
                error => dispatch({type: TYPES.DELETE_USER_END, id: user.id, user, error})
            );
        });
    };
};

export const enableUser = (user) => (dispatch) => dispatch(
    apiEnableUser(user.id)
).then(() => dispatch(createAction(
    TYPES.DATA_UPDATE_SINGLE, {user: {...user, enabled: true}}
)));

export const fetchApiKeys = () => (dispatch) => dispatch(
    makeAuthApiRequest(API.AUTH.API_KEYS, jsonGetRequest())
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    return Promise.reject({requestError: response});
}));

export const generateNewApiKey = () => (dispatch) => dispatch(showApiKeyForm(true));

export const showApiKeyForm = (show = true, apiKey = {}) => (dispatch) => {
    dispatch({type: TYPES.UPDATE_API_KEY_MODAL, apiKey, show});
};

export const submitApiKey = (data, dispatch) => {
    const apiKeyPrefix = data.apiKeyPrefix;
    const uri = apiKeyPrefix ? API.AUTH.API_KEY.replace(':key', apiKeyPrefix) : API.AUTH.API_KEYS;
    return dispatch(
        makeAuthApiRequest(uri, jsonPostRequest({
            body: JSON.stringify(apiKeyPrefix ? {
                name: data.name,
                ipRange: data.ipRange,
                webHookUrl: data.webHookUrl
            } : data)
        }))
    ).then(response => response.json()
    ).then(apiKey => {
        const type = apiKeyPrefix ? TYPES.UPDATE_API_KEY_END : TYPES.GENERATE_API_KEY_END;
        return dispatch({type: type, apiKey: apiKey});
    }).catch(handleApiValidationErrorResponse
    ).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};

export const deleteApiKey = (apiKey) => (dispatch) => {
    swal({
        title: l('Are you sure?'),
        type: 'warning',
        showCancelButton: true
    }, () => {
        dispatch({type: TYPES.DELETE_API_KEY_START, apiKey: apiKey});

        dispatch(
            makeAuthApiRequest(API.AUTH.API_KEY.replace(':key', apiKey.apiKeyPrefix), deleteRequest())
        ).then(
            () => dispatch({type: TYPES.DELETE_API_KEY_END, apiKey: apiKey})
        ).catch(
            error => dispatch({type: TYPES.DELETE_API_KEY_END, apiKey, error})
        );
    });
};


const createPageLoader = (action, loaderPromise) => (input) => (dispatch) => {
    dispatch(createAction(action + "_load_start", {}));

    loaderPromise(dispatch, input).then((data) => {
        dispatch(createAction(action + "_loaded", {data}));
        dispatch(createAction(action + "_load_end", {isLoaded: true}));
    }).catch(error => {
        warn("Error while fetching resources!", error);
        let errors = [];

        if (error.requestError) {
            errors.push(l("Unknown response received from server"));
        } else {
            errors.push(l("Unknown error occurred"));
        }

        dispatch(createAction(action + "_load_end", {errors: errors}));
    });
};

export const initProfilePage = createPageLoader(
    TYPES.DATA,
    (dispatch, input) => {
        if (!input.isAdmin) {
            return dispatch(fetchUser(input.userId))
                .then(([user]) => {
                    return {user};
                });
        }

        return Promise.all([
            dispatch(fetchUsers()),
            dispatch(fetchInsuranceTypes()),
            dispatch(fetchApiKeys())
        ]).then(([users, insuranceTypes, apiKeys]) => {
            return {
                users,
                user: users.find((user) => user.id == input.userId),
                insuranceTypes,
                apiKeys
            };
        });
    }
);

//////////////////////////////////////////////////////

export const updatePassword = (data, dispatch) => {
    return dispatch(
        apiUpdatePassword(data.oldPassword, data.password)
    ).then(() =>
        dispatch(reset('passwordUpdate'))
    ).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};

//////////////////////////////////////////////////////

export const updateMobile = (mobile) => (dispatch) => {
    dispatch({type: TYPES.UPDATE_MOBILE, mobile});
};

export const verifyMobile = (data, dispatch) => {
    return dispatch(
        apiverifyMobile(data.mobile, data.mobileToken)
    ).then(() => {
        dispatch(updateMobile(data.mobile));
        dispatch(reset('twoFactorAuth'));
    }).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};
