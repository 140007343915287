import Fields, * as FieldGroups from './config/formFields';
import {translateError} from '../common/form/helper';
import tabs from './config/tabs';

/**
 * Maps flattened API field to form field
 *
 * @param {string} apiField
 * @return {string}
 */
export const mapFormFieldName = (apiField) => {
    switch (apiField) {
        case 'id':
            return Fields.ID;
        case 'name':
            return Fields.NAME;
        case 'areaOfOperations':
            return Fields.AREA_OF_OPERATIONS;
        case 'employees':
            return Fields.EMPLOYEES;
        case 'businessIdentificationNumber':
            return Fields.BUSINESS_IDENTIFICATION_NUMBER;
    }

    // map contact fields
    let contactField = mapContactFieldName(apiField, 'contact', FieldGroups.ContactDataFields);

    if (contactField !== apiField) {
        return contactField;
    }

    // map correspondence fields
    let correspondenceField = mapContactFieldName(apiField, 'correspondence', FieldGroups.CorrespondenceDataFields);

    if (correspondenceField !== apiField) {
        return correspondenceField;
    }

    // map billing fields
    let billingField = mapContactFieldName(apiField, 'billing', FieldGroups.BillingDataFields);

    if (billingField !== apiField) {
        return billingField;
    }

    // return api field if nothing mapped
    return apiField;
};

/**
 * Maps flattened API contact field to contact form field
 *
 * @param {string} apiField
 * @param {string} prefix
 * @param {object} ContactFields
 * @return {string}
 */
export const mapContactFieldName = (apiField, prefix, ContactFields) => {
    switch (apiField) {
        case prefix + '.lastName':
            return ContactFields.LAST_NAME;
        case prefix + '.function':
            return ContactFields.FUNCTION;
        case prefix + '.addressLine1':
            return ContactFields.ADDRESS_1;
        case prefix + '.addressLine2':
            return ContactFields.ADDRESS_2;
        case prefix + '.town':
            return ContactFields.TOWN;
        case prefix + '.country':
            return ContactFields.COUNTRY;
        case prefix + '.telephone':
            return ContactFields.TELEPHONE;
        case prefix + '.mobile':
            return ContactFields.MOBILE;
        case prefix + '.email':
            return ContactFields.EMAIL;
        default:
            return apiField;
    }
};

/**
 * Builds offer API request object from form data
 *
 * @param {object} formData
 * @return {object}
 */
export const buildApiRequestData = (formData) => {
    return {
        id: formData[Fields.ID],
        name: formData[Fields.NAME],
        areaOfOperations: formData[Fields.AREA_OF_OPERATIONS],
        industryClassificationCode: formData[Fields.INDUSTRY_CLASSIFICATION_CODE],
        employees: formData[Fields.EMPLOYEES],
        businessIdentificationNumber: formData[Fields.BUSINESS_IDENTIFICATION_NUMBER] || null,
        contact: buildApiRequestContact(formData, FieldGroups.ContactDataFields),
        correspondence: (
            formData[Fields.HAS_CORRESPONDENCE] ? buildApiRequestContact(formData, FieldGroups.CorrespondenceDataFields) : null
        ),
        billing: (
            formData[Fields.HAS_BILLING] ? buildApiRequestContact(formData, FieldGroups.BillingDataFields) : null
        )
    };
};

/**
 * Builds user contact API request object from form data and contact fields
 *
 * @param {object} formData
 * @param {object} ContactFields
 * @return {object}
 */
export const buildApiRequestContact = (formData, ContactFields) => {
    return {
        lastName: formData[ContactFields.LAST_NAME],
        firstName: formData[ContactFields.FIRST_NAME],
        function: formData[ContactFields.FUNCTION],
        addressLine1: formData[ContactFields.ADDRESS_1],
        addressLine2: formData[ContactFields.ADDRESS_2],
        zip: formData[ContactFields.ZIP],
        town: formData[ContactFields.TOWN],
        country: formData[ContactFields.COUNTRY],
        telephone: formData[ContactFields.TELEPHONE],
        mobile: formData[ContactFields.MOBILE],
        email: formData[ContactFields.EMAIL]
    };
};

/**
 * Creates form field object based on apiField and apiData
 *
 * @param {string} apiField
 * @param {object} apiData
 * @return {object}
 */
export const buildFormField = (apiField, apiData = {}) => {
    let fieldName = mapFormFieldName(apiField);

    let fieldValue = apiField.split('.').reduce((carry, part) => {
        return carry ? carry[part] : carry;
    }, apiData);

    return {[fieldName]: fieldValue};
};

/**
 * Builds form data object from offer API object
 *
 * @param {object} apiData
 * @return {object}
 */
export const buildFormData = (apiData = {}) => {
    return {
        ...buildFormField('id', apiData),
        ...buildFormField('name', apiData),
        ...buildFormField('areaOfOperations', apiData),
        ...buildFormField('employees', apiData),
        ...buildFormField('businessIdentificationNumber', apiData),
        ...buildFormField('industryClassificationCode', apiData),
        ...buildContactFormData('contact', apiData),
        [Fields.HAS_CORRESPONDENCE]: apiData.correspondence !== null,
        ...buildContactFormData('correspondence', apiData),
        [Fields.HAS_BILLING]: apiData.billing !== null,
        ...buildContactFormData('billing', apiData)
    };
};

/**
 * Builds contact form data from offer API object
 *
 * @param prefix
 * @param apiData
 * @return {object}
 */
export const buildContactFormData = (prefix, apiData) => {
    return {
        ...buildFormField(prefix + '.lastName', apiData),
        ...buildFormField(prefix + '.firstName', apiData),
        ...buildFormField(prefix + '.function', apiData),
        ...buildFormField(prefix + '.addressLine1', apiData),
        ...buildFormField(prefix + '.addressLine2', apiData),
        ...buildFormField(prefix + '.zip', apiData),
        ...buildFormField(prefix + '.town', apiData),
        ...buildFormField(prefix + '.country', apiData),
        ...buildFormField(prefix + '.telephone', apiData),
        ...buildFormField(prefix + '.mobile', apiData),
        ...buildFormField(prefix + '.email', apiData)
    };
};

/**
 * Builds form errors object from API validation errors
 *
 * @param validationErrors
 * @return {object}
 */
export const buildFormErrors = (validationErrors) => {
    if (!Array.isArray(validationErrors)) {
        return {};
    }

    return validationErrors.reduce((carry, {path, ...data}) => {
        let field = mapFormFieldName(path);
        carry[field] = carry[field] || '';
        carry[field] = carry[field] + translateError(data.key);
        carry[field] += ' ';

        return carry;
    }, {});
};

/**
 * Extract form errors based on wizard tab
 *
 * @param {object} errors
 * @param {int|string} tab
 * @return {object}
 */
export const extractTabErrors = (errors, tab) => {
    switch (tab) {
        case tabs.GENERAL_DATA:
            return extractErrorsForFields(FieldGroups.GeneralDataFields, errors);
        case tabs.CONTACT_DATA:
            return extractErrorsForFields(FieldGroups.ContactDataFields, errors);
        case tabs.CORRESPONDENCE_DATA:
            return extractErrorsForFields(FieldGroups.CorrespondenceDataFields, errors);
        case tabs.BILLING_DATA:
            return extractErrorsForFields(FieldGroups.BillingDataFields, errors);
        default:
            return {};
    }
};

/**
 * Returns true if step has errors
 *
 * @param {object} errors
 * @param {string} step
 * @return {boolean}
 */
export const hasTabErrors = (errors, step) => {
    let stepErrors = extractTabErrors(errors, step);
    return Object.keys(stepErrors).length > 0;
};

/**
 * Extract form errors based on fields configuration
 *
 * @param {object} fields
 * @param {object} errors
 * @return {object}
 */
export const extractErrorsForFields = (fields, errors) => {
    return Object.keys(fields).reduce((carry, fieldKey) => {
        // get the actual field name string
        let fieldName = fields[fieldKey];

        // if errors contain this field, add it to carry
        if (errors[fieldName]) {
            carry[fieldName] = errors[fieldName];
        }

        return carry;
    }, {});
};
