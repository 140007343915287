import React from 'react';
import {connect} from 'react-redux';
import CustomerDetails from '../../../request-details/component/customerDetails.jsx';
import OfferDetails from '../../../request-details/component/offerRequestDetails.jsx';
import OfferRoundDetails from '../../../request-details/component/offerRoundDetails.jsx';
import CurrentPremium from '../../../request-details/component/currentInsuranceData.jsx';
import SelectedInsurances from '../../../request-details/component/seletedInsurances.jsx';
import {getFormSubmitErrors, getFormValues} from 'redux-form';
import {getAdvisoryMandate, getHarmFile} from '../../reducer';
import {Fields, FORM_NAME} from '../../config/form.js';
import {Field} from '../redux-form-wrapper.jsx';
import * as Ibox from '../../../../ui-components/ibox.jsx';
import {renderCheckboxWithLink} from '../../../../common/form/renderer.jsx';
import {l} from '../../../../../i18n/translator';
import CustomerOnly from '../../../../common/auth/acl/customerOnly.jsx'
import {Fields as OfferRequestFields} from '../../../../../../config/domain/offerRequest.js'

let SummaryForm = ({auth, formValues, formErrors, advisoryMandate, harmFile, insurances = [], contact = {}}) => {

    const offerRequest = formValues || {};
    const offerRequestContact = offerRequest.contact || contact;

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    {/* Contact Data */}
                    <CustomerDetails customerName={offerRequest[OfferRequestFields.CUSTOMER_NAME]} contact={offerRequestContact}
                                 businessId={offerRequest[OfferRequestFields.BUSINESS_IDENTIFICATION_NUMBER]}
                                 customerIndustryClassificationCode={offerRequest[OfferRequestFields.CUSTOMER_INDUSTRY_CLASSIFICATION_CODE]}/>
                </div>

                <div className="col-md-6">
                    {/* Basic data */}
                    <OfferDetails {...{offerRequest}}/>
                    <OfferRoundDetails {...{offerRequest}}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {/* Insurance List */}
                    <SelectedInsurances {...{offerRequest, insurances}} />
                </div>
                <div className="col-md-6">
                    {/* Offer Data */}
                    <CurrentPremium {...{
                        offerRequest,
                        insurances
                    }}/>
                </div>
            </div>

            <CustomerOnly auth={auth}>
                <div className="row">
                    <div className="col-md-12">
                        <Ibox.Container>
                            <Ibox.Title>
                                <h5>
                                    <i className="fa fa-check-square-o"></i>&nbsp;
                                    {l('Confirmation')}
                                </h5>
                            </Ibox.Title>
                            <Ibox.Content>
                                <Field name={Fields.ACCEPT_TERMS} component={renderCheckboxWithLink}
                                       label={<span>{l('accept_terms_part1')}<a href="https://www.insurtec.ch/terms"
                                                                                target="_blank">{l('accept_terms_link')}</a>{l('accept_terms_part2')}</span>}/>
                                <Field name={Fields.ACCEPT_CONTRACTUAL_FEE_AGREEMENT} component={renderCheckboxWithLink}
                                       label={<span>{l('accept_contractual_fee_agreement_part1')}<a
                                           href="https://www.insurtec.ch/contractual_fee"
                                           target="_blank">{l('accept_contractual_fee_agreement_link')}</a>{l('accept_contractual_fee_agreement_part2')}</span>}/>

                                {formErrors && (formErrors[Fields.ACCEPT_TERMS] || formErrors[Fields.ACCEPT_CONTRACTUAL_FEE_AGREEMENT]) ?
                                    <span
                                        style={{color: 'red'}}>{l('You need to accept these to publish.')}</span> : null}
                            </Ibox.Content>
                        </Ibox.Container>
                    </div>
                </div>
            </CustomerOnly>
        </div>
    )
};


export default connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        formErrors: getFormSubmitErrors(FORM_NAME)(state),
        advisoryMandate: getAdvisoryMandate(state),
        harmFile: getHarmFile(state)
    })
)(SummaryForm);
