import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import classNames from 'classnames';
import {States as OfferRequestStates} from '../../../../config/domain/offerRequest';
import {l} from '../../../i18n/translator';
import {redirectTo} from '../../../router/action';
import AppLayout from '../../layout/app-layout.jsx';
import {BreadCrumbs} from '../../layout/app-layout.jsx';
import CustomerOnly from '../../common/auth/acl/customerOnly.jsx'
import LoadedPage from '../../common/page/loaded-page/component.jsx';
import RequestList from './component/requestList.jsx';
import * as PageActions from './action/page';
import * as ListActions from './action/list';

/**
 * OfferRequestListPage component
 *
 */
class OfferRequestListPage extends React.Component {

    componentWillMount() {
        let {params = {}, actions} = this.props;
        let offerRequestId = params.id;

        // do page init
        actions.init(offerRequestId);
    }

    render() {
        let {auth, pageState, actions} = this.props;
        let {filters, data: {insuranceTypes}, offerRequests} = pageState;
        let list = offerRequests.items ? filterList(offerRequests.items, filters) : [];

        return (
            <AppLayout>
                {/* Title and breadcrumbs */}
                <BreadCrumbs title={l('Requests list')} icon="file-text-o"/>

                {/* Page content */}
                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="ibox">
                            <div className="ibox-title">
                                <Row>
                                    <Col md={4} sm={4}>
                                        <h3>
                                            {l('Requests list')}
                                        </h3>
                                    </Col>

                                    <Col md={4} sm={6} className="text-center">
                                        <div className="btn-group">
                                            <CustomerOnly auth={auth}>
                                                <button className={classNames("table-filter", {active: filters.indexOf(OfferRequestStates.CREATED) !== -1})}
                                                       onClick={e => actions.filter(onFilterChange(filters, OfferRequestStates.CREATED))}>
                                                    {l('state_created')}
                                                </button>
                                            </CustomerOnly>

                                            <button className={classNames("table-filter", {active: filters.indexOf(OfferRequestStates.PUBLISHED) !== -1})}
                                                   onClick={e => actions.filter(onFilterChange(filters, OfferRequestStates.PUBLISHED))}>
                                                {l('state_published')}
                                            </button>

                                            <button className={classNames("table-filter", {active: filters.indexOf(OfferRequestStates.EXPIRED) !== -1})}
                                                   onClick={e => actions.filter(onFilterChange(filters, OfferRequestStates.EXPIRED))}>
                                                {l('Archived')}
                                            </button>
                                        </div>
                                    </Col>

                                    <Col md={4} sm={2} className="text-right">
                                        {pageState.offerRequests.isLoading ? (
                                            <span>
                                                <i className="fa fa-cog"></i>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        ) : null}
                                    </Col>
                                </Row>
                            </div>
                            <div className="ibox-content">
                                <RequestList
                                    auth={auth}
                                    deleting={pageState.deleting}
                                    withdrawing={pageState.withdrawing}
                                    offerRequestList={list}
                                    insuranceTypes={insuranceTypes}
                                    actions={actions.offerRequest}
                                />
                            </div>
                        </div>
                    </div>
                </LoadedPage>
            </AppLayout>
        )
    }
}

const Filters = {
    [OfferRequestStates.PUBLISHED]: (offerRequest) => offerRequest.state === OfferRequestStates.PUBLISHED,
    [OfferRequestStates.EXPIRED]: (offerRequest) => offerRequest.state === OfferRequestStates.EXPIRED,
    [OfferRequestStates.CREATED]: (offerRequest) => offerRequest.state === OfferRequestStates.CREATED
};

/**
 * @param {Array} activeFilters
 * @param {string} filter
 */
let onFilterChange = (activeFilters, filter) => {
    let filterIndex = activeFilters.indexOf(filter);

    if (filterIndex === -1) {
        return [...activeFilters, filter];
    } else {
        return [...activeFilters.slice(0, filterIndex), ...activeFilters.slice(filterIndex + 1)];
    }
};

/**
 * @param {Array} list
 * @param {Array} filters

 * @return {Array}
 */
let filterList = (list, filters) => {
    // if no active filters: return list
    if (filters.length <= 0) {
        return list;
    }

    // filter list
    return list.filter(offerRequest => {
        // go trough al filters and return true if item should be included
        return filters.reduce((prev, filter) => {
            if (!Filters[filter]) {
                return prev;
            } else {
                return prev || Filters[filter](offerRequest);
            }
        }, false);
    })
};

function mapStateToProps(state) {
    return {
        pageState: state.page.offerRequestList,
        auth: state.auth,
        i18n: state.i18n
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            filter: (filters) => {
                dispatch(ListActions.filterList(filters))
            },
            goToPage: (page) => {
                dispatch(ListActions.setPage(page));
            },
            init: () => {
                dispatch(PageActions.init());
            },
            offerRequest: {
                deleteOfferRequest: (offerRequestId) => {
                    dispatch(ListActions.deleteOfferRequest(offerRequestId));
                },
                redirectTo: (uri) => {
                    dispatch(redirectTo(uri));
                },
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfferRequestListPage);

