import i18next from 'i18next';

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_NS = 'translation';

i18next.init({
    debug: false,
    lng: DEFAULT_LOCALE,
    fallbackLng: DEFAULT_LOCALE,
    ns: [DEFAULT_NS],
    defaultNS: [DEFAULT_NS],
    nsSeparator: false,
    keySeparator: false
});

export default i18next;


/**
 * Alias for `translate`
 *
 * @param {string} id
 * @param {object=} params
 * @param {null|string=} locale
 *
 * @return {string}
 */
export const l = i18next.t.bind(i18next);
