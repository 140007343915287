import React from 'react';
import {Link} from 'react-router-dom';
import AppNavbarTop from './navbar-top.jsx';
import {l} from '../../i18n/translator';
import URI from '../../../config/uri';


/**
 * AppLayout AppLayout of the application
 *
 * @param children
 */
export const InsurTecLayout = ({children}) => (
    <div className="md-skin no-sidebar">
        <div id="wrapper">
            <div id="page-wrapper" className="gray-bg">
                <AppNavbarTop/>

                {children}
            </div>
        </div>
    </div>
);

export const BreadCrumbs = (props) => (
    <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-12">
            {props.children ? (
                <div className="right"><div className="vcenter">{props.children}</div></div>
            ) : null}

            {props.icon ? (
                <div className="inline breadcrumb-icon">
                    <i className={`fa fa-${props.icon}`}></i>
                </div>
            ) : null}
            <div className="inline">
                <h2>{props.title}</h2>

                <ol className="breadcrumb">
                    <li>
                        <Link to={URI.DASHBOARD}>{l('Dashboard')}</Link>
                    </li>
                    {props.items ? props.items.map((item) => {
                        if (item && item.label && item.uri)  {
                            return (<li key={item.label}><Link to={item.uri}>{item.label}</Link></li>);
                        }
                        return (<li key={item}>{item}</li>);
                    }): null}

                    <li className="active">
                        {props.title}
                    </li>
                </ol>
            </div>
        </div>
    </div>
);


export default InsurTecLayout;
