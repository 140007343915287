import {userCompanyLoaded} from '../../../auth/action';
import {l} from '../../../i18n/translator';
import {buildFormData} from '../helper';
import {GeneralDataFields} from '../config/formFields';
import {validate, save as apiSave, companyLookup} from './api';
import TYPES from './type';


export const save = () => {
    return (dispatch, getState) => {
        let {form} = getState().page.administration;
        let formData = form.values;

        dispatch({
            type: TYPES.SAVE_START
        });

        dispatch(
            validate(formData)
        ).then(() => dispatch(
            apiSave(formData)
        )).then((company) => {
            let newFormData = buildFormData(company);

            dispatch({
                type: TYPES.SAVE_END,
                formData: newFormData
            });

            dispatch(userCompanyLoaded(company));

            // clear flag
            setTimeout(() => dispatch({
                type: TYPES.CLEAR_SAVED_FLAG
            }), 1000);

        }).catch((error) => {
            dispatch({
                type: TYPES.SAVE_END,
                error
            });

            if (error.validationErrors) {
                window.scrollTo(0, 0);
            }
        });
    };
};

export const lookupCompany = (businessId) => (dispatch) => {
    return dispatch(
        companyLookup(businessId)
    ).then(company => dispatch(
        setFormData(company)
    )).catch(e => {
        if (!e.requestError) throw e;

        const validationErrors = {
            [GeneralDataFields.BUSINESS_IDENTIFICATION_NUMBER]: l('Lookup found nothing.')
        };

        dispatch({
            type: TYPES.SAVE_END,
            error: {validationErrors}
        });
    });
};

export const setFormData = (apiData) => {
    let formData = buildFormData(apiData);

    return {
        type: TYPES.SET_FORM_DATA,
        formData: formData
    };
};

export const onValueChange = (field, value) => {
    return {
        type: TYPES.FORM_FIELD_CHANGED,
        field: field,
        value: value
    };
};
