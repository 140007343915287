import React from 'react';
import {connect} from 'react-redux';
import {InputAdornment} from '@material-ui/core';
import {Button, Col, Row} from 'react-bootstrap';
import {l} from '../../../../../../../i18n/translator.js';
import {Fields as CreditOfferRequestFields} from '../../../../../../../../config/domain/offerRequest/credit.js';
import {sumNumbers} from '../../../../../../../helper/number.js';
import {formValueSelector} from 'redux-form';
import {Field, FieldArray} from '../../../redux-form-wrapper.jsx';
import MenuItem from '@material-ui/core/MenuItem';
import {
    renderCurrencyField,
    renderNumberField,
    renderSelectField,
    renderTextField
} from '../../../../../../common/form/renderer.jsx';
import {FORM_NAME} from '../../../../config/form';
import {creditFieldKey as dataFieldKey} from '../../../../helper';
import {LocalCurrencyWithSymbol} from '../../../../../../common/i18n/number.jsx';

const valueSelector = formValueSelector(FORM_NAME);

export let SalesStructureForm = ({currency, intl, fields, meta: {touched, error}, values = []}) => (
    <div>
        <Row>
            <Col md={6}>
                <h3 className="m-b-sm">
                    {l('Sales structure and payment terms')}
                </h3>
            </Col>
            <Col md={6}>
                <Button className="pull-right" onClick={() => fields.push({})}>
                    <i className="fa fa-plus"/> {l('Add Term Record')}
                </Button>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                {fields.map((field, index) => (
                    <div className="m-t-sm">
                        <Row key={index} className="m-b-md">
                            <Col md={4}>
                                <Field
                                    name={`${field}.region`}
                                    label={l('Country')}
                                    component={renderTextField}
                                    fullWidth={true}
                                />
                                <Button className="m-t-md" onClick={() => fields.remove(index)}>
                                    <i className="fa fa-trash"/>
                                </Button>
                            </Col>

                            <Col md={8}>
                                <Row>
                                    <Col md={6}>
                                        <Field
                                            name={`${field}.salesPreviousYear`}
                                            label={l('Sales previous year')}
                                            component={renderCurrencyField}
                                            fullWidth={true}
                                            currency={currency}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            name={`${field}.numberOfBuyers`}
                                            label={l('Number of buyers')}
                                            component={renderNumberField}
                                            fullWidth={true}
                                        />
                                    </Col>
                                </Row>

                                <h4 className="m-b-n-sm">{l('Payment terms (days)')}</h4>
                                <Row>
                                    <Col md={6}>
                                        <Field
                                            name={`${field}.paymentTermDaysStandard`}
                                            component={renderNumberField}
                                            label={l('Standard')}
                                            fullWidth={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            name={`${field}.paymentTermDaysMaximum`}
                                            component={renderNumberField}
                                            fullWidth={true}
                                            label={l('Maximum')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                ))}
            </Col>
        </Row>

        {touched && error && (
            <div className="text-danger">
                {error}
            </div>
        )}

        <hr />
        <Row className="m-b-lg">
            <Col md={4}>
                <strong className="text-uppercase pull-right">
                    {l('Total sales')}
                </strong>
            </Col>

            <Col md={8}>
                    <LocalCurrencyWithSymbol value={values.reduce((carry, item) => {
                        return sumNumbers(carry, item.salesPreviousYear);
                    }, 0)} currency={currency} />
            </Col>
        </Row>
        {/*<Row className="m-b-lg">*/}
            {/*<Col md={4}>*/}
                {/*<FormControl.Static className="text-right">*/}
                    {/*<strong className="text-uppercase">*/}
                        {/*{l('Total sales')}*/}
                    {/*</strong>*/}
                {/*</FormControl.Static>*/}
            {/*</Col>*/}

            {/*<Col md={8}>*/}
                {/*<InputGroup>*/}
                    {/*<InputGroup.Addon>{currency}</InputGroup.Addon>*/}
                    {/*<FormControl*/}
                        {/*readOnly*/}
                        {/*componentClass={LocalNumberInput}*/}
                        {/*value={values.reduce((carry, item) => {*/}
                            {/*return sumNumbers(carry, item.salesPreviousYear);*/}
                        {/*}, 0)}*/}
                    {/*/>*/}
                {/*</InputGroup>*/}
            {/*</Col>*/}
        {/*</Row>*/}
    </div>
);

SalesStructureForm = connect(
    state => ({
        values: valueSelector(state, dataFieldKey(CreditOfferRequestFields.SALES_SALESSTRUCTURE)) || []
    })
)(SalesStructureForm);

export const ForecastLastYears = ({currency, fields, meta: {touched, error}}) => (
    <div className="text-left">
        <Field
            name={dataFieldKey(CreditOfferRequestFields.SALES_VOLUME_LASTTHREEYEARS + '[0]')}
            component={renderCurrencyField}
            label={l('{{count}} years ago', {count: 1})}
            fullWidth={true}
            currency={currency}
        />

        <Field
            name={dataFieldKey(CreditOfferRequestFields.SALES_VOLUME_LASTTHREEYEARS + '[1]')}
            component={renderCurrencyField}
            label={l('{{count}} years ago', {count: 2})}
            fullWidth={true}
            currency={currency}
        />

        <Field
            name={dataFieldKey(CreditOfferRequestFields.SALES_VOLUME_LASTTHREEYEARS + '[2]')}
            component={renderCurrencyField}
            label={l('{{count}} years ago', {count: 3})}
            fullWidth={true}
            currency={currency}
        />

        {touched && error && (
            <div className="text-danger">
                {error}
            </div>
        )}
    </div>
);

export const SalesDetailsForm = ({currency, i18n}) => (
    <div className="m-t-md">
        <h3 className="m-b-md">
            {l('Which currency do you use')}
        </h3>

        <Field
            name={dataFieldKey(CreditOfferRequestFields.SALES_CURRENCY)}
            component={renderSelectField}
            label={null}
            fullWidth={true}
        >
            <MenuItem value="CHF" primaryText={l('CHF')}/>
            <MenuItem value="EUR" primaryText={l('EUR')}/>
            <MenuItem value="USD" primaryText={l('USD')}/>
        </Field>


        <h3 className="m-b-md">
            {l('Trading volume expectations')}
        </h3>

        <Row className="m-b-lg">
            <Col md={6} className="text-left form-horizontal label-left">
                <h4 className="m-b-n-sm">{l('Sales forecast')}</h4>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SALES_VOLUME_FORECASTNEXTYEAR)}
                    component={renderCurrencyField}
                    label={l('Next year')}
                    fullWidth={true}
                    currency={currency}
                />

                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SALES_VOLUME_FORECASTCURRENTYEAR)}
                    component={renderCurrencyField}
                    label={l('Current year')}
                    fullWidth={true}
                    currency={currency}
                />
            </Col>

            <Col md={6}>
                <h4 className="m-b-n-sm">{l('Sales forecast over last 3 years')}</h4>
                <FieldArray
                    name={dataFieldKey(CreditOfferRequestFields.SALES_VOLUME_LASTTHREEYEARS)}
                    component={ForecastLastYears}
                    currency={currency}
                    i18n={i18n}
                />
            </Col>
        </Row>

        <FieldArray
            name={dataFieldKey(CreditOfferRequestFields.SALES_SALESSTRUCTURE)}
            component={SalesStructureForm}
            currency={currency}
            i18n={i18n}
        />


        <h3 className="m-b-md">
            {l('Non-insurable sales')}
        </h3>

        <Row>
            <Col md={2}>
                <Field
                    inputStyle={{textAlign: 'right'}}
                    name={dataFieldKey(CreditOfferRequestFields.SALES_NONINSURABLESALESPERCENTAGE)}
                    component={renderNumberField}
                    label={null}
                    fullWidth={true}
                    InputProps={{startAdornment: <InputAdornment position="start">%</InputAdornment>}}
                />
            </Col>
            <Col md={9}>
                {l('percent of our overall sales is generated through business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).')}
            </Col>
        </Row>
    </div>
);
