import {SubmissionError} from 'redux-form';
import {redirectTo} from '../../../router/action';
import URI from '../../../../config/uri';
import API from '../../../../config/api/routes';
import {handleApiValidationErrorResponse} from '../../../../lib/http';
import {l} from '../../../i18n/translator';
import {userAuthenticated} from '../../../auth/action';
import {jsonPostRequest} from '../../../api/request';
import {makeApiRequest} from '../../../api/action';
import {buildErrors} from '../../common/form/helper';

export const TYPES = {
    LOGIN_SUCCESS: 'page.login.login_success',
    LOGIN_MOBILE_TOKEN_REQUIRED: 'page.login.login_mobile_token_required',
};

export let loginSuccess = (user, ref) => {
    return (dispatch) => {
        // signal login success
        dispatch({type: TYPES.LOGIN_SUCCESS, user: user});

        // signal authentication success
        dispatch(userAuthenticated(user));

        // redirect to dashboard
        dispatch(redirectTo(ref ? ref : URI.DASHBOARD));
    };
};

export const apiLogin = (data) => {
    const {user, password, mobileToken} = data;
    return (dispatch) => {
        return dispatch(makeApiRequest(API.AUTH.LOGIN, jsonPostRequest({
                body: JSON.stringify({email: user, password, mobileToken})
            }))
        ).catch(handleApiValidationErrorResponse);
    };
};

export let doLogin = (data, dispatch) => {
    const {redirect} = data;
    const loginData = {...data};
    delete loginData.redirect;

    return dispatch(
        apiLogin(loginData)
    ).then(response => response.json()
    ).then(user => dispatch(loginSuccess(user, redirect))
    ).catch((error) => {
        if (error.validationErrors) {
            const validationErrors = buildErrors(error.validationErrors);
            if (validationErrors.mobileToken) {
                dispatch({type: TYPES.LOGIN_MOBILE_TOKEN_REQUIRED});
            }
            throw new SubmissionError(validationErrors);
        }
        if (error.requestError) {
            throw new SubmissionError({_error: l('Username or password incorrect.')});
        }
        throw new SubmissionError({_error: l('Something went wrong, please reload the page and try again.')});
    });
};
