import {
    uploadOfferFile as apiUploadOfferFile
} from '../../../../api/sdk-action';
import TYPES from './type';


export const uploadOfferFile = (offer, file) => {
    return (dispatch) => {
        dispatch({
            type: TYPES.OFFER_FILE_UPLOAD_START
        });

        dispatch(
            apiUploadOfferFile(offer, file, (e) => dispatch({
                type: TYPES.OFFER_FILE_UPLOAD_PROGRESS,
                progress: Math.round(e.percent)
            }))
        ).then(fileData => {
            // optimistically update file name
            dispatch({
                type: TYPES.OFFER_FILE_UPLOAD_END,
                file: {name: file.name, ...fileData},
                offer: offer
            });
        }).catch(error => {
            dispatch({
                type: TYPES.OFFER_FILE_UPLOAD_END,
                error: error
            });
        });
    };
};

export const onOfferFileSelected = (file) => {
    return {
        type: TYPES.OFFER_FILE_SELECTED,
        file: file
    };
};