

/**
 * Returns browsers locales from `navigator` object
 *
 * @return {Array}
 */
export const getBrowserLocales = () => {
    if (!navigator) {
        return [];
    }

    if (navigator.languages) {
        return navigator.languages;
    }

    if (window.navigator.userLanguage) {
        return [window.navigator.userLanguage];
    }

    if (window.navigator.language) {
        return [window.navigator.language];
    }

    return [];
};

/**
 * Returns first supported browser locale or undefined if nothing found
 *
 * @param  {Array} enabledLocales
 * @return {string|null}
 */
export const getBrowserLocale = (enabledLocales = []) => {
    let browserLocales = getBrowserLocales();

    let locale = browserLocales.find(locale => {
        return enabledLocales.indexOf(locale) !== -1;
    });

    return locale === undefined ? null : locale;
};