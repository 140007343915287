
import React from 'react';
import URI from '../../../../config/uri';
import  {Link} from 'react-router-dom';

export const PageForbiddenBox = () => (
    <div className="middle-box text-center animated fadeInDown">
        <h1>403</h1>
        <h3 className="font-bold">Access Forbidden</h3>

        <div className="error-desc">
            Sorry, but you do not have access to this page.
            Try checking the URL for error or
            <Link to={URI.DASHBOARD}> go back to homepage.</Link>
        </div>
    </div>
);
