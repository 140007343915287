export default {
    " < 1 day": " < 1 day",
    " < 3 days": " < 3 days",
    " <= 100%": " <= 100%",
    " <= 110%": " <= 110%",
    " <= 130%": " <= 130%",
    "...": "...",
    "(excl. private customers, subsidiaries, affiliated companies and public corporations)": "(excl. private customers, subsidiaries, affiliated companies and public corporations)",
    "{{count}} years": "{{count}} year",
    "{{count}} years ago": "{{count}} year ago",
    "{{count}} years ago_plural": "{{count}} years ago",
    "{{count}} years_plural": "{{count}} years",
    "# Bids": "# Bids",
    "# of Buyers": "# of Buyers",
    "# of credit/financial reports obtained annually": "# of credit/financial reports obtained annually",
    "# Offers": "# Offers",
    "% Complete": "% Complete",
    "> 300": "> 300",
    "100 to 300": "100 to 300",
    "1st": "1st",
    "1st biggest case": "1st biggest case",
    "1st Quarter": "1st Quarter",
    "1st reminder": "1st reminder",
    "2 Biggest Customers": "2 Biggest Customers",
    "20 to 100": "20 to 100",
    "2nd": "2nd",
    "2nd biggest case": "2nd biggest case",
    "2nd Quarter": "2nd Quarter",
    "2nd reminder": "2nd reminder",
    "3rd": "3rd",
    "3rd Quarter": "3rd Quarter",
    "3rd reminder": "3rd reminder",
    "4th": "4th",
    "4th Quarter": "4th Quarter",
    "4th reminder": "4th reminder",
    "5 to 20": "5 to 20",
    "abroad": "abroad",
    "Abroad": "Abroad",
    "Abstain": "Abstain",
    "Abstain from offer": "Abstain from offer",
    "abstained": "abstained",
    "Accept offer": "Accept offer",
    "accept_contractual_fee_agreement_link": "contractual fee agreement",
    "accept_contractual_fee_agreement_part1": "I confirm that I have read and accept the ",
    "accept_contractual_fee_agreement_part2": ".",
    "accept_terms_link": "terms and agreements",
    "accept_terms_part1": "I confirm that I have read and accept the ",
    "accept_terms_part2": ".",
    "Accident Insurance Data": "Accident Insurance Data",
    "Accidentally added": "Accidentally added",
    "Account requires a password. Please set the password now.": "Account requires a password. Please set the password now.",
    "Accounts receivable": "Accounts receivable",
    "Action not allowed for current user.": "Action not allowed for current user.",
    "Actions": "Actions",
    "Activity": "Activity",
    "Add": "Add",
    "Add Customer": "Add Customer",
    "Add Debt": "Add Debt",
    "Add new": "Add new",
    "Add Term Record": "Add Term Record",
    "Additional information": "Additional information",
    "Address": "Address",
    "Address line 1": "Address line 1",
    "Address line 2": "Address line 2",
    "Adjustments ongoing": "Adjustments ongoing",
    "admin": "admin",
    "Administration": "Administration",
    "advisory mandate": "advisory mandate",
    "Advisory mandate": "Advisory mandate",
    "Advisory Mandate": "Advisory Mandate",
    "After publishing you will not be able to change anything anymore.": "After publishing you will not be able to change anything anymore.",
    "All": "All",
    "All insurances": "All insurances",
    "Amount": "Amount",
    "Amount in (000s)": "Amount in (000s)",
    "An error occurred while loading widget.": "An error occurred while loading widget.",
    "An error occurred while uploading file.": "An error occurred while uploading file.",
    "An error occurred while widget was working.": "An error occurred while widget was working.",
    "Analysis": "Analysis",
    "Analysis criteria": "Analysis criteria",
    "analysis_criteria_rating_type_default": "Default",
    "analysis_criteria_rating_type_number": "Number",
    "analysis_criteria_rating_type_options": "Options",
    "analysis_criteria_rating_type_checkbox": "Checkbox",
    "analysis_criteria_type_coverage": "Coverage",
    "analysis_criteria_type_premium": "Premium",
    "analysis_criteria_type_utility": "Utility",
    "Annual turnover": "Annual turnover",
    "Archived": "Archived",
    "Are you sure?": "Are you sure?",
    "Area": "Area",
    "Area of Operations": "Area of Operations",
    "Assessment": "Assessment",
    "Auto Extend": "Auto Extend",
    "Back": "Back",
    "Back to login": "Back to login",
    "Bad": "Bad",
    "Bad Debts and Distress Claims": "Bad Debts and Distress Claims",
    "Bad debts and distressed claims from the current to the last 3 years": "Bad debts and distressed claims from the current to the last 3 years",
    "bank guarantee, in following cases:": "bank guarantee, in following cases:",
    "Bank guarantee, in following cases:": "Bank guarantee, in following cases:",
    "Basic data": "Basic data",
    "Begin legal proceedings": "Begin legal proceedings",
    "Best offer": "Best offer",
    "bidding": "published",
    "Bidding End Date": "Bidding End Date",
    "Biggest cases": "Biggest cases",
    "Biggest Customers": "Biggest Customers",
    "Biggest open position": "Biggest open position",
    "Billing": "Billing",
    "Billing Address": "Billing Address",
    "Billing frequency": "Billing frequency",
    "billing_frequency_12": "Yearly",
    "billing_frequency_3": "Quarterly",
    "billing_frequency_6": "Half yearly",
    "BIN": "BIN",
    "Broker comment": "Broker comment",
    "Business Identification Number": "Business Identification Number",
    "Buyer Credit Risk Requests": "Buyer Credit Risk Requests",
    "By 3rd party in:": "By 3rd party in:",
    "Call/visit buyer": "Call/visit buyer",
    "Cancel": "Cancel",
    "canceled": "canceled",
    "Canceling ...": "Canceling ...",
    "Cease delivering": "Cease delivering",
    "Change Password": "Change Password",
    "CHF": "CHF",
    "City": "City",
    "Clear": "Clear",
    "Clear selection": "Clear selection",
    "Click to download the advisory mandate template: ": "Click to download the advisory mandate template: ",
    "Click to select a date": "Click to select a date",
    "Click to select date": "Click to select date",
    "Close": "Close",
    "Comment": "Comment",
    "Company": "Company",
    "Company name": "Company name",
    "Company was successfully created": "Company was successfully created",
    "Confirm New Password": "Confirm New Password",
    "Confirm Password": "Confirm Password",
    "Confirmation": "Confirmation",
    "Contact": "Contact",
    "Contact Address": "Contact Address",
    "Contact name": "Contact name",
    "Contract Data": "Contract Data",
    "Correspondence": "Correspondence",
    "Correspondence Address": "Correspondence Address",
    "Could not find specified offer or offer request.": "Could not find specified offer or offer request.",
    "Could not find specified offer request.": "Could not find specified offer request.",
    "Could not find specified user.": "Could not find specified user.",
    "Could not verify mobile.": "Could not verify mobile.",
    "Could not verify user. User could be already verified or not found.": "Could not verify user. User could be already verified or not found.",
    "Country": "Country",
    "Couverage Incentive": "Couverage Incentive",
    "Coverage Analysis": "Coverage Analysis",
    "Coverage assessment": "Coverage assessment",
    "Create": "Create",
    "Create an account": "Create an account",
    "Create auto rating": "Create auto rating",
    "Create customer": "Create customer",
    "Create new account": "Create new account",
    "Create offer": "Create offer",
    "Create Offer Request": "Create Offer Request",
    "Create Offer Overview": "Create Offer Overview",
    "Create user": "Create User",
    "created": "created",
    "Created on": "Created on",
    "Credit Insurance Data": "Credit Insurance Data",
    "Credit Limits and Receivables Security": "Credit Limits and Receivables Security",
    "Credit limits are decided by": "Credit limits are decided by",
    "Criteria": "Criteria",
    "Curr. Premium": "Curr. Premium",
    "Current": "Current",
    "Current insurance": "Current insurance",
    "Current Insurance Data": "Current Insurance Data",
    "Current insurance policy": "Current insurance policy",
    "Current Insurer": "Current Insurer",
    "Current number": "Current number",
    "Current policy number": "Current policy number",
    "Current year": "Current year",
    "Customer": "Customer",
    "Customer details": "Customer details",
    "Customer name": "Customer Name",
    "Customer Requests": "Customer Requests",
    "Customer was successfully created": "Customer was successfully created",
    "Customers": "Customers",
    "Customers with max. outstanding debt in 000s": "Customers with max. outstanding debt in 000s",
    "Dashboard": "Dashboard",
    "data_table__info": "Translation from https://datatables.net/plug-ins/i18n/",
    "data_table_decimal": "",
    "data_table_emptyTable": "No data available in table",
    "data_table_first": "First",
    "data_table_info": "Showing _START_ to _END_ of _TOTAL_ entries",
    "data_table_infoEmpty": "Showing 0 to 0 of 0 entries",
    "data_table_infoFiltered": "(filtered from _MAX_ total entries)",
    "data_table_infoPostFix": "",
    "data_table_last": "Last",
    "data_table_lengthMenu": "Show _MENU_ entries",
    "data_table_loadingRecords": "Loading...",
    "data_table_next": "Next",
    "data_table_previous": "Previous",
    "data_table_processing": "Processing...",
    "data_table_search": "Search:",
    "data_table_sortAscending": ": activate to sort column ascending",
    "data_table_sortDescending": ": activate to sort column descending",
    "data_table_thousands": ",",
    "data_table_zeroRecords": "No matching records found",
    "Date": "Date",
    "Date created": "Date created",
    "Date published": "Date published",
    "Days after original due date": "Days after original due date",
    "daysLeft": "1 day left",
    "daysLeft_plural": "{{count}} days left",
    "Debt Collection and Credit Insurance": "Debt Collection and Credit Insurance",
    "Debt collection offices": "Debt collection offices",
    "Debt enforcement request, debt collection agency, lawyer": "Debt enforcement request, debt collection agency, lawyer",
    "Debts": "Debts",
    "Decoy bird": "Decoy bird",
    "Delete": "Delete",
    "Deleting ...": "Deleting ...",
    "Description": "Description",
    "Desired start date": "Desired start date",
    "Details of sales": "Details of sales",
    "Development of accounts receivable": "Development of accounts receivable",
    "Development per quarter": "Development per quarter",
    "Disabled.": "Disabled.",
    "Do not have an account?": "Do not have an account?",
    "Do you currently have insurance?": "Do you currently have insurance?",
    "Do you want to proceed?": "Do you want to proceed?",
    "Drop files here or click to open file select dialog.": "Drop files here or click to open file select dialog.",
    "E-mail": "E-mail",
    "E-Mail": "E-Mail",
    "E-Mail sent": "E-Mail sent",
    "Edit": "Edit",
    "Edit comment": "Edit comment",
    "Edit customer": "Edit customer",
    "Edit user": "Edit user",
    "Edit User": "Edit User",
    "Email": "Email",
    "Email address required.": "Email address required.",
    "Employees": "Employees",
    "Enable": "Enable",
    "End Date for Insurance Companies": "End Date for Insurance Companies",
    "End of offer round": "End of offer round",
    "Ending Soon": "Ending Soon",
    "Enforced debt collection": "Enforced debt collection",
    "Enter your email": "Enter your email",
    "Enter your username/email": "Enter your username/email",
    "Error": "Error",
    "Error while loading data.": "Error while loading data.",
    "EUR": "EUR",
    "excess": "excess",
    "Extend offer request ending": "Extend offer request ending",
    "Extends offer request ending when last offer published is withing one day of defined end date.": "Extends offer request ending when last offer published is withing one day of defined end date.",
    "File is not valid %err%": "File is not valid %err%",
    "Final requested": "Final requested",
    "Finance department": "Finance department",
    "Finance Department": "Finance Department",
    "Financial statements from our customers": "Finantial statements from our customers",
    "financial statements request cases": "financial statements request cases",
    "Find your NOGA code": "Find your NOGA code",
    "finished": "expired",
    "First Name": "First Name",
    "Flag": "Flag",
    "Flagged": "Flagged",
    "Flop": "Flop",
    "For existing buyers": "For existing buyers",
    "For new buyers": "For new buyers",
    "Forecast Current year:": "Forecast Current year:",
    "Forecast Next year:": "Forecast Next year:",
    "Forgot password?": "Forgot password?",
    "Forgot your password?": "Forgot your password?",
    "Function": "Function",
    "General": "General",
    "General Conditions of Insurance": "General Conditions of Insurance",
    "Generate": "Generate",
    "Go to dashboard": "Go to dashboard",
    "Go to details": "Go to details",
    "Good": "Good",
    "Gross premium": "Gross premium (incl. stamp tax & fees)",
    "Grunddeckung": "Grunddeckung",
    "Harm history": "Harm history",
    "Hide current insurance": "Hide current insurance",
    "History": "History",
    "How should the offer premium be rated?": "How should the offer premium be rated?",
    "How would you like this request setup?": "How would you like this request setup?",
    "I don't know": "I don't know",
    "I have read and accept Terms and Agreements.": "I have read and accept Terms and Agreements.",
    "Id": "Id",
    "If yes, the credit limits are decided by the:": "If yes, the credit limits are decided by the:",
    "If you leave now, all changes will be lost. Are you sure you want to leave?": "If you leave now, all changes will be lost. Are you sure you want to leave?",
    "In 1 month": "In 1 month",
    "In 2 months": "In 2 months",
    "In 2 weeks": "In 2 weeks",
    "In order to assess the credit risk associated with each buyer, we request...": "In order to assess the credit risk associated with each buyer, we request...",
    "in Switzerland": "in Switzerland",
    "Industry Classification Code (NOGA)": "Industry Classification Code (NOGA)",
    "Information bureaus": "Information bureaus",
    "Information Bureaus": "Information Bureaus",
    "Insurance": "Insurance",
    "Insurance Data": "Insurance Data",
    "Insurance holder": "Insurance holder",
    "Insurance information": "Insurance information",
    "Insurance period": "Insurance period",
    "Insurance Period": "Insurance Period",
    "Insurance type": "Insurance type",
    "Insurance Type": "Insurance Type",
    "Insurance type permissions": "Insurance type permissions",
    "Insurance types": "Versicherungsarten",
    "Insurances": "Insurances",
    "Insurer": "Insurer",
    "Invalid Value": "Invalid Value",
    "Is a classification (premium increase as a result of the course of the claim) provided for by the existing insurance?": "Is a classification (premium increase as a result of the course of the claim) provided for by the existing insurance?",
    "Issued offers": "Issued offers",
    "key": "key",
    "Last Name": "Last Name",
    "Last offer": "Last offer",
    "Last offer date": "Last offer date",
    "Last offer time": "Last offer time",
    "Last updated:": "Last updated:",
    "Loading...": "Loading...",
    "Log out": "Log out",
    "Logging in, please wait": "Logging in, please wait",
    "Login": "Login",
    "Lookup": "Lookup",
    "Lookup found nothing.": "Lookup found nothing.",
    "Management Receivable": "Management Receivable",
    "Mandatory Data": "Mandatory Data",
    "Max. outstanding debt": "Max. outstanding debt",
    "Maximum": "Maximum",
    "Men": "Men",
    "Messages": "Messages",
    "Min. offer": "Min. offer",
    "Min. Offer": "Min. Offer",
    "Mobile": "Mobile",
    "Mobile has been verified": "Mobile has been verified",
    "Mobile number": "Mobile number",
    "Mobile Number": "Mobile Number",
    "Mobile Token": "Mobile Token",
    "Mobile Verification": "Mobile Verification",
    "Name": "Name",
    "Name / Address": "Name / Address",
    "Net premium": "Net premium (incl. stamp tax & fees)",
    "Neutral": "Neutral",
    "never": "never",
    "Never": "Never",
    "New best offer received": "New best offer received",
    "New insurance data request received": "New insurance data request received",
    "New message received": "New message received",
    "New messages": "New messages",
    "New offer received": "New offer received",
    "New offer request received": "New offer request received",
    "New password": "New password",
    "New Password": "New Password",
    "New row": "New row",
    "Next": "Next",
    "Next year": "Next year",
    "No": "No",
    "No chat selected.": "No chat selected.",
    "No comment": "No comment",
    "No conversations found.": "No conversations found.",
    "No current insurance": "No current insurance",
    "No data": "No data",
    "No files attached": "No files attached",
    "No harm history": "No harm history",
    "No messages at the moment.": "No messages at the moment.",
    "No notifications at the moment": "No notifications at the moment",
    "No offers requiring manual processing": "No offers requiring manual processing.",
    "No offers yet": "No offers yet",
    "No target premium": "No target premium",
    "No users found. Create a user by clicking \"Create user\" button.": "No users found. Create a user by clicking \"Create user\" button.",
    "No, leave it be": "No, leave it be",
    "Non-insurable sales": "Non-insurable sales",
    "None": "None",
    "None selected": "None selected",
    "None specified": "None specified",
    "Not specified": "Not specified",
    "Number": "Number",
    "Number of buyers": "Number of buyers",
    "Number of collections initiated in last 12 months": "Number of collections initiated in last 12 months",
    "Number of collections/legal proceedings initiated in the last 12 months": "Number of collections/legal proceedings initiated in the last 12 months",
    "Number of Employees": "Number of Employees",
    "Occasionally": "Occasionally",
    "of our overall sales is generated through business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).": "of our overall sales is generated through business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).",
    "Offer": "Offer",
    "Offer {{state}}": "Offer {{state}}",
    "Offer id": "Offer id",
    "Offer overview": "Offer overview",
    "Offer Rating": "Offer Rating",
    "Offer request": "Offer request",
    "Offer Request #": "Offer Request #",
    "Offer request #:number": "Offer request #:number",
    "Offer request created": "Offer request created",
    "Offer Request Details": "Offer Request Details",
    "Offer request files": "Offer request files",
    "Offer request is not complete, please edit the request and add missing information.": "Offer request is not complete, please edit the request and add missing information.",
    "Offer request published": "Offer request published",
    "Offer request requires validation": "Offer request requires validation",
    "Offer request updated": "Offer request updated",
    "Offer requires validation": "Offer requires validation",
    "Offer Round": "Offer Round",
    "Offer round end": "Offer round end",
    "Offer round type": "Offer round type",
    "offerRoundType_auction": "Auction",
    "offerRoundType_firstShot": "First Shot",
    "Offers": "Offers",
    "Offers - Pending": "Offers - Pending",
    "Offers - Requiring manual processing": "Offers - Requiring manual processing",
    "Offers count": "Offers count",
    "Offical offer file": "Offical offer file",
    "Office Address": "Office Address",
    "Official offer file": "Official offer file",
    "OK": "OK",
    "Old Password": "Old Password",
    "Optional": "Optional",
    "Orders in excess of": "Orders in excess of",
    "Orders in excess of: %excess%": "Orders in excess of: %excess%",
    "Other": "Other",
    "Other:": "Other:",
    "Our reminder procedure is as follows (in days):": "Our reminder procedure is as follows (in days):",
    "Our reminder procedure is as follows:": "Our reminder procedure is as follows:",
    "Our sources": "Our sources",
    "Ourself in:": "Ourself in:",
    "Over last 3 years:": "Over last 3 years:",
    "Overall Analysis": "Overall Analysis",
    "overall sales generated trough business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).": "overall sales generated trough business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).",
    "Overview": "Overview",
    "Password": "Password",
    "Password cannot be empty": "Password cannot be empty",
    "Password empty!": "Password empty!",
    "Password you entered is not valid. Please try again.": "Password you entered is not valid. Please try again.",
    "Passwords do not match": "Passwords do not match",
    "Passwords do not match!": "Passwords do not match!",
    "Passwords do not match.": "Passwords do not match.",
    "Payment terms (days)": "Payment terms (days)",
    "pending": "pending",
    "pending_insurance": "pending_insurance",
    "pending_mandate": "pending_mandate",
    "pending_validation": "pending_validation",
    "percent of our overall sales is generated through business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).": "percent of our overall sales is generated through business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).",
    "Permissions": "Permissions",
    "Phone": "Phone",
    "Please add at least one offer.": "Please add at least one offer.",
    "Please check your email for password reset link.": "Please check your email for password reset link.",
    "Please check your email for your activation link.": "Please check your email for your activation link.",
    "Please choose a reason": "Please choose a reason",
    "Please enter a reason": "Please enter a reason",
    "Please enter custom reason": "Please enter custom reason",
    "Please enter insurance data for this offer request": "Please enter insurance data for this offer request",
    "Please enter insurance information": "Please enter insurance information",
    "Please enter your username/email.": "Please enter your username/email.",
    "Please input your information": "Please input your information",
    "Please list your 5-10 biggest customers": "Please list your 5-10 biggest customers",
    "Please see the offer details in the link bellow.": "Please see the offer details in the link bellow.",
    "Please select": "Please select",
    "Please select customer and insurance type to enable template download.": "Please select customer and insurance type to enable template download.",
    "Please select insurance type and broker to enable template download.": "Please select insurance type and broker to enable template download.",
    "Please upload your offer as PDF here": "Please upload your offer as PDF here",
    "Points": "Points",
    "Policy number": "Policy number",
    "Policy Number": "Policy Number",
    "Premium": "Premium",
    "Premium (%)": "Premium (%)",
    "Premium Amount": "Premium Amount",
    "Premium Analysis": "Premium Analysis",
    "Premium assessment": "Premium assessment",
    "Premium Incentive": "Premium Incentive",
    "Premium Volume": "Premium Volume",
    "Previous": "Previous",
    "Print": "Print",
    "Private Notes": "Private Notes",
    "Procedure": "Procedure",
    "Profile": "Profile",
    "Publish": "Publish",
    "published": "published",
    "Published on": "Published on",
    "Publishing ...": "Publishing ...",
    "Rank": "Rank",
    "Rank %num%": "Rank %num%",
    "Ranking of the best offer (price & coverage) by this insurance": "Ranking of the best offer (price & coverage) by this insurance",
    "Ranking of the offer with the cheapest premium by this insurance": "Ranking of the offer with the cheapest premium by this insurance",
    "Ranking of the offer with the best coverage by this insurance": "Ranking of the offer with the best coverage by this insurance",
    "Rating": "Rating",
    "Rating Criteria": "Rating Criteria",
    "Read": "Read",
    "Read only": "Read only",
    "Register now": "Register now",
    "Reject offer": "Reject offer",
    "Reject reason": "Reject reason",
    "Reload the page": "Reload the page",
    "Reminder procedure": "Reminder procedure",
    "Reminders & Collections": "Reminders & Collections",
    "Remove": "Remove",
    "Removing...": "Removing...",
    "Repeat password": "Repeat password",
    "Repeat Password": "Repeat Password",
    "Request final": "Request final",
    "Request insurance offer": "Request insurance offer",
    "Requests - Bidding": "Requests - Bidding",
    "Requests - Finished": "Requests - Finished",
    "Requests - Pending": "Requests - Pending",
    "Requests Finished": "Requests Finished",
    "Requests list": "Requests list",
    "Requests Selected": "Requests Selected",
    "Requirement": "Requirement",
    "Resend E-Mail": "Resend E-Mail",
    "Reset password": "Reset password",
    "retention of title, in following cases:": "retention of title, in following cases:",
    "Retention of title, in the following cases:": "Retention of title, in the following cases:",
    "Retry": "Retry",
    "Revenue": "Revenue",
    "Role": "Role",
    "Salaries Job Accidents": "Salaries Job Accidents",
    "Salaries Non-Job Accidents": "Salaries Non-Job Accidents",
    "Sales": "Sales",
    "Sales department": "Sales department",
    "Sales Department": "Sales Department",
    "Sales forecast": "Sales forecast",
    "Sales forecast over last 3 years": "Sales forecast over last 3 years",
    "Sales previous year": "Sales previous year",
    "Sales structure and payment terms": "Sales structure and payment terms",
    "Sales volume in 000s": "Sales volume in 000s",
    "Same as contact": "Same as contact",
    "Same as Contact Address": "Same as Contact Address",
    "Save": "Save",
    "Save request": "Save request",
    "Saving": "Saving",
    "Savings": "Savings",
    "Savings %": "Savings %",
    "Savings CHF": "Savings CHF",
    "Select all": "Select all",
    "Select customer": "Select customer",
    "Select desired broker": "Select desired broker",
    "Select insurance type": "Select insurance type",
    "Select Offered Insurances (max.3)": "Select Offered Insurances (max.3)",
    "Selected by Customer": "Selected by Customer",
    "Selected Insurances": "Selected Insurances",
    "Send message": "Send message",
    "Sending E-Mail": "Sending E-Mail",
    "Senior Management": "Senior Management",
    "Senior nanagement": "Senior nanagement",
    "Show": "Show",
    "Show disabled users": "Show disabled users",
    "Show Offer": "Show Offer",
    "Sign the advisory mandate to get a broker": "Sign the advisory mandate to get a broker",
    "SMS Code": "SMS Code",
    "Snob": "Snob",
    "Something went wrong, please reload the page and try again.": "Something went wrong, please reload the page and try again.",
    "sometimes": "sometimes",
    "sometimes, in the following cases": "sometimes, in the following cases",
    "Sometimes, in the following cases:": "Sometimes, in the following cases:",
    "Special Conditions of Insurance": "Special Conditions of Insurance",
    "SSL": "SSL",
    "Standard": "Standard",
    "Star": "Star",
    "Start Date": "Start Date",
    "Start exploring InsurTec": "Start exploring INSURTEC",
    "state_abstained": "Abstained",
    "state_bidding": "Bidding",
    "state_rejected": "Rejected",
    "state_withdrawn": "Withdrawn",
    "state_created": "Created",
    "state_finished": "Finished",
    "state_pending": "Pending",
    "state_pending_insurance": "Pending: Insurance",
    "state_pending_mandate": "Pending: Mandate",
    "state_pending_validation": "Pending: Validation",
    "state_published": "Published",
    "Status": "Status",
    "Structure": "Structure",
    "Structure of accounts receivable": "Structure of accounts receivable",
    "Submit": "Submit",
    "Summary": "Summary",
    "Switzerland": "Switzerland",
    "Systematically": "Systematically",
    "Targ. Prem.": "Targ. Prem.",
    "Target premium": "Target premium",
    "Telephone": "Telephone",
    "template.pdf": "template.pdf",
    "Thank you for publishing your offer request. Our system will now get to work to find the best insurance premium possible": "Thank you for publishing your offer request. Our system will now get to work to find the best insurance premium possible",
    "Thank you for updating this offer request details. You can now close the page or click on the link bellow to go to dashboard.": "Thank you for updating this offer request details. You can now close the page or click on the link bellow to go to dashboard.",
    "There are currently no messages.": "There are currently no messages.",
    "There were some errors while fetching data. Please try to reload the page manually or by clicking on the link bellow.": "There were some errors while fetching data. Please try to reload the page manually or by clicking on the link bellow.",
    "There were some validation errors.": "There were some validation errors.",
    "These items can not be changed after offer request has been published.": "These items can not be changed after offer request has been published.",
    "These ratings can not be changed after offer has been published.": "These ratings can not be changed after offer has been published.",
    "This field is invalid (email).": "Please enter a valid email address",
    "This field is invalid (notEmpty).": "Field is required",
    "This field is invalid (notNull).": "Field is required",
    "This field is invalid (phone).": "Please enter a valid phone number",
    "This field is invalid (size).": "Field has incorrect length",
    "This is a chanel for broadcasting message to all insurances. No other messages will be displayed here": "This is a chanel for broadcasting message to all insurances. No other messages will be displayed here",
    "This is given to insurances as a target premium. This can as well be seen as a maximum premium.": "This is given to insurances as a target premium. This can as well be seen as a maximum premium.",
    "This offer request ends soon.": "This offer request ends soon.",
    "This will remove current advisory mandate!": "This will remove current advisory mandate!",
    "Token": "Token",
    "Total": "Total",
    "Total #": "Total #",
    "Total amount": "Total amount",
    "Total must be between 0 and 100": "Total must be between 0 and 100",
    "Total number": "Total number",
    "Total receivables (in 000s) at the end of the most recent 4 quarters:": "Total receivables (in 000s) at the end of the most recent 4 quarters:",
    "Total sales": "Total sales",
    "Trading volume expectations": "Trading volume expectations",
    "Trend": "Trend",
    "Type": "Type",
    "Type of business": "Type of business",
    "Unknown error occurred": "Unknown error occurred",
    "Unknown notification": "Unknown notification",
    "Unknown response received from server": "Unknown response received from server",
    "Unknown response received from server.": "Unknown response received from server.",
    "up to 5": "up to 5",
    "Up to 5": "Up to 5",
    "Update offer request data": "Update offer request data",
    "Upload": "Upload",
    "Upload harm file": "Upload harm file",
    "Uploading": "Uploading",
    "USD": "USD",
    "user": "user",
    "User is being verified, please wait...": "User is being verified, please wait...",
    "User not found.": "User not found.",
    "Username": "Username",
    "Username or password incorrect.": "Username or password incorrect.",
    "Username/Email": "Username/Email",
    "Users": "Users",
    "Utility Analysis": "Utility Analysis",
    "Utility rating": "Utility rating",
    "Utility sum": "Utility sum",
    "UVG Insurance Data": "UVG Insurance Data",
    "UVG Salaries": "UVG Salaries",
    "UVG Salaries Job Accidents": "UVG Salaries Job Accidents",
    "UVG Salaries Non Job Accidents": "UVG Salaries Non Job Accidents",
    "Validate": "Validate",
    "Validate and Publish": "Validate and Publish",
    "Value must be a number!": "Value must be a number!",
    "Value must be between 0 and 100": "Value must be between 0 and 100",
    "Value must have 5+ characters": "Value must have 5+ characters",
    "Verify": "Verify",
    "Verify Mobile": "Verify Mobile",
    "Verify user": "Verify user",
    "Verify with your mobile for better security": "Verify with your mobile for better security",
    "Very bad": "Very bad",
    "Very good": "Very good",
    "View": "View",
    "VIEW ALL": "VIEW ALL",
    "View details": "View details",
    "View offer": "View offer",
    "We conduct the debt collection ourself": "We conduct the debt collection ourself",
    "We enforce dept collection": "We enforce dept collection",
    "We have debt collection conducted by third parties": "We have debt collection conducted by third parties",
    "We request annual financial statements from our customers": "We request annual financial statements from our customers",
    "We secure our receivables through a...": "We secure our receivables through a...",
    "We set credit limits for our buyers": "We set credit limits for our buyers",
    "Weight": "Weight",
    "Weight (%)": "Weight (%)",
    "Welcome": "Welcome",
    "Welcome to InsurTec": "Welcome to InsurTec",
    "Welcome to InsurTec! Create your first offer request within just a few clicks!": "Welcome to InsurTec! Create your first offer request within just a few clicks!",
    "What conditions would you like?": "What conditions would you like?",
    "What is the reason for rejecting?": "What is the reason for rejecting?",
    "What is the reason for withdrawing?": "What is the reason for withdrawing?",
    "when reaching Offer Request End Date": "when reaching Offer Request End Date",
    "when reaching Target Insurance Premium": "when reaching Target Insurance Premium",
    "Which currency do you use": "Which currency do you use",
    "Which insurance companies would you like?": "Which insurance companies would you like?",
    "Which insurance is this for?": "Which insurance is this for?",
    "Withdraw": "Withdraw",
    "Withdraw offer": "Withdraw offer",
    "Withdraw offer request": "Withdraw offer request",
    "Withdraw reason": "Withdraw reason",
    "Withdrawing ...": "Withdrawing ...",
    "withdrawn": "withdrawn",
    "Women": "Women",
    "Working, please wait": "Working, please wait",
    "Write": "Write",
    "Wrong data": "Wrong data",
    "Year": "Year",
    "Yearly right of termination": "Yearly right of termination",
    "Yes": "Yes",
    "Yes, reject offer": "Yes, reject offer",
    "Yes, withdraw offer": "Yes, withdraw offer",
    "Yes, withdraw offer request": "Yes, withdraw offer request",
    "You can enter custom reason here": "You can enter custom reason here",
    "You can enter your reason here": "You can enter your reason here",
    "You can start writing here": "You can start writing here",
    "You have been selected for official offer! Please upload your official offer agreement.": "You have been selected for official offer! Please upload your official offer agreement.",
    "You have not setup company information yet.": "You have not setup company information yet.",
    "You have to accept terms and agreements.": "You have to accept terms and agreements.",
    "You have unsaved data": "You have unsaved data",
    "You need to accept these to publish.": "You need to accept these to publish.",
    "Your account has been verified!": "Your account has been verified!",
    "Your biggest customers": "Your biggest customers",
    "Your password has been reset!": "Your password has been reset!",
    "Your Requests": "Your Requests",
    "Your user request is being processed!": "Your user request is being processed!",
    "Zip Code": "Zip Code",
    "Zusatzdeckungen": "Zusatzdeckungen"
};
