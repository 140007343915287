import loadedPageReducer, {PAGE_INITIAL_STATE} from '../../common/page/loaded-page/reducer';
import {PAGE_ACTIONS} from './action/type';

const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    data: {
        insuranceTypes: [],
        insurances: [],
        brokers: []
    },
    form: {
        values: {},
        errors: {},
        isDirty: false
    },
    isSaving: false,
    isSaved: false,
    isPublishing: false,
    isPublished: false
};


// export const getPageState = (state) => state.page.createFinishedOfferRequest;

/**
 * Root reducer for CreateOfferRequest page
 *
 * @param state
 * @param action
 */
let createFinishedOfferRequestReducer = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS),
    };
};

export default createFinishedOfferRequestReducer;
