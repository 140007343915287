import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import URI from '../../../../config/uri';
import {l} from '../../../i18n/translator';
import AppLayout from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx';
import CreateOfferRequestWizard from './component/form.jsx'
import * as PageActions from './action/page';


/**
 * CreateOfferRequestPage component
 *
 * @param {object} state The state of the application
 * @param {object} actions Actions for this page
 */

export class CreateOfferRequestPage extends React.Component {

    componentWillMount() {
        let {match: {params = {}}, actions} = this.props;
        let offerRequestId = params.id;

        // do page init
        actions.init(offerRequestId);
    }

    componentWillUnmount() {
        this.props.actions.cleanup();
    }

    componentWillReceiveProps(nextProps) {
        let {params = {}, actions} = this.props;
        let nextParams = nextProps.params || {};

        if (params.id && params.id !== nextParams.id) {
            actions.cleanup();
            actions.init(nextParams.id);
        }
    }

    render() {
        const {pageState} = this.props;

        const {location, match:{params}, route, router, routeParams} = this.props;
        const routerProps = {location, params, route, router, routeParams};

        return (
            <AppLayout>

                {/* Title and breadcrumbs */}
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-12">
                        <h2>
                            {l('Request insurance offer')}

                            <div className="pull-right ssl">
                                <i className="fa fa-lock"/>
                                <span>{l('SSL')}</span>
                            </div>
                        </h2>

                        <ol className="breadcrumb">
                            <li>
                                <Link to={URI.DASHBOARD}>{l('Dashboard')}</Link>
                            </li>
                            <li className="active">
                                <strong>{l('Request insurance offer')}</strong>
                            </li>
                        </ol>
                    </div>
                </div>

                {/* Page content */}
                <LoadedPage pageState={pageState}>

                    <div className="row wrapper wrapper-content animated fadeInRight">
                        <div className="col-md-12 text-center">
                            <CreateOfferRequestWizard {...routerProps} />
                        </div>
                    </div>

                </LoadedPage>

            </AppLayout>
        );
    }
}


export default connect(
    (state) => {
        const {
            isPublished, isLoading, isLoaded, notFound, forbidden, loadErrors, data: {offerRequest}
        } = state.page.createOfferRequest;

        return {
            auth: state.auth,
            pageState: {
                isPublished, isLoading, isLoaded, notFound, forbidden, loadErrors, offerRequest
            }
        };
    },
    (dispatch) => ({
        actions: bindActionCreators({
            init: PageActions.init,
            cleanup: PageActions.cleanup
        }, dispatch)
    })
)(CreateOfferRequestPage);
