/**
 * Created by br0wn on 12/4/16.
 */
import React from 'react';
import {Col, ControlLabel, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {l} from '../../../../../../../i18n/translator.js';
import {Fields as CreditOfferRequestFields} from '../../../../../../../../config/domain/offerRequest/credit.js';

import {Field} from '../../../redux-form-wrapper.jsx';
import {renderCheckboxGroup, renderNumberField, renderTextField} from '../../../../../../common/form/renderer.jsx';
import {creditFieldKey as dataFieldKey} from '../../../../helper';


const ReminderFields = [
    {field: dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_FIRST), title: '1st reminder'},
    {field: dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_SECOND), title: '2nd reminder'},
    {field: dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_THIRD), title: '3rd reminder'},
    {field: dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_FOURTH), title: '4th reminder'},
    {field: dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_CALLORVISIT), title: 'Call/visit buyer'},
    {field: dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_CEASEDELIVERING), title: 'Cease delivering'}
];


export const RemindersAndCollections = () => (
    <div className="m-t-md">
        <h3 className="m-b-n-sm">{l('Our reminder procedure is as follows (in days):')}</h3>
        <Row>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_FIRST)}
                    label={l('1st reminder')}
                    component={renderTextField}
                    fullWidth={true}
                />
            </Col>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_SECOND)}
                    label={l('2nd reminder')}
                    component={renderTextField}
                    fullWidth={true}
                />
            </Col>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_THIRD)}
                    label={l('3rd reminder')}
                    component={renderTextField}
                    fullWidth={true}
                />
            </Col>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_FOURTH)}
                    label={l('4th reminder')}
                    component={renderTextField}
                    fullWidth={true}
                />
            </Col>
        </Row>
        <Row>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_CALLORVISIT)}
                    label={l('Call/visit buyer')}
                    component={renderTextField}
                    fullWidth={true}
                />
            </Col>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_CEASEDELIVERING)}
                    label={l('Cease delivering')}
                    component={renderTextField}
                    fullWidth={true}
                />
            </Col>
            <Col md={3}>
                <div>
                    <OverlayTrigger placement="bottom" overlay={(
                        <Tooltip id="legal-procedenings">
                            {l('Debt enforcement request, debt collection agency, lawyer')}
                        </Tooltip>
                    )}>
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_REMINDER_BEGINLEGALPROCEEDINGS)}
                        label={l('Begin legal proceedings')}
                        component={renderTextField}
                        fullWidth={true}
                    />
                    </OverlayTrigger>
                </div>
            </Col>
        </Row>

        <div className="m-t-xl" />

        <h3 className="m-b-md">
            {l('Enforced debt collection')}
        </h3>

        <Row>
            <Col md={6}>
                <h5>{l('We conduct the debt collection ourself')}</h5>

                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_ENFORCEDDEBTCOLLECTION_SELF)}
                    component={renderCheckboxGroup}
                    renderComponent={({Checkbox, Error}) => (
                        <div>
                            <Checkbox
                                value="switzerland"
                                label={l('in Switzerland')}
                            />

                            <Checkbox
                                value="abroad"
                                label={l('abroad')}
                            />

                            <div>
                                <Error/>
                            </div>
                        </div>
                    )}
                />
            </Col>
            <Col md={6}>
                <h5>{l('We have debt collection conducted by third parties')}</h5>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_ENFORCEDDEBTCOLLECTION_THIRDPARTY)}
                    component={renderCheckboxGroup}
                    renderComponent={({Checkbox, Error}) => (
                        <div>
                            <Checkbox
                                value="switzerland"
                                label={l('in Switzerland')}
                            />

                            <Checkbox
                                value="abroad"
                                label={l('abroad')}
                            />

                            <div>
                                <Error/>
                            </div>
                        </div>
                    )}
                />
            </Col>
        </Row>

        <div className="m-t-md" />

        <h5>{l('Number of collections/legal proceedings initiated in the last 12 months')}</h5>
        <Field
            className="m-t-n-md"
            name={dataFieldKey(CreditOfferRequestFields.SCHEDULE_ENFORCEDDEBTCOLLECTION_OCCURRENCESLASTTWELVEMONTHS)}
            component={renderNumberField}
            label={null}
        />
    </div>
);
