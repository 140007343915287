import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm:ss';
// const MILISECONDS_FORMAT = 'SSS';
const DATETIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;

const END_DAY_TIME = '23:59:59';
// const END_DAY_TIME_MILISECONDS = END_DAY_TIME + '.' + '999';

/**
 * Returns next year relative to current year
 *
 * @return {string}
 */
export const getNextYear = () => {
    const currentYear = getCurrentYear();
    const nextYear = moment(`${currentYear}-01-01`, 'YYYY-MM-DD').add(1, 'years');

    return nextYear.format('YYYY');
};

/**
 * Returns current year
 * @return {string}
 */
export const getCurrentYear = () => {
    return moment().format('YYYY');
};

/**
 * Returns date that is offset for `value` of `unit`
 *  ex. getDateByOffset(2, 'weeks');
 *
 * @param value
 * @param unit
 * @return {Date}
 */
export const getDateByOffset = (value, unit) => {
    const now = moment();
    const next = value < 0 ? now.subtract(Math.abs(value), unit) : now.add(value, unit);

    return next.toDate();
};

/**
 * Returns ISO 8601 date string
 *
 * @param {Date} date
 * @return {string}
 */
export const getISOString = (date) => {
    return moment(date).toISOString();
};

/**
 * Parses date string (uses optional format) and returns date object
 *
 * @param {string} date
 * @param {string} format
 * @return {Date}
 */
export const parseToDateObject = (date, format = undefined) => {
    return moment(date, format).toDate();
};

/**
 * Formats date by format
 *
 * @param date
 * @param format
 */
export const formatDate = (date, format = 'YYYY-MM-DD') => {
    return moment(date).format(format);
};

/**
 * Returns date object set to end of the specified date (23:59:59).
 * Expects date to be in format of `YYYY-MM-DD`
 *
 * @param date
 * @return {Date}
 */
export const toDayEnd = (date) => {
    const endDayDateTime = date + ' ' + END_DAY_TIME;

    return parseToDateObject(endDayDateTime, DATETIME_FORMAT);
};
