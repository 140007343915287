import React from 'react';
import classNames from 'classnames';
import {l} from '../../../../../i18n/translator';
import {Icons} from '../../../../../../config/icons.js';


const ThreadList = ({threads = [], selectedThread, onThreadSelect, className}) => (
    <div className={className}>

        {threads.sort((t1, t2) => {
            let c1 = t1.type.localeCompare(t2.type);
            if (c1 != 0) {
                return c1;
            } else {
                return t1.name.localeCompare(t2.name);
            }
        }).map((thread, index) => (
            <div key={index}
                 className={classNames("chat-user", {
                     'gray-bg': selectedThread == thread.id
                 })}
                 style={{cursor: 'pointer'}}
                 onClick={(e) => onThreadSelect(thread.id)}>
                {thread.unreadMessagesCount > 0 ? (
                    <div className="pull-right" style={{
                        height: '36px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <span className="label label-warning">{thread.unreadMessagesCount}</span>
                    </div>
                ) : null}

                <div className="chat-avatar">
                    <i className={`fa ${Icons.CompanyType[thread.type.toUpperCase()]} fa-2x`}></i>
                </div>

                <div className="chat-user-name">
                        {thread.name}
                </div>
            </div>
        ))}

        {threads.length <= 0 ? (
            <h4>
                {l('No conversations found.')}
            </h4>
        ) : null}

    </div>
);

export default ThreadList;
