/**
 * Created by br0wn on 11/22/16.
 */
import {createAction} from '../../action';
import {fetchLatestMessages, fetchKpi, fetchOfferRequestList} from '../../api/sdk-action';
import {canUserCreateOfferRequest} from '../../auth/helper';


export const TYPE = {
    KPI_LIST_LOADED: 'dashboard.kpi.list_loaded',
    DASHBOARD_LOADING: 'dashboard.loading',
    DASHBOARD_LOADED: 'dashboard.loaded',

    WIDGET_INIT: 'dashboard.widget.init',
    WIDGET_INIT_SUCCESS: 'dashboard.widget.init_success',
    WIDGET_INIT_ERROR: 'dashboard.widget.init_error',
    WIDGET_WORKING: 'dashboard.widget.working',
    WIDGET_ERROR: 'dashboard.widget.error',
    WIDGET_UPDATE: 'dashboard.widget.update',

    WELCOME_MESSAGE_UPDATE: 'dashboard.showWelcomeMessage.update'
};


export const fetchKpiList = () => (dispatch) => {
    return dispatch(
        fetchKpi()
    ).then(kpiValues => {
        const kpiList = Object.keys(kpiValues).map(type => ({type, value: kpiValues[type]}));

        return dispatch(createAction(
            TYPE.KPI_LIST_LOADED, {kpiList}
        ));
    });
};

export const fetchMessages = () => (dispatch) => {
    return dispatch(fetchLatestMessages()).then(messages => messages.slice(0, 10));
};

export const initCustomerDashboard = (auth) => (dispatch) => {
    dispatch(dashboardLoading());

    Promise.all([
        dispatch(hasCustomerOfferRequests())
    ]).then(([hasCustomerOfferRequests]) => {
        dispatch(updateWelcomeMessage({show: !hasCustomerOfferRequests && canUserCreateOfferRequest(auth)}));
        dispatch(dashboardLoaded());
    }).catch(error => {
        dispatch(dashboardLoaded());
        // rethrow
        throw error;
    });
};

export const hasCustomerOfferRequests = () => (dispatch) => dispatch(
    fetchOfferRequestList()
).then(list => {
    return list.totalItems > 0;
});


export const widgetInitialized = (widgetType, data) => createAction(
    TYPE.WIDGET_INIT_SUCCESS, {widgetType, data}
);

export const widgetInitializing = (widgetType) => createAction(
    TYPE.WIDGET_INIT, {widgetType}
);

export const widgetInitError = (widgetType, error) => createAction(
    TYPE.WIDGET_INIT_ERROR, {widgetType, error}
);

export const widgetUpdate = (widgetType, data) => createAction(
    TYPE.WIDGET_UPDATE, {widgetType, data}
);

export const widgetError = (widgetType, error) => createAction(
    TYPE.WIDGET_ERROR, {widgetType, error}
);

export const widgetWorking = (widgetType, isWorking) => createAction(
    TYPE.WIDGET_WORKING, {widgetType, isWorking}
);

export const dashboardLoading = () => createAction(TYPE.DASHBOARD_LOADING);
export const dashboardLoaded = () => createAction(TYPE.DASHBOARD_LOADED);

export const updateWelcomeMessage = (data = {}) => createAction(
    TYPE.WELCOME_MESSAGE_UPDATE, data
);