/**
 * Created by br0wn on 11/2/16.
 */
import {Types as NotificationTypes} from '../../config/domain/notification';
import {Types} from './action';

export const INITIAL_STATE = {
    list: [],
    lastSeen: null
};

export const DEFAULT_NOTIFICATION_META = {
    seen: false
};


/*
 * Selectors
 */

export const getNotificationsList = (state) => {
    return state.notifications.list || [];
};

export const getNewNotificationsCount = (state) => {
    const list = getNotificationsList(state);

    return list.reduce((carry, notification) => {
        if (!notification.meta) {
            return carry;
        }

        return carry + (notification.meta.seen ? 0 : 1);
    }, 0);
};


/*
 * Reducers
 */

const prepareNotificationMeta = (state, notification) => {
    // add meta
    notification = {
        ...notification,
        meta: {
            ...DEFAULT_NOTIFICATION_META,
            seen: state.lastSeen != null && notification.creationDate <= state.lastSeen
        }
    };

    return notification;
};

const updateNotificationInList = (list = [], notification, insertIfNew = true) => {
    // do nothing for heart beat
    if (notification.notificationType == NotificationTypes.HEARTBEAT) {
        return list;
    }

    const notificationIndex = list.findIndex(n => n.id === notification.id);

    if (notificationIndex == -1) {
        if (insertIfNew) {
            // add to top of the list
            list = [notification, ...list];
        }
    } else {
        // update notification in list
        list = [
            ...list.slice(0, notificationIndex),
            {
                // merge with existing
                ...list[notificationIndex],
                ...notification
            },
            ...list.slice(notificationIndex + 1)
        ];
    }

    return list;
};

const markNotificationSeen = (notification) => {
    return {
        // merge with existing
        ...notification,
        meta: {
            ...(notification.meta || DEFAULT_NOTIFICATION_META),
            seen: true
        }
    };
};

const updateLastSeen = (lastSeen, notification) => {
    return notification.creationDate && lastSeen < notification.creationDate ?
        notification.creationDate : lastSeen;
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case  Types.NOTIFICATIONS_LOADED: {
            return {
                ...state,
                list: action.notifications.map(notification => prepareNotificationMeta(state, notification))
            };
        }
        case Types.NOTIFICATION_RECEIVED: {
            const notification = prepareNotificationMeta(state, action.notification);

            return {
                ...state,
                list: updateNotificationInList(state.list, notification)
            };
        }
        case Types.NOTIFICATION_SEEN: {
            const notification = markNotificationSeen(action.notification);

            return {
                ...state,
                list: updateNotificationInList(state.list, notification, false),
                lastSeen: updateLastSeen(state.lastSeen, notification)
            };
        }
        case Types.ALL_NOTIFICATIONS_SEEN: {
            const list = state.list.map(notification => markNotificationSeen(notification));
            const lastSeen = list.reduce((carry, notification) => updateLastSeen(carry, notification), state.lastSeen);

            return {
                ...state, list, lastSeen
            };
        }
    }

    return state;
};

export default notificationsReducer;
