import React from 'react';
import classNames from 'classnames';
import {l} from '../../../i18n/translator.js';
import {States} from '../../../../config/domain/offer.js';
import URI from '../../../../config/uri';
import {fetchInsuranceTypes, fetchOffersList} from '../../../api/sdk-action';
import {BaseWidget} from './widget-base.jsx';
import {getInsuranceTypeName} from '../../common/insurnaceType';
import {redirectToWithoutDispatch} from '../../../router/action';

export class ManualProcessingOffersWidget extends BaseWidget {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        const filterStates = [States.CREATED].join(',');
        const listLength = 10;

        Promise.all([
            this.props.dispatch(fetchInsuranceTypes()),
            this.props.dispatch(fetchOffersList(1, 'state,requiresManualProcessing', filterStates, listLength))
        ]).then(([insuranceTypes, offers]) => {
            this.props.widgetInitialized(this.getType(), {offers: offers.items, insuranceTypes})
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    getType() {
        return 'offers-requiring-manual-processing';
    }

    getTitle() {
        return (
            <span>
                <i className="fa fa-exclamation-triangle"/>&nbsp;
                {l('Offers - Requiring manual processing')}
            </span>
        );
    }

    renderContent() {
        const {data} = this.props;
        const offers = data.offers || [];
        const insuranceTypes = data.insuranceTypes || [];

        const handleClick = (item) => {
            redirectToWithoutDispatch(URI.OFFER_REQUEST_DETAILS.replace(':id', item.offerRequestId));
        };

        return (
            <div className="no-padding">
                <table
                    className={classNames('table table-link-row table-row-mark table-offer-request table-striped table-hover')}>
                    <thead>
                    <tr>
                        <th>{l('Customer name')}</th>
                        <th>{l('Type')}</th>
                        <th>{l('Offer Request #')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        offers.length > 0
                            ? offers.map((item, index) => {
                                return (
                                    <tr key={item.id} onClick={() => handleClick(item)}>
                                        <td><span>{item.customerCompanyName}</span></td>
                                        <td><span>{getInsuranceTypeName(insuranceTypes, item.insuranceTypeId)}</span></td>
                                        <td><span>#{item.offerRequestId}</span></td>
                                    </tr>
                                );
                            })
                            : <tr>
                                <td colSpan="3"><span><i>{l('No offers requiring manual processing')}</i></span></td>
                            </tr>
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}
