import {warn} from '../../../lib/logger';
import {l} from '../../i18n/translator';
import ACTION_TYPES, {PAGE_ACTIONS} from './action/type';
import loadedPageReducer, {PAGE_INITIAL_STATE} from '../common/page/loaded-page/reducer';

const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    form: {
        values: {},
        errors: {}
    },
    isSaving: false,
    isSaved: false
};

/**
 * Reducer for Administration page
 *
 * @param state
 * @param action
 */
const administrationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.SAVE_START: {
            return {
                ...state,
                isSaving: true,
                form: {
                    ...state.form,
                    errors: {}
                }
            };
        }
        case ACTION_TYPES.SAVE_END: {
            let errors = {};
            let isSaved = state.isSaved;

            if (action.error && action.error.validationErrors) {
                errors = {...action.error.validationErrors};
            } else if (action.error && action.error.requestError) {
                alert(l("Unknown response received from server"));
                warn(action.error.requestError);
            } else if (action.error) {
                alert(l("Unknown error occurred"));
                warn(action.error);
            } else {
                isSaved = true;
            }

            return {
                ...state,
                isSaving: false,
                isSaved: isSaved,
                form: {
                    ...state.form,
                    values: (action.formData || state.form.values),
                    errors
                }
            };
        }
        case ACTION_TYPES.CLEAR_SAVED_FLAG: {
            return {
                ...state,
                isSaved: false
            };
        }
        case ACTION_TYPES.FORM_FIELD_CHANGED: {
            let errors = {...state.form.errors};
            delete errors[action.field];

            return {
                ...state,
                form: {
                    ...state.form,
                    values: {
                        ...state.form.values,
                        ...{[action.field]: action.value}
                    },
                    // clear error on user input
                    errors: errors
                }
            };
        }
        case ACTION_TYPES.SET_FORM_DATA: {
            return {
                ...state,
                form: {
                    ...state.form,
                    values: (action.formData || state.form.values)
                }
            };
        }
    }

    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS)
    };
};

export default administrationReducer;
