import {States as OfferStates} from '../../../../config/domain/offer';
import {Fields as OfferRequestFields} from '../../../../config/domain/offerRequest.js';
import {createOffersWithAnalysis, createRatingCalculator} from '../../common/analysis';
import {CriteriaTypes, RatingFields} from '../../../../config/domain/analysis';
import {isCreatedFinishedOffer} from '../../../model/offerRequest';

/**
 * Calculates statistics of offers per insurance
 *
 * @param offerList
 * @param insurances
 */
export const calculateInsuranceOfferStatistics = ({offerRequest, offers, insurances}) => {
    let insurancesStatistics = [];
    offers.forEach((offer) => {
        let existing = offer.insuranceId
            ? insurancesStatistics.find(insurance => insurance.id === offer.insuranceId)
            : insurancesStatistics.find(insurance => insurance.name === offer.insuranceName);
        if (!existing) {
            let insuranceToAdd = offer.insuranceId
                ? insurances.find(insurance => insurance.id === offer.insuranceId)
                : {id: offer.insuranceId, name: offer.insuranceName};
            if (insuranceToAdd) {
                insurancesStatistics.push({...insuranceToAdd});
            }
        }
    });
    insurancesStatistics.sort(function (i1, i2) {
        return i1.name.localeCompare(i2.name);
    });
    const showAllOfferRequests = isCreatedFinishedOffer(offerRequest);
    const utilityAnalysisRankMap = createUtilityAnalysisRankMap(offerRequest);
    const offersWithAnalysis = createOffersWithAnalysis(offerRequest, offers, {onlyPublished: !showAllOfferRequests});

    const offerFilterCheck = showAllOfferRequests
        ? (offer) => offer.state !== OfferStates.WITHDRAWN && offer.state !== OfferStates.REJECTED
        : (offer) => offer.state === OfferStates.PUBLISHED || offer.state === OfferStates.ABSTAINED;

    const statistics = insurancesStatistics.map(insurance => {
        // get insurance offers
        let insuranceOffers = offersWithAnalysis.filter(item => {
            const offer = item.offer;
            if (offerFilterCheck(offer)) {
                if (offer.insuranceId) {
                    return offer.insuranceId === insurance.id;
                } else {
                    return offer.insuranceName === insurance.name;
                }
            }
            return false;
        });

        // calculate offers' stats
        let item = insuranceOffers.reduce((result, item) => {
            const offer = item.offer;
            result.insuranceId = offer.insuranceId;

            // if abstained
            if (offer.state === OfferStates.ABSTAINED) {
                result.abstained = true;
                return result;
            }

            // set best offer
            if (!result.perfectDistance || item.perfectDistance <= result.perfectDistance) {
                result.perfectDistance = item.perfectDistance;
                result.bestOffer = offer;
                result.bestOfferItem = item;
            }

            // set best premium
            if (!result.bestPremium || offer.premiumGross <= result.bestPremium) {
                result.bestPremium = offer.premiumGross;
                result.bestPremiumItem = item;
            }

            // set best coverage
            if (!result.bestCoverage || item.coverageAnalysisTotal >= result.bestCoverage) {
                result.bestCoverage = item.coverageAnalysisTotal;
                result.bestCoverageItem = item;
            }

            // set latest offer time
            if (!result.latestOfferTime || offer.publishDate <= result.latestOfferTime) {
                result.latestOfferTime = offer.publishDate;
            }

            if (offer.isCurrent) {
                result.isCurrent = true;
            }

            return result;
        }, {});

        // set item to object if not defined
        item = item || {};

        // set flag showing if there is statistics data
        item.offerCount = insuranceOffers.filter(item => !item.offer.isCurrent).length;
        item.utilityRank = utilityAnalysisRankMap[insurance.id || insurance.name];

        // return statistics item
        return {...item, ...insurance};
    });

    setRanks(statistics);

    return sortByFloat(statistics, (a) => a.perfectDistance);
};

const createUtilityAnalysisRankMap = (offerRequest) => {
    const ratingCalculator = createRatingCalculator(offerRequest, null, {criteriaType: CriteriaTypes.UTILITY});
    const ratings = offerRequest[OfferRequestFields.UTILITY_ANALYSIS_RATINGS];

    const rankingMap = {};
    const pointsMap = {};

    if (ratings) {
        ratings.forEach((rating) => {
            const insurance = rating.insuranceId || rating.insuranceName;

            if (!pointsMap[insurance]) {
                pointsMap[insurance] = 0;
            }

            const ratingCriteriaId = rating[RatingFields.CRITERIA_ID];
            const ratingRatingData = rating[RatingFields.RATING_DATA];
            if (ratingCriteriaId && insurance && ratingRatingData) {
                pointsMap[insurance] += ratingCalculator.calculatePoints(ratingCriteriaId, ratingRatingData);
            }
        });
        const pointsArray = Object.keys(pointsMap).map((insurance) => {
            return {insurance: insurance, points: pointsMap[insurance]};
        });

        pointsArray.sort((o1, o2) => o2.points - o1.points);
        pointsArray.forEach((item, index) => {
            rankingMap[item.insurance] = index + 1;
        });
    }

    return rankingMap;
};

const setRanks = (statistics) => {
    sortByFloat(statistics, (a) => a.perfectDistance).forEach((item, index) => item.rank = index + 1);
    sortByFloat(statistics, (a) => a.bestPremium).forEach((item, index) => item.premiumRank = index + 1);
    sortByFloatDesc(statistics, (a) => a.bestCoverage).forEach((item, index) => item.coverageRank = index + 1); // the bigger the better!
};

const sortByFloat = (statistics, fieldFn) => {
    return statistics.sort(floatCompareBaseFn(fieldFn));
};
const sortByFloatDesc = (statistics, fieldFn) => {
    return statistics.sort(floatCompareBaseFn(fieldFn, 'desc'));
};

const floatCompareBaseFn = (fieldFn, direction = 'asc') => {
    return (a, b) => {
        const fieldA = fieldFn(a);
        const fieldB = fieldFn(b);
        if (!fieldA && !fieldB) {
            return 0;
        } else if (!fieldA) {
            // if a does not have ranking, it has to go to the end
            // so returned value is as a > b
            return 1;
        } else if (!fieldB) {
            // if b does not have ranking, it has to go to the end
            // so returned value is as a < b
            return -1;
        }

        let floatNumberA = parseFloat(fieldA);
        let floatNumberB = parseFloat(fieldB);

        if (direction === 'asc') {
            return floatNumberA < floatNumberB ? -1 : (floatNumberA > floatNumberB ? 1 : 0);
        } else {
            return floatNumberA < floatNumberB ? 1 : (floatNumberA > floatNumberB ? -1 : 0);
        }
    };
};
