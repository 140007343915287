import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import {l} from '../../../i18n/translator';
import {AutoRatingFields} from '../../../../config/domain/analysis.js';
import {redirectTo} from '../../../router/action.js';
import URI from '../../../../config/uri';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx'
import * as Ibox from '../../ui-components/ibox.jsx';
import {initAutoRatingsList} from './action.js';
import {AnalysisRatingField} from '../../common/form/renderer';

export class AutoRatingsListPage extends React.Component {

    componentWillMount() {
        this.props.actions.init();
    }

    render() {
        const {dispatch, auth, pageState} = this.props;
        const {data} = pageState;

        const autoRatings = [...(data.autoRatings || [])];
        const insuranceTypes = [...(data.insuranceTypes || [])];
        const insuranceTypeNameById = {};
        insuranceTypes.forEach((insuranceType) => insuranceTypeNameById[insuranceType.id] = insuranceType.name);

        const criteria = [...(data.criteria || [])];
        const criteriaById = {};
        criteria.forEach(c => criteriaById[c.id] = c);
        const criteriaNameById = {};
        criteria.forEach(c => criteriaNameById[c.id] = c.name);

        const insurances = [...(data.insurances || [])];
        const insuranceNameById = {};
        insurances.forEach(c => insuranceNameById[c.id] = c.name);

        return (
            <InsurTecLayout>
                <BreadCrumbs title={l('Auto ratings')} icon="bar-chart"/>

                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content">
                        <Row>
                            <Col md={12}>
                                <Ibox.Container>
                                    <Ibox.Title>{l('Auto ratings')}</Ibox.Title>

                                    <Ibox.Content className="p-sm">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th>{l('Insurance type')}</th>
                                                <th>{l('Analysis criteria')}</th>
                                                <th>{l('Insurance')}</th>
                                                <th>{l('General Conditions of Insurance')}</th>
                                                <th>{l('Special Conditions of Insurance')}</th>
                                                <th>{l('Rating')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {autoRatings.map((autoRating, index) => {
                                                const criteria = criteriaById[autoRating[AutoRatingFields.ANALYSIS_CRITERIA_ID]];
                                                let ratingFormatter = criteria
                                                    ? (ratingData) => <AnalysisRatingField ratingData={ratingData}
                                                                                           criteria={criteria}/>
                                                    : (ratingData) => ratingData;

                                                return (
                                                    <tr key={autoRating[AutoRatingFields.ID]}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => dispatch(redirectTo(URI.ANALYSIS_AUTO_RATING_EDIT.replace(':id', autoRating.id)))}>
                                                        <td>{insuranceTypeNameById[autoRating[AutoRatingFields.INSURANCE_TYPE_ID]]}</td>
                                                        <td>{criteriaNameById[autoRating[AutoRatingFields.ANALYSIS_CRITERIA_ID]]}</td>
                                                        <td>{insuranceNameById[autoRating[AutoRatingFields.INSURANCE_ID]]}</td>
                                                        <td>{autoRating[AutoRatingFields.GENERAL_CONDITIONS_OF_INSURANCE_VERSION]}</td>
                                                        <td>{autoRating[AutoRatingFields.SPECIAL_CONDITIONS_OF_INSURANCE_VERSION]}</td>
                                                        <td>{ratingFormatter(autoRating[AutoRatingFields.RATING_DATA])}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>

                                        <Button variant="contained"
                                                color="primary"
                                                onClick={() => dispatch(redirectTo(URI.ANALYSIS_AUTO_RATING_CREATE))}>
                                            {l('Create auto rating')}
                                        </Button>
                                    </Ibox.Content>
                                </Ibox.Container>
                            </Col>
                        </Row>
                    </div>
                </LoadedPage>
            </InsurTecLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        auth: state.auth,
        user: state.auth.user,
        pageState: {...state.page.analysisAutoRatingsList}
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: () => dispatch(initAutoRatingsList())
        },
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoRatingsListPage);
