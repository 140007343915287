import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import * as queryString from 'query-string';
import {Field, FieldArray, reduxForm, formValueSelector, isPristine, submit} from 'redux-form';

import PasswordForm from './components/password.jsx';
import VerifyMobileForm from './components/mobile.jsx';

import URI from '../../../../config/uri';
import {l} from '../../../i18n/translator';

/**
 * VerifyUserPage component
 *
 * @param {object} state The state of the application
 * @param {object} actions Component actions
 */
export class VerifyUserPage extends React.Component {
    render() {
        const {step, location:{search}} = this.props;
        const {token, email} = queryString.parse(search);
        return (
            <div id="wrapper" className="gray-bg" style={{height: '100%'}}>
                <div className="verifyscreen middle-box text-center animated fadeInDown">

                    <div>
                        <img src={require('../../../../resources/images/logo.png')}/>
                    </div>

                    <div>
                        {step == 'user' ? (<PasswordForm token={token} email={email}/>) : null}

                        {step != 'user' ? (
                                <div>
                                    <div>
                                        <h3 className="m-b-lg">{l('Your account has been verified!')}</h3>
                                    </div>
                                    <div>
                                        <Link to={URI.DASHBOARD}>
                                            {l('Start exploring InsurTec')}
                                            <i className="fa fa-arrow-right m-l-sm"></i>
                                        </Link>
                                    </div>
                                </div>
                            ) : null}
                        {step == 'mobile' ? (<VerifyMobileForm token={token} email={email}/>) : null}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
        step: state.page.verifyUser.step
    };
}
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyUserPage);
