import React from 'react';
import {PageForbiddenBox} from '../common/page/forbidden.jsx';
import AppLayout from './app-layout.jsx';


/**
 * NotFoundPage
 *
 */
let ForbiddenPage = () => (
    <AppLayout>
        <PageForbiddenBox/>
    </AppLayout>
);

export default ForbiddenPage;
