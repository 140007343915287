/**
 * Created by br0wn on 1/23/17.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {redirectToWithoutDispatch} from '../../../../router/action';
import {l} from '../../../../i18n/translator';
import URI from '../../../../../config/uri';
import {ACTIONS} from '../../../../../config/domain/entity';
import {EntityActionOnly} from '../../../common/auth/acl/entityAction.jsx';

export const EditButton = ({step, offerRequest = {}}) => (
    <EntityActionOnly action={ACTIONS.EDIT} allowedActions={offerRequest.actions}>
        <Button bsSize="sm" className="pull-right m-t-n-xs" onClick={() => redirectToWithoutDispatch({
            pathname: URI.EDIT_OFFER_REQUEST.replace(':id', offerRequest.id),
            query: {step}
        }) }>
            <i className="fa fa-pencil"/>&nbsp;
            {l('Edit')}
        </Button>
    </EntityActionOnly>
);


export default connect(
    state => ({
        offerRequest: state.page.offerRequestDetails.data.offerRequest || {}
    })
)(EditButton);
