export const Fields = {
    ID: 'id',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    FUNCTION: 'function',
    ADDRESS_LINE1: 'addressLine1',
    ADDRESS_LINE2: 'addressLine2',
    ZIP: 'zip',
    TOWN: 'town',
    COUNTRY: 'country',
    TELEPHONE: 'telephone',
    MOBILE: 'mobile',
    EMAIL: 'email'
};
