/**
 * Created by br0wn on 11/23/16.
 */
import React from 'react';
import classNames from 'classnames';


export const ActivityList = (props) => (
    <div {...props} className={classNames('feed-activity-list', props.className)}>
        {props.children}
    </div>
);

export const Element = (props) => (
    <div {...props} className={classNames('feed-element', props.className)}>
        {props.children}
    </div>
);