/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {l} from '../../../i18n/translator.js';
import URI from '../../../../config/uri.js';
import {fetchInsuranceTypes, fetchOfferRequestList} from '../../../api/sdk-action';
import {BaseWidget} from './widget-base.jsx';
import {
    Column as OfferRequestColumn,
    EndDateCellComponent,
    favoriteValueFormat,
    PremiumValueComponent,
    Table as OfferRequestTable
} from '../../insurance-offer/request-list/component/table.jsx';
import {getInsuranceTypeName} from '../../common/insurnaceType.js';


export class CustomerRequestsForInsuranceWidget extends BaseWidget {

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        const listLength = 10;

        Promise.all([
            this.props.dispatch(fetchInsuranceTypes()),
            this.props.dispatch(fetchOfferRequestList(1, null, null, listLength))
        ]).then(([insuranceTypes, offerRequestList]) => {
            this.props.widgetInitialized(this.getType(), {list: offerRequestList.items, insuranceTypes})
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    getType() {
        return 'requests-customer-insurance';
    }

    getTitle() {
        return (
            <span>
                <i className="fa fa-file-text"/>&nbsp;
                {l('Customer Requests')}
            </span>
        );
    }

    renderContent() {
        const {data} = this.props;
        const list = data.list || [];
        const insuranceTypes = data.insuranceTypes || [];

        return (
            <div className="no-padding">
                <OfferRequestTable items={list} className="m-b-none table-hover table-striped">
                    <OfferRequestColumn name={l('Customer name')} field="customerName"/>
                    <OfferRequestColumn name={l('Type')} field="insuranceTypeId"
                                        valueFormat={(id) => getInsuranceTypeName(insuranceTypes, id)}/>
                    <OfferRequestColumn name={l('Status')} field="state" valueFormat={(state) => l(`state_${state}`)}/>
                    <OfferRequestColumn name={l('Offer round end')} cellComponent={EndDateCellComponent}/>
                    <OfferRequestColumn name={l('Targ. Prem.')} field="targetInsurancePremium" valueComponent={PremiumValueComponent}/>
                    <OfferRequestColumn name={l('Min. offer')} field="offerPremiumGrossMin" valueComponent={PremiumValueComponent}/>
                    <OfferRequestColumn name={l('# Offers')} field="offerCount"/>
                    <OfferRequestColumn name={l('Rank')} field="rank"/>
                    <OfferRequestColumn field="favorite" valueFormat={favoriteValueFormat}/>
                </OfferRequestTable>

                <div className="text-center p-h-xs">
                    <Link to={URI.OFFER_REQUEST_LIST} className="btn btn-default">
                        {l('VIEW ALL')}
                    </Link>
                </div>
            </div>
        )
    }
}
