import React from 'react';
import {DashboardKpiListContainer} from '../kpi/kpi-list.jsx';
import {PendingRequests, BiddingRequests, FinishedRequests, PendingOffers, NewMessages} from '../widget/index';


export class DashboardPage extends React.Component {

    render() {
        return (
            <div className="dashboard--broker">

                {/* TOP WIDGETS */}
                <div className="row wrapper m-t-md">
                    <div className="col-md-12">

                        {/* KPI LIST*/}
                        <DashboardKpiListContainer />

                    </div>
                </div>

                {/* Page content */}
                <div className="row wrapper wrapper-content animated fadeInRight">
                    <div className="col-md-4">
                        <PendingRequests />
                        <PendingOffers />
                    </div>
                    <div className="col-md-4">
                        <BiddingRequests />
                        <NewMessages />
                    </div>
                    <div className="col-md-4">
                        <FinishedRequests />
                    </div>
                </div>
            </div>
        )
    }
}

export default DashboardPage;
