/**
 * Created by br0wn on 10/25/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import URI from '../../../../config/uri.js'
import {l} from'../../../i18n/translator.js';
import AppLayout from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx';
import {UserForm} from '../component/userForm.jsx';
import * as PageActions from '../action/edit';


export class EditUserPage extends React.Component {

    componentWillMount() {
        const {params = {}, actions} = this.props;
        actions.init(params.id);
    }

    componentWillReceiveProps(nextProps) {
        const {params = {}, actions} = this.props;
        const nextParams = nextProps.params || {};

        if (params.id !== nextParams.id) {
            actions.init(nextParams.id);
        }
    }

    componentWillUnmount() {
        const {actions} = this.props;
        actions.cleanup();
    }

    render() {
        const {pageState, actions} = this.props;
        const user = pageState.data.user || null;
        const isEdit = null !== user;

        return (
            <AppLayout>
                <LoadedPage pageState={pageState}>

                    {/* Title and breadcrumbs */}
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-10">
                            {isEdit ? (
                                <h2>{l('Edit user')} #{user.id}</h2>
                            ) : (
                                <h2>{l('Create user')}</h2>
                            )}

                            <ol className="breadcrumb">
                                <li>
                                    <Link to={URI.DASHBOARD}>{l('Dashboard')}</Link>
                                </li>
                                <li className="active">
                                    <Link to={URI.USER_LIST}>{l('Users')}</Link>
                                </li>
                            </ol>
                        </div>
                    </div>

                    {/* Page Content */}
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="ibox">
                            <div className="ibox-content">

                                <UserForm
                                    isEdit={isEdit}
                                    formData={pageState.form}
                                    onSubmit={actions.onSubmit}
                                    onValueChange={actions.onValueChange}
                                />

                            </div>
                        </div>
                    </div>

                </LoadedPage>
            </AppLayout>
        )
    }
}


export default connect(
    state => ({
        i18n: state.i18n,
        pageState: state.page.userEdit
    }),
    dispatch => ({
        actions: {
            init: (...args) => dispatch(PageActions.init(...args)),
            onSubmit: (...args)=> dispatch(PageActions.submitForm(...args)),
            onValueChange: (...args) => dispatch(PageActions.onValueChange(...args)),
            cleanup: () => dispatch(PageActions.cleanup())
        }
    })
)(EditUserPage);
