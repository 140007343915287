import {l} from '../../../../i18n/translator';
import {warn} from '../../../../../lib/logger';
import {fetchOfferRequest} from './api';
import {setApiFormData} from './form';
import TYPES from '../../create-request/action/type';
import {dataLoaded, pageLoadEnd} from '../../create-request/action/page';


export const init = (offerRequestId, token) => {
    return (dispatch) => {
        dispatch({type: TYPES.PAGE_LOAD_START, token: token});

        dispatch(
            fetchOfferRequest(offerRequestId)
        ).then(offerRequest => {
            dispatch(dataLoaded('offerRequest', offerRequest));
            dispatch(setApiFormData(offerRequest));

            // dispatch page loaded
            dispatch(pageLoadEnd({isLoaded: true}));
        }).catch(error => {
            warn("Error while loading offer!", error);

            if (error.notFound || error.forbidden) {
                return dispatch(pageLoadEnd({notFound: true}));
            }

            let errors = [];

            if (error.requestError) {
                errors.push(l("Unknown response received from server"));
            } else {
                errors.push(l("Unknown error occurred"));
            }

            dispatch(pageLoadEnd({errors}));
        });
    };
};

export const loadFile = (fileId, name) => {
    return () => {
        if (!fileId) {
            return Promise.resolve(null);
        }

        return Promise.resolve({
            id: fileId,
            name
        });
    };
};

export {dataLoaded, pageLoadEnd, cleanup} from '../../create-request/action/page';
