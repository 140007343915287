import React from 'react';
import PropTypes from "prop-types";

const Tab = (props) => {
  return (
    <li className={props.isActive(props.name) ? 'active' : ''}>
      <a data-toggle="tab" onClick={() => props.onSelect(props.name)}>
        {props.children}
      </a>
    </li>
  );
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.func,
  onSelect: PropTypes.func
};

export default Tab;
