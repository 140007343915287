import React from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, isPristine, reduxForm, submit} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import {showApiKeyForm, submitApiKey} from '../action.js';
import {l} from '../../../i18n/translator';
import {renderTextField} from '../../common/form/renderer.jsx';

const SimpleForm = (props) => {
    const {createdApiKey, handleSubmit, pristine, reset, submitting} = props;
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md={6}>
                    <div>
                        <Field name="name" component={renderTextField} label={l('Name (optional)')} fullWidth/>
                    </div>
                </Col>
                <Col md={6}>
                    <div>
                        <Field name="ipRange" component={renderTextField} label={l('IP Range')} fullWidth/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div>
                        <Field name="webHookUrl" component={renderTextField} label={l('Webhook URL (optional)')}
                               fullWidth/>
                    </div>
                </Col>
            </Row>
            {createdApiKey.apiKey ? (
                <Row>
                    <Col md={12}>
                        <div>
                            <h2>{l('Your created API Key:')}</h2>
                            <code>{createdApiKey.apiKey}</code>
                        </div>
                    </Col>
                </Row>
            ) : null}
        </form>
    )
};

const selector = formValueSelector('apiKey');
export const ApiKeyForm = connect(
    state => {
        return {
            i18n: state.i18n,
            createdApiKey: state.page.profile.apiKeyModal.apiKey,
            isEdit: state.page.profile.apiKeyModal.apiKey.apiKeyPrefix && !state.page.profile.apiKeyModal.apiKey.apiKey,
        };
    }
)(reduxForm({
    form: 'apiKey',
    onSubmit: submitApiKey
})(SimpleForm));

export class ApiKeyModalComponent extends React.Component {
    render() {
        const {show, isEdit, apiKey = {}, pristine} = this.props;
        const {actions} = this.props;
        return (
            <Dialog open={show} onBackdropClick={actions.close}>
                <DialogTitle>{isEdit ? l('Edit Api Key') : l('Generate New Api Key')}</DialogTitle>
                <DialogContent>
                    <ApiKeyForm initialValues={apiKey}/>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={actions.close}>{l('Close')}</Button>
                    {apiKey.apiKey ? null : (<Button color="primary" disabled={pristine}
                                                     onClick={actions.submit}>{isEdit ? l('Save') : l('Create')}</Button>)}
                </DialogActions>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        form: {...state.form.apiKey},
        pristine: isPristine('apiKey')(state),
        isEdit: state.page.profile.apiKeyModal.apiKey.apiKeyPrefix && !state.page.profile.apiKeyModal.apiKey.apiKey,
        ...state.page.profile.apiKeyModal,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            close: () => dispatch(showApiKeyForm(false)),
            submit: () => dispatch(submit('apiKey'))
        }
    }
}

export const ApiKeyModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ApiKeyModalComponent);
