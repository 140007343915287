import {warn} from '../../../../lib/logger';
import {handleErrorResponse, isBadRequest, isForbidden} from '../../../../lib/http';
import {l} from '../../../i18n/translator';
import API from  '../../../../config/api/routes';
import API_ERRORS from  '../../../../config/api/errors';
import {makeRequest, jsonPostRequest} from '../../../api/request';
import {buildErrors} from '../../common/form/helper';


export const TYPES = {
    SUBMIT_START: 'page.reset_password.submit_start',
    SUBMIT_SUCCESS: 'page.reset_password.submit_success',
    SUBMIT_FAILED: 'page.reset_password.submit_failed'
};


export let submit = (token, user, password, repeatPassword) => {
    return (dispatch) => {
        // dispatch login start
        dispatch({
            type: TYPES.SUBMIT_START
        });

        // validate input
        if (password !== repeatPassword) {
            return dispatch(submitError({
                message: l('Passwords do not match.')
            }));
        }
        if (!password) {
            return dispatch(submitError({
                message: l('Password cannot be empty')
            }));
        }

        // make request
        let request = makeRequest(API.AUTH.NEW_PASSWORD, jsonPostRequest({
            body: JSON.stringify({
                token: token,
                email: user,
                password: password
            })
        }));

        // validate response
        request
            .then(() => dispatch({
                type: TYPES.SUBMIT_SUCCESS
            }))
            .catch(e => handleErrorResponse(e, response => {
                // handle unknown error
                if (!isBadRequest(response) || !isForbidden(response)) {
                    throw e;
                }

                // handle error
                return response.json().then(response => {
                    const errors = buildErrors(response.errors);

                    if (errors.password) {
                        return dispatch(submitError({
                            message: l('Password you entered is not valid. Please try again.')
                        }));
                    }

                    if (response.error == API_ERRORS.AUTH.NEW_PASSWORD.INVALID_TOKEN) {
                        return dispatch(submitError({
                            message: l('User not found.')
                        }));
                    }

                    throw e;
                });
            }))
            .catch(e => {
                // log error
                warn("Fetch error", e);
                // show error message
                dispatch(submitError({
                    message: l('Something went wrong, please reload the page and try again.'),
                    error: e
                }));
            });
    };
};

export let submitError = (error) => {
    return (dispatch) => {
        // error
        dispatch({
            type: TYPES.SUBMIT_FAILED,
            errors: {...error}
        });
    };
};
