import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {fetchAnalysisCriteriaList, fetchInsuranceTypes} from '../../../api/sdk-action';

export const ACTION_TYPES = createPageActionTypes('page.analysis_criteria_list.');

export const initCriteriaList = createPageLoader(
    ACTION_TYPES,
    (dispatch) => {
        return Promise.all([
            dispatch(fetchAnalysisCriteriaList({showInactive: true})),
            dispatch(fetchInsuranceTypes()),
        ]).then(([criteria, insuranceTypes]) => {
            return {criteria, insuranceTypes};
        });
    }
);
