const TYPES = {
    CANCEL_WIZARD: 'page.create_offer_request.wizard.cancel',

    HARM_FILE_UPLOAD_START: 'page.create_offer_request.form.harm_file.upload_start',
    HARM_FILE_UPLOAD_END: 'page.create_offer_request.form.harm_file.upload_end',
    HARM_FILE_UPLOAD_PROGRESS: 'page.create_offer_request.form.harm_file.upload_progress',
    HARM_FILE_SELECTED: 'page.create_offer_request.form.harm_file_selected',
    HARM_FILE_REMOVE_START: 'page.create_offer_request.form.harm_file_remove_start',
    HARM_FILE_REMOVE_END: 'page.create_offer_request.form.harm_file_remove_end',

    ADVISORY_MANDATE_UPLOAD_START: 'page.create_offer_request.form.advisory_mandate.upload_start',
    ADVISORY_MANDATE_UPLOAD_END: 'page.create_offer_request.form.advisory_mandate.upload_end',
    ADVISORY_MANDATE_UPLOAD_PROGRESS: 'page.create_offer_request.form.advisory_mandate.upload_progress',
    ADVISORY_MANDATE_SELECTED: 'page.create_offer_request.form.advisory_mandate_selected',
    ADVISORY_MANDATE_REMOVE_START: 'page.create_offer_request.form.advisory_mandate_remove_start',
    ADVISORY_MANDATE_REMOVE_END: 'page.create_offer_request.form.advisory_mandate_remove_end',

    SAVE_START: 'page.create_offer_request.save_start',
    SAVE_END: 'page.create_offer_request.save_end',
    CLEAR_SAVED_FLAG: 'page.create_offer_request.clear_saved_flag',

    SET_FORM_DATA: 'page.create_offer_request.form.set_data',
    SCROLL_TO_FIELD: 'page.create_offer_request.form.scroll_to_field',

    VALIDATE_START: 'page.create_offer_request.validate_start',
    VALIDATE_END: 'page.create_offer_request.validate_end',
    CLEAR_VALIDATE_FLAG: 'page.create_offer_request.clear_validate_flag',

    PUBLISH_START: 'page.create_offer_request.publish_start',
    PUBLISH_END: 'page.create_offer_request.publish_end',

    DATA_LOADED: 'page.create_offer_request.data_loaded',

    PAGE_LOAD_START: 'page.create_offer_request.load_start',
    PAGE_LOAD_END: 'page.create_offer_request.load_end',
    PAGE_RESET: 'page.create_offer_request.reset'
};

export const HARM_FILE_UPLOAD_ACTIONS = {
    UPLOAD_START: TYPES.HARM_FILE_UPLOAD_START,
    UPLOAD_END: TYPES.HARM_FILE_UPLOAD_END,
    UPLOAD_PROGRESS: TYPES.HARM_FILE_UPLOAD_PROGRESS,
    REMOVE_FILE_START: TYPES.HARM_FILE_REMOVE_START,
    REMOVE_FILE_END: TYPES.HARM_FILE_REMOVE_END,
    FILE_SELECTED: TYPES.HARM_FILE_SELECTED
};

export const ADVISORY_MANDATE_UPLOAD_ACTIONS = {
    UPLOAD_START: TYPES.ADVISORY_MANDATE_UPLOAD_START,
    UPLOAD_END: TYPES.ADVISORY_MANDATE_UPLOAD_END,
    UPLOAD_PROGRESS: TYPES.ADVISORY_MANDATE_UPLOAD_PROGRESS,
    REMOVE_FILE_START: TYPES.ADVISORY_MANDATE_REMOVE_START,
    REMOVE_FILE_END: TYPES.ADVISORY_MANDATE_REMOVE_END,
    FILE_SELECTED: TYPES.ADVISORY_MANDATE_SELECTED
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    DATA_LOADED: TYPES.DATA_LOADED,
    PAGE_RESET: TYPES.PAGE_RESET
};

export default TYPES;
