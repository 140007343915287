import React from 'react';
import {connect} from 'react-redux';
import {CompanyTypes as UserTypes} from '../../../config/domain/user';
import {getUserType, getUser} from '../../auth/helper.js';
import AppLayout from '../layout/app-layout.jsx';
import Spinner from '../common/misc/spiner.jsx';
import CustomerDashboard from './dashboard-type/customerDashboard.jsx';
import InsuranceDashboard from './dashboard-type/insuranceDashboard.jsx';
import BrokerDashboard from './dashboard-type/brokerDashboard.jsx';


const getUserDashboard = (auth) => {
    if (null === getUser(auth)) {
        // user not loaded yet
        return (<Spinner/>)
    }

    switch (getUserType(auth)) {
        case UserTypes.CUSTOMER: {
            return (<CustomerDashboard/>);
        }
        case UserTypes.INSURANCE: {
            return (<InsuranceDashboard/>);
        }
        case UserTypes.BROKER: {
            return (<BrokerDashboard/>);
        }
        default: {
            return (<div>User type is unknown.</div>);
        }
    }
};


const DashboardPage = ({auth}) => {
    const dashboard = getUserDashboard(auth);

    return (
        <AppLayout>
            {dashboard}
        </AppLayout>
    )
};


export default connect(state => ({auth: state.auth, i18n: state.i18n}))(DashboardPage);
