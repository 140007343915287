import React from 'react'
import {Link} from 'react-router-dom';
import {l} from '../../../../i18n/translator';
import URI from '../../../../../config/uri';

const PublishSuccess = () => {
    return (
        <div>
            <div className="middle-box text-center animated fadeInDown" style={{marginTop: '0px'}}>
                <h2 className="font-bold">
                    <i className="fa fa-thumbs-up"></i>
                    &nbsp;
                    {l('Offer request updated')}
                </h2>

                <div className="error-desc">
                    {l('Thank you for updating this offer request details. You can now close the page or click on the link bellow to go to dashboard.')}

                    <br /><br />
                    <Link to={URI.DASHBOARD}>
                        <div className="btn btn-primary">
                            {l('Go to dashboard')}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default PublishSuccess;
