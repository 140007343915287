import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {l} from '../../../../i18n/translator';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {STEP_BASIC_DATA} from '../../create-request/config/wizard';
import EditButton from './editButton.jsx';

const Contact = ({i18n, customerName, contact = {}, businessId = null, customerIndustryClassificationCode = null}) => {
    const Line = ({title, children, className}) => (
        <Row className={className}>
            <Col md={4}><strong>{title}</strong></Col>
            <Col md={8}>{children}</Col>
        </Row>
    );
    const LineOptional = ({title, children, className}) => children ? (
        <Line title={title} className={className}>{children}</Line>) : null;

    return (
        <Ibox.Container>
            <Ibox.Title>
                <h5>
                    <i className="fa fa-user"/>&nbsp;
                    {l('Customer details')}
                </h5>

                <EditButton step={STEP_BASIC_DATA}/>
            </Ibox.Title>

            <Ibox.Content>
                {customerName ? (
                    <div>
                        <h4>{l('Company name')}</h4>
                        <div className="m-b-md">{customerName}</div>
                    </div>
                ) : null}

                <Line title={l('BIN')}>{businessId}</Line>
                <LineOptional title={l('NOGA-Code')}>{customerIndustryClassificationCode}</LineOptional>

                <div  className="m-t-md"/>

                <h4>{l('Contact')}</h4>
                <Line title={l('Name')}>{contact.firstName + ' ' + contact.lastName}</Line>
                <LineOptional title={l('Function')}>{contact.function}</LineOptional>
                <LineOptional title={l('Mobile')}>{contact.mobile}</LineOptional>
                <LineOptional title={l('Phone')}>{contact.phone}</LineOptional>
                <Line title={l('Email')}><a href={`mailto:${contact.email}`}>{contact.email}</a></Line>

                <div  className="m-t-md"/>

                <h4>{l('Address')}</h4>
                <Row>
                    <Col md={4}><strong>{l('Office Address')}</strong></Col>
                    <Col md={8}>
                        <address>
                            {contact.addressLine1} {contact.addressLine2}<br />
                            {contact.zip} {contact.town} {contact.country ? ', ' + contact.country : null}
                        </address>
                    </Col>
                </Row>
            </Ibox.Content>
        </Ibox.Container>
    );
};

export default Contact;
