import React from 'react';
import {withStyles} from "@material-ui/core/styles";

import {calcPremiumPercentage, CRITERIA_ID_PREMIUM, formatPercentageFloat} from '../../analysis';
import {AnalysisBaseForm, AnalysisForm, styles} from './analysis.form.jsx'
import {CriteriaFields, CriteriaTypes, RatingFields} from '../../../../../config/domain/analysis';

export class PremiumAnalysisBaseForm extends AnalysisBaseForm {
    constructor(props) {
        super(props);
    }

    // override
    getAnalysisType() {
        return CriteriaTypes.PREMIUM;
    }

    getNonEditableCriteriaIds() {
        return [CRITERIA_ID_PREMIUM];
    }

    onCriteriaChange(criteria, {ratings}) {
        this.updatePremiumWeight(criteria);
        super.onCriteriaChange(criteria, {ratings});
    }

    renderPoints({criteria, rating, points, ratingCalculator}) {
        if (criteria && criteria[CriteriaFields.ID] === CRITERIA_ID_PREMIUM) return formatPercentageFloat(1);
        if (!criteria) { // total
            return formatPercentageFloat(1 + points);
        }

        let ratingData = rating && rating[RatingFields.RATING_DATA];
        let ratingNumber = ratingCalculator.calculateRating(criteria[CriteriaFields.ID], ratingData);
        return formatPercentageFloat(calcPremiumPercentage(criteria[CriteriaFields.WEIGHT], ratingNumber));
    }

    // new functions

    updatePremiumWeight(criteria) {
        const sum = criteria.reduce((acc, item, index) => {
            if (item[CriteriaFields.ID] !== CRITERIA_ID_PREMIUM && item[CriteriaFields.WEIGHT]) {
                return parseFloat(item.weight) + acc;
            }
            return acc;
        }, 0);

        const premiumCriteriaIndex = criteria.findIndex(c => c[CriteriaFields.ID] === CRITERIA_ID_PREMIUM);
        if (premiumCriteriaIndex >= 0) {
            criteria.splice(premiumCriteriaIndex, 1, {
                ...criteria[premiumCriteriaIndex],
                weight: 1 - sum
            });
        }
    }
}


export const PremiumAnalysisForm = withStyles(styles)(PremiumAnalysisBaseForm);

PremiumAnalysisForm.propTypes = AnalysisForm.propTypes;
