import ACTION_TYPES, {PAGE_ACTIONS} from '../create-request/action/type';


const INITIAL_STATE = {
    token: null,
    isPublished: false
};


/*
 * Selectors
 */
export const getPageState = state => state.page.updateOfferRequest;

export const getToken = state => getPageState(state).token;

export const getFormPageState = state => state.page.createOfferRequest;

export const getFormData = state => getFormPageState(state).form;

export const getHarmFileData = state => getFormPageState(state).harmFileUpload;

export const getData = state => getFormPageState(state).data;


/**
 * Root reducer for UpdateOfferRequest page
 *
 * @param state
 * @param action
 */
let updateOfferRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.PUBLISH_END: {
            if (action.error) {
                return state;
            }

            return {
                ...state, isPublished: true
            };
        }
        case PAGE_ACTIONS.PAGE_LOAD_START: {
            return {...state, token: action.token};
        }
        case ACTION_TYPES.PAGE_RESET: {
            return {...INITIAL_STATE};
        }
    }

    return state;
};

export default updateOfferRequestReducer;
