export const PAGE_INITIAL_STATE = {
    isLoading: false,
    isLoaded: false,
    notFound: false,
    forbidden: false,
    loadErrors: [],
    data: {}
};

let loadedPageReducer = (state = PAGE_INITIAL_STATE, action, ACTION_TYPES) => {
    switch (action.type) {
        case ACTION_TYPES.PAGE_LOAD_START: {
            return {
                ...PAGE_INITIAL_STATE,
                isLoading: true
            };
        }
        case ACTION_TYPES.PAGE_LOAD_END: {
            return {
                ...state,
                isLoading: false,
                isLoaded: !!action.isLoaded,
                loadErrors: action.errors || [],
                notFound: !!action.notFound,
            };
        }
        case ACTION_TYPES.PAGE_RESET: {
            return {
                ...PAGE_INITIAL_STATE
            };
        }
        case ACTION_TYPES.DATA_LOADED: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                }
            };
        }
    }

    return state;
};

export default loadedPageReducer;
