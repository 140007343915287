import {warn} from '../../../../lib/logger';
import {l} from '../../../i18n/translator';
import {objectMerge} from '../../../helper/core';
import singleFileUploadReducer from '../../common/form/file-upload/reducer/singleFileUploadReducer';
import loadedPageReducer, {PAGE_INITIAL_STATE} from '../../common/page/loaded-page/reducer';
import ACTION_TYPES, {ADVISORY_MANDATE_UPLOAD_ACTIONS, HARM_FILE_UPLOAD_ACTIONS, PAGE_ACTIONS} from './action/type';

const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    data: {
        insuranceTypes: [],
        insurances: [],
        brokers: []
    },
    form: {
        values: {},
        errors: {},
        isDirty: false
    },
    harmFileUpload: undefined,
    advisoryMandateUpload: undefined,
    isSaving: false,
    isSaved: false,
    isPublishing: false,
    isPublished: false
};


export const getPageState = (state) => state.page.createOfferRequest;

export const getHarmFile = (state) => getPageState(state).harmFileUpload.uploadedFile;

export const getAdvisoryMandate = (state) => getPageState(state).advisoryMandateUpload.uploadedFile;


/**
 * Root reducer for CreateOfferRequest page
 *
 * @param state
 * @param action
 */
let createOfferRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.SAVE_START: {
            return {
                ...state,
                isSaving: true
            };
        }
        case ACTION_TYPES.CLEAR_SAVED_FLAG: {
            return {
                ...state,
                isSaved: false
            };
        }
        case ACTION_TYPES.SAVE_END: {
            let isSaved = state.isSaved;

            if (action.error && action.error.requestError) {
                alert(l("Unknown response received from server"));
                warn(action.error.requestError);
            } else if (action.error) {
                alert(l("Unknown error occurred"));
                warn(action.error);
            } else {
                isSaved = true;
            }

            return {
                ...state,
                isSaving: false,
                isSaved: isSaved
            };
        }
        case ACTION_TYPES.VALIDATE_START: {
            return {
                ...state,
                form: {
                    ...state.form,
                    errors: {}
                },
                isValidating: true
            };
        }
        case ACTION_TYPES.VALIDATE_END: {
            return {
                ...state,
                isValidating: false,
                isValidated: !!action.isValidated
            };
        }
        case ACTION_TYPES.CLEAR_VALIDATE_FLAG: {
            return {...state, isValidated: false};
        }
        case ACTION_TYPES.PUBLISH_START: {
            return {
                ...state,
                form: {
                    ...state.form,
                    errors: {}
                },
                isPublishing: true
            };
        }
        case ACTION_TYPES.PUBLISH_END: {
            let errors = {};

            if (action.error && action.error.validationErrors) {
                errors = {...action.error.validationErrors};
            }
            else if (action.error && action.error.requestError) {
                alert(l("Unknown response received from server"));
                warn(action.error.requestError);
            } else if (action.error) {
                alert(l("Unknown error occurred"));
                warn(action.error);
            }

            return {
                ...state,
                form: {
                    ...state.form,
                    errors
                },
                isPublishing: false,
                isPublished: !!action.isPublished
            };
        }
        case ACTION_TYPES.CANCEL_WIZARD: {
            return {
                ...state,
                form: {
                    values: {},
                    errors: {}
                }
            };
        }
        case ACTION_TYPES.SET_FORM_DATA: {
            let values = state.form.values;

            if (action.update == true) {
                values = objectMerge(values, (action.formData || {}));
            } else {
                values = (action.formData || {});
            }

            return {
                ...state,
                form: {
                    ...state.form, values
                }
            };
        }
        case ACTION_TYPES.PAGE_RESET: {
            return {
                ...INITIAL_STATE,
                ...loadedPageReducer(state, action, PAGE_ACTIONS)
            };
        }
    }

    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS),
        harmFileUpload: singleFileUploadReducer(state.harmFileUpload, action, HARM_FILE_UPLOAD_ACTIONS),
        advisoryMandateUpload: singleFileUploadReducer(state.advisoryMandateUpload, action, ADVISORY_MANDATE_UPLOAD_ACTIONS)
    };
};

export default createOfferRequestReducer;
