import DEFAULT_TYPES from '../action/type';
import standardChatReducer from './standard';

export const INITIAL_STATE = {
    threads: [],
    selectedThread: null,
    isLoadingThreadList: false,
    isThreadListLoaded: false,
    threadStates: {},
    status: null
};

const updateThread = (threads = [], thread) => {
    const index = threads.findIndex(t => t.id == thread.id);

    if (index === -1) {
        return [...threads, thread];
    }

    return [
        ...threads.slice(0, index),
        {...threads[index], ...thread},
        ...threads.slice(index + 1)
    ];
};

const threadedChatReducer = (state = INITIAL_STATE, action, ACTION_TYPES = DEFAULT_TYPES) => {
    switch (action.type) {
        case ACTION_TYPES.THREAD_SELECTED: {
            const {threadId} = action;

            return {
                ...state,
                threads: updateThread(state.threads, {id: threadId, unreadMessagesCount: 0}),
                selectedThread: threadId
            };
        }
        case ACTION_TYPES.THREAD_LIST_LOAD_START: {
            return {
                ...state,
                isLoadingThreadList: true
            };
        }
        case ACTION_TYPES.THREAD_LIST_LOAD_END: {
            let threads = [...state.threads];

            if (action.error) {
                // handle error
            } else {
                threads = action.threads;
            }

            return {
                ...state,
                isLoadingThreadList: false,
                isThreadListLoaded: true,
                threads
            };
        }
        case ACTION_TYPES.THREAD_MESSAGES_LOAD_START: {
            return {
                ...state,
                threadStates: {
                    ...state.threadStates,
                    [action.threadId]: standardChatReducer(state.threadStates[action.threadId], action, ACTION_TYPES)
                }
            };
        }
        case ACTION_TYPES.THREAD_MESSAGES_LOAD_END: {
            return {
                ...state,
                threadStates: {
                    ...state.threadStates,
                    [action.threadId]: standardChatReducer(state.threadStates[action.threadId], action, ACTION_TYPES)
                }
            };
        }
        case ACTION_TYPES.MESSAGE_SUBMIT_START: {
            return {
                ...state,
                threadStates: {
                    ...state.threadStates,
                    [action.threadId]: standardChatReducer(state.threadStates[action.threadId], action, ACTION_TYPES)
                }
            };
        }
        case ACTION_TYPES.MESSAGE_SUBMIT_END: {
            return {
                ...state,
                threadStates: {
                    ...state.threadStates,
                    [action.threadId]: standardChatReducer(state.threadStates[action.threadId], action, ACTION_TYPES)
                }
            };
        }
        case ACTION_TYPES.CLEAR_MESSAGE_SENT: {
            return {
                ...state,
                threadStates: {
                    ...state.threadStates,
                    [action.threadId]: standardChatReducer(state.threadStates[action.threadId], action, ACTION_TYPES)
                }
            };
        }
        case ACTION_TYPES.MESSAGE_RECEIVED: {
            let {threads, threadStates, selectedThread} = state;
            let thread = threads.find(t => t.id == action.threadId);

            if (!thread) {
                break;
            }

            // update message count if thread is not selected
            if (selectedThread !== thread.id) {
                thread = {...thread, unreadMessagesCount: (thread.unreadMessagesCount || 0) + 1};
                threads = updateThread(threads, thread);
            }

            // update messages if thread loaded
            if (threadStates[thread.id]) {
                threadStates = {
                    ...threadStates,
                    [thread.id]: standardChatReducer(threadStates[thread.id], action, ACTION_TYPES)
                };
            }

            return {
                ...state,
                threadStates,
                threads
            };
        }
    }

    return state;
};

export default threadedChatReducer;