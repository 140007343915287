/**
 * Created by pboos on 10/25/17.
 */
import React from 'react';
import {injectIntl} from 'react-intl';
import {Bubble, Chart} from 'react-chartjs-2';
import {l} from '../../../../../i18n/translator';
import {Fields as OfferFields} from '../../../../../../config/domain/offer';
import {colorForId} from '../../../../common/analysis.js';

class OverallAnalysisGraphComponent extends React.Component {
    getInsuranceName(insuranceId) {
        const insurance = this.props.insurances.find((insurance) => insurance.id === insuranceId);
        return insurance ? insurance.name : insuranceId;
    }

    getInsuranceId(offer) {
        return offer.insuranceId || offer.insuranceName;
    }

    structureBubbleChartData(offerRequest, offersWithAnalysis) {
        const {intl, actions} = this.props;

        const dataSets = [];

        offersWithAnalysis.forEach((offerWithInfo, index) => {
            const offer = offerWithInfo.offer;
            const insuranceId = this.getInsuranceId(offer);
            let dataSet = dataSets.find((dataSet) => dataSet.insuranceId === insuranceId);
            if (!dataSet) {
                dataSet = {
                    insuranceId: insuranceId, // needed for the find (combine same insurance offers into same dataset)
                    label: this.getInsuranceName(insuranceId),
                    isCurrent: offer.isCurrent,
                    data: [],
                    backgroundColor: colorForId(insuranceId.toString(), 1),
                };
                dataSets.push(dataSet);
            }
            const premium = offerWithInfo.offer[OfferFields.PREMIUM_GROSS];
            const bubbleRadius = offer.isCurrent ? 14 : 7;
            dataSet.data.push({x: offerWithInfo.coverageAnalysisTotal, y: premium, r: bubbleRadius, offerIndex: index});
        });

        // update to sort correctly (lowest premium to top)
        dataSets.forEach(dataSet => {
            for (let i = 0; i < dataSet.data.length; i++) {
                const y = dataSet.data[i].y;
                dataSet.data[i].y = -y;
            }
        });
        const minPremium = Math.min.apply(null, offersWithAnalysis.map((offerWithInfo) =>
            Math.min(offerWithInfo.adjustedPremium, offerWithInfo.offer[OfferFields.PREMIUM_GROSS])));
        const maxPremium = Math.max.apply(null, offersWithAnalysis.map((offerWithInfo) =>
            Math.max(offerWithInfo.adjustedPremium, offerWithInfo.offer[OfferFields.PREMIUM_GROSS])));
        return {
            type: 'bubble',
            data: {
                datasets: dataSets
            },
            options: {
                layout: {
                    padding: 15 // needs to be the same as biggest radius
                },
                legend: {
                    fillColor: "rgba(230,177,55,1)",
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {display: true, labelString: l('Couverage Incentive')},
                        scaleShowLabels: false,
                        ticks: {suggestedMin: 0, suggestedMax: 10}
                    }],
                    yAxes: [{
                        scaleLabel: {display: true, labelString: l('Premium Incentive')},
                        ticks: {
                            // display: false,
                            suggestedMax: -minPremium + (maxPremium - minPremium) * 0.1,
                            suggestedMin: -maxPremium - (maxPremium - minPremium) * 0.1
                        },
                        gridLines: {
                            display: false
                        },
                        afterTickToLabelConversion: function (q) {
                            for (const tick in q.ticks) {
                                const premium = Math.abs(q.ticks[tick]);
                                q.ticks[tick] = `${intl.formatNumber(premium, {
                                    style: 'decimal',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })} CHF`;
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const dataSet = data.datasets[tooltipItem.datasetIndex];
                            const premium = Math.abs(dataSet.data[tooltipItem.index].y);
                            return `${dataSet.label}: ${intl.formatNumber(premium, {
                                style: 'decimal',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })} CHF`;
                        }
                    }
                },
                animation: {
                    duration: 1 // needed so that chart.chart.controller is not undefined in afterDraw
                }
            },
            plugins: [{
                beforeDraw: function (chart, easing) {
                    const helpers = Chart.helpers;
                    const ctx = chart.chart.ctx;
                    const chartArea = chart.chartArea;

                    const width = chartArea.right - chartArea.left;
                    const height = chartArea.bottom - chartArea.top;
                    const size = Math.max(width, height);

                    const gradient = ctx.createLinearGradient(chartArea.left, chartArea.top + size, chartArea.left + size, chartArea.top);
                    gradient.addColorStop(0.0, 'rgba(244, 119, 66, 0.5)');
                    gradient.addColorStop(0.6, 'rgba(255, 233, 71, 0.5)');
                    gradient.addColorStop(1.0, 'rgba(78, 255, 66, 0.5)');

                    ctx.save();
                    // gradient
                    ctx.fillStyle = gradient;//chart.config.options.chartArea.backgroundColor;
                    ctx.fillRect(chartArea.left, chartArea.top, width, height);

                    // cross
                    ctx.beginPath();
                    ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';
                    ctx.moveTo(chartArea.left, chartArea.top + height / 2);
                    ctx.lineTo(chartArea.right, chartArea.top + height / 2);

                    ctx.moveTo(chartArea.left + width / 2, chartArea.top);
                    ctx.lineTo(chartArea.left + width / 2, chartArea.bottom);
                    ctx.stroke();

                    // ctx.font
                    const padding = 10;
                    ctx.font = '24px "gibsonregular", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif';
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'top';
                    ctx.fillText(l('Decoy bird'), chartArea.left + padding, chartArea.top + padding);
                    ctx.textAlign = 'right';
                    ctx.fillText(l('Star'), chartArea.right - padding, chartArea.top + padding);
                    ctx.textBaseline = 'bottom';
                    ctx.fillText(l('Snob'), chartArea.right - padding, chartArea.bottom - padding);
                    ctx.textAlign = 'left';
                    ctx.fillText(l('Flop'), chartArea.left + padding, chartArea.bottom - padding);
                    ctx.restore();
                },
                afterDraw: (chart) => {
                    if (!chart.chart.controller) {
                        return;
                    }

                    const yAxis = chart.scales['y-axis-0'];
                    const ctx = chart.chart.ctx;
                    ctx.save();
                    ctx.font = '12px "gibsonregular", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif';
                    ctx.lineWidth = 2;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    chart.data.datasets.forEach(function (dataset, i) {
                        const meta = chart.chart.controller.getDatasetMeta(i);
                        meta.data.forEach(function (item, index) {
                            if (!chart.isDatasetVisible(i)) {
                                return;
                            }

                            const data = dataset.data[index];
                            ctx.strokeStyle = dataset.backgroundColor;

                            ctx.beginPath();
                            // -adjustedPremium, because we flipped everything to minus
                            const offerWithAnalysis = offersWithAnalysis[data.offerIndex];
                            if (offerWithAnalysis) {
                                const adjustedY = yAxis.getPixelForValue(-offerWithAnalysis.adjustedPremium);
                                if (item._model.y - adjustedY !== 0) {
                                    ctx.moveTo(item._model.x, item._model.y);
                                    ctx.lineTo(item._model.x, adjustedY);
                                    ctx.moveTo(item._model.x - 5, adjustedY);
                                    ctx.lineTo(item._model.x + 5, adjustedY);
                                    ctx.stroke();
                                }
                            }
                        });
                    });
                    ctx.restore();
                },
                afterEvent: function (chart, event) {
                    const x = event.x;
                    const y = event.y;

                    if (event.type === 'click') {
                        const element = chart.getElementAtEvent(event);
                        if (element && element.length > 0) {
                            // click on criteria rating
                            const datasetIndex = element[0]._datasetIndex;
                            const dataIndex = element[0]._index;

                            const data = chart.data.datasets[datasetIndex].data[dataIndex];
                            const offerIndex = data.offerIndex;
                            const offer = offersWithAnalysis[offerIndex].offer;
                            if (actions && actions.onOfferClick) {
                                actions.onOfferClick(offer);
                            }
                        }
                    }
                }
            }]
        };
    };

    render() {
        let {offerRequest, offersWithAnalysis} = this.props;
        let bubbleChartData = this.structureBubbleChartData(offerRequest, offersWithAnalysis);
        return (<Bubble {...bubbleChartData} />);
    }
}

export const OverallAnalysisGraph = injectIntl(OverallAnalysisGraphComponent);
