import moment from 'moment';

import {
    CurrentInsuranceFields,
    Fields as OfferRequestFields,
    States as OfferRequestStates
} from '../../../config/domain/offerRequest.js';
import {getUser} from '../../auth/helper';
import {getDateByOffset, parseToDateObject} from '../../helper/date';
import {Fields as OfferFields} from '../../../config/domain/offer.js';

/**
 * Sorts offer list by publish/creation data
 *
 * @param {Array} offerList
 * @param {'asc'|'desc'} order
 * @return {Array}
 */
export const sortOfferList = (offerList, order = 'asc') => {
    let direction = order === 'asc' ? 1 : -1;

    return [...offerList].sort((a, b) => {
        a = moment(a.publishDate || a.creationDate);
        b = moment(b.publishDate || b.creationDate);

        return direction * (a > b ? 1 : (a < b ? -1 : 0));
    });
};


/**
 * Returns true if offer request is ending in supplied time span.
 * For time span structure  @see getDateByOffset
 *
 * @param {object} offerRequest
 * @param {[int, string]} timeSpan
 * @return {boolean}
 */
export const isOfferRequestEndingIn = (offerRequest, timeSpan = [2, 'days']) => {
    const endDate = parseToDateObject(offerRequest[OfferRequestFields.OFFER_ROUND_END_DATE]);
    const timeSpanFromNow = getDateByOffset(...timeSpan);

    return endDate < timeSpanFromNow && !hasOfferRequestPassedEndDate(offerRequest);
};


/**
 * Returns true if offerRequest passed end date
 *
 * @param {object} offerRequest
 * @return {boolean}
 */
export const hasOfferRequestPassedEndDate = (offerRequest) => {
    const endDate = parseToDateObject(offerRequest[OfferRequestFields.OFFER_ROUND_END_DATE]);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);

    return endDate < (new Date());
};


/**
 * Returns offerRequest savings in percentage
 *
 * @param offerRequest
 * @return {int|null}
 */
export const getOfferRequestSavings = (offerRequest) => {
    const currentInsurance = (offerRequest || {})[OfferRequestFields.CURRENT_INSURANCE];
    if (!currentInsurance || currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS] === 0 || !offerRequest[OfferRequestFields.OFFER_PREMIUM_GROSS_MIN]) {
        return null;
    }

    let percent = currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS] - offerRequest[OfferRequestFields.OFFER_PREMIUM_GROSS_MIN];
    percent = percent / currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS];
    percent = percent * 100;

    return Math.round(percent);
};

/**
 * Returns offer that are created by specified user.
 *
 * @param offerList
 * @param auth
 * @return {Array}
 */
export const getOwnOffers = (offerList = [], auth) => {
    const user = getUser(auth);
    return user
        ? offerList.filter(offer =>
            offer[OfferFields.CREATOR_COMPANY_ID] == user.companyId
            && !offer[OfferFields.IS_CURRENT]
        )
        : [];
};

/**
 * Returns offer that was selected by customer
 *
 * @param offerList
 * @return {object|undefined}
 */
export const getSelectedOffer = (offerList) => {
    return offerList.find(offer => offer[OfferFields.OFFICIAL_OFFER_REQUESTED]);
};

/**
 *
 * @param offerRequest
 */
export const isOfferRequestFinished = (offerRequest) => offerRequest[OfferRequestFields.STATE] === OfferRequestStates.EXPIRED;
