import React from 'react';
import PropTypes from "prop-types";

const TabContent = (props) => {
  const tabPanes = React.Children.map(props.children,
    (child) => React.cloneElement(child, {isActive: props.isActive})
  );
  return (
    <div className="tab-content">
      {tabPanes}
    </div>
  );
};

TabContent.componentName = 'TabContent';

TabContent.propTypes = {
  componentName: PropTypes.oneOf([TabContent.componentName]),
  children: PropTypes.node.isRequired,
  isActive: PropTypes.func
};

TabContent.defaultProps = {
  componentName: TabContent.componentName
};

export default TabContent;
