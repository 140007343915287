import loadedPageReducer, {PAGE_INITIAL_STATE} from '../../common/page/loaded-page/reducer';
import {ACTION_TYPES} from './action';

const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    isLoading: false,
    isLoaded: false,
    loadErrors: [],
    data: {
        criteria: [],
        insuranceTypes: []
    }
};

let analysisCriteriaListReducer = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ...loadedPageReducer(state, action, ACTION_TYPES)
    };
};

export default analysisCriteriaListReducer;
