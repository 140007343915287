import {createAction} from '../../../../action';
import {warn} from '../../../../../lib/logger';
import {l} from '../../../../i18n/translator';

const PAGE_ACTION_TYPES_POSTFIX = {
    PAGE_LOAD_START: 'load_start',
    PAGE_LOAD_END: 'load_end',
    PAGE_RESET: 'reset',
    DATA_LOADED: 'data_loaded'
};

export const createPageActionTypes = (prefix) => {
    return {
        PAGE_LOAD_START: prefix + PAGE_ACTION_TYPES_POSTFIX.PAGE_LOAD_START,
        PAGE_LOAD_END: prefix + PAGE_ACTION_TYPES_POSTFIX.PAGE_LOAD_END,
        PAGE_RESET: prefix + PAGE_ACTION_TYPES_POSTFIX.PAGE_RESET,
        DATA_LOADED: prefix + PAGE_ACTION_TYPES_POSTFIX.DATA_LOADED
    };
};

export const createPageLoader = (PAGE_ACTION_TYPES, loaderPromise) => (input) => (dispatch) => {
    dispatch(createAction(PAGE_ACTION_TYPES.PAGE_LOAD_START, {}));

    loaderPromise(dispatch, input).then((data) => {
        dispatch(createAction(PAGE_ACTION_TYPES.DATA_LOADED, {data}));
        dispatch(createAction(PAGE_ACTION_TYPES.PAGE_LOAD_END, {isLoaded: true}));
    }).catch(error => {
        warn("Error while fetching resources!", error);
        let errors = [];

        if (error.requestError) {
            errors.push(l("Unknown response received from server"));
        } else {
            errors.push(l("Unknown error occurred"));
        }

        dispatch(createAction(PAGE_ACTION_TYPES.PAGE_LOAD_END, {errors: errors}));
    });
};
