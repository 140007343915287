import lodash from 'lodash';
import swal from 'sweetalert'; // eslint-disable-line
import {
    change,
    initialize,
    setSubmitFailed,
    setSubmitSucceeded,
    startSubmit,
    stopSubmit,
    SubmissionError
} from 'redux-form';
import {push} from 'connected-react-router';

import {TYPES} from './type';
import {
    createFinishedOfferRequest,
    fetchCustomers,
    fetchFinishedOfferRequest,
    fetchInsurances,
    fetchInsuranceTypes,
    publishFinishedOfferRequest
} from '../../../../api/sdk-action';
import {redirectTo} from '../../../../router/action';
import URI from '../../../../../config/uri';
import {processValidationError} from '../../create-request/action/form';
import {FORM_NAME} from '../page.jsx';
import {Fields} from '../../../../../config/domain/offerRequest.js';
import {l} from '../../../../i18n/translator';

export const init = (offerRequestId) => {
    return (dispatch) => {
        dispatch({type: TYPES.PAGE_LOAD_START});

        Promise.all([
            dispatch(fetchCustomers()),
            dispatch(fetchInsuranceTypes()),
            offerRequestId ? dispatch(fetchFinishedOfferRequest(offerRequestId)) : null,
        ]).then(([customers, insuranceTypes, finishedOfferRequest]) => {
            dispatch(dataLoaded('customers', customers));
            dispatch(dataLoaded('insuranceTypes', insuranceTypes));

            if (finishedOfferRequest) {
                dispatch(initialize(FORM_NAME, finishedOfferRequest));
                dispatch(loadInsurancesForInsuranceType(finishedOfferRequest.insuranceTypeId));
            }

            dispatch({type: TYPES.PAGE_LOAD_END, isLoaded: true});
        });
    };
};

export const publish = (data, dispatch) => {
    swal({
        title: l('Are you sure?'),
        text: l('After publishing you will not be able to change anything anymore.'),
        type: 'warning',
        showCancelButton: true
    }, () => {
        dispatch(customSubmit(FORM_NAME, () => {
            let requestData = lodash.clone(data);
            if (requestData.customer && requestData.customer.userEmail && requestData.customer.userEmail.trim().length === 0) {
                delete requestData.customer.userEmail
            }
            return dispatch(createFinishedOfferRequest(requestData))
                .then((offerRequest) => dispatch(publishFinishedOfferRequest(offerRequest.id)))
                .then((offerRequest) => dispatch(redirectTo(URI.OFFER_REQUEST_DETAILS.replace(':id', offerRequest.id))))
                .catch((error) => processValidationError(error, requestData));
        }));
    });
};

const customSubmit = (formName, func) => (dispatch) => {
    dispatch(startSubmit(formName));

    func()
        .then(() => dispatch(setSubmitSucceeded(formName)))
        .catch((error) => {
            if (error instanceof SubmissionError) {
                dispatch(stopSubmit(formName, error.errors));
                dispatch(setSubmitFailed(formName));
            }
        });
};

export const save = (data, dispatch) => {
    let requestData = lodash.clone(data);
    if (requestData.customer && requestData.customer.userEmail && requestData.customer.userEmail.trim().length === 0) {
        delete requestData.customer.userEmail
    }

    return dispatch(createFinishedOfferRequest(requestData))
        .then((offerRequest) => {
            dispatch(change(FORM_NAME, Fields.ID, offerRequest.id));
            if (!requestData.id) {
                dispatch(push(URI.CREATE_OFFER_REQUEST_FINISHED_EDIT.replace(':id', offerRequest.id)));
            }
        })
        .catch((error) => processValidationError(error, requestData));
};

export const loadInsurancesForInsuranceType = (insuranceTypeId) => {
    return (dispatch) => {
        dispatch(fetchInsurances(insuranceTypeId))
            .then((insurances) => {
                dispatch(dataLoaded('insurances', insurances));
            });
    };
};

export const dataLoaded = (name, values) => {
    return {type: TYPES.DATA_LOADED, data: {[name]: values}};
};
