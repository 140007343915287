import {TYPES as ACTION_TYPES} from './action';

const INITIAL_STATE = {
    user: null
};

/**
 *
 * @param state
 * @param action
 * @return {object}
 */
let authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ACTION_TYPES.USER_AUTHENTICATED: {
            return {
                ...state,
                user: action.user
            };
        }
        case ACTION_TYPES.USER_LOGGED_OUT: {
            return {
                ...state,
                user: null
            };
        }
        case ACTION_TYPES.USER_COMPANY_LOAD_SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    company: action.company
                }
            };
        }
    }

    return state;
};

export default authReducer;
