import React from 'react';

import {renderSelectField} from '../renderer';
import {l} from '../../../../i18n/translator';
import {Field} from '../../../insurance-offer/create-request/component/redux-form-wrapper';
import {OfferRoundTypes} from '../../../../../config/domain/offerRequest.js';

export default ({name, ...other}) => {
    return <Field
        name={name}
        component={renderSelectField}
        label={l('Offer round type')}
        options={[
            {value: OfferRoundTypes.AUCTION, label: l('offerRoundType_auction')},
            {value: OfferRoundTypes.FIRST_SHOT, label: l('offerRoundType_firstShot')},
        ]}
        {...other}
    />;
}
