/**
 * Created by br0wn on 12/8/16.
 */
import {States} from '../../config/domain/offerRequest';


export const isInState = (state, offerRequest) => offerRequest && offerRequest.state === state;

export const isInStateCreated = (offerRequest) => isInState(States.CREATED, offerRequest);

export const isInStateAbstained = (offerRequest) => isInState(States.ABSTAINED, offerRequest);

export const isCreatedFinishedOffer = (offerRequest) => offerRequest && isInStateCreated(offerRequest) && offerRequest && offerRequest.data && offerRequest.data.finished;
