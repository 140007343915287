/**
 * Created by br0wn on 11/28/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import {l} from '../../../../i18n/translator.js';
import FileUploadForm from '../../../common/form/file-upload/component/singleFileUpload.jsx';
import * as DetailsActions from '../../offer-details/action/details';


export class OfferSelected extends React.Component {

    render() {
        const {offerUploadState, actions, offer, ...props} = this.props;

        return (
            <div {...props}>
                <h1>
                    {l('You have been selected for official offer! Please upload your official offer agreement.')}
                </h1>

                <div className="inline widget white-bg upload-official-offer">
                    <FileUploadForm
                        {...offerUploadState}
                        actions={{
                            ...actions.officialOfferUpload,
                            upload: (file) => actions.officialOfferUpload.upload(offer, file)
                        }}
                    />
                </div>
            </div>
        )
    }

}


export const OfferSelectedContainer = connect(
    state => ({
        offerUploadState: state.page.offerDetails.offerUploadState,
        auth: state.auth,
        i18n: state.i18n
    }),
    dispatch => ({
        actions: {
            officialOfferUpload: {
                upload: (offer, file) => dispatch(DetailsActions.uploadOfferFile(offer, file)),
                remove: () => null,
                onFileSelected: (file) => dispatch(DetailsActions.onOfferFileSelected(file)),
            }
        }
    })
)(OfferSelected);