export const TYPES = {
  TABS_CONTAINER_SELECT_TAB: 'common.component.tabnav.select_tab',
  TABS_CONTAINER_DESTROY: 'common.component.tabnav.destroy'
};

export function selectTab(namespace, tab) {
  return { type: TYPES.TABS_CONTAINER_SELECT_TAB, namespace, tab};
}

export function destroy(namespace) {
  return { type: TYPES.TABS_CONTAINER_DESTROY, namespace};
}

export const actions = {selectTab, destroy};
