

import {isNotAuthorized, isResponse} from '../../../lib/http';

/**
 * Returns rejected promise if request is authorised, otherwise calls the specified callback and returns
 * resolved promise
 *
 * @param {Response} response
 * @param {function=} callback
 * @return {Promise.<*>}
 */
export let handleNotAuthorizedResponse = (response, callback) => {
    if (isResponse(response) && isNotAuthorized(response)) {
        // call callback if supplied
        if (callback && callback.call) {
            return callback(response);
        }

        // fail the request
        return Promise.resolve(null);
    }

    return Promise.reject(response);
};
