import loadedPageReducer, {PAGE_INITIAL_STATE} from '../../common/page/loaded-page/reducer';
import {ACTION_TYPES} from './action';

const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    isLoading: false,
    isLoaded: false,
    loadErrors: [],
    data: {
        autoRating: null,
        criteria: [],
        insuranceTypes: [],
        insurances: [],
    }
};

let analysisAutoRatingEditReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_CRITERIA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    criteria: action.criteria,
                }
            };
        }
    }
    return {
        ...state,
        ...loadedPageReducer(state, action, ACTION_TYPES)
    };
};

export default analysisAutoRatingEditReducer;
