import swal from 'sweetalert'; // eslint-disable-line
import {l} from '../../../../i18n/translator';
import URI from '../../../../../config/uri';
import {redirectTo} from '../../../../router/action';
import TYPES from './type';
import {
    abstainFromOffer as apiAbstainFromOffer,
    acceptOfficialOffer as apiAcceptOfficialOffer,
    deleteOffer as apiDeleteOffer,
    deleteOfferRequest as apiDeleteOfferRequest,
    deleteOfferRequestFile as apiDeleteOfferRequestFile,
    favoriteOfferRequest as apiFavoriteOfferRequest,
    publishOfferRequest as apiPublishOfferRequest,
    requestOfficialOffer as apiRequestOfficialOffer,
    unfavoriteOfferRequest as apiUnfavoriteOfferRequest,
    updateOfferRequestNotes as apiUpdateOfferRequestNotes,
    updateOfferRequestUtilityAnalysis as apiUpdateOfferRequestUtilityAnalysis,
    withdrawOfferRequest as apiWithdrawOfferRequest
} from '../../../../api/sdk-action';
import {cleanup, dataLoaded, init, updateStatistics} from './page';

export const deleteOfferRequest = (offerRequestId) => {
    return (dispatch) => {
        swal({
            title: l('Are you sure?'),
            type: 'warning',
            showCancelButton: true
        }, () => {
            dispatch({
                type: TYPES.DELETE_OFFER_REQUEST_START,
                id: offerRequestId
            });

            return dispatch(
                apiDeleteOfferRequest(offerRequestId)
            ).then(
                () => {
                    dispatch({
                        type: TYPES.DELETE_OFFER_REQUEST_END,
                        id: offerRequestId
                    });

                    dispatch(redirectTo(URI.OFFER_REQUEST_LIST));
                }
            ).catch(
                error => dispatch({
                    type: TYPES.DELETE_OFFER_REQUEST_END,
                    id: offerRequestId,
                    error
                })
            );
        });
    };
};

export const publishOfferRequest = (offerRequest) => {
    return (dispatch) => {
        dispatch({
            type: TYPES.PUBLISH_OFFER_REQUEST_START
        });

        return dispatch(
            apiPublishOfferRequest(offerRequest)
        ).then(
            (offerRequest) => {
                dispatch({
                    type: TYPES.PUBLISH_OFFER_REQUEST_END,
                    offerRequest
                });
            }
        ).catch(
            error => dispatch({
                type: TYPES.PUBLISH_OFFER_REQUEST_END,
                error
            })
        );
    };
};

export const withdrawOfferRequest = (offerRequestId, reason) => {
    return (dispatch) => {
        dispatch({
            type: TYPES.WITHDRAW_OFFER_REQUEST_START,
            id: offerRequestId
        });

        return dispatch(
            apiWithdrawOfferRequest(offerRequestId, reason)
        ).then(
            (offerRequest) => dispatch({
                type: TYPES.WITHDRAW_OFFER_REQUEST_END,
                offerRequest
            })
        ).catch(
            error => dispatch({
                type: TYPES.WITHDRAW_OFFER_REQUEST_END,
                id: offerRequestId,
                error
            })
        );
    };
};

export const deleteOffer = (offerRequestId, offerId) => {
    return (dispatch) => {
        swal({
            title: l('Are you sure?'),
            type: 'warning',
            showCancelButton: true
        }, () => {
            dispatch({
                type: TYPES.DELETE_OFFER_START,
                id: offerId
            });

            return dispatch(
                apiDeleteOffer(offerId)
            ).then(
                () => dispatch({
                    type: TYPES.DELETE_OFFER_END,
                    id: offerId
                })
            ).catch(
                error => dispatch({
                    type: TYPES.DELETE_OFFER_END,
                    id: offerId,
                    error
                })
            );
        });
    };
};

export const abstainFromOffer = (offerRequestId, insuranceId = undefined) => {
    return (dispatch) => {
        swal({
            title: l('Are you sure?'),
            type: 'warning',
            showCancelButton: true
        }, () => {
            dispatch({
                type: TYPES.ABSTAIN_OFFER_START,
                id: offerRequestId
            });

            return dispatch(
                apiAbstainFromOffer(offerRequestId, insuranceId)
            ).then(
                () => {
                    dispatch({
                        type: TYPES.ABSTAIN_OFFER_END,
                        id: offerRequestId
                    });

                    // reset page
                    dispatch(cleanup());
                    dispatch(init(offerRequestId));
                }
            ).catch(
                error => dispatch({
                    type: TYPES.ABSTAIN_OFFER_END,
                    id: offerRequestId,
                    error
                })
            );
        });
    };
};

export const updateOffer = (offer) => (dispatch, getState) => {
    // update offer
    dispatch({type: TYPES.UPDATE_OFFER, offer});
    dispatchUpdateStatistics(dispatch, getState);
};

export const removeOffer = (offer) => (dispatch, getState) => {
    // update offer
    dispatch({type: TYPES.REMOVE_OFFER, offer});
    dispatchUpdateStatistics(dispatch, getState);
};

export const updateOfferRequestNotes = (offerRequest, notes) => (dispatch) => {
    return dispatch(
        apiUpdateOfferRequestNotes(offerRequest, notes)
    ).then(
        data => dispatch(dataLoaded('offerRequest', {...offerRequest, ...data}))
    );
};

export const toggleOfferRequestFavorite = (offerRequest) => (dispatch) => {
    const {favorite} = offerRequest;

    return dispatch(
        favorite ? apiUnfavoriteOfferRequest(offerRequest) : apiFavoriteOfferRequest(offerRequest)
    ).then(
        () => dispatch(dataLoaded('offerRequest', {...offerRequest, favorite: !favorite}))
    );
};

export const requestOfficialOffer = (offer) => (dispatch) => {
    return dispatch(
        apiRequestOfficialOffer(offer.id)
    ).then(
        (offer) => dispatch(updateOffer(offer))
    );
};

export const acceptOfficialOffer = (offer) => (dispatch) => {
    alert('Popup with some text, will be styled later');

    return dispatch(
        apiAcceptOfficialOffer(offer.id)
    ).then(
        (offer) => dispatch(updateOffer(offer))
    );
};

export const onFileUploaded = (file) => ({
    type: TYPES.FILE_UPLOADED,
    file: file
});

export const removeFile = (offerRequest, attachment) => {
    return (dispatch) => {
        return dispatch(
            apiDeleteOfferRequestFile(offerRequest.id, attachment.id)
        ).then(() => {
            dispatch({type: TYPES.FILE_REMOVED, file: attachment});

            return attachment;
        }).catch(error => Promise.reject(error));
    };
};


export const updateWithdrawRequestModal = (data = {}, replace = false) => {
    return {
        type: TYPES.UPDATE_WITHDRAW_REQUEST_MODAL, data, replace
    };
};

export const updateUtilityAnalysis = (offerRequest, {criteria, ratings}) => {
    return (dispatch, getState) => {
        dispatch({type: TYPES.UTILITY_ANALYSIS_UPDATED, criteria, ratings});
        dispatchUpdateStatistics(dispatch, getState);
    };
};

export const updateUtilityAnalysisApi = (offerRequest, utilityAnalysis) => {
    if (utilityAnalysis.ratings) {
        //filter out non ratings
        utilityAnalysis.ratings = utilityAnalysis.ratings.filter((rating)=>rating.ratingData !== undefined);
    }
    return (dispatch) => {
        return dispatch(
            apiUpdateOfferRequestUtilityAnalysis(offerRequest.id, utilityAnalysis)
        ).catch(error => Promise.reject(error));
    };
};

const dispatchUpdateStatistics = function (dispatch, getState) {
    // update stats with new data
    const {offerRequest, offerList, insurances} = getState().page.offerRequestDetails.data;
    dispatch(updateStatistics({offerRequest, offerList, insurances}));
};
