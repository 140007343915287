import loadedPageReducer, {PAGE_INITIAL_STATE} from "../common/page/loaded-page/reducer";
import {PAGE_ACTIONS, TYPES} from "./action";
import {updateUserInList} from '../common/user/userEditModal-reducer';

export const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    data: {
        users: [],
        user: {},
        insuranceTypes: []
    },
    userEditModal: {
        show: false,
        user: {}
    },
    apiKeyModal: {
        show: false,
        apiKey: {}
    },
    deleting: []
};

const profilePageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPES.USERS_LOADED: {
            const {users} = action;

            const newAction = {
                type: TYPES.DATA_LOADED,
                data: {users}
            };

            return loadedPageReducer(state, newAction, PAGE_ACTIONS);
        }
        case TYPES.UPDATE_USER_EDIT_MODAL: {
            return {
                ...state,
                userEditModal: {
                    show: action.show,
                    user: action.user
                }
            };
        }
        case TYPES.DATA_UPDATE_SINGLE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    users: updateUserInList(state.data.users, action.user)
                }
            };
        }
        case TYPES.DELETE_USER_START: {
            const users = [...state.data.users];
            const foundUserIndex = users.findIndex((user) => user.id == action.id);
            if (foundUserIndex >= 0) {
                users.splice(foundUserIndex, 1);
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    users: users
                }
            };
        }
        case TYPES.DELETE_USER_END: {
            if (action.error) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        users: updateUserInList(state.data.users, action.user)
                    }
                };
            }
            break;
        }
        case TYPES.DELETE_API_KEY_START: {
            const apiKeys = [...state.data.apiKeys];
            const foundApiKeyIndex = apiKeys.findIndex((apiKey) => apiKey.apiKeyPrefix == action.apiKey.apiKeyPrefix);
            if (foundApiKeyIndex >= 0) {
                apiKeys.splice(foundApiKeyIndex, 1);
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    apiKeys: apiKeys
                }
            };
        }
        case TYPES.DELETE_API_KEY_END: {
            if (action.error) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        apiKeys: [action.apiKey, ...state.data.apiKeys]
                    }
                };
            }
            break;
        }

        case TYPES.UPDATE_API_KEY_MODAL: {
            return {
                ...state,
                apiKeyModal: {
                    show: action.show || false,
                    apiKey: action.apiKey
                }
            };
        }
        case TYPES.GENERATE_API_KEY_END: {
            if (!action.error) {
                const apiKey = {...action.apiKey};
                delete apiKey.apiKey;
                return {
                    ...state,
                    data: {
                        ...state.data,
                        apiKeys: [...state.data.apiKeys, apiKey]
                    },
                    apiKeyModal: {
                        ...state.apiKeyModal,
                        apiKey: action.apiKey,
                    }
                };
            }
            break;
        }
        case TYPES.UPDATE_API_KEY_END: {
            if (!action.error) {
                const apiKey = {...action.apiKey};
                delete apiKey.apiKey;
                const apiKeys = [...state.data.apiKeys];
                const foundApiKeyIndex = apiKeys.findIndex((apiKey) => apiKey.apiKeyPrefix == action.apiKey.apiKeyPrefix);
                if (foundApiKeyIndex >= 0) {
                    apiKeys[foundApiKeyIndex] = apiKey;
                }
                return {
                    ...state,
                    data: {
                        ...state.data,
                        apiKeys: apiKeys
                    },
                    apiKeyModal: {
                        show: false,
                        apiKey: {},
                    }
                };
            }
            break;
        }
        case TYPES.UPDATE_MOBILE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        mobile: action.mobile
                    }
                }
            };
        }
    }
    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS)
    };
};

export default profilePageReducer;
