import I18nConfig from '../../config/i18n';
import {getBrowserLocale, getBrowserLocales} from './helper';

export const TYPES = {
    SET_LOCALE: 'i18n.set_locale',
    SET_BROWSER_LOCALE: 'i18n.set_browser_locale'
};


export const setLocale = (locale) => {
    return (dispatch) => {
        // update storage
        localStorage.setItem('i18n.locale', locale);

        // dispatch action
        dispatch({
            type: TYPES.SET_LOCALE,
            locale: locale
        });
    };
};

export const setBrowserLocale = (locale) => {
    return {
        type: TYPES.SET_BROWSER_LOCALE,
        locale
    };
};

export const loadBrowserLocale = () => {
    return (dispatch) => {
        const browserLocales = getBrowserLocales();
        dispatch(setBrowserLocale(browserLocales[0] || I18nConfig.default_locale));
    };
};

export const loadLocaleFromStorage = () => {
    return (dispatch) => {
        let storageLocale = localStorage.getItem('i18n.locale') || null;
        let browserLocale = getBrowserLocale(I18nConfig.enabled_locales);

        if (null !== storageLocale) {
            dispatch(setLocale(storageLocale));
        } else if (null !== browserLocale) {
            dispatch(setLocale(browserLocale));
        }
    };
};
