export default {
    COMPANY_NOT_SET_UP: 'error_company_not_setup',

    AUTH: {
        RESET_PASSWORD: {
            INVALID_USER: 'error_user_email_not_found_or_not_verified'
        },
        NEW_PASSWORD: {
            INVALID_TOKEN: 'error_invalid_token',
            INVALID_PASSWORD: 'error_register_password_rules'
        }
    }
};