/**
 * Created by br0wn on 1/23/17.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Field, Fields, reduxForm} from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {l} from '../../../../i18n/translator.js';
import {renderSelectField, renderTextField} from '../../../common/form/renderer.jsx';

export class WithdrawOfferRequestForm extends React.Component {

    static renderCustomReason({preDefinedReason, customReason}) {
        if (preDefinedReason.input.value !== 'other') {
            return null;
        }

        return renderTextField({
            ...customReason,
            placeholder: l('You can enter custom reason here')
        });
    }

    static validate(values) {
        const errors = {};

        if (!values.preDefinedReason) {
            errors.preDefinedReason = l('Please choose a reason');
        }

        if (values.preDefinedReason == 'other' && !values.customReason) {
            errors.customReason = l('Please enter custom reason');
        }

        return errors;
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <form onSubmit={handleSubmit} className="text-center">
                <h2>
                    {l('What is the reason for withdrawing?')}
                </h2>
                <div>
                    <Field name="preDefinedReason" component={renderSelectField} placeholder={l('Please select')}>
                        <MenuItem value={"Accidentally added"} primaryText={l("Accidentally added")}/>
                        <MenuItem value={"Wrong data"} primaryText={l("Wrong data")}/>
                        <MenuItem value={"other"} primaryText={l("Other")}/>
                    </Field>
                </div>
                <div>
                    <Fields names={['preDefinedReason', 'customReason']}
                            component={WithdrawOfferRequestForm.renderCustomReason}/>
                </div>
            </form>
        )
    }

}

export const WithdrawOfferRequestReduxForm = reduxForm({
    form: 'withdraw-offer-request',
    validate: WithdrawOfferRequestForm.validate
})(WithdrawOfferRequestForm);


export class WithdrawOfferRequestModal extends React.Component {

    constructor(props) {
        super(props);

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm() {
        if (!this.form) {
            throw new Error("Form reference does not exist");
        }

        this.form.submit();
    }

    onSubmit(values) {
        const {onSubmit} = this.props;
        const reason = values.preDefinedReason == 'other' ? values.customReason : values.preDefinedReason;

        if (onSubmit && onSubmit.call) {
            onSubmit(reason);
        }
    }

    render() {
        const {show, onCancel, meta: {submitting}} = this.props;

        return (
            <Dialog open={show} onBackdropClick={onCancel}>
                <DialogTitle>{l('Withdraw offer request')}</DialogTitle>
                <DialogContent>
                    <WithdrawOfferRequestReduxForm ref={node => this.form = node} onSubmit={this.onSubmit.bind(this)}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={submitting} onClick={onCancel}>{l('No, leave it be')}</Button>
                    <Button disabled={submitting} color="primary" onClick={this.submitForm}>
                        {l('Yes, withdraw offer request')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(
    state => {
        const pageState = state.page.offerRequestDetails;

        return {
            show: pageState.withdrawRequestModal.show,
            meta: {submitting: pageState.isWithdrawing}
        }
    }
)(WithdrawOfferRequestModal);
