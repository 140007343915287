import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {l} from '../../../../../i18n/translator';
import {Fields, FORM_NAME} from '../../config/form';

import {change} from 'redux-form';
import {Field} from '../redux-form-wrapper.jsx';
import {renderCheckboxGroup} from '../../../../common/form/renderer.jsx';


const selectAll = (insurances) => {
    return insurances.map(insurance => insurance.id);
};


const InsuranceCompaniesList = ({Checkbox, Error, insurances}) => {
    const halfLength = Math.ceil(insurances.length / 2);
    const insuranceGroups = [
        insurances.slice(0, halfLength),
        insurances.slice(halfLength)
    ];

    return (
        <Row>
            <Col md={12} className="m-b-sm">
                <Error/>
            </Col>

            {insurances.length >= 4 ? (
                insuranceGroups.map((insurances, index) => (
                    <div className="col-md-6" key={index}>
                        {insurances.map((insurance, index) => (
                            <Checkbox key={index} value={insurance.id} label={insurance.name}
                                      style={{display: 'block'}}/>
                        ))}
                    </div>
                ))
            ) : (
                <div className="col-md-12">
                    {insurances.map((insurance, index) => (
                        <Checkbox key={index} value={insurance.id} label={insurance.name}
                                  style={{display: 'block'}}/>
                    ))}
                </div>
            )}
        </Row>
    );
};


let InsuranceCompaniesForm = ({insurances = [], fieldChange}) => {

    return (
        <div>
            <h3 className="m-b-md">
                {l('Which insurance companies would you like?')}
            </h3>

            <Row>
                <Col md={12}>
                    <div className="m-b-sm">
                        <div className="btn btn-sm btn-white"
                             onClick={(e) => fieldChange(Fields.INSURANCE_ID, selectAll(insurances))}>
                            {l('Select all')}
                        </div>
                        &nbsp;
                        <div className="btn btn-sm btn-white" onClick={(e) => fieldChange(Fields.INSURANCE_ID, [])}>
                            {l('Clear')}
                        </div>
                    </div>

                    <Field name={Fields.INSURANCE_ID} component={renderCheckboxGroup}
                           renderComponent={InsuranceCompaniesList} insurances={insurances}/>
                </Col>
            </Row>
        </div>
    )
};

export default connect(
    null, dispatch => ({
        fieldChange: (field, value) => dispatch(change(FORM_NAME, field, value))
    })
)(InsuranceCompaniesForm)
