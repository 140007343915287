import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {renderTextField} from '../../../common/form/renderer.jsx';
import {l} from '../../../../i18n/translator';
import * as Actions from '../action';
import Error from '../../../common/auth/error.jsx';


export class VerifyPasswordForm extends React.Component {
    componentWillMount() {
        const {handleSubmit, initialize, email, token} = this.props;
        this.props.dispatch(initialize({email, token: token}));
        setTimeout(() => handleSubmit(), 100);
    }

    render() {
        const {handleSubmit, submitting, pristine, error, invalid} = this.props;
        return (
            <form className="m-t" onSubmit={handleSubmit}>
                <h3 className="m-b-lg">{l('Verify user')}</h3>
                {submitting ? (
                    <p>
                        {l('User is being verified, please wait...')}
                    </p>
                ) : null}
                {!submitting && !error ? (
                    <div>
                        {l('Account requires a password. Please set the password now.')}
                        <div className="m-t-sm">
                            <div>
                                <div>
                                    <Field name="password" type="password" component={renderTextField}
                                           disabled={submitting}
                                           label={l('Password')}/>
                                    <span className="m-l-sm"/>
                                    <Field name="passwordConfirm" type="password" component={renderTextField}
                                           disabled={submitting}
                                           label={l('Confirm Password')}/>
                                </div>
                            </div>

                            <div className="m-t-sm">
                                <Button
                                    color="primary"
                                    disabled={submitting || pristine || invalid}
                                    onClick={handleSubmit}>
                                    {l('Verify')}
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : null}
                {error ? (<Error error={error}/>) : null}
            </form>
        );
    }
}

const validate = values => {
    const errors = {};
    if (values.password != values.passwordConfirm) {
        errors.password = errors.passwordConfirm = l('Passwords do not match');
    }
    return errors;
};

function mapStateToProps(state) {
    return {state};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default reduxForm({
    form: 'verify',
    onSubmit: Actions.submit,
    validate: validate
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyPasswordForm));
