const HTTP_STATUS_BAD_REQUEST = 400;
const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_STATUS_FORBIDDEN = 403;
const HTTP_STATUS_NOT_FOUND = 404;
const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;

/**
 * @param {Response} response
 * @return {boolean}
 */
export const isNotAuthorized = (response) => {
    return response.status == HTTP_STATUS_UNAUTHORIZED;
};

/**
 * @param {Response} response
 * @return {boolean}
 */
export const isForbidden = (response) => {
    return response.status == HTTP_STATUS_FORBIDDEN;
};

/**
 * @param {Response} response
 * @return {boolean}
 */
export const isBadRequest = (response) => {
    return response.status == HTTP_STATUS_BAD_REQUEST;
};

/**
 * @param {Response} response
 * @return {boolean}
 */
export const isNotFound = (response) => {
    return response.status == HTTP_STATUS_NOT_FOUND;
};

/**
 * @param {Response} response
 * @return {boolean}
 */
export const isUnprocessableEntity = (response) => {
    return response.status == HTTP_STATUS_UNPROCESSABLE_ENTITY;
};

/**
 * @param {*} response
 * @return {boolean}
 */
export const isResponse = (response) => {
    return response instanceof Response;
};

/**
 * Reject failed response
 *
 * @param {*} response
 */
export const resolveResponsePromise = (response) => {
    return response.ok ? Promise.resolve(response) : Promise.reject(response);
};

/**
 * If error is a response, supplied callback will be called with response as parameter and it's returned
 * value will be returned. Otherwise, rejected promise will be returned.
 *
 * @param {*|Response} error
 * @param {function} callback
 * @return {*|Promise}
 */
export const handleErrorResponse = (error, callback) => {
    if (isResponse(error)) {
        return callback(error);
    } else {
        throw error;
    }
};

/**
 * Handles the error response and checks if it has validation errors or error.
 */
export const handleApiValidationErrorResponse = error => handleErrorResponse(error, response => {
    if (!isBadRequest(response)) {
        return Promise.reject({requestError: response});
    }

    // process validation errors
    return response.json().then(data => {
        return Promise.reject({requestError: response, error: data.error, validationErrors: data.errors});
    });
});

/**
 * Handles the error response and checks if it has validation errors or error.
 */
export const handleApiValidationErrorResponseOld = error => handleErrorResponse(error, response => {
    if (!isBadRequest(response)) {
        return Promise.reject({requestError: response});
    }

    // process validation errors
    return response.json().then(data => {
        const validationErrors = {};
        if (data.errors) {
            data.errors.forEach(item => validationErrors[item.path] = item.key);
        }
        return Promise.reject({requestError: response, error: data.error, validationErrors: validationErrors});
    });
});

/**
 * Makes request to URL and returns promise that will;
 *  be resolved if status is OK,
 *  be rejected with Response instance as parameter if response is not OK
 *  be rejected with error as parameter if fetch fails or other error happens
 *
 * @param {string} url
 * @param {object=} config
 * @return {Promise}
 */
export const makeRequest = (url, config) => {
    return fetch(url, config).then(response => resolveResponsePromise(response));
};
