/**
 * Created by br0wn on 10/26/16.
 */
import React from 'react';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {l} from '../../../i18n/translator.js';
import AdminOnly from '../auth/acl/adminOnly';

export class ShowDisabledUsersToggle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showDisabled: false,
            isLoading: false
        };

        this.onChange = this.onChange.bind(this);
    }

    updateUserList() {
        const {onUpdateUsers} = this.props;
        const {showDisabled} = this.state;

        this.setState({isLoading: true});

        const promise = onUpdateUsers(showDisabled);
        if (promise && typeof promise.then === 'function') {
            promise.then(() => this.setState({isLoading: false}))
                .catch(e => {
                    this.setState({isLoading: false});
                    throw e;
                });
        } else {
            this.setState({isLoading: false});
        }
    }

    onChange() {
        const {showDisabled} = this.state;

        this.setState({
            showDisabled: !showDisabled
        }, () => this.updateUserList())
    }

    render() {
        const {isLoading, showDisabled} = this.state;
        // eslint-disable-next-line
        const {dispatch, onUpdateUsers, ...otherProperties} = this.props;
        return (
            <div {...otherProperties}>
                <FormControlLabel
                    className="inline"
                    label={l('Show disabled users')}
                    control={<Switch value={showDisabled} onChange={this.onChange}/>}
                />
                <div className="inline" style={{width: 20}}>
                    {isLoading && <i className="fa fa-cog"/>}
                </div>
            </div>
        )
    }
}

export const UserList = ({
                             auth, users = [], updating = [],
                             onDeleteUser = (() => null),
                             onEnableUser = (() => null),
                             onUserClick = (() => null),
                             onUpdateUsers = (() => null),
                             onResendEmail = null,
                             showToggle = false,
                         }) => {
    users = [...users]; // because it is part of state and we sort it afterwards

    const isOwnUser = (user) => auth && auth.user && auth.user.id == user.id;
    const clickUser = (user, event) => {
        event.stopPropagation();
        if (!isOwnUser(user)) {
            onUserClick(user);
        }
    };
    const deleteUser = (user, event) => {
        event.stopPropagation();
        if (!isOwnUser(user)) {
            onDeleteUser(user);
        }
    };
    const enableUser = (user, event) => {
        event.stopPropagation();
        if (!isOwnUser(user)) {
            onEnableUser(user);
        }
    };
    const resendEmail = (user, event) => {
        event.stopPropagation();
        if (!isOwnUser(user)) {
            onResendEmail(user);
        }
    };
    return (<>
            {!!users && showToggle &&
            <ShowDisabledUsersToggle className="text-right m-t-n-xs m-b-xs" onUpdateUsers={onUpdateUsers}/>}
            <table className="table table-striped table-hover">
                <thead>
                <tr>
                    <th>
                        {l('Email')}
                    </th>
                    <th>
                        {l('Role')}
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {users.sort((u1, u2) => u1.email.localeCompare(u2.email)).map((user, index) => {
                    const isDeleting = updating.indexOf(user.id) !== -1;

                    return (
                        <tr key={index} style={{cursor: 'pointer'}} onClick={(event) => clickUser(user, event)}>
                            <td>
                                {user.email}
                            </td>
                            <td>
                                {user.role}
                            </td>
                            <td>
                                <AdminOnly auth={auth}>
                                    {!isOwnUser(user) && user.enabled && (
                                        <a
                                            onClick={(event) => deleteUser(user, event)}
                                            style={{color: 'red'}}
                                        >
                                            <i className={classNames('fa', {
                                                'fa-trash': !isDeleting,
                                                'fa-cog': isDeleting
                                            })} title={l('Delete')}/> {l('Delete')}
                                        </a>
                                    )}
                                    {!isOwnUser(user) && user.enabled && !user.verified && onResendEmail && (
                                        <a className='m-l-md' onClick={(event) => resendEmail(user, event)}>
                                            <i className='fa fa-send' title={l('Resend E-Mail')}/> {l('Resend E-Mail')}
                                        </a>
                                    )}

                                    {!isOwnUser(user) && !user.enabled && (
                                        <a
                                            onClick={(event) => enableUser(user, event)}
                                            style={{color: 'green'}}
                                        >
                                            <i className="fa fa-plus" title={l('Enable')}/> {l('Enable')}
                                        </a>
                                    )}
                                </AdminOnly>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    )
};
