import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TabContent from './TabContent';
import Tabs from './Tabs';
import {actions} from './actions';

class TabsContainer extends Component {

  constructor (props, context) {
    super(props, context);
    this.handleSelection = this.handleSelection.bind(this);
    this.activeTab = this.activeTab.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.destroy(this.props.namespace);
  }

  activeTab() {
    const thisTabsContainerState = this.props.tabsContainerState[this.props.namespace];
    return thisTabsContainerState ? thisTabsContainerState.activeTab : this.props.defaultActive;
  }

  handleSelection(tab) {
    this.props.actions.selectTab(this.props.namespace, tab);
    if(this.props.onSelect) {
      this.props.onSelect(tab, this.activeTab());
    }
  }

  render() {
    const tabsContainerChildren = React.Children.map(this.props.children,
      (child) => {
        const isActive = tab => this.activeTab() == tab;
        if (child.props.componentName == Tabs.componentName) {
          return React.cloneElement(child, {isActive, onSelect: this.handleSelection});
        } else if (child.props.componentName == TabContent.componentName) {
          return React.cloneElement(child, {isActive});
        } else {
            return child;
        }
      }
    );

    return (
      <div className="tabs-container">
        {tabsContainerChildren}
      </div>
    );
  }
}

TabsContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  tabsContainerState: PropTypes.object.isRequired,
  //optional
  defaultActive: PropTypes.string,
  onSelect: PropTypes.func
};

function mapStateToProps(state) {
  return { tabsContainerState: state.tabnav };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch), dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabsContainer);
