import {prefixedPersonDataFormFields} from '../../common/form/person-data/config/formFields';

export const GeneralDataFields = {
    ID: 'model.id',
    NAME: 'name',
    BUSINESS_IDENTIFICATION_NUMBER: 'businessIdentificationNumber',
    INDUSTRY_CLASSIFICATION_CODE: 'industryClassificationCode',
    AREA_OF_OPERATIONS: 'areaOfOperations',
    EMPLOYEES: 'employees',
};

export const UiFields = {
    HAS_CORRESPONDENCE: 'ui.hasCorrespondence',
    HAS_BILLING: 'ui.hasBilling'
};

export const ContactDataFields = prefixedPersonDataFormFields('contact.');

export const CorrespondenceDataFields = prefixedPersonDataFormFields('correspondence.');

export const BillingDataFields = prefixedPersonDataFormFields('billing.');

const Fields = {
    ...GeneralDataFields,
    ...ContactDataFields,
    ...CorrespondenceDataFields,
    ...BillingDataFields,
    ...UiFields
};

export default Fields;
