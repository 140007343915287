/**
 * Created by br0wn on 11/24/16.
 */
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import {l} from '../../../../i18n/translator.js';
import {isUserOfType} from '../../../../auth/helper';
import {CompanyTypes} from '../../../../../config/domain/user.js';
import {States as OfferStates} from '../../../../../config/domain/offer.js';
import {CurrentInsuranceFields, Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';
import {PremiumValue} from '../../common/i18n/premium.jsx';
import {isOfferRequestFinished} from '../../helper';
import {acceptOfficialOffer, requestOfficialOffer} from '../action/offerRequest';
import {colorForRank} from '../../../common/analysis';

export const OffersStatistics = ({statistics, offerRequest, auth, actions}) => {
    const isFinished = isOfferRequestFinished(offerRequest);

    const currentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE] || {};
    const data = statistics.map((item) => {
        const currentInsurancePremiumGross = currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS];
        const savings = item.bestPremium && currentInsurancePremiumGross
            ? currentInsurancePremiumGross - item.bestPremium
            : undefined;
        return {
            id: item.name,
            overallRank: item.rank,
            overallOffer: item.bestOfferItem && item.bestOfferItem.offer,

            premiumRank: item.premiumRank,
            premiumOffer: item.bestPremiumItem && item.bestPremiumItem.offer,

            coverageRank: item.coverageRank,
            coverageOffer: item.bestCoverageItem && item.bestCoverageItem.offer,

            utilityRank: item.utilityRank,

            insurance: item.name,

            bestPremium: item.bestPremium,
            offerCount: item.offerCount,
            abstained: item.abstained,

            savings: savings,
            savingsPercentage: savings ? Math.round(100 * savings / currentInsurancePremiumGross) : undefined,

            isCurrent: item.isCurrent,
        };
    });

    if (isFinished) {
        const offerReceivedInsuranceNames = data.map((item) => item.insurance);
        const insuranceNamesWithoutOffer = offerRequest.requestFromInsuranceNames
            .filter((insuranceName) => !offerReceivedInsuranceNames.includes(insuranceName))
            .sort()
            .forEach((insuranceName) => {
                data.push({
                    id: insuranceName,
                    insurance: insuranceName,
                });
            });
    }

    const onRankingClick = (row, key) => (e) => {
        if (e) e.stopPropagation();

        let offer = undefined;
        if (key === 'overall') offer = row.overallOffer;
        else if (key === 'premium') offer = row.premiumOffer;
        else if (key === 'coverage') offer = row.coverageOffer;
        if (offer) {
            actions.onOfferClick(offer);
        }
    };
    const options = {
        sortIndicator: false, //disable sort indicator
        sortOrder: 'asc',
        onRowClick: (row, column) => {
            if (column === 1) onRankingClick(row, 'overall')();
            else if (column === 2) onRankingClick(row, 'premium')();
            else if (column === 3) onRankingClick(row, 'coverage')();
        }
    };
    const rankingFormatter = (value, row, formatExtraData, rowIdx) =>
        !row.abstained || row.isCurrent ?
            <div onClick={onRankingClick(row, formatExtraData)} style={{
                color: colorForRank(value),
                fontWeight: value < 4 ? 'bold' : 'normal',
                textDecoration: value < 4 ? 'underline' : 'none'
            }}>{value}.</div> : null;
    const offerCountFormatter = (value, row) => row.abstained ? l(`state_${OfferStates.ABSTAINED}`) : row.offerCount;
    const premiumFormatter = (value) => value ? <PremiumValue value={value}/> : null;
    const premiumSavingsFormatter = (value) => value ?
        <div style={{color: value >= 0 ? '#5a7a56' : '#8a3d3d'}}><PremiumValue value={value}/></div> : null;
    const savingsFormatter = (value) => value ?
        <div style={{color: value >= 0 ? '#5a7a56' : '#8a3d3d'}}>{value} %</div> : null;
    const insuranceFormatter = (value, row) => {
        const currentInsuranceStyle = {fontStyle: 'italic', color: '#868a3d'};
        return (
            <div style={row.isCurrent ? currentInsuranceStyle : {}}>
                {row.utilityRank
                    ? <div>
                        <span className="label label-default"
                              style={{color: 'white', backgroundColor: colorForRank(row.utilityRank)}}>
                            <i className="fa fa-heart"/> {row.utilityRank}.
                        </span> {value}
                    </div>
                    : value}
            </div>);
    };
    const rankingColumnClass = (fieldValue, row, rowIdx, colIdx) => {
        return colIdx >= 1 && colIdx <= 4 && row.offerCount ? 'ranking' : undefined;
    };
    return (
        <BootstrapTable className='ranking' data={data} options={options} striped hover condensed>
            <TableHeaderColumn dataField='id' isKey={true} hidden>Id</TableHeaderColumn>
            <TableHeaderColumn dataField='overallRank' dataFormat={rankingFormatter} formatExtraData='overall'
                               width='7%' dataSort={true} columnClassName={rankingColumnClass} defaultASC
                               headerText={l('Ranking of the best offer (price & coverage) by this insurance')}>
                {l('Rank')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField='premiumRank' dataFormat={rankingFormatter} formatExtraData='premium'
                               width='7%' dataSort={true} columnClassName={rankingColumnClass} defaultASC
                               headerText={l('Ranking of the offer with the cheapest premium by this insurance')}>
                <i className="fa fa-dollar"/> {l('Rank')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField='coverageRank' dataFormat={rankingFormatter} formatExtraData='coverage'
                               width='7%' dataSort={true} columnClassName={rankingColumnClass} defaultASC
                               headerText={l('Ranking of the offer with the best coverage by this insurance')}>
                <i className="fa fa-shield"/> {l('Rank')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField='insurance' dataFormat={insuranceFormatter} dataSort={true}
                               defaultASC>{l('Insurance')}</TableHeaderColumn>
            <TableHeaderColumn dataField='offerCount' width='10%' dataFormat={offerCountFormatter}
                               dataSort={true}>{l('# Offers')}</TableHeaderColumn>
            <TableHeaderColumn dataField='bestPremium' dataFormat={premiumFormatter}
                               dataSort={true} defaultASC>{l('Premium')}</TableHeaderColumn>
            {isUserOfType(auth, [CompanyTypes.CUSTOMER, CompanyTypes.BROKER]) && ([
                <TableHeaderColumn key='savings' dataField='savings' dataFormat={premiumSavingsFormatter}
                                   dataSort={true}>{l('Savings CHF')}</TableHeaderColumn>,
                <TableHeaderColumn key='savingsPercentage' dataField='savingsPercentage'
                                   dataFormat={savingsFormatter} width='10%'
                                   dataSort={true}>{l('Savings %')}</TableHeaderColumn>
            ])}
        </BootstrapTable>
    )
};


export class UserOfferActionsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isRequestInProgress: false};
    }

    doRequest(requestAction) {
        this.setState({isRequestInProgress: true});

        return requestAction().then(
            () => this.setState({isRequestInProgress: false})
        ).catch(
            () => this.setState({isRequestInProgress: false})
        )
    }

    renderRequestFinal() {
        const {offer, requestOfficialOffer} = this.props;
        const {isRequestInProgress} = this.state;
        const onClick = () => this.doRequest(() => requestOfficialOffer(offer));

        return (
            <Button bsStyle="primary" onClick={onClick} disabled={isRequestInProgress}>
                {l('Request final')}
            </Button>
        );
    }

    renderFinalRequested() {
        return (
            <div className="text-navy">
                {l('Final requested')}
            </div>
        );
    }

    renderAcceptOffer() {
        const {offer, acceptOfficialOffer} = this.props;
        const {isRequestInProgress} = this.state;
        const onClick = () => this.doRequest(() => acceptOfficialOffer(offer));

        return (
            <Button bsStyle="danger" onClick={onClick} disabled={isRequestInProgress}>
                {l('Accept offer')}
            </Button>
        );
    }

    render() {
        const {offer} = this.props;

        if (!offer) {
            return null;
        }

        if (!offer.officialOfferRequested && !offer.officialOfferFileId) {
            return this.renderRequestFinal()
        }

        if (offer.officialOfferRequested && !offer.officialOfferFileId) {
            return this.renderFinalRequested();
        }

        if (!offer.officialOfferAccepted) {
            return this.renderAcceptOffer();
        }

        return null;
    }

}

export const UserOfferActions = connect(
    state => ({
        i18n: state.i18n
    }),
    dispatch => bindActionCreators({requestOfficialOffer, acceptOfficialOffer}, dispatch)
)(UserOfferActionsComponent);
