// MAIN WIZARD
export const STEP_BASIC_DATA = 'basic_data';
export const STEP_CURRENT_INSURER = 'current_insurer';
export const STEP_CONTRACT_DATA = 'contract_data';
export const STEP_INSURANCE_DATA = 'insurance_data';
export const STEP_OFFER_DATA = 'offer_data';
export const STEP_OFFER_RATING = 'offer_rating';
export const STEP_SUMMARY = 'summary';

export const FIRST_STEP = STEP_BASIC_DATA;
export const LAST_STEP = STEP_SUMMARY;

// CREDIT INSURANCE STEPS
export const STEP_DETAILS_OF_SALES = 'details_of_sales';
export const STEP_ACCOUNTS_RECEIVABLE = 'accounts_receivable';
export const STEP_MANAGEMENT_RECEIVABLE = 'management_receivable';
export const STEP_REMINDERS_COLLECTIONS = 'reminders_collections';
export const STEP_ADDITIONAL_INFORMATION = 'additional_information';
