/**
 * Created by br0wn on 11/22/16.
 */
import {TYPE} from './action';

export const WIDGET_STATE = {
    data: {},
    isInitializing: false,
    isInitialized: false,
    initError: null,
    isWorking: false,
    error: false
};

export const INITIAL_STATE = {
    kpiList: [],
    widgets: {},
    isLoading: false,
    showWelcomeMessage: false
};


const widgetReducer = (widgets, action) => {
    const {widgetType} = action;

    if (!widgetType) {
        return widgets;
    }

    const widget = widgets[widgetType] || {...WIDGET_STATE};

    switch (action.type) {
        case TYPE.WIDGET_INIT: {
            return {
                ...widgets, [widgetType]: {
                    ...widget,
                    isInitializing: true,
                    initError: null
                }
            };
        }
        case TYPE.WIDGET_INIT_SUCCESS: {
            return {
                ...widgets, [widgetType]: {
                    ...widget,
                    isInitialized: true,
                    isInitializing: false,
                    initError: null,
                    data: action.data
                }
            };
        }
        case TYPE.WIDGET_INIT_ERROR: {
            return {
                ...widgets, [widgetType]: {
                    ...widget,
                    isInitializing: false,
                    initError: action.error
                }
            };
        }
        case TYPE.WIDGET_UPDATE: {
            return {
                ...widgets, [widgetType]: {
                    ...widget,
                    data: {...widget.data, ...action.data}
                }
            };
        }
        case TYPE.WIDGET_WORKING: {
            return {
                ...widgets, [widgetType]: {
                    ...widget,
                    isWorking: action.isWorking
                }
            };
        }
        case TYPE.WIDGET_ERROR: {
            return {
                ...widgets, [widgetType]: {
                    ...widget,
                    error: action.error
                }
            };
        }
    }

    return widgets;
};


const dashboardPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPE.WELCOME_MESSAGE_UPDATE: {
            return {
                ...state,
                showWelcomeMessage: action.show
            };
        }
        case TYPE.KPI_LIST_LOADED: {
            return {
                ...state,
                kpiList: action.kpiList || []
            };
        }
        case TYPE.DASHBOARD_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case TYPE.DASHBOARD_LOADED: {
            return {
                ...state,
                isLoading: false
            };
        }
    }

    return {
        ...state,
        widgets: widgetReducer(state.widgets, action)
    };
};


export default dashboardPageReducer;
