/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {ButtonGroup, Col, Row} from 'react-bootstrap';
import classNames from 'classnames';
import {l} from '../../../i18n/translator.js';
import URI from '../../../../config/uri.js';
import {States} from '../../../../config/domain/offerRequest.js';
import {isUndefined} from '../../../helper/core.js';
import {LocalDate} from '../../common/i18n/datetime.jsx';
import {fetchInsuranceTypes, fetchOfferRequestList} from '../../../api/sdk-action';
import * as Ibox from '../../ui-components/ibox.jsx';
import {BaseWidget} from './widget-base.jsx';
import {
    Column as OfferRequestColumn,
    EndDateCellComponent,
    favoriteValueFormat,
    PremiumValueComponent,
    SavingsCellComponent,
    Table as OfferRequestTable
} from '../../insurance-offer/request-list/component/table.jsx';


const FILTER_STATE = {
    'pending': false,
    'bidding': false,
    'archived': false
};

const FILTER_VALUES = {
    'pending': [States.PENDING_INSURANCE, States.PENDING_MANDATE, States.PENDING_VALIDATION].join(','),
    'bidding': States.PUBLISHED,
    'archived': [States.EXPIRED, States.WITHDRAWN]
};


export class CustomerRequestsWidget extends BaseWidget {

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        const {dispatch} = this.props;

        Promise.all([
            this.fetchFilteredList(),
            dispatch(fetchInsuranceTypes())
        ]).then(([data, insuranceTypes]) => {
            this.props.widgetInitialized(this.getType(), {
                list: data.items,
                insuranceTypes,
                filters: {...FILTER_STATE}
            })
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    onFilterClick(filter) {
        let {filters = FILTER_STATE} = this.props.data;

        if (filter) {
            if (isUndefined(filters[filter])) {
                return;
            }

            if (filters[filter]) {
                // deactivate already active filter
                filters = {...FILTER_STATE, [filter]: false}
            } else {
                // activate filter
                filters = {...FILTER_STATE, [filter]: true}
            }
        } else {
            filters = {...FILTER_STATE};
        }

        this.props.widgetUpdate(this.getType(), {filters});
        this.updateList(filters);
    }

    updateList(filters) {
        this.props.widgetWorking(this.getType(), true);

        filters = this.getFiltersList(filters);

        this.fetchFilteredList(filters).then(
            data => {
                this.props.widgetUpdate(this.getType(), {list: data.items});
                this.props.widgetWorking(this.getType(), false);
            }
        ).catch(
            error => {
                this.props.widgetError(this.getType(), error);
                this.props.widgetWorking(this.getType(), false);
            }
        )
    }

    fetchFilteredList(filters = []) {
        const {dispatch} = this.props;
        const listLength = 10;

        let filterBy = null;
        let filterValue = null;

        if (filters.length) {
            filterBy = 'state';
            filterValue = filters.map(f => FILTER_VALUES[f]).join(',');
        }

        return dispatch(fetchOfferRequestList(
            1, filterBy, filterValue, listLength
        ));
    }

    getFiltersList(filters) {
        return Object.keys(filters).filter(key => !!filters[key]);
    }

    getType() {
        return 'requests-customer';
    }

    getTitle() {
        return (
            <span>
                <i className="fa fa-file-text"/>&nbsp;
                {l('Your Requests')}
            </span>
        )
    }

    renderTitle() {
        return (
            <Ibox.Title>
                <Row>
                    <Col xs={3}>
                        <h5>{this.getTitle()}</h5>
                    </Col>
                    <Col xs={6} className="text-center">
                        <div className="m-t-n-xs">
                            {this.renderListFilter()}
                        </div>
                    </Col>
                    <Col xs={3}>
                        {this.renderErrorNotification()}
                        {this.renderWorkingNotification()}
                    </Col>
                </Row>
            </Ibox.Title>
        )
    }

    renderListFilter() {
        if (!this.isInitialized()) {
            return null;
        }

        const filters = this.props.data.filters || FILTER_STATE;

        const FilterButton = ({filter, children}) => (
            <button
                className={classNames('table-filter', {'active': filters[filter] || (!filter && !Object.values(filters).includes(true))})}
                onClick={() => this.onFilterClick(filter)}>
                {children}
            </button>
        );

        return (
            <ButtonGroup bsSize="small">
                <FilterButton>
                    {l('All')}
                </FilterButton>
                <FilterButton filter="pending">
                    {l('state_pending')}
                </FilterButton>
                <FilterButton filter="bidding">
                    {l('state_bidding')}
                </FilterButton>
                <FilterButton filter="archived">
                    {l('Archived')}
                </FilterButton>
            </ButtonGroup>
        );
    }

    renderContent() {
        const {data} = this.props;
        const list = data.list || [];
        const insuranceTypes = data.insuranceTypes || [];

        const getInsuranceTypeName = (id) => {
            const insuranceType = insuranceTypes.find(i => i.id == id);
            return insuranceType ? insuranceType.name : null;
        };

        return (
            <div className="no-padding">
                <OfferRequestTable items={list} className="m-b-none table-hover table-striped">
                    <OfferRequestColumn name={l('Number')} field="id"/>
                    <OfferRequestColumn name={l('Type')} field="insuranceTypeId" valueFormat={getInsuranceTypeName}/>
                    <OfferRequestColumn name={l('Offer round end')} cellComponent={EndDateCellComponent}/>
                    <OfferRequestColumn name={l('# Offers')} field="offerCount"/>
                    <OfferRequestColumn name={l('Status')} field="state" valueFormat={(state) => l(`state_${state}`)}/>
                    <OfferRequestColumn name={l('Min. offer')} field="offerPremiumGrossMin" valueComponent={PremiumValueComponent}/>
                    <OfferRequestColumn name={l('Savings')} cellComponent={SavingsCellComponent}/>
                    <OfferRequestColumn name={l('Last offer')} field="offerCreationDateLatest" valueComponent={LocalDate}/>
                    <OfferRequestColumn field="favorite" valueFormat={favoriteValueFormat}/>
                </OfferRequestTable>

                <div className="text-center p-h-xs">
                    <Link to={URI.OFFER_REQUEST_LIST} className="btn btn-default">
                        {l('VIEW ALL')}
                    </Link>
                </div>
            </div>
        )
    }
}
