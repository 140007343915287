import swal from 'sweetalert';
import {SubmissionError} from 'redux-form';

import {
    apiEnableCustomerUser,
    apiGetCustomerUsers,
    apiPostCustomerUser,
    apiSendEmailCustomerUser,
    fetchInsuranceTypes
} from '../../../api/sdk-action';
import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {mapFormValuesToUser} from '../../../helper/mapping/user';
import {buildErrors} from '../../common/form/helper';
import {createAction} from '../../../action';
import {l} from '../../../i18n/translator';

export const ACTION_TYPES = {
    // CREATE_USER_START: 'page.customer_users.create_started',
    // CREATE_USER_END: 'page.customer_users.create_ended',

    UPDATE_USER_EDIT_MODAL: 'page.customer_users.update_user_edit_modal',
    DATA_UPDATE_SINGLE: 'page.customer_users.users.data_update_single',

    ENABLE_USER_START: 'page.customer_users.users.enable_user.start',
    ENABLE_USER_END: 'page.customer_users.users.enable_user.end',
};

export const PAGE_LOADER_ACTION_TYPES = createPageActionTypes('page.customer_users.');

export const initCustomerUsersList = (customerId) => createPageLoader(
    PAGE_LOADER_ACTION_TYPES,
    (dispatch) => {
        return Promise.all([
            dispatch(apiGetCustomerUsers(customerId)),
            dispatch(fetchInsuranceTypes())
        ]).then(([users, insuranceTypes]) => {
            return {users, insuranceTypes};
        });
    }
)();

export const updateUsersList = (customerId, showDisabled) => (dispatch) => {
    dispatch(apiGetCustomerUsers(customerId, showDisabled))
        .then((users) => {
            dispatch(createAction(PAGE_LOADER_ACTION_TYPES.DATA_LOADED, {data: {users}}));
        });
};

export const submitUser = (customerId, data, dispatch) => {
    let userValues = mapFormValuesToUser(data);
    // we currently removed the username field from the form because we always want it to be the same for now
    userValues.username = userValues.email;
    return dispatch(apiPostCustomerUser(customerId, userValues)
    ).then((user) => {
        dispatch(updateUserDataSingle(user));
        return dispatch(closeUserEditForm());
    }).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};

export const enableUser = (customerId, user, enable = true) => {
    return (dispatch) => {
        dispatch({type: ACTION_TYPES.ENABLE_USER_START, id: user.id, enable});

        return dispatch(apiEnableCustomerUser(customerId, user.id, enable)
        ).then(
            () => dispatch({type: ACTION_TYPES.ENABLE_USER_END, id: user.id, enable})
        ).catch(
            error => dispatch({type: ACTION_TYPES.ENABLE_USER_END, id: user.id, enable, user, error})
        );
    };
};

export const resendEmail = (customerId, user) => (dispatch) => {
    swal({
        title: l('Sending E-Mail'),
        type: 'info',
        showConfirmButton: false,
    });

    dispatch(apiSendEmailCustomerUser(customerId, user.id))
        .then(() => {
            swal({
                title: '',
                text: l('E-Mail sent'),
                type: 'success',
            });
        });
};

export const showUserEditForm = (user = {}) => (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_USER_EDIT_MODAL, user, show: true});
};

export const closeUserEditForm = () => (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_USER_EDIT_MODAL, user: {}, show: false});
};

export const updateUserDataSingle = (user) => (dispatch) => {
    dispatch({type: ACTION_TYPES.DATA_UPDATE_SINGLE, user});
};
