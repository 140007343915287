/**
 * Created by br0wn on 12/1/16.
 */


export const updateOffer = (state, offer) => {
    const {offerList = []} = state.data;
    const offerIndex = offerList.findIndex(o => o.id == offer.id);

    // skip if offer not found
    if (offerIndex == -1) {
        return addOffer(state, offer);
    }

    return updateOfferInState(state, offerList, offer, offerIndex);
};

export const updateOfferRatingComment = (state, offer, analysisField, criteriaId, comment) => {
    const {offerList = []} = state.data;
    const offerIndex = offerList.findIndex(o => o.id === offer.id);

    // skip if offer not found
    if (offerIndex === -1) {
        return state;
    }

    const updatedOffer = {...state.data.offerList[offerIndex]};

    updatedOffer[analysisField].find((rating) => rating.criteriaId === criteriaId).commentBroker = comment;

    return updateOfferInState(state, offerList, updatedOffer, offerIndex);
};

export const addOffer = (state, offer) => {
    const {offerList = []} = state.data;

    return {
        ...state,
        data: {
            ...state.data,
            offerList: [...offerList, offer]
        }
    };
};

export const removeOffer = (state, offer) => {
    const {offerList = []} = state.data;
    const offerIndex = offerList.findIndex(o => o.id == offer.id);

    // skip if offer not found
    if (offerIndex == -1) {
        return state;
    }

    return {
        ...state,
        data: {
            ...state.data,
            offerList: [
                ...offerList.slice(0, offerIndex),
                ...offerList.slice(offerIndex + 1)
            ]
        }
    };
};

const updateOfferInState = function (state, offerList, offer, offerIndex) {
    return {
        ...state,
        data: {
            ...state.data,
            offerList: [
                ...offerList.slice(0, offerIndex),
                {
                    ...offerList[offerIndex],
                    ...offer
                },
                ...offerList.slice(offerIndex + 1)
            ]
        }
    };
};
