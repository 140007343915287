import {initialize, SubmissionError} from 'redux-form';

import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {
    apiCreateCriteria,
    apiUpdateCriteria,
    fetchAnalysisCriteria,
    fetchInsuranceTypes
} from '../../../api/sdk-action';
import {CriteriaFields} from '../../../../config/domain/analysis';
import URI from '../../../../config/uri.js';
import {buildErrors} from '../../common/form/helper';
import {FORM_NAME} from './page.jsx';
import {redirectTo} from '../../../router/action';

export const ACTION_TYPES = {
    UPDATE_CRITERIA: 'page.analysis_criteria_edit.update_criteria',
    ...createPageActionTypes('page.analysis_criteria_edit.')
};

export const initCriteriaEdit = createPageLoader(
    ACTION_TYPES,
    (dispatch, {criteriaId}) => {
        return Promise.all([
            criteriaId !== null ? dispatch(fetchAnalysisCriteria(criteriaId)) : {},
            dispatch(fetchInsuranceTypes()),
        ]).then(([criteria, insuranceTypes]) => {
            dispatch(initialize(FORM_NAME, criteria));
            return {criteria, insuranceTypes};
        });
    }
);

export const saveCriteria = (data, dispatch) => {
    data = {...data};
    return dispatch(
        data[CriteriaFields.ID]
            ? apiUpdateCriteria(data[CriteriaFields.ID], data)
            : apiCreateCriteria(data)
    ).then(criteria => {
        if (!data.id) {
            dispatch({type: ACTION_TYPES.UPDATE_CRITERIA, criteria: criteria});
            dispatch(redirectTo(URI.ANALYSIS_CRITERIA_EDIT.replace(':id', criteria.id)));
        }
    }).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};
