import * as queryString from 'query-string';

export const updateQueryString = (query, obj) => {
    const queryObj = queryString.parse(query);
    if (typeof obj === 'object') {
        Object.keys(obj).forEach((key) => {
            queryObj[key] = obj[key];
        });
    }
    return '?' + queryString.stringify(queryObj);
};
