import React from 'react';
import {Link} from 'react-router-dom';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {l} from '../../../../i18n/translator';
import URI from '../../../../../config/uri';
import {isDefined} from '../../../../helper/core.js';
import {LocalDate} from '../../../common/i18n/datetime.jsx';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {PremiumValue} from '../../common/i18n/premium.jsx';
import {STEP_CONTRACT_DATA} from '../../create-request/config/wizard';
import EditButton from './editButton.jsx';
import {Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';
import {IBoxLine} from '../../../common/ibox-line';

const OfferRequestDetails = ({offerRequest}) => {
    return (
        <Ibox.Container>
            <Ibox.Title>
                <h5>
                    <i className="fa fa-tag"/>&nbsp;
                    {l('Offer Request Details')}
                </h5>

                <EditButton step={STEP_CONTRACT_DATA}/>
            </Ibox.Title>

            <Ibox.Content>
                <IBoxLine title={l('Start Date')}><LocalDate value={offerRequest[OfferRequestFields.DESIRED_INSURANCE_START]}/></IBoxLine>

                <div className="m-t-md"/>

                <IBoxLine title={l('Billing frequency')}>
                    {offerRequest[OfferRequestFields.INSURANCE_BILLING_FREQUENCY_MONTHS] ? l(`billing_frequency_${offerRequest[OfferRequestFields.INSURANCE_BILLING_FREQUENCY_MONTHS]}`) : null}
                </IBoxLine>
                <IBoxLine title={l('Insurance Period')}>
                    {isDefined(offerRequest.insurancePeriodYears) ? (
                        l('{{count}} years', {count: offerRequest[OfferRequestFields.INSURANCE_PERIOD_YEARS]})
                    ) : null}
                </IBoxLine>
                <IBoxLine title={l('Yearly right of termination')}>
                    {isDefined(offerRequest[OfferRequestFields.INSURANCE_YEARLY_RIGHT_OF_TERMINATION]) ? (
                        offerRequest[OfferRequestFields.INSURANCE_YEARLY_RIGHT_OF_TERMINATION] ? l('Yes') : l('No')
                    ) : null}
                </IBoxLine>


                {offerRequest[OfferRequestFields.TARGET_INSURANCE_PREMIUM] ? (
                    <div>
                        <div className="m-t-md"/>
                        <Row>
                            <Col md={6}>
                                <strong>
                                    {l('Target premium')}&nbsp;
                                    <OverlayTrigger overlay={(
                                        <Tooltip id="target-premium">
                                            {l('This is given to insurances as a target premium. This can as well be seen as a maximum premium.')}
                                        </Tooltip>
                                    )}>
                                        <i className="fa fa-info-circle"/>
                                    </OverlayTrigger>
                                </strong>
                            </Col>

                            <Col md={6}>
                                <PremiumValue value={offerRequest[OfferRequestFields.TARGET_INSURANCE_PREMIUM]}/>
                            </Col>
                        </Row>
                    </div>
                ) : null}

                <div className="m-t-md"/>

                <IBoxLine title={l('Advisory Mandate')}>
                    {offerRequest.files && offerRequest.files.advisoryMandate ? (
                        <span>
                            {offerRequest.id ? (
                                <Link
                                    to={URI.OFFER_REQUEST_FILE
                                        .replace(':id', offerRequest[OfferRequestFields.ID])
                                        .replace(':fileId', offerRequest.files.advisoryMandate.id)}
                                    target="_blank">
                                    {offerRequest.files.advisoryMandate.fileName}
                                </Link>
                            ) : (
                                <Link to={URI.FILE.replace(':id', offerRequest.files.advisoryMandate.id)}
                                      target="_blank">
                                    {offerRequest.files.advisoryMandate.fileName}
                                </Link>
                            )}
                        </span>
                    ) : null}
                </IBoxLine>
            </Ibox.Content>
        </Ibox.Container>
    );
};

export default OfferRequestDetails;
