import {TYPES as ACTION_TYPES} from './action';

const INITIAL_STATE = {
    form: {
        errors: {}
    },
    isFetching: false,
    isSuccess: false
};

/**
 * Main App reducer
 *
 * @param state
 * @param action
 *
 * @return {object}
 */
let resetPasswordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.SUBMIT_START: {
            return {
                ...INITIAL_STATE,
                isFetching: true
            };
        }
        case ACTION_TYPES.SUBMIT_SUCCESS: {
            return {
                ...INITIAL_STATE,
                isSuccess: true
            };
        }
        case ACTION_TYPES.SUBMIT_FAILED: {
            return {
                ...state,
                form: {
                    errors: action.errors
                },
                isFetching: false,
                isSuccess: false
            };
        }
    }

    return state;
};

export default resetPasswordReducer;
