import React from 'react';
import {withRouter} from 'react-router';
import {Link, NavLink} from 'react-router-dom';
import {Dropdown, MenuItem} from 'react-bootstrap';
import {connect} from 'react-redux';
import classNames from 'classnames';
import swal from 'sweetalert'; // eslint-disable-line
import Button from '@material-ui/core/Button';
import {l} from '../../i18n/translator';
import URI from '../../../config/uri';
import {CompanyTypes} from '../../../config/domain/user.js';
import {canUserCreateOfferRequest, getUser, isUserLoggedIn, isUserOfType} from '../../auth/helper.js';
import BrokerOnly from '../common/auth/acl/brokerOnly.jsx';
import {redirectTo} from '../../router/action.js';
import {doLogout} from '../../auth/action';
import {setLocale} from '../../i18n/action';
import NotificationsNavbarWidget from './navbar-top/notificationsWidget.jsx';
import {ENVIRONMENT, IS_PRODUCTION} from '../../../config/api/endpoint.js';
import {redirectToWithoutDispatch} from '../../router/action';
import AdminOnly from '../common/auth/acl/adminOnly';


const MenuLinkItem = withRouter(
    ({router, children, params, match, location, routes, staticContext, ...props}) => (
        <li className={classNames({
            'active': location && location.pathname === props.to
        })}>
            <NavLink {...props} activeClassName="active">
                {children}
            </NavLink>
        </li>
    )
);

const MenuItemWithRouter = withRouter(({router, children, to, ...props}) => (
    <MenuItem onClick={e => redirectToWithoutDispatch(to)}>
        {children}
    </MenuItem>
));


/**
 * AppNavbarTop
 */
export let AppNavbarTop = ({i18n, auth, actions, dispatch, router}) => (
    <div className="row">

        {/* MAIN NAVBAR */}
        <nav className="navbar navbar-static-top navbar--main" role="navigation">

            {/* BRAND ICON */}
            <div className="navbar-header">

                {/*<a className="navbar-minimalize minimalize-styl-2 btn btn-primary">*/}
                {/*<i className="fa fa-bars"></i>*/}
                {/*</a>*/}

                {
                    !IS_PRODUCTION ? (<div className="watermark">{ENVIRONMENT}</div>) : null
                }

                <Link to={URI.DASHBOARD} className="navbar-logo">
                    <img src={require('../../../resources/images/logo-top.png')}/>
                </Link>

                {isUserLoggedIn(auth) ? (
                    <span className="navbar-brand navbar-welcome">
                        {l('Welcome')}, {getUser(auth).email}
                    </span>
                ) : null}
            </div>

            <div className="navbar-collapse collapse" id="navbar">

                {/* RIGHT MENU */}
                <ul className="nav navbar-top-links navbar-right">
                    {/* Notifications */}
                    <NotificationsNavbarWidget/>

                    {/* Language switch */}
                    <Dropdown id="nav-dropdown-locale" componentClass="li">
                        <Dropdown.Toggle componentClass="a" useAnchor>
                            {i18n.locale.substr(0, 2).toUpperCase()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <MenuItem onClick={e => actions.onLanguageChange('de-CH')}>
                                <img src={require('../../../resources/images/flags/16/Germany.png')}/> Deutsch
                            </MenuItem>
                            <MenuItem onClick={e => actions.onLanguageChange('en')}>
                                <img src={require('../../../resources/images/flags/16/United-Kingdom.png')}/> English
                            </MenuItem>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Profile */}
                    {isUserLoggedIn(auth) ? (
                        <Dropdown id="nav-dropdown-profile" componentClass="li">
                            <Dropdown.Toggle componentClass="a" useAnchor>
                                <i className="fa fa-user fa-lg"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <MenuItemWithRouter to={URI.PROFILE}>
                                    <i className="fa fa-gear"></i> {l('Profile')}
                                </MenuItemWithRouter>
                                <MenuItem onClick={e => actions.doLogout(e)}>
                                    <i className="fa fa-sign-out"></i> {l('Log out')}
                                </MenuItem>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : null}
                </ul>
            </div>
        </nav>

        {/* MENU NAVBAR */}
        <nav className="navbar navbar--menu white-bg border-bottom" role="navigation">
            <div className="navbar-collapse collapse" id="navbar">

                {/* MAIN MENU */}
                <ul className="nav navbar-nav left">
                    <MenuLinkItem to={URI.DASHBOARD}>
                        {l('Dashboard')}
                    </MenuLinkItem>

                    <MenuLinkItem to={URI.OFFER_REQUEST_LIST}>
                        {isUserOfType(auth, CompanyTypes.CUSTOMER) ? l('Requests list') : l('Customer Requests')}
                    </MenuLinkItem>

                    <BrokerOnly auth={auth}>
                        <MenuLinkItem to={URI.CUSTOMERS}>
                            {l('Customers')}
                        </MenuLinkItem>
                    </BrokerOnly>
                    <BrokerOnly auth={auth}>
                        <AdminOnly auth={auth}>
                            <>
                                <MenuLinkItem to={URI.ANALYSIS_CRITERIA}>
                                    {l('Criteria')}
                                </MenuLinkItem>
                                <MenuLinkItem to={URI.ANALYSIS_AUTO_RATINGS}>
                                    {l('Auto rating')}
                                </MenuLinkItem>
                            </>
                        </AdminOnly>
                    </BrokerOnly>
                </ul>


                {isUserOfType(auth, [CompanyTypes.BROKER]) && canUserCreateOfferRequest(auth) ? (
                    <ul className="nav navbar-nav right">
                        <li className="m-l-sm">
                            <div style={{marginTop: '8px'}}>
                                <Button variant="contained" color="primary"
                                        onClick={() => dispatch(redirectTo(URI.CREATE_OFFER_REQUEST))}>
                                    {l('Create Offer Request')}
                                </Button>
                                <span className="m-l">
                                    <Button variant="contained" color="primary"
                                            onClick={() => dispatch(redirectTo(URI.CREATE_OFFER_REQUEST_FINISHED))}>
                                        {l('Create Offer Overview')}
                                    </Button>
                                </span>
                            </div>
                        </li>
                    </ul>
                ) : null}
            </div>
        </nav>
    </div>
);

function mapStateToProps(state) {
    return {
        auth: state.auth,
        i18n: state.i18n
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            doLogout: () => {
                swal({
                    title: l('Are you sure?'),
                    type: 'warning',
                    showCancelButton: true
                }, () => {
                    dispatch(doLogout());
                });
            },
            onLanguageChange: (locale) => {
                dispatch(setLocale(locale));
            }
        }, dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppNavbarTop);
