import React from 'react';
import PropTypes from "prop-types";
import {Roles as UserRoles} from '../../../../../config/domain/user';
import UserRoleOnly from './userRoleOnly.jsx';

/**
 * Shows content only for user type insurance
 *
 * @param {object} auth
 * @param {*} children
 * @return {*}
 */
const AdminOnly = ({auth, children}) => (
    <UserRoleOnly role={UserRoles.ADMIN} auth={auth}>
        {children}
    </UserRoleOnly>
);

AdminOnly.propTypes = {
    'auth': PropTypes.object.isRequired
};

export default AdminOnly;
