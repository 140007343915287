import {AttachmentTypes} from '../../../../../config/domain/file';

export const removeOfferRequestFile = (state, file) => {
    const files = {...state.data.offerRequest.files};

    switch (file.attachmentType) {
        case AttachmentTypes.MANDATE:
            files.advisoryMandate = null;
            break;
        case AttachmentTypes.HARM_HISTORY:
            removeFileFromArray(files.harmHistory, file);
            break;
        case AttachmentTypes.ATTACHMENT:
            removeFileFromArray(files.attachments, file);
            break;
    }

    return updateStateWithFiles(state, files);
};

export const addOfferRequestFile = (state, file) => {
    const files = {...state.data.offerRequest.files};

    switch (file.attachmentType) {
        case AttachmentTypes.MANDATE:
            files.advisoryMandate = file;
            break;
        case AttachmentTypes.HARM_HISTORY:
            files.harmHistory.push(file);
            break;
        case AttachmentTypes.ATTACHMENT:
            files.attachments.push(file);
            break;
    }

    return updateStateWithFiles(state, files);
};

export const removeFileFromArray = (filesArray, file) => {
    const fileIndex = filesArray.findIndex(f => f.id == file.id);
    if (fileIndex >= 0) {
        filesArray.splice(fileIndex, 1);
    }
};

export const updateStateWithFiles = (state, files) => {
    return {
        ...state,
        data: {
            ...state.data,
            offerRequest: {
                ...state.data.offerRequest,
                files
            }
        }
    };
};
