import React from 'react';
import {FormattedDate, FormattedTime, FormattedRelative} from 'react-intl';


export const LocalDate = ({value, children, ...props}) => {
    return value ? (
        <FormattedDate
            value={value}
            {...props}
        >
            {children}
        </FormattedDate>
    ) : null;
};

export const LocalTime = ({value, children, ...props}) => (
    <FormattedTime
        value={value}
        {...props}
    >
        {children}
    </FormattedTime>
);

export const LocalRelative = ({value, children, ...props}) => (
    <FormattedRelative
        value={value}
        {...props}
    >
        {children}
    </FormattedRelative>
);