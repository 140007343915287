import React from 'react';
import {connect} from 'react-redux';
import {Types as InsuranceTypes} from '../../../../../../config/domain/insurance.js';
import {InsuranceType} from '../../../common/insuranceType.jsx';

import {Fields, FORM_NAME} from '../../config/form';
import {CreditInsuranceForm} from './insuranceData/credit.form.jsx';
import {UVGInsuranceForm} from './insuranceData/uvg.form.jsx';
import {DefaultInsuranceForm} from './insuranceData/default.form.jsx';

import {formValueSelector} from 'redux-form';


const InsuranceDataForm = ({insuranceTypeId, ...props}) => {
    return (
        <InsuranceType.Container activeType={insuranceTypeId}>
            <InsuranceType.Type type={InsuranceTypes.CREDIT}>
                <CreditInsuranceForm {...props} />
            </InsuranceType.Type>

            <InsuranceType.Type type={InsuranceTypes.UVG}>
                <UVGInsuranceForm {...props} />
            </InsuranceType.Type>

            <InsuranceType.Default>
                <DefaultInsuranceForm {...props} />
            </InsuranceType.Default>
        </InsuranceType.Container>
    )
};

// Decorate with connect to read form values
const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        insuranceTypeId: selector(state, Fields.INSURANCE_TYPE_ID),
        offerRequestId: selector(state, Fields.ID)
    })
)(InsuranceDataForm);
