import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {l} from '../../../../i18n/translator';
import URI from '../../../../../config/uri';
import {ACTIONS} from '../../../../../config/domain/entity';
import {EntityActionOnly} from '../../../common/auth/acl/entityAction.jsx';
import {isCreatedFinishedOffer} from '../../../../model/offerRequest';


const DetailsActions = (props) => {
    const {
        offerRequest, actions: {deleteOfferRequest, toggleWithdrawModal, publishOfferRequest},
        isDeleting, isWithdrawing, isPublishing, publishError
    } = props;

    return (
        <div className="p-h-xs m-b-md m-t-sm">
            <EntityActionOnly action={ACTIONS.EDIT} allowedActions={offerRequest.actions}>
                <Link
                    to={URI.EDIT_OFFER_REQUEST.replace(':id', offerRequest.id)}
                    className="btn  btn-success m-r-sm"
                >
                    <span className="fa fa-pencil"></span>
                    &nbsp;
                    {l('Edit')}
                </Link>
            </EntityActionOnly>

            <EntityActionOnly action={ACTIONS.DELETE} allowedActions={offerRequest.actions}>
                <button
                    className="btn  btn-danger m-r-sm"
                    disabled={isDeleting}
                    onClick={(e) => deleteOfferRequest(offerRequest.id)}
                >
                    <span className={classNames('fa', {
                        'fa-trash': !isDeleting,
                        'fa-cog': isDeleting
                    })}></span>
                    &nbsp;
                    {isDeleting ? l('Deleting ...') : l('Delete')}
                </button>
            </EntityActionOnly>

            {!isCreatedFinishedOffer(offerRequest) ?
                <EntityActionOnly action={ACTIONS.PUBLISH} allowedActions={offerRequest.actions}>
                    <button
                        disabled={isPublishing}
                        className="btn  btn-primary m-r-sm"
                        onClick={(e) => publishOfferRequest(offerRequest)}
                    >
                    <span className={classNames('fa', {
                        'fa-save': !isPublishing,
                        'fa-cog': isPublishing
                    })}></span>
                        &nbsp;
                        {isPublishing ? l('Publishing ...') : l('Publish')}
                    </button>
                </EntityActionOnly> : null}

            <EntityActionOnly action={ACTIONS.WITHDRAW} allowedActions={offerRequest.actions}>
                <button
                    disabled={isWithdrawing}
                    className="btn  btn-danger"
                    onClick={(e) => toggleWithdrawModal(true)}
                >
                    <span className={classNames('fa', {
                        'fa-trash': !isWithdrawing,
                        'fa-cog': isWithdrawing
                    })}></span>
                    &nbsp;
                    {isWithdrawing ? l('Withdrawing ...') : l('Withdraw')}
                </button>
            </EntityActionOnly>

            <div className="text-danger m-t-sm">
                {publishError}
            </div>
        </div>
    )
};

export default DetailsActions;
