import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {l} from '../../../../i18n/translator.js';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {Fields as OfferRequestFields, OfferRoundTypes} from '../../../../../config/domain/offerRequest';
import {Fields as AuctionFields} from '../../../../../config/domain/offerRequest/offer-round/auction';
import {IBoxLine} from '../../../common/ibox-line';
import {LocalDate} from '../../../common/i18n/datetime';
import {isDefined} from '../../../../helper/core';

const AuctionDetail = ({offerRoundData}) => (
    <div>
        <IBoxLine title={l('Auto Extend')}>
            {isDefined(offerRoundData[AuctionFields.END_AUTO_EXTEND]) ? (
                offerRoundData[AuctionFields.END_AUTO_EXTEND] ? l('Yes') : l('No')
            ) : null}
        </IBoxLine>
    </div>
);
const FirstShotDetail = ({offerRoundData}) => (
    <div/>
);

const OfferRoundTypeDetail = ({offerRoundType, offerRoundData}) => {
    if (offerRoundType === OfferRoundTypes.AUCTION) {
        return (<AuctionDetail offerRoundData={offerRoundData}/>);
    } else if (offerRoundType === OfferRoundTypes.FIRST_SHOT) {
        return (<FirstShotDetail/>);
    }
    return (<div/>);
};

export class OfferRoundDetailsComponent extends React.Component {

    render() {
        const {offerRequest} = this.props;

        return (
            <Ibox.Container>
                <Ibox.Title>
                    <h5>
                        <i className="fa fa-file"/>&nbsp;
                        {l('Offer Round')}
                    </h5>
                </Ibox.Title>
                {offerRequest[OfferRequestFields.OFFER_ROUND_TYPE] ? (
                    <Ibox.Content>
                        <IBoxLine title={l('Offer round type')}>
                            {l(`offerRoundType_${offerRequest[OfferRequestFields.OFFER_ROUND_TYPE]}`)}
                        </IBoxLine>
                        <IBoxLine title={l('Bidding End Date')}>
                            <LocalDate value={offerRequest[OfferRequestFields.OFFER_ROUND_END_DATE]}/>
                        </IBoxLine>
                        <OfferRoundTypeDetail
                            offerRoundType={offerRequest[OfferRequestFields.OFFER_ROUND_TYPE]}
                            offerRoundData={offerRequest[OfferRequestFields.OFFER_ROUND_DATA] || {}}/>
                    </Ibox.Content>
                ) : <Ibox.Content/>}
            </Ibox.Container>
        );
    }
}

export default connect(
    state => ({
        i18n: state.i18n
    }),
    dispatch => bindActionCreators({}, dispatch)
)(OfferRoundDetailsComponent);
