import {SubmissionError} from 'redux-form';

import TYPES from '../../create-request/action/type';
import {setFormData, getFormData} from '../../create-request/action/form';
import {buildFormData, buildApiRequestData, buildFormErrors} from '../../create-request/helper';
import {publish as apiPublish} from './api';
import {Fields as OfferRequestFields, CurrentInsuranceFields} from '../../../../../config/domain/offerRequest.js';


export const publish = () => {
    return (dispatch, getState) => {
        dispatch({
            type: TYPES.PUBLISH_START
        });

        // generate request data
        let formData = getFormData(getState);
        const offerRequest = buildApiRequestData(formData);
        const currentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE];

        // might be set from api, but we have no form field for it. So if -1 (I don't know, remove it)
        if (currentInsurance && currentInsurance[CurrentInsuranceFields.ADJUSTMENTS_ONGOING] === -1) {
            delete currentInsurance[CurrentInsuranceFields.ADJUSTMENTS_ONGOING];
        }

        return dispatch(
            apiPublish(offerRequest)
        ).then(() => {
            dispatch({
                type: TYPES.PUBLISH_END,
                isPublished: true
            });

            window.scrollTo(0, 0);
        }).catch(error => {
            // process validation errors if present
            if (error.validationErrors) {
                const validationErrors = buildFormErrors(error.validationErrors, formData);
                throw new SubmissionError(validationErrors);
            }

            // dispatch end with errors action
            dispatch({
                type: TYPES.PUBLISH_END,
                error
            });
        });
    };
};

export const setApiFormData = (apiData, update = false) => {
    return (dispatch) => {
        let values = buildFormData(apiData);

        dispatch(setFormData(values, update));

        return values;
    };
};
