import React from 'react';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import {Link} from 'react-router-dom';
import {formValueSelector} from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem';

import URI from '../../../../../../config/uri';
import {l} from '../../../../../i18n/translator';
import {isDefined} from '../../../../../helper/core.js';
import {Fields, FORM_NAME} from '../../config/form';
import {Field} from '../redux-form-wrapper.jsx';
import {renderSelectField, renderSingleFileUpload} from '../../../../common/form/renderer.jsx';
import {isUserOfType} from '../../../../../auth/helper.js';
import {CompanyTypes} from '../../../../../../config/domain/user';

const renderBrokerField = ({removeAdvisoryMandate, input, ...props}) => {
    const {value, onChange} = input;

    const wrappedOnChange = (newValue) => {
        if (!value || newValue === value) {
            return onChange(value);
        }

        swal({
            title: l('This will remove current advisory mandate!'),
            text: l('Do you want to proceed?'),
            type: 'warning',
            showCancelButton: true,
        }, () => {
            // remove advisory mandate
            removeAdvisoryMandate();
            // change field
            onChange(newValue)
        });
    };

    return renderSelectField({input: {...input, onChange: wrappedOnChange}, ...props});
};


export class BrokerForm extends React.Component {

    render() {
        const {auth, offerRequestId, brokers, companyId, brokerId, insuranceTypeId, removeAdvisoryMandate, i18n} = this.props;
        const templateDownloadEnabled = isDefined(brokerId) && isDefined(insuranceTypeId)
            && (isUserOfType(auth, CompanyTypes.CUSTOMER) || isDefined(companyId));

        return (
            <div>
                <h3 className="m-b-md">
                    {l('Sign the advisory mandate to get a broker')}
                </h3>

                {brokers && brokers.length > 1 ? (
                    <div className="m-b-md">
                        <Field
                            name={Fields.BROKER}
                            component={renderBrokerField}
                            label={l('Select desired broker')}
                            removeAdvisoryMandate={removeAdvisoryMandate}
                            disabled={isUserOfType(auth, CompanyTypes.BROKER)}
                            fullWidth={true}
                        >
                            {brokers.map(broker => (
                                <MenuItem key={broker.id} value={broker.id} primaryText={broker.name}/>
                            ))}
                        </Field>
                    </div>
                ) : null}

                <div className="h-150">
                    {templateDownloadEnabled ? (
                        <p>
                            {l('Click to download the advisory mandate template: ')}&nbsp;

                            <Link
                                to={
                                    URI.OFFER_REQUEST_FILES_ADVISORY_MANDATE_TEMPLATE
                                    + '?' + (companyId ? `customer=${companyId}&` : '')
                                    + `broker=${brokerId}&insuranceType=${insuranceTypeId}`
                                }
                                target="_blank"
                            >
                                {l('template.pdf')}
                            </Link>
                        </p>
                    ) : (
                        <p>
                            {isUserOfType(auth, CompanyTypes.CUSTOMER)
                                ? l('Please select insurance type and broker to enable template download.')
                                : l('Please select customer and insurance type to enable template download.')
                            }
                        </p>
                    )}

                    <Field
                        name={Fields.ADVISORY_MANDATE_FILE}
                        component={renderSingleFileUpload}
                        offerRequestId={offerRequestId}
                        i18n={i18n}
                    />
                </div>
            </div>
        );
    }
}

// Decorate with connect to read form values
const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        companyId: selector(state, Fields.COMPANY_ID),
        brokerId: selector(state, Fields.BROKER),
        insuranceTypeId: selector(state, Fields.INSURANCE_TYPE_ID),
        offerRequestId: selector(state, Fields.ID)
    })
)(BrokerForm);
