/**
 * Created by br0wn on 11/24/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {l} from '../../../../i18n/translator.js';
import {ACTIONS} from '../../../../../config/domain/entity';
import {EntityActionOnly} from '../../../common/auth/acl/entityAction.jsx';
import {LocalDate} from '../../../common/i18n/datetime.jsx';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {updateOfferRequestNotes} from '../action/offerRequest';


export class OfferRequestNotesComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {editMode: false, requestInProgress: false}
    }

    submitNotes(value) {
        const {updateNotes, offerRequest} = this.props;
        this.setState({requestInProgress: true});

        updateNotes(offerRequest, value).then(
            () => this.setState({editMode: false, requestInProgress: false})
        ).catch(
            () => this.setState({requestInProgress: false})
        );
    }

    renderEdit() {
        const {offerRequest} = this.props;
        const {requestInProgress} = this.state;

        return (
            <Ibox.Content>
                <textarea
                    disabled={requestInProgress}
                    ref={node => this.noteInput = node}
                    defaultValue={offerRequest.notes}
                    className="form-control h-150"
                />

                <div className="m-t-sm">
                    <Button disabled={requestInProgress} onClick={() => this.submitNotes(this.noteInput.value)}>
                        {l('Submit')}

                        {requestInProgress ? (
                            <span>
                                &nbsp;
                                <i className="fa fa-cog"/>
                            </span>
                        ) : null}
                    </Button>
                    &nbsp;
                    <Button disabled={requestInProgress} onClick={() => this.setState({editMode: false})}>
                        {l('Cancel')}
                    </Button>
                </div>
            </Ibox.Content>
        );
    };

    renderNotes() {
        const {offerRequest} = this.props;

        return offerRequest.notes ? (
            <Ibox.Content className="no-padding">
                <div className="text-center">
                    <small>
                        {l('Last updated:')}&nbsp;
                        <LocalDate value={offerRequest.notesLastModified}/>
                    </small>
                </div>
                <div className="p-sm">
                    {offerRequest.notes.split("\n").map((part, key) => (
                        <span key={key}>{part}<br /></span>
                    ))}
                </div>
            </Ibox.Content>
        ) : (
            <Ibox.Content className="no-data">
                {l('None specified')}
            </Ibox.Content>
        )
    };

    render() {
        const {editMode = false} = this.state;
        const {offerRequest} = this.props;

        return (
            <Ibox.Container>
                <Ibox.Title>
                    <h5>
                        <i className="fa fa-book"/>&nbsp;
                        {l('Private Notes')}
                    </h5>

                    {!editMode ? (
                        <EntityActionOnly action={ACTIONS.EDIT_NOTES} allowedActions={offerRequest.actions}>
                            <Button bsSize="sm" className="pull-right m-t-n-xs" onClick={() => this.setState({editMode: true})}>
                                <i className="fa fa-pencil"/>&nbsp;
                                {l('Edit')}
                            </Button>
                        </EntityActionOnly>
                    ) : null}
                </Ibox.Title>

                {editMode ?
                    this.renderEdit() :
                    this.renderNotes()
                }
            </Ibox.Container>
        )
    }
}

export const OfferRequestNotes = connect(
    state => ({
        i18n: state.i18n,
        offerRequest: state.page.offerRequestDetails.data.offerRequest
    }),
    dispatch => ({
        updateNotes: (offerRequest, notes) => dispatch(updateOfferRequestNotes(offerRequest, {notes}))
    })
)(OfferRequestNotesComponent);
