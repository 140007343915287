import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {formValueSelector} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import RadioButton from '../../../../common/form/RadioButton.jsx';
import {l} from '../../../../../i18n/translator';
import BrokerOnly from '../../../../common/auth/acl/brokerOnly.jsx';
import {Fields, FORM_NAME} from '../../config/form';
import {Field} from '../redux-form-wrapper.jsx';
import {
    renderCheckbox,
    renderCurrencyField,
    renderRadioGroup,
    renderSelectField,
    renderSingleFileUpload,
    renderTextField
} from '../../../../common/form/renderer.jsx';
import {onCurrentInsurancePremiumChange} from '../../action/form.js';


export class CurrentInsurerForm extends React.Component {

    render() {
        const {auth, offerRequestId, insurances = [], hasPreviousInsurer, noHarmHistory, i18n} = this.props;

        return (
            <div>
                <h3 className="m-b-md">
                    {l('Do you currently have insurance?')}
                </h3>

                <Field name={Fields.HAS_CURRENT_INSURANCE} component={renderRadioGroup} row>
                    <RadioButton value={'yes'} label={l('Yes')}/>
                    <RadioButton value={'no'} label={l('No')}/>
                </Field>

                <Row className={classNames({hidden: hasPreviousInsurer !== 'yes'})}>
                    <Col md={8}>
                        <Field name={Fields.CURRENT_INSURANCE_ID} component={renderSelectField}
                               label={l('Current Insurer')} fullWidth={true}>
                            {insurances.map((insurance, index) => (
                                <MenuItem key={index} value={insurance.id} primaryText={insurance.name}/>
                            ))}
                        </Field>
                    </Col>
                    <Col md={4}>
                        <Field name={Fields.CURRENT_INSURANCE_POLICY_NUMBER} component={renderTextField}
                               label={l('Current policy number')} fullWidth={true}/>
                    </Col>
                </Row>

                <Row className={classNames({hidden: hasPreviousInsurer !== 'yes'})}>
                    <Col md={6}>
                        <BrokerOnly auth={auth}>
                            <Field name={Fields.CURRENT_INSURANCE_PREMIUM_GROSS} component={renderCurrencyField}
                                   onChange={this.props.actions.onCurrentInsurancePremiumChange}
                                   label={l('Gross premium')} fullWidth={true}/>
                        </BrokerOnly>
                        {/*<BrokerOnly auth={auth}>
                         <Field name={Fields.CURRENT_INSURANCE_PREMIUM_NET} component={renderCurrencyField}
                         label={l('Net premium')}/>
                         </BrokerOnly>*/}
                    </Col>
                </Row>

                <div className={classNames({hidden: hasPreviousInsurer !== 'yes'})}>
                    <p className="font-bold m-t-lg">
                        {l('Is a classification (premium increase as a result of the course of the claim) provided for by the existing insurance?')}
                    </p>
                    <Field name={Fields.CURRENT_INSURANCE_ADJUSTMENTS_ONGOING} component={renderRadioGroup}
                           isValueInteger={true} row>
                        <RadioButton value={'1'} label={l('Yes')}/>
                        <RadioButton value={'0'} label={l('No')}/>
                        <RadioButton value={'-1'} label={l("I don't know")}/>
                    </Field>

                    <div className="m-t-lg"></div>

                    <BrokerOnly auth={auth}>
                        <h4>{l('Harm history')}</h4>

                        <Field name={Fields.CURRENT_INSURANCE_NO_HARM_HISTORY} component={renderCheckbox}
                               label={l('No harm history')}/>

                        {noHarmHistory ? null : (
                            <div>
                                <p>{l('Upload harm file')}:</p>
                                <Field
                                    name={Fields.CURRENT_INSURANCE_HARM_HISTORY_FILES}
                                    component={renderSingleFileUpload}
                                    offerRequestId={offerRequestId}
                                    i18n={i18n}
                                />
                            </div>
                        )}
                    </BrokerOnly>
                </div>
            </div>
        );
    }
}

// Decorate with connect to read form values
const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        hasPreviousInsurer: selector(state, Fields.HAS_CURRENT_INSURANCE),
        noHarmHistory: selector(state, Fields.CURRENT_INSURANCE_NO_HARM_HISTORY),
        offerRequestId: selector(state, Fields.ID)
    }),
    (dispatch) => ({
        actions: bindActionCreators({
            onCurrentInsurancePremiumChange: onCurrentInsurancePremiumChange,
        }, dispatch)
    })
)(CurrentInsurerForm);
