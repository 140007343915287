/**
 * Created by br0wn on 11/2/16.
 */

export const Types = {
    HEARTBEAT: 'heartbeat',
    NEW_OFFER: 'offer.new',
    NEW_BEST_OFFER: 'offer.new.best',
    NEW_OFFER_REQUEST: ' offer_request.new',
    NEW_MESSAGE: 'message.new',

    OFFER_REQUEST_INSURANCE_DATA: 'offer_request.request.insurance_data',
    OFFER_REQUEST_VALIDATION_REQUIRED: 'offer_request.validation_required',

    OFFER_VALIDATION_REQUIRED: 'offer.validation_required',
};
