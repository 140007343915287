/**
 * Created by br0wn on 1/23/17.
 */
import React from 'react';
import {Field, reduxForm} from 'redux-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {l} from '../../../../i18n/translator.js';
import {renderTextField} from '../../../common/form/renderer.jsx';
import {Fields as OfferFields} from '../../../../../config/domain/offer.js';

export class RejectOfferForm extends React.Component {

    static validate(values) {
        const errors = {};

        if (!values[OfferFields.REJECT_REASON]) {
            errors[OfferFields.REJECT_REASON] = l('Please enter a reason');
        }

        return errors;
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <form onSubmit={handleSubmit} className="text-center">
                <h2>
                    {l('What is the reason for rejecting?')}
                </h2>
                <div>
                    <Field name={OfferFields.REJECT_REASON} component={renderTextField}
                           placeholder={l('You can enter your reason here')}
                    fullWidth/>
                </div>
            </form>
        )
    }

}

export const RejectOfferReduxForm = reduxForm({
    form: 'reject-offer',
    validate: RejectOfferForm.validate
})(RejectOfferForm);


export class RejectOfferModal extends React.Component {

    constructor(props) {
        super(props);

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm() {
        if (!this.form) {
            throw new Error("Form reference does not exist");
        }

        this.form.submit();
    }

    onSubmit(values) {
        const {onSubmit} = this.props;

        if (onSubmit && onSubmit.call) {
            onSubmit(values[OfferFields.REJECT_REASON]);
        }
    }

    render() {
        const {show, onCancel, meta: {submitting}} = this.props;

        return (
            <Dialog open={show} onBackdropClick={onCancel}>
                <DialogTitle>{l('Reject offer')}</DialogTitle>
                <DialogContent>
                    <RejectOfferReduxForm ref={node => this.form = node} onSubmit={this.onSubmit.bind(this)}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={submitting} onClick={onCancel}>{l('No, leave it be')}</Button>
                    <Button disabled={submitting} color="primary"
                            onClick={this.submitForm}>{l('Yes, reject offer')}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
