const TYPES = {
    DATA_LOADED: 'page.offer_request_details.data_loaded',

    PAGE_LOAD_START: 'page.offer_request_details.load_start',
    PAGE_LOAD_END: 'page.offer_request_details.load_end',
    PAGE_RESET: 'page.offer_request_details.reset',

    UPDATE_OFFER_EDIT_MODAL: 'page.offer_request_details.offer_request.UPDATE_OFFER_EDIT_MODAL',
    UPDATE_WITHDRAW_REQUEST_MODAL: 'page.offer_request_details.offer_request.UPDATE_WITHDRAW_REQUEST_MODAL',

    DELETE_OFFER_REQUEST_START: 'page.offer_request_details.offer_request.delete_start',
    DELETE_OFFER_REQUEST_END: 'page.offer_request_details.offer_request.delete_end',

    PUBLISH_OFFER_REQUEST_START: 'page.offer_request_details.offer_request.publish_start',
    PUBLISH_OFFER_REQUEST_END: 'page.offer_request_details.offer_request.publish_end',

    WITHDRAW_OFFER_REQUEST_START: 'page.offer_request_details.offer_request.withdraw_start',
    WITHDRAW_OFFER_REQUEST_END: 'page.offer_request_details.offer_request.withdraw_end',

    DELETE_OFFER_START: 'page.offer_request_details.offer.delete_start',
    DELETE_OFFER_END: 'page.offer_request_details.offer.delete_end',

    ABSTAIN_OFFER_START: 'page.offer_request_details.offer.abstain_start',
    ABSTAIN_OFFER_END: 'page.offer_request_details.offer.abstain_end',

    UPDATE_OFFER: 'page.offer_request_details.offer.update',
    REMOVE_OFFER: 'page.offer_request_details.offer.remove',
    UPDATE_OFFER_COMMENT: 'page.offer_request_details.offer.update_comment',

    THREAD_LIST_LOAD_START: 'page.offer_request_details.chat.thread_list_load_start',
    THREAD_LIST_LOAD_END: 'page.offer_request_details.chat.thread_list_load_end',

    THREAD_SELECTED: 'page.offer_request_details.chat.thread_selected',

    THREAD_MESSAGES_LOAD_START: 'page.offer_request_details.chat.thread_messages_load_start',
    THREAD_MESSAGES_LOAD_END: 'page.offer_request_details.chat.thread_messages_load_end',

    MESSAGE_SUBMIT_START: 'page.offer_request_details.chat.message_submit_start',
    MESSAGE_SUBMIT_END: 'page.offer_request_details.chat.message_submit_end',

    CLEAR_MESSAGE_SENT: 'page.offer_request_details.chat.clear_message_sent',

    FILE_UPLOADED: 'page.offer_request_details.files.uploaded',
    FILE_REMOVED: 'page.offer_request_details.files.removed',

    UTILITY_ANALYSIS_UPDATED: 'page.offer_request_details.utility_analysis.updated'
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    PAGE_RESET: TYPES.PAGE_RESET,
    DATA_LOADED: TYPES.DATA_LOADED
};

export const CHAT_ACTIONS = {
    THREAD_LIST_LOAD_START: TYPES.THREAD_LIST_LOAD_START,
    THREAD_LIST_LOAD_END: TYPES.THREAD_LIST_LOAD_END,

    THREAD_SELECTED: TYPES.THREAD_SELECTED,

    THREAD_MESSAGES_LOAD_START: TYPES.THREAD_MESSAGES_LOAD_START,
    THREAD_MESSAGES_LOAD_END: TYPES.THREAD_MESSAGES_LOAD_END,

    MESSAGE_SUBMIT_START: TYPES.MESSAGE_SUBMIT_START,
    MESSAGE_SUBMIT_END: TYPES.MESSAGE_SUBMIT_END,

    CLEAR_MESSAGE_SENT: TYPES.CLEAR_MESSAGE_SENT
};

export default TYPES;
