/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import classNames from 'classnames';


export const Title = (props) => (
    <div {...props} className={classNames('ibox-title', props.className)}>
        {props.children}
    </div>
);

export const Content = (props) => (
    <div {...props} className={classNames('ibox-content', props.className)}>
        {props.children}
    </div>
);

export const Footer = (props) => (
    <div {...props} className={classNames('ibox-footer', props.className)}>
        {props.children}
    </div>
);

export const Tools = (props) => (
    <div {...props} className={classNames('ibox-tools', props.className)}>
        {props.children}
    </div>
);

export const Container = (props) => (
    <div {...props} className={classNames('ibox', props.className)}>
        {props.children}
    </div>
);