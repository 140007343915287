import React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import {l} from '../../../../i18n/translator';
import {ACTIONS} from '../../../../../config/domain/entity';
import {CompanyTypes} from '../../../../../config/domain/user';
import {Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';
import {Fields as OfferFields} from '../../../../../config/domain/offer.js';
import {sortOfferList} from '../../helper';
import {LocalDate, LocalTime} from '../../../common/i18n/datetime.jsx';
import {EntityActionOnly, EntityAnyActionOnly, WrapWhenActionAllowed} from '../../../common/auth/acl/entityAction.jsx';
import {PremiumValue} from '../../common/i18n/premium.jsx';
import BrokerOnly from '../../../common/auth/acl/brokerOnly.jsx';
import {isUserOfType} from '../../../../auth/helper.js';
import {hasPublishedOffer} from '../../../../model/offer';
import CustomSelect from '../../../common/form/select';
import {isActionPermitted} from '../../../../auth/helper';

export class IssuedOffers extends React.Component {

    render() {
        const {offerRequest, offers, allOffers, insurances, auth, actions: {abstain, createOffer, viewOffer, editOffer, deleteOffer}, deleteStatus: {deletingOffers}} = this.props;

        const abstainInsurances = offerRequest[OfferRequestFields.REQUEST_FROM_INSURANCE_IDS]
            ? insurances.filter((insurance) => {
                return offerRequest[OfferRequestFields.REQUEST_FROM_INSURANCE_IDS].indexOf(insurance.id) !== -1
                    && !hasPublishedOffer(allOffers, insurance.id);
            }) : null;
        const sortedOffers = sortOfferList(offers, 'desc');
        return (
            <div className="ibox">
                <div className="ibox-title">
                    <h5>
                        <i className="fa fa-file-text"/>&nbsp;
                        {l('Issued offers')}
                    </h5>
                </div>


                {sortedOffers.length > 0 ? (
                    <div className="ibox-content p-w-xs">
                        <table className="table m-b-none table-striped table-hover">
                            <thead>
                            <tr>
                                <th>{l('Date')}</th>
                                <th>{l('Premium')}</th>
                                <th>{l('Status')}</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedOffers.map((offer, index) => {
                                const isDeleting = deletingOffers.indexOf(offer[OfferFields.ID]) !== -1;
                                const dateTime = offer[OfferFields.PUBLISH_DATE] || offer[OfferFields.CREATION_DATE];
                                const canView = isActionPermitted(ACTIONS.VIEW, offer[OfferFields.ACTIONS]);
                                return (
                                    <tr key={index}
                                        onClick={canView ? () => viewOffer(offerRequest, offer) : () => null}
                                        className={canView ? "pointer" : undefined}>
                                        <td>
                                            <WrapWhenActionAllowed
                                                action={ACTIONS.VIEW}
                                                allowedActions={offer[OfferFields.ACTIONS]}
                                                WrapperComponent={
                                                    ({children}) => (
                                                        <button className="btn btn-link no-margins no-padding"
                                                                onClick={() => viewOffer(offerRequest, offer)}>
                                                            {children}
                                                        </button>
                                                    )
                                                }
                                            >
                                                <LocalDate value={dateTime}/>
                                                <small className="m-l-xs">
                                                    <LocalTime value={dateTime}/>
                                                </small>
                                            </WrapWhenActionAllowed>
                                        </td>

                                        <td>
                                            {offer[OfferFields.PREMIUM_GROSS] ? (
                                                <span>
                                                    <PremiumValue value={offer[OfferFields.PREMIUM_GROSS]}/>
                                                </span>
                                            ) : null}
                                        </td>

                                        <td>
                                            {offer[OfferFields.REQUIRES_MANUAL_PROCESSING] ? (
                                                <i className="fa fa-exclamation-triangle red m-r-xs"></i>) : null}
                                            {l(`state_${offer[OfferFields.STATE]}`)}
                                        </td>

                                        <td className="text-right" style={{width: '80px'}}>
                                            <EntityActionOnly action={ACTIONS.EDIT}
                                                              allowedActions={offer[OfferFields.ACTIONS]}>
                                                <button className="btn btn-xs  btn-link" style={{color: 'green'}}
                                                        onClick={() => editOffer(offerRequest, offer)}>
                                                    <i className="fa fa-pencil" title={l('Edit')}></i>
                                                </button>
                                            </EntityActionOnly>

                                            <EntityActionOnly action={ACTIONS.DELETE}
                                                              allowedActions={offer[OfferFields.ACTIONS]}>
                                                <button
                                                    className="btn btn-xs btn-link"
                                                    disabled={isDeleting}
                                                    onClick={(e) => deleteOffer(offerRequest[OfferRequestFields.ID], offer[OfferFields.ID])}
                                                    style={{color: 'red'}}
                                                >
                                                    <i className={classNames('fa', {
                                                        'fa-trash': !isDeleting,
                                                        'fa-cog': isDeleting
                                                    })} title={l('Delete')}></i>
                                                </button>
                                            </EntityActionOnly>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="ibox-content ibox-heading">
                        <h4>{l('No offers yet')}</h4>
                    </div>
                )}

                <EntityAnyActionOnly actions={[ACTIONS.ABSTAIN, ACTIONS.CREATE_OFFER]}
                                     allowedActions={offerRequest[OfferRequestFields.ACTIONS]}>
                    <div className="ibox-content p-xs">
                        <div className="m-t-sm"/>

                        <EntityActionOnly action={ACTIONS.ABSTAIN}
                                          allowedActions={offerRequest[OfferRequestFields.ACTIONS]}>
                            <button className="btn btn-danger" onClick={(e) => {
                                if (isUserOfType(auth, CompanyTypes.BROKER)) {
                                    this.setState({showAbstainDialog: true});
                                } else {
                                    abstain(offerRequest[OfferRequestFields.ID]);
                                }
                            }}>
                                {l('Abstain from offer')}
                            </button>
                            &nbsp;&nbsp;
                        </EntityActionOnly>

                        <EntityActionOnly action={ACTIONS.CREATE_OFFER}
                                          allowedActions={offerRequest[OfferRequestFields.ACTIONS]}>
                            <button className="btn btn-primary" onClick={(e) => createOffer(offerRequest)}>
                                {l('Create offer')}
                            </button>
                        </EntityActionOnly>
                    </div>
                </EntityAnyActionOnly>

                <BrokerOnly auth={auth}>
                    <Dialog
                        style={{scroll: 'visible'}}
                        open={this.state && this.state.showAbstainDialog || false}
                        onBackdropClick={() => this.setState({showAbstainDialog: false})}>
                        <DialogTitle>{l('Abstain')}</DialogTitle>
                        <DialogContent style={{scroll: 'visible'}}>
                            <div style={{width: '300px'}}>
                                <CustomSelect
                                    label={l('Insurance')}
                                    value={this.state && this.state.insuranceId}
                                    onChange={(value) => this.setState({insuranceId: value})}
                                    fullWidth
                                    isInDialog>
                                    {abstainInsurances
                                        ? abstainInsurances.map((insurance, index) => (
                                            <MenuItem key={index} value={insurance.id} primaryText={insurance.name}/>
                                        )) : null}
                                </CustomSelect>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => this.setState({showAbstainDialog: false})}>
                                {l('Cancel')}
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    abstain(offerRequest[OfferRequestFields.ID], this.state.insuranceId);
                                    this.setState({showAbstainDialog: false});
                                }}>
                                {l('Abstain')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </BrokerOnly>
            </div>
        );
    }
}

export default IssuedOffers;
