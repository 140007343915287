import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import MenuItem from '@material-ui/core/MenuItem';
import {l} from '../../../../../i18n/translator';
import {Fields, FORM_NAME} from '../../config/form';
import {onInsuranceTypeChanged} from '../../action/page';

import {change} from 'redux-form';
import {Field} from '../redux-form-wrapper.jsx';
import {renderSelectField} from '../../../../common/form/renderer.jsx';


let renderInsuranceTypeField = ({onInsuranceTypeChanged, fieldChange, input, ...props}) => {
    const {value, onChange} = input;
    const Component = renderSelectField;

    const wrappedOnChange = (newValue) => {
        if (!value || value === newValue) {
            // reset fields if there was no old value
            if (!value) onInsuranceTypeChanged(newValue);
            // trigger change
            return onChange(newValue);
        }

        swal({
            title: l('Are you sure?'),
            text: "All entered insurance data will be reset.",
            type: 'warning',
            showCancelButton: true
        }, () => {
            // reset
            onInsuranceTypeChanged(newValue);
            // change field
            onChange(newValue)
        });
    };

    return (
        <Component {...props} input={{...input, onChange: wrappedOnChange}}/>
    );
};

renderInsuranceTypeField = connect(
    null,
    dispatch => bindActionCreators({onInsuranceTypeChanged}, dispatch)
)(renderInsuranceTypeField);


export class InsuranceTypeForm extends React.Component {

    render() {
        const {insuranceTypes, change} = this.props;

        return (
            <div>
                <h3>
                    {l('Which insurance is this for?')}
                </h3>
                <Field
                    name={Fields.INSURANCE_TYPE_ID}
                    component={renderInsuranceTypeField}
                    label={l('Select insurance type')}
                    fieldChange={change}
                    fullWidth={true}
                >
                    {insuranceTypes.filter(insuranceType => insuranceType.permission == 'WRITE').map(insuranceType => (
                        <MenuItem key={insuranceType.id} value={insuranceType.id}
                                  primaryText={insuranceType.name}/>
                    ))}
                </Field>
            </div>
        );
    }
}

export default connect(
    null, dispatch => ({
        change: (field, value) => dispatch(change(FORM_NAME, field, value))
    })
)(InsuranceTypeForm);
