/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {l} from '../../../i18n/translator.js';
import URI from '../../../../config/uri.js';
import {States} from '../../../../config/domain/offerRequest.js';
import {fetchInsuranceTypes, fetchOfferRequestList} from '../../../api/sdk-action'
import {BaseWidget} from './widget-base.jsx';
import {
    Column as OfferRequestColumn,
    favoriteValueFormat,
    Table as OfferRequestTable
} from '../../insurance-offer/request-list/component/table.jsx';
import {getInsuranceTypeName} from '../../common/insurnaceType';

export class PendingRequestsWidget extends BaseWidget {

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        const pendingStates = [States.CREATED, States.PENDING_VALIDATION, States.PENDING_MANDATE, States.PENDING_INSURANCE].join(',');
        const listLength = 10;

        Promise.all([
            this.props.dispatch(fetchOfferRequestList(
                1, 'state', pendingStates, listLength
            )),
            this.props.dispatch(fetchInsuranceTypes())

        ]).then(([data, insuranceTypes]) => {
            this.props.widgetInitialized(this.getType(), {list: data.items, insuranceTypes})
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    getType() {
        return 'broker-requests-pending';
    }

    getTitle() {
        return l('Requests - Pending');
    }

    renderContent() {
        const {data} = this.props;
        const list = data.list || [];
        const insuranceTypes = data.insuranceTypes || [];

        return (
            <div className="no-padding">
                <OfferRequestTable items={list} className="m-b-none table-hover table-striped">
                    <OfferRequestColumn name={l('Customer name')} field="customerName"/>
                    <OfferRequestColumn name={l('Type')} field="insuranceTypeId"
                                        valueFormat={(id) => getInsuranceTypeName(insuranceTypes, id)}/>
                    <OfferRequestColumn
                        name={l('% Complete')} field="percentageComplete"
                        valueFormat={value => `${Math.round(value * 100)}%`}
                    />
                    <OfferRequestColumn field="favorite" valueFormat={favoriteValueFormat}/>
                </OfferRequestTable>

                <div className="text-center p-h-xs">
                    <Link to={URI.OFFER_REQUEST_LIST} className="btn btn-default">
                        {l('VIEW ALL')}
                    </Link>
                </div>
            </div>
        )
    }
}
