import {SubmissionError} from 'redux-form';
import {handleApiValidationErrorResponse} from '../../../../lib/http';
import {l} from '../../../i18n/translator';
import API from  '../../../../config/api/routes';
import {jsonPostRequest} from '../../../api/request';
import {userAuthenticated} from '../../../auth/action';
import {makeApiRequest, makeAuthApiRequest} from '../../../api/action';
import {buildErrors} from '../../common/form/helper';


export const TYPES = {
    STEP: 'page.verify.step',
    MOBILE_SUCCESS: 'page.verify.mobile_success',
    MOBILE_REQUIRE_TOKEN: 'page.verify.mobile.require.token'
};

export const apiVerify = (data) => {
    const {email, token, password} = data;
    return (dispatch) => {
        return dispatch(makeApiRequest(API.AUTH.VERIFY, jsonPostRequest({
                body: JSON.stringify({
                    email: email,
                    token: token,
                    password: password
                })
            }))
        ).then(
            response => response.json()
        ).catch(handleApiValidationErrorResponse);
    };
};

export const apiVerifyMobile = (data) => {
    const {email, token, mobile, mobileToken} = data;
    return (dispatch) => {
        // we are already authenticated because of the first step, therefore we use makeAuthApiRequest
        return dispatch(makeAuthApiRequest(API.AUTH.VERIFY_MOBILE, jsonPostRequest({
                body: JSON.stringify({
                    email: email,
                    token: token,
                    mobile: mobile,
                    mobileToken: mobileToken
                })
            }))
        ).catch(handleApiValidationErrorResponse);
    };
};

export let submit = (data, dispatch) => {
    return dispatch(
        apiVerify(data)
    ).then((user) => {
        dispatch(userAuthenticated(user));
        dispatch({type: TYPES.STEP, step: 'mobile'});
    }).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        if (error.requestError) {
            throw new SubmissionError({_error: l('Could not verify user. User could be already verified or not found.')});
        }
        throw new SubmissionError({_error: l('Something went wrong, please reload the page and try again.')});
    });
};

export let verifyMobile = (data, dispatch) => {
    return dispatch(
        apiVerifyMobile(data)
    ).then(() => {
        dispatch({type: TYPES.MOBILE_SUCCESS});
        // dispatch({type: TYPES.STEP, step: 'mobile'}); // we stay and show a 'mobile verified message'
    }).catch((error) => {
        if (error.validationErrors) {
            const validationErrors = buildErrors(error.validationErrors);
            if (validationErrors.mobileToken) {
                dispatch({type: TYPES.MOBILE_REQUIRE_TOKEN, required: true});
            }
            throw new SubmissionError(validationErrors);
        }
        if (error.requestError) {
            throw new SubmissionError({_error: l('Could not verify mobile.')});
        }
        throw new SubmissionError({_error: l('Something went wrong, please reload the page and try again.')});
    });
};
