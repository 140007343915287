import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {l} from '../../../../../../i18n/translator';
import {Field} from '../../redux-form-wrapper';
import {Fields} from '../../../config/form';
import {Fields as AuctionFields} from '../../../../../../../config/domain/offerRequest/offer-round/auction.js';
import {renderCheckbox} from '../../../../../common/form/renderer';

export const AuctionForm = ({...props}) => {
    return (
        <div {...props}>
            <OverlayTrigger placement="bottom" overlay={(
                <Tooltip id="end-auto-extend">
                    {l('Extends offer request ending when last offer published is withing one day of defined end date.')}
                </Tooltip>
            )}>
                <Field name={`${Fields.OFFER_ROUND_DATA}.${AuctionFields.END_AUTO_EXTEND}`} component={renderCheckbox}
                       label={l('Extend offer request ending')}
                       defaultValue={true}/>
            </OverlayTrigger>
        </div>
    );
};
