import React from 'react';
import {connect} from 'react-redux';
import * as queryString from 'query-string';

import {l} from '../../../i18n/translator';
import {isUserLoggedIn} from '../../../auth/helper.js';
import InsurTecLayout from '../../layout/app-layout.jsx';
import AppNavbarTop from '../../layout/navbar-top.jsx';
import {AuthShape} from '../../common/auth/shape'
import LoadedPage from '../../common/page/loaded-page/component.jsx';
import UpdateOfferRequestForm from './component/form.jsx';
import PublishSuccess from './component/publishSuccess.jsx';
import * as PageActions from './action/page';
import * as FormActions from './action/form';
import {getFormPageState, getToken} from './reducer';

export const UserContentWrapper = ({auth, children}) => {
    return !isUserLoggedIn(auth) ? (
        <div className="md-skin">
            <div id="wrapper" className="gray-bg">
                <div id="page-wrapper" className="gray-bg" style={{marginLeft: '0px'}}>
                    <AppNavbarTop/>

                    {children}
                </div>
            </div>
        </div>
    ) : (
        <InsurTecLayout>
            {children}
        </InsurTecLayout>
    );
};

UserContentWrapper.propTypes = {
    auth: AuthShape.isRequired
};


class UpdateOfferRequestDataPage extends React.Component {

    componentWillMount() {
        let {match: {params = {}}, location: {search}, actions} = this.props;
        let offerRequestId = params.id;
        let token = queryString.parse(search).token;

        // do page init
        actions.init(offerRequestId, token);
    }

    componentWillUnmount() {
        this.props.actions.cleanup();
    }

    componentWillReceiveProps(nextProps) {
        let {params = {}, location: {search}, actions} = this.props;
        let nextParams = nextProps.params || {};
        const query = queryString.parse(search);
        const nextQuery = nextProps.location && nextProps.location.search ? queryString.parse(nextProps.location.search) : {};

        if (params.id !== nextParams.id || query.token !== nextQuery.token) {
            actions.cleanup();
            actions.init(nextParams.id, nextQuery.token);
        }
    }

    render() {
        const {pageState, auth} = this.props;
        const {isPublished, data: {offerRequest}} = pageState;

        return (
            <UserContentWrapper auth={auth}>
                <LoadedPage pageState={pageState}>
                    {pageState.isLoaded ? (
                        <div>
                            {/* Title and breadcrumbs */}
                            <div className="row wrapper border-bottom white-bg page-heading">
                                <div className="col-lg-10">
                                    <h2>{l('Update offer request data')}</h2>

                                    {offerRequest ? (
                                        <ol className="breadcrumb">
                                            <li className="active">
                                                <strong>
                                                    {l('Offer request')} #{offerRequest.id}
                                                </strong>
                                            </li>
                                        </ol>
                                    ) : null}
                                </div>
                            </div>

                            <div className="wrapper wrapper-content animated fadeInRight">
                                {!isPublished ? (
                                    <UpdateOfferRequestForm {...this.props} />
                                ) : (
                                    <PublishSuccess {...this.props} />
                                )}
                            </div>
                        </div>
                    ) : null}
                </LoadedPage>
            </UserContentWrapper>
        )

    }

}

function mapStateToProps(state) {
    return {
        pageState: getFormPageState(state),
        initialValues: state.page.createOfferRequest.form.values,
        token: getToken(state),
        i18n: state.i18n,
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: (offerRequestId, token) => {
                dispatch(PageActions.init(offerRequestId, token))
            },
            cleanup: () => {
                dispatch(PageActions.cleanup())
            },
            form: {
                publish: () => {
                    return dispatch(FormActions.publish())
                }
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateOfferRequestDataPage);
