import lodash from 'lodash';
import flatten from 'flat';

/**
 * Checks if value is undefined
 *
 * @param {*} value
 * @return {boolean}
 */
export const isUndefined = value => typeof value === "undefined";

/**
 * Checks if value is null
 *
 * @param {*} value
 * @return {boolean}
 */
export const isNull = value => value === null;

/**
 * Checks if value is defined and not null
 *
 * @param {*} value
 * @return {boolean}
 */
export const isDefined = value => !isUndefined(value) && !isNull(value);

/**
 * Checks if value is a function
 *
 * @param {*} value
 * @return {boolean}
 */
export const isFunction = value => lodash.isFunction(value);

/**
 * Checks if value is array
 *
 * @param {*} value
 * @return {boolean}
 */
export const isArray = value => Array.isArray(value);

/**
 * Checks if value is string
 *
 * @param value
 * @return {boolean}
 */
export const isString = value => lodash.isString(value);

/**
 * Checks if value has zero length (using `length` property, if property is not defined it will return false)
 *
 * @param {*} value
 * @return {boolean}
 */
export const isZeroLength = value => isDefined(value.length) ? value.length <= 0 : false;

/**
 * Checks if value is empty (defined and no zero length)
 *
 * @param {*} value
 * @return {boolean}
 */
export const isEmpty = value => {
    return !isDefined(value) || isZeroLength(value);
};

/**
 * Returns value of deep object prop. Props are chained with dot character ('.').
 * If object has prop named the same as passed prop it will be returned.
 *
 *  Usage: objectProp('some.deep.prop', object);
 *   for {'some.deep.prop': 'foo'} it will return 'foo'
 *   for { some: {deep: {prop: bar} } } it will return bar
 *
 * @param {string} prop
 * @param {object} object
 * @param {*} defaultValue
 *
 * @return {*}
 */
export const objectProp = (prop, object = {}, defaultValue) => {
    const value = lodash.get(object, prop);

    if (!isDefined(value) && !isUndefined(defaultValue)) {
        return defaultValue;
    }

    return value;
};

/**
 * Sets value of deep object prop. Props are chained with dot character ('.').
 * If object has prop named the same as passed prop it's value will be set.
 *
 * @param {string} prop
 * @param {*} value
 * @param {object} object
 */
export const objectPropSet = (prop, value, object = {}) => lodash.set(object, prop, value);

/**
 * If deep object prop is an empty string it will be set to null. Props are chained with dot character ('.').
 *
 * @param {string} prop
 * @param {object} object
 */
export const objectPropBlankToNull = (prop, object = {}) => {
    const value = objectProp(prop, object);
    if (typeof value === 'string' && value.trim().length === 0) {
        return objectPropSet(prop, null, object);
    }
    return object;
};

/**
 * Removes deep object prop. Mutates the object and returns it.
 *
 * @param {string} prop
 * @param {object} object
 * @return {object}
 */
export const objectPropUnset = (prop, object) => {
    const [match, parentProp, arrayIndex] = prop.match(/^(.*)\[(\d+)\]$/) || [];

    // if not index prop, assume it's object and do standard operation
    if (!match) {
        lodash.unset(object, prop);
        return object;
    }

    const parent = objectProp(parentProp, object);

    if (!Array.isArray(parent)) {
        lodash.unset(object, prop);
        return object;
    }

    parent.splice(arrayIndex, 1);

    return object;
};

/**
 * Filters object keys
 *
 * @param {object} object
 * @param {function} filter
 * @return {Array}
 */
export const objectFilterKeys = (object, filter = key => key) => {
    return Object.keys(object).filter(filter);
};

/**
 * Returns part of the object based on keys.
 * If `keys` is a function, it will act as a filter for object keys (@see objectFilterKeys)
 *
 * @param {object} object
 * @param {Array|function} keys
 * @return {object}
 */
export const objectPickByKeys = (object, keys) => {
    if (!isArray(keys) && !isFunction(keys)) {
        throw new Error("Keys parameter must be function or array");
    }

    keys = isFunction(keys) ? objectFilterKeys(object, keys) : keys;
    keys = !isArray(keys) ? [] : keys;

    return keys.reduce((carry, key) => {
        const value = objectProp(key, object);

        return isUndefined(value) ? carry : objectPropSet(key, value, carry);
    }, {});
};

/**
 * Flattens nested object
 *
 * @param {object} object
 * @param {object=} options
 * @return {object}
 */
export const objectFlatten = (object, options) => {
    const flattenObject = flatten(object, options);

    return Object.keys(flattenObject).reduce((carry, key) => {
        // replace '.0' notation with '[0]' notation for arrays
        const newKey = key.replace(/\.(\d+)/g, '[$1]');

        return {...carry, [newKey]: flattenObject[key]};
    }, {});
};

/**
 * Creates a new object by deep merging objects passed. Does not mutate passed objects.
 *
 * @param {object} args
 */
export const objectMerge = (...args) => lodash.merge({}, ...args);
