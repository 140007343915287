import * as queryString from 'query-string';
import URI from '../../config/uri';
import {isUserLoggedIn, isUserOfType, hasUserRole} from '../auth/helper';
import {redirectToWithoutDispatch} from './action';

export const requireAuth = (store) => (props) => {
    const state = store.getState();

    if (!isUserLoggedIn(state.auth)) {
        redirectToWithoutDispatch({
            pathname: URI.LOGIN,
            search: '?' + queryString.stringify({ref: props.location.pathname}),
            state: {nextPathname: props.location.pathname}
        });
        return false;
    }
    return true;
};

export const requireAnonymous = (store) => (/*props*/) => {
    const state = store.getState();
    if (isUserLoggedIn(state.auth)) {
        redirectToWithoutDispatch({pathname: URI.DASHBOARD});
        return false;
    }
    return true;
};

export const requireUserType = (type, store) => (/*props*/) => {
    const {auth} = store.getState();
    const hasAccess = isUserOfType(auth, type);

    if (!hasAccess) {
        redirectToWithoutDispatch({pathname: URI.ACCESS_FORBIDDEN});
        return false;
    }
    return true;
};

export const requireUserRole = (role, store) => (/*props*/) => {
    const {auth} = store.getState();
    const hasAccess = hasUserRole(auth, role);

    if (!hasAccess) {
        redirectToWithoutDispatch({pathname: URI.ACCESS_FORBIDDEN});
        return false;
    }
    return true;
};
