/**
 * Created by br0wn on 11/29/16.
 */
import React from 'react';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {l} from '../../../../i18n/translator.js';
import {CompanyTypes} from '../../../../../config/domain/user.js';
import UserTypeOnly from '../../../common/auth/acl/userTypeOnly.jsx'
import {toggleOfferRequestFavorite} from '../action/offerRequest';


export class FavoriteButtonComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {requestInProgress: false};
    }

    toggleFavorite() {
        const {offerRequest, toggleFavorite} = this.props;
        this.setState({requestInProgress: true});

        toggleFavorite(offerRequest).then(
            () => this.setState({requestInProgress: false})
        ).catch(
            () => this.setState({requestInProgress: false})
        );
    }

    render() {
        const {offerRequest, auth} = this.props;
        const {requestInProgress} = this.state;

        return (
            <UserTypeOnly auth={auth} type={[CompanyTypes.BROKER, CompanyTypes.INSURANCE]}>
                <Button
                    bsStyle="default"
                    className="text-uppercase "
                    style={{
                        padding: '8px 15px',
                        fontSize: '20px',
                    }}
                    disabled={requestInProgress}
                    onClick={() => this.toggleFavorite()}
                >
                    <i className="fa fa-star text-danger"/>&nbsp;
                    {offerRequest.favorite ? l('Flagged') : l('Flag') }
                </Button>
            </UserTypeOnly>
        )
    }

}

export const FavoriteButton = connect(
    state => ({
        i18n: state.i18n,
        offerRequest: state.page.offerRequestDetails.data.offerRequest,
        auth: state.auth
    }),
    dispatch => ({
        toggleFavorite: (offerRequest) => dispatch(toggleOfferRequestFavorite(offerRequest))
    })
)(FavoriteButtonComponent);
