import {TYPES as ACTION_TYPES} from './action';

const INITIAL_STATE = {
    step: 'user',
    mobileTokenEnabled: false
};

/**
 * Main App reducer
 *
 * @param state
 * @param action
 *
 * @return {object}
 */
let verifyUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.STEP: {
            return {
                ...state,
                step: action.step
            };
        }

        case ACTION_TYPES.MOBILE_REQUIRE_TOKEN: {
            return {
                ...state,
                mobileTokenEnabled: action.required
            };
        }
    }

    return state;
};

export default verifyUserReducer;
