/**
 * Created by br0wn on 1/14/17.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import {updateQueryString} from '../../../helper/querystring';
import {redirectTo} from '../../../router/action';

export class NavItem extends React.Component {

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        const {id, onSelect} = this.props;

        if (onSelect && onSelect.call) {
            onSelect(e, id);
        }
    }

    render() {
        const {
            id,
            title,
            isActive,
            onSelect, // eslint-disable-line
            ...props
        } = this.props;

        return (
            <Button data-target={`#${id}`} {...props} onClick={this.onClick} active={isActive}>
                {props.index + 1}. {title}
            </Button>
        )
    }

}

NavItem.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,

    title: PropTypes.string,
    isActive: PropTypes.bool,
    onSelect: PropTypes.func
};


export class ContentItem extends React.Component {

    render() {
        const {
            id,
            isActive,
            className: classNameProp,
            children,
            ...props
        } = this.props;

        const className = classNames(
            'wizard-content',
            classNameProp,
            {
                hidden: !isActive
            }
        );

        return (
            <div id={id} className={className}>
                {children}
            </div>
        )
    }
}

ContentItem.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,

    isActive: PropTypes.bool,

};


export class StepNav extends React.Component {
    render() {
        const {
            id,
            navComponent: Component,
            navProps,
            onSelect,
            isActive,
            children,
            ...props
        } = this.props;

        return (
            <Component {...props} {...navProps} {...{id, onSelect, isActive, children}}/>
        );
    }
}

StepNav.defaultProps = {
    navComponent: NavItem
};

StepNav.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,

    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),

    navComponent: PropTypes.any,
    navProps: PropTypes.object,
    isActive: PropTypes.bool,
    isLastActive: PropTypes.bool,
    onSelect: PropTypes.func
};


export class Step extends React.Component {

    render() {
        const {
            id,
            contentComponent: Component,
            contentProps: props,
            isActive,
            title,
            prevStepId,
            nextStepId,
            setStep,
            onPrevStep,
            onNextStep,
            onSave, isSaving, isSaved, isPublished,
            onSummary,
            onValidate, isValidating, isValidated,
            onPublish, isPublishing,
            children
        } = this.props;

        return (
            <Component {...props} {...{
                id, isActive, title, prevStepId, nextStepId, setStep, onPrevStep, onNextStep,
                onSave, isSaving, isSaved, onSummary,
                onValidate, isValidating, isValidated, onPublish, isPublishing, isPublished
            }}>
                {children}
            </Component>
        );
    }
}

Step.defaultProps = {
    contentComponent: ContentItem
};

Step.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,

    isActive: PropTypes.bool,

    onSelect: PropTypes.func,
    navComponent: PropTypes.any,
    navProps: PropTypes.object,

    contentComponent: PropTypes.any,
    contentProps: PropTypes.object
};


export class Wizard extends React.Component {

    constructor(props) {
        super(props);

        this.setStep = this.setStep.bind(this);
    }

    isActive(stepId) {
        const {defaultActive} = this.props;
        let {activeStep = '', parent = ''} = this.props;

        if (parent && parent.length) {
            parent.split(':').forEach(item => {
                activeStep = activeStep.replace(new RegExp(`^${item}:?`), '');
            })
        }

        let activeCheck = activeStep || defaultActive;

        // handle step IDs in format [step:sub-step:...:n-sub-step]
        return `:${activeCheck}:`.indexOf(`:${stepId}:`) !== -1;
    }

    getActiveIndex() {
        const childrenCount = this.props.children.length;
        for (let index = 0; index < childrenCount; index++) {
            let active = this.isActive(this.props.children[index].props.id);
            if (active) {
                return index;
            }
        }
        return -1;
    }

    getStepIds() {
        return React.Children.map(this.props.children, (child, index) => {
            return child.props.id;
        });
    }

    setStep(stepId) {
        const {location, router} = this.props;

        const search = updateQueryString(location.search, {step: stepId});
        this.props.dispatch(redirectTo({
            ...location,
            search: search,
        }));
    }

    addParentToId(parent, stepId) {
        return (parent ? `${parent}:` : '') + stepId;
    }

    renderNav() {
        const {children, onSelect: globalOnSelect, parent} = this.props;
        const activeIndex = this.getActiveIndex();
        const isLastActive = activeIndex == (this.props.children.length - 1);

        return React.Children.map(children, (child, index) => {
            const {id, navComponent, navProps, title, onSelect: childOnSelect} = child.props;

            const isActive = this.isActive(id);
            const onSelect = childOnSelect || globalOnSelect;
            const fullId = this.addParentToId(parent, id);

            return (
                <StepNav key={index} index={index} {...{
                    id: fullId,
                    activeIndex,
                    isActive,
                    isLastActive,
                    navComponent,
                    navProps,
                    onSelect,
                    title,
                    children: child.props.children
                }} />
            )
        });
    }

    renderContent() {
        const {children, parent, onSave, isSaving, isSaved, onSummary, onValidate, isValidating, isValidated, onPublish, isPublishing, isPublished} = this.props;
        const stepIds = this.getStepIds();

        return React.Children.map(children, (child, index) => {
            const {id, title} = child.props;
            const isActive = this.isActive(id);
            const fullId = this.addParentToId(parent, id);

            const prevStepId = index > 0 ? stepIds[index - 1] : undefined;
            const nextStepId = index < stepIds.length ? stepIds[index + 1] : undefined;

            const onPrevStep = prevStepId ? () => this.setStep(prevStepId) : undefined;
            const onNextStep = nextStepId ? () => this.setStep(nextStepId) : undefined;

            return React.cloneElement(child, {
                id: fullId,
                key: index,
                title,
                isActive,
                prevStepId,
                nextStepId,
                onPrevStep,
                onNextStep,
                setStep: this.setStep,
                onSave, isSaving, isSaved,
                onValidate, isValidating, isValidated,
                onSummary,
                onPublish, isPublishing, isPublished
            });
        });
    }

    render() {
        const {
            defaultActive, // eslint-disable-line
            activeStep, // eslint-disable-line
            onSelect, // eslint-disable-line
            parent,  // eslint-disable-line
            onSave, isSaving, isSaved, // eslint-disable-line
            onSummary, // eslint-disable-line
            onValidate, isValidating, isValidated, // eslint-disable-line
            onPublish, isPublishing, isPublished, // eslint-disable-line
            location, params, route, router, routeParams, // eslint-disable-line
            dispatch,
            component: Component,
            navbarComponent: NavbarComponent,
            containerComponent: ContainerComponent,
            ...props
        } = this.props;

        const isLastActive = this.isActive(props.children[props.children.length - 1].props.id);

        return (
            <Component {...props}>
                <NavbarComponent orientation={props.orientation} isLastActive={isLastActive}>
                    {this.renderNav()}
                </NavbarComponent>
                {props.orientation != 'vertical' ? (
                    <ContainerComponent>
                        {this.renderContent()}
                    </ContainerComponent>
                ) : null}
            </Component>
        )
    }
}

Wizard.defaultProps = {
    component: 'div',
    navbarComponent: 'div',
    containerComponent: 'div',
    parent: undefined
};

Wizard.propTypes = {
    defaultActive: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    activeStep: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    parent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    component: PropTypes.any,
    navbarComponent: PropTypes.any,
    containerComponent: PropTypes.any,
    onSelect: PropTypes.func
};

Wizard.Step = Step;
