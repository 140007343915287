
import React from 'react';
import URI from '../../../../config/uri';
import  {Link} from 'react-router-dom';

export const PageNotFoundBox = ({style = {}}) => (
    <div className="middle-box text-center animated fadeInDown" style={style}>
        <h1>404</h1>
        <h3 className="font-bold">Page Not Found</h3>

        <div className="error-desc">
            Sorry, but the page you are looking for has not been found.
            Try checking the URL for error, then hit the refresh button on your browser or
            <Link to={URI.DASHBOARD}> go back to homepage.</Link>
        </div>
    </div>
);
