import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Row} from 'react-bootstrap';
import {scroller} from 'react-scroll';
import {Link} from 'react-router-dom';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import RadioButton from '../../../common/form/RadioButton.jsx';
import URI from '../../../../../config/uri';
import {ACTIONS} from '../../../../../config/domain/entity.js';
import {l} from '../../../../i18n/translator';
import {updateQueryParameter} from '../../../../../lib/url';
import {isActionPermitted} from '../../../../auth/helper.js';
import * as Ibox from '../../../ui-components/ibox.jsx';
import CustomerDetails from '../../request-details/component/customerDetails.jsx';
import {Fields, FORM_NAME} from '../../create-request/config/form';
import InsuranceDataForm from '../../create-request/component/form/insuranceData.form.jsx';
import {
    renderCheckbox,
    renderCurrencyField,
    renderRadioGroup,
    renderSingleFileUpload,
    renderTextField
} from '../../../common/form/renderer.jsx';
import {CurrentInsuranceFields, Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';

const WizardActions = () => null;

const WizardContent = ({children}) => (<div>{children}</div>);


class UpdateOfferRequestForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentStep: ''
        };

        this.setStep = this.setStep.bind(this);
    }

    setStep(currentStep) {
        this.setState({currentStep}, () => {
            const step = `step-${currentStep}`;

            setTimeout(() => scroller.scrollTo(step, {
                duration: 300,
                offset: -20,
                smooth: true
            }));
        });
    }

    render() {
        const {props} = this;
        const {i18n, auth, pageState, actions, token, handleSubmit, noHarmHistory} = props;
        const {isPublishing, data} = pageState;
        const {offerRequest} = data;
        const currentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE] || {};

        const canEdit = isActionPermitted(ACTIONS.EDIT_INSURANCE_DATA, offerRequest.actions);

        const {currentStep} = this.state;
        const stepProps = {i18n, auth, ...data, currentStep, setStep: this.setStep, WizardActions, WizardContent};

        return (

            <div>
                <Row>
                    <Col md={6}>
                        <CustomerDetails customerName={offerRequest[OfferRequestFields.CUSTOMER_NAME]}
                                         contact={offerRequest[OfferRequestFields.CONTACT]}
                                         businessId={offerRequest[OfferRequestFields.BUSINESS_IDENTIFICATION_NUMBER]}
                                         customerIndustryClassificationCode={offerRequest[OfferRequestFields.CUSTOMER_INDUSTRY_CLASSIFICATION_CODE]}/>
                    </Col>

                    <Col md={6}>
                        <Ibox.Container>
                            <Ibox.Content>
                                <h2 className="">
                                    {l('Policy number')}
                                    &nbsp;
                                    #{currentInsurance[CurrentInsuranceFields.POLICY_NUMBER] || '?'}
                                </h2>

                                <dl className="dl-horizontal m-t-md">
                                    <dt>
                                        {l('Insurance type')}:
                                    </dt>
                                    <dd>
                                        {offerRequest.insuranceTypeName}
                                    </dd>
                                </dl>

                                {offerRequest.files && offerRequest.files.advisoryMandate && (
                                    <dl className="dl-horizontal m-t-md">
                                        <dt>
                                            {l('Advisory mandate')}:
                                        </dt>
                                        <dd>
                                            <Link to={updateQueryParameter(
                                                URI.OFFER_REQUEST_INSURANCE_FILE
                                                    .replace(':id', offerRequest.id)
                                                    .replace(':fileId', offerRequest.files.advisoryMandate.id),
                                                'token', token
                                            )} target="_blank">
                                                {offerRequest.files.advisoryMandate.fileName}
                                            </Link>
                                        </dd>
                                    </dl>
                                )}
                            </Ibox.Content>
                        </Ibox.Container>
                    </Col>

                </Row>

                <Row>
                    <Col mdOffset={2} md={8}>
                        <Ibox.Container>
                            <Ibox.Title>
                                <h5>{l('Please enter insurance information')}</h5>
                            </Ibox.Title>

                            <Ibox.Content className="">
                                <Row className="m-b-lg">
                                    <Col md={6}>
                                        <Field name={Fields.CURRENT_INSURANCE_PREMIUM_GROSS} fullWidth
                                               component={renderCurrencyField} label={l('Gross premium')}/>
                                        {/*<Field name={Fields.CURRENT_INSURANCE_PREMIUM_NET}
                                               component={renderCurrencyField} label={l('Net premium')}/>*/}

                                        <Field name={Fields.CURRENT_INSURANCE_POLICY_NUMBER} fullWidth
                                               component={renderTextField} label={l('Current policy number')}/>
                                    </Col>

                                    <Col md={6}>
                                        <div className="hidden-sm hidden-xs" style={{paddingTop: '30px'}}/>

                                        <p className="font-bold">
                                            {l('Is a classification (premium increase as a result of the course of the claim) provided for by the existing insurance?')}
                                        </p>
                                        <Field name={Fields.CURRENT_INSURANCE_ADJUSTMENTS_ONGOING}
                                               component={renderRadioGroup}>
                                            <RadioButton value={'1'} label={l('Yes')}/>
                                            <RadioButton value={'0'} label={l('No')}/>
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="h-150">
                                        <h5>{l('Harm history')}</h5>

                                        <Field name={Fields.CURRENT_INSURANCE_NO_HARM_HISTORY}
                                               component={renderCheckbox} label={l('No harm history')}/>

                                        {noHarmHistory ? null : (
                                            <div>
                                                <p>{l('Upload harm file')}:</p>
                                                <Field
                                                    name={Fields.CURRENT_INSURANCE_HARM_HISTORY_FILES}
                                                    component={renderSingleFileUpload}
                                                    offerRequestId={offerRequest.id}
                                                    currentInsuranceToken={token}
                                                    i18n={i18n}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Ibox.Content>
                        </Ibox.Container>


                        <Ibox.Container>
                            <Ibox.Content className="text-center">
                                <InsuranceDataForm {...stepProps} hideAttachments={true}/>
                            </Ibox.Content>
                        </Ibox.Container>

                    </Col>
                </Row>

                <div className="row">
                    <div className="col-sm-offset-6 col-sm-6 text-right">
                        <Button disabled={!canEdit || props.isPublishing} bsStyle="primary"
                                onClick={handleSubmit(() => actions.form.publish())}>
                            {l('Validate and Publish')}

                            {isPublishing ? (
                                <i className="fa fa-cog m-l-md"></i>
                            ) : null}
                        </Button>
                    </div>
                </div>
            </div>
        )
    };
}

const selector = formValueSelector(FORM_NAME);

export default reduxForm({
    form: FORM_NAME, // a unique name for this form,
    enableReinitialize: true
})(connect(
    state => ({
        noHarmHistory: selector(state, Fields.CURRENT_INSURANCE_NO_HARM_HISTORY)
    })
)(UpdateOfferRequestForm));
