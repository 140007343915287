export const updateUserInList = (users, updatedUser) => {
    const updatedUsers = [...users];
    const foundUserIndex = updatedUsers.findIndex((user) => user.id == updatedUser.id);
    if (foundUserIndex >= 0) {
        updatedUsers[foundUserIndex] = updatedUser;
    } else {
        updatedUsers.push(updatedUser);
    }
    return updatedUsers;
};
