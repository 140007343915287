

const TYPES = {
    THREAD_LIST_LOAD_START: 'chat.thread_list_load_start',
    THREAD_LIST_LOAD_END: 'chat.thread_list_load_end',

    THREAD_SELECTED: 'chat.thread_selected',

    THREAD_MESSAGES_LOAD_START: 'chat.thread_messages_load_start',
    THREAD_MESSAGES_LOAD_END: 'chat.thread_messages_load_end',

    MESSAGE_RECEIVED: 'chat.message_received',
    MESSAGE_SUBMIT_START: 'chat.message_submit_start',
    MESSAGE_SUBMIT_END: 'chat.message_submit_end',

    CLEAR_MESSAGE_SENT: 'chat.clear_message_sent'
};

export default TYPES;