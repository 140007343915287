import React from 'react';
import {connect} from 'react-redux';
import * as queryString from 'query-string';

import URI from '../../../../config/uri';
import API from '../../../../config/api/routes';
import {updateQueryParameter} from '../../../../lib/url';
import {getUserToken} from '../../../auth/helper.js';
import {createUrl} from '../../../api/request';
import {redirectTo, redirectToURL} from '../../../router/action';
import {AUTHORIZATION_QUERY_STRING} from '../../../../config/api/endpoint';

/**
 * OfferRequestListPage component
 *
 */
class FilePage extends React.Component {

    getFileUrl(path, params, query) {
        switch (path) {
            case URI.OFFICIAL_OFFER_FILE: {
                return createUrl(API.OFFER_OFFICIAL_FILE.replace(':id', params.id));
            }
            case URI.OFFER_REQUEST_FILE: {
                return createUrl(API.OFFER_REQUEST_FILE.replace(':id', params.id).replace(':fileId', params.fileId));
            }
            case URI.OFFER_REQUEST_FILES_ADVISORY_MANDATE: {
                return createUrl(API.OFFER_REQUEST_ADVISORY_MANDATE.replace(':id', params.id));
            }
            case URI.OFFER_REQUEST_FILES_ADVISORY_MANDATE_TEMPLATE: {
                const queryString = '?'
                    + (query.customer ? `customer=${query.customer}&` : '')
                    + `broker=${encodeURIComponent(query.broker)}&insuranceType=${encodeURIComponent(query.insuranceType)}`;
                return createUrl(API.OFFER_REQUEST_ADVISORY_MANDATE_TEMPLATE.replace(':id', params.id) + queryString);
            }
            case URI.OFFER_REQUEST_INSURANCE_ADVISORY_MANDATE: {
                return createUrl(API.OFFER_REQUEST_INSURANCE_ADVISORY_MANDATE.replace(':id', params.id));
            }
            case URI.OFFER_REQUEST_INSURANCE_FILE: {
                return createUrl(API.OFFER_REQUEST_INSURANCE_FILE.replace(':id', params.id).replace(':fileId', params.fileId));
            }
            case URI.FILE: {
                return createUrl(API.FILE.replace(':id', params.id));
            }
            default: {
                return null;
            }
        }
    }

    componentWillMount() {
        const {i18n, dispatch} = this.props;
        const token = this.getAuthToken(this.props);
        let fileUrl = this.getFileUrl(this.props.match.path, this.props.match.params, queryString.parse(this.props.location.search));
        fileUrl = updateQueryParameter(fileUrl, 'lang', i18n.locale);
        fileUrl = updateQueryParameter(fileUrl, AUTHORIZATION_QUERY_STRING, token);

        if (fileUrl !== null) {
            dispatch(redirectToURL(fileUrl));
        } else {
            dispatch(redirectTo(URI.NOT_FOUND))
        }
    }

    getAuthToken(props) {
        const {auth, location: {search}} = props;

        const queryToken = queryString.parse(search).token;
        const userToken = getUserToken(auth);

        return queryToken || userToken;
    }

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        i18n: state.i18n
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilePage);

