/**
 * Created by br0wn on 11/2/16.
 */
import {Types as NotificationTypes} from '../../config/domain/notification';
import {createAction} from '../action';
import {fetchOffer, fetchOfferRequest, fetchNotifications} from '../api/sdk-action';

export const Types = {
    NOTIFICATIONS_LOADED: 'notifications.loaded',
    NOTIFICATION_RECEIVED: 'notifications.notification_received',
    NOTIFICATION_SEEN: 'notifications.notification_seen',
    ALL_NOTIFICATIONS_SEEN: 'notifications.all_notifications_seen',

    OFFER_REQUEST_RECEIVED: 'notifications.offer_request_received',
    OFFER_RECEIVED: 'notifications.offer_request_received',
    MESSAGE_RECEIVED: 'notifications.message_received',
};


export const notificationReceived = (notification) => (dispatch) => {
    const {notificationType, payload} = notification;

    dispatch(createAction(
        Types.NOTIFICATION_RECEIVED, {notification}
    ));

    switch (notificationType) {
        case NotificationTypes.NEW_OFFER_REQUEST: {
            return dispatch(
                fetchOfferRequest(payload.offerRequestId)
            ).then(offerRequest => dispatch(
                createAction(Types.OFFER_REQUEST_RECEIVED, {offerRequest})
            ));
        }
        case NotificationTypes.NEW_OFFER: {
            return dispatch(
                fetchOffer(payload.offerId)
            ).then(offer => dispatch(
                createAction(Types.OFFER_REQUEST_RECEIVED, {offer, offerRequestId: payload.offerRequestId})
            ));
        }
        case NotificationTypes.NEW_MESSAGE: {
            const {message, offerRequestId} = payload;

            return Promise.resolve(dispatch(createAction(
                Types.MESSAGE_RECEIVED, {message, offerRequestId}
            )));
        }
    }
};

export const notificationsLoaded = (notifications) => createAction(
    Types.NOTIFICATIONS_LOADED, {notifications}
);

export const notificationSeen = (notification) => createAction(
    Types.NOTIFICATION_SEEN, {notification}
);

export const allNotificationsSeen = () => createAction(
    Types.ALL_NOTIFICATIONS_SEEN, {}
);


export const loadNotifications = () => (dispatch) => {
    dispatch(
        fetchNotifications()
    ).then(
        notifications => dispatch(notificationsLoaded(notifications))
    );
};
