import React from 'react';
import {Link} from 'react-router-dom';
import URI from '../../../../config/uri';
import {l} from '../../../i18n/translator';

const Error = ({error}) => {
    return error ? (
            <div>
                <strong className="label label-danger">
                    {l('Error')}
                </strong>

                <p className="text-left text-danger m-t-sm">
                    {error}
                </p>

                <div>
                    <Link to={URI.LOGIN}>
                        <small>
                            <i className="fa fa-arrow-left m-r-sm"></i>
                            {l('Back to login')}
                        </small>
                    </Link>
                </div>
            </div>
        ) : null;
};
export default Error;
