import {l} from '../../../i18n/translator';
import {warn} from '../../../../lib/logger';
import {States as OfferRequestStates} from '../../../../config/domain/offerRequest';
import loadedPageReducer from '../../common/page/loaded-page/reducer';
import ACTION_TYPES, {PAGE_ACTIONS} from './action/type';

const INITIAL_STATE = {
    isLoading: false,
    isLoaded: false,
    notFound: false,
    loadErrors: [],
    offerRequests: {
        isLoading: false,
        items: [],
        page: 1,
        totalPages: 1,
        totalItems: 0
    },
    data: {
        insuranceTypes: []
    },
    filters: [],
    deleting: [],
    withdrawing: []
};

/**
 * Root reducer for CreateOfferRequest page
 *
 * @param state
 * @param action
 */
let offerRequestListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.FILTER_LIST: {
            return {
                ...state,
                filters: action.filters
            };
        }
        case ACTION_TYPES.SET_PAGE: {
            return {
                ...state,
                offerRequests: {
                    ...state.offerRequests,
                    page: action.page
                }
            };
        }
        case ACTION_TYPES.LIST_LOAD_START: {
            return {
                ...state,
                offerRequests: {
                    ...state.offerRequests,
                    isLoading: true
                }
            };
        }
        case ACTION_TYPES.LIST_LOAD_END: {
            let offerRequests = {...state.offerRequests};

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            } else {
                offerRequests = {...action.offerRequests};
            }

            return {
                ...state,
                offerRequests: {
                    ...offerRequests,
                    isLoading: false
                }
            };
        }
        case ACTION_TYPES.DELETE_OFFER_REQUEST_START: {
            return {
                ...state,
                deleting: [...state.deleting, action.id]
            };
        }
        case ACTION_TYPES.DELETE_OFFER_REQUEST_END: {
            let items = [...state.offerRequests.items];
            let deleting = [...state.deleting];

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            } else {
                let item = items.find(item => item.id == action.id);
                let itemIndex = items.indexOf(item);

                if (itemIndex !== -1) {
                    items = [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)];
                }
            }

            let deletingIndex = deleting.indexOf(action.id);
            deleting = [...deleting.slice(0, deletingIndex), ...deleting.slice(deletingIndex + 1)];

            return {
                ...state,
                deleting: deleting,
                offerRequests: {
                    ...state.offerRequests,
                    items: items
                }
            };
        }
        case ACTION_TYPES.WITHDRAW_OFFER_REQUEST_START: {
            return {
                ...state,
                withdrawing: [...state.withdrawing, action.id]
            };
        }
        case ACTION_TYPES.WITHDRAW_OFFER_REQUEST_END: {
            let items = [...state.offerRequests.items];
            let withdrawing = [...state.withdrawing];

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            } else {
                let item = items.find(item => item.id == action.id);
                let itemIndex = items.indexOf(item);

                if (itemIndex !== -1) {
                    // set item state
                    item = {...item, state: OfferRequestStates.WITHDRAWN};

                    // update items
                    items = [...items.slice(0, itemIndex), item, ...items.slice(itemIndex + 1)];
                }
            }

            let withdrawingIndex = withdrawing.indexOf(action.id);
            withdrawing = [...withdrawing.slice(0, withdrawingIndex), ...withdrawing.slice(withdrawingIndex + 1)];

            return {
                ...state,
                withdrawing: withdrawing,
                offerRequests: {
                    ...state.offerRequests,
                    items: items
                }
            };
        }
    }

    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS)
    };
};

export default offerRequestListReducer;
