import singleFileUploadReducer, {INITIAL_STATE as UPLOAD_INITIAL_STATE} from '../../common/form/file-upload/reducer/singleFileUploadReducer';
import {OFFER_UPLOAD_ACTIONS} from './action/type';


const INITIAL_STATE = {
    offerUploadState: {...UPLOAD_INITIAL_STATE}
};


const offerDetailsReducer = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        offerUploadState: singleFileUploadReducer(state.offerUploadState, action, OFFER_UPLOAD_ACTIONS)
    };
};

export default offerDetailsReducer;
