import API from '../../../../../config/api/routes';
import API_ERRORS from '../../../../../config/api/errors';
import {handleErrorResponse, isBadRequest, isForbidden} from '../../../../../lib/http';
import {jsonPostRequest} from '../../../../api/request';
import {makeAuthApiRequest} from '../../../../api/action';
import {buildApiRequestData} from '../helper';


export const validate = (formData) => {
    return (dispatch) => {
        // generate request data
        let requestData = buildApiRequestData(formData);

        return dispatch(makeAuthApiRequest(
            API.OFFER_REQUEST_VALIDATE, jsonPostRequest({
                body: JSON.stringify(requestData)
            })
        )).catch(e => handleErrorResponse(e, response => {
            // if response is not expected reject with error
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }
            // process validation errors
            return response.json().then(data => {
                return Promise.reject({validationErrors: data.errors});
            });
        }));
    };
};

export const save = (formData) => {
    return (dispatch) => {
        // generate request data
        let requestData = buildApiRequestData(formData);

        return dispatch(makeAuthApiRequest(
            API.OFFER_REQUESTS, jsonPostRequest({
                body: JSON.stringify(requestData)
            }))
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            if (isBadRequest(response)) {
                return response.json().then(data => {
                    if (data.errors) {
                        return Promise.reject({validationErrors: data.errors});
                    } else {
                        return Promise.reject({data: data, requestError: response});
                    }
                });
            } else if (isForbidden(response)) {
                // process validation errors
                return response.json().then(data => {
                    if (data.error == API_ERRORS.COMPANY_NOT_SET_UP) {
                        return Promise.reject({companyError: API_ERRORS.COMPANY_NOT_SET_UP});
                    } else {
                        return Promise.reject({requestError: response});
                    }
                });
            } else {
                return Promise.reject({requestError: response});
            }
        }));
    };
};
