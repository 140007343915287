import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import * as queryString from 'query-string';

import URI from '../../../../config/uri';
import {l} from '../../../i18n/translator';
import * as Actions from './action';

/**
 * ForgotPasswordPage component
 *
 * @param {object} state The state of the application
 * @param {object} actions Component actions
 * @param {object} query Query params
 */
export let ResetPasswordPage = ({state, actions, location: {search}}) => {
    let password;
    let repeatPassword;

    const {token, email} = queryString.parse(search);
    const {isFetching, isSuccess, form: {errors}} = state.page.resetPassword;

    return (
        <div id="wrapper" className="gray-bg" style={{height: '100%'}}>
            <div className="middle-box text-center loginscreen animated fadeInDown">

                <div>
                    <img src={require('../../../../resources/images/logo.png')}/>
                </div>

                {isSuccess ? (
                    <div>
                        <h3>Your new password has been saved!</h3>

                        <p>You can now log in again.</p>
                    </div>
                ) : (
                    <div>
                        <h3>Enter new password</h3>

                        <form className="m-t" role="form" action="index.html" onSubmit={e => actions.submit(e, token, email, password.value, repeatPassword.value, isFetching) }>

                            <div className={classNames("form-group", {'has-error': errors.password || errors.message})}>
                                <input type="password" className="form-control" placeholder={l('New password')}
                                       ref={node => {
                                           password = node
                                       }}/>
                            </div>

                            <div className={classNames("form-group", {'has-error': errors.repeatPassword || errors.message})}>
                                <input type="password" className="form-control" placeholder={l('Repeat password')}
                                       ref={node => {
                                           repeatPassword = node
                                       }}/>

                                <div className="help-block m-b-none">
                                    {errors.message}
                                </div>
                            </div>

                            <button disabled={isFetching} type="submit" className="btn btn-primary block full-width m-b">
                                {!isFetching ? (
                                    l('Submit')
                                ) : (
                                    <span>
                                        <i className="fa fa-cog m-r-sm"></i>
                                        {l('Working, please wait')}
                                    </span>
                                )}
                            </button>
                        </form>
                    </div>
                )}

                <div>
                    <Link to={URI.LOGIN}>
                        <small>
                            <i className="fa fa-arrow-left m-r-sm"></i>
                            {l('Back to login')}
                        </small>
                    </Link>
                </div>

            </div>

        </div>
    );
};

function mapStateToProps(state) {
    return {state};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            submit: (e, token, user, password, repeatPassword, isFetching) => {
                e.preventDefault();

                if (!isFetching) {
                    dispatch(Actions.submit(token, user, password, repeatPassword));
                }
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordPage);

