import {l} from '../../../i18n/translator';
import {objectProp, objectPropSet, objectFlatten} from '../../../helper/core';


/**
 * Creates form field object based on apiField and apiData
 *
 * @param {string} apiField
 * @param {object} apiData
 * @param {function=} resolveField
 * @param {function=} resolveValue
 * @return {object}
 */
export const buildFormField = (apiField, apiData = {}, resolveField = field => field, resolveValue = value => value) => {
    const fieldName = resolveField(apiField);
    const fieldValue = objectProp(apiField, apiData);
    const resolvedValue = resolveValue(fieldValue, fieldName);

    return {[fieldName]: resolvedValue};
};

/**
 * Creates API data structure (branched) based on form data
 *
 * @param {object} formData
 * @param {function=} resolveField
 * @param {function=} resolveValue
 * @return {object}
 */
export const buildApiRequestData = (formData, resolveField = field => field, resolveValue = value => value) => {
    return Object.keys(formData).reduce((carry, field) => {
        const apiField = resolveField(field);
        const formValue = resolveValue(formData[field], field);

        return objectPropSet(apiField, formValue, carry);
    }, {});
};

/**
 * Creates form data structure (flattened) based on API data
 * @param apiData
 * @param {function=} resolveField
 * @param {function=} resolveValue
 * @return {object}
 */
export const buildFormData = (apiData, resolveField = field => field, resolveValue = value => value) => {
    apiData = objectFlatten(apiData || {});

    return Object.keys(apiData).reduce((carry, field) => {
        const builtField = buildFormField(field, apiData, resolveField, resolveValue);
        return {
            ...carry,
            ...builtField
        };
    }, {});
};

export const translateError = (key) => {
    return l(`This field is invalid (${key}).`);
};

/**
 * Builds form errors object from API validation errors
 *
 * @param {Array} validationErrors
 * @param {function=} resolvePath
 * @return {object}
 */
export const buildErrors = (validationErrors, resolvePath = path => path) => {
    if (!Array.isArray(validationErrors)) {
        return {};
    }

    return validationErrors.reduce((carry, {path, ...data}) => {
        path = resolvePath(path);

        const errorMessage = translateError(data.key);
        objectPropSet(path, errorMessage, carry);

        // carry[path] = carry[path] || '';
        // carry[path] = carry[path] + errorMessage;
        // carry[path] += ' ';

        return carry;
    }, {});
};

/**
 * Extract form errors based on fields configuration
 *
 * @param {object} fields
 * @param {object} errors
 * @return {object}
 */
export const extractErrorsForFields = (fields, errors) => {
    return Object.keys(fields).reduce((carry, fieldKey) => {
        // get the actual field name string
        let fieldName = fields[fieldKey];

        // if errors contain this field, add it to carry
        if (errors[fieldName]) {
            carry[fieldName] = errors[fieldName];
        }

        return carry;
    }, {});
};
