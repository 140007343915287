import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import {Element, scroller} from 'react-scroll';
import moment from 'moment';
import {debounce} from 'lodash';
import AppLayout, {BreadCrumbs} from '../../layout/app-layout.jsx';
import {l} from '../../../i18n/translator';
import URI from '../../../../config/uri';
import {CompanyTypes as UserTypes} from '../../../../config/domain/user';
import {Fields as OfferRequestFields, States as OfferRequestStates} from '../../../../config/domain/offerRequest';
import {Fields as OfferFields, States as OfferStates} from '../../../../config/domain/offer';
import {Types as InsuranceTypes} from '../../../../config/domain/insurance';
import {isInStateCreated} from '../../../../app/model/offerRequest';
import {getUser, isUserOfType} from '../../../auth/helper.js';
import LoadedPage from '../../common/page/loaded-page/component.jsx';
import InsuranceOnly from '../../common/auth/acl/insuranceOnly.jsx';
import CustomerOnly from '../../common/auth/acl/customerOnly.jsx';
import UserTypeOnly from '../../common/auth/acl/userTypeOnly.jsx';
import ThreadedChat from '../../common/chat/component/threaded/container.jsx';
import * as Ibox from '../../ui-components/ibox.jsx';
import {InsuranceType} from '../common/insuranceType.jsx';
import {getOwnOffers, getSelectedOffer} from '../helper.js';
import {getInsuranceTypeName} from '../../common/insurnaceType.js';
import CustomerDetails from './component/customerDetails.jsx';
import OfferRequestDetails from './component/offerRequestDetails.jsx';
import OfferRoundDetails from './component/offerRoundDetails.jsx';
import CurrentPremium from './component/currentInsuranceData.jsx';
import SelectedInsurances from './component/seletedInsurances.jsx';
import IssuedOffers from './component/issuedOffers.jsx';
import OfferRequestActivity from './component/offerRequestActivity.jsx';
import {CreationDate, PublishDate, StatusLabel} from './component/status.jsx';
import OfferRequestDetailsActions from './component/detailsActions.jsx';
import {PremiumGraph} from './component/premiumGraph.jsx';
import {CoverageAnalysisGraph} from './component/analysis/coverageAnalysisGraph.jsx';
import {OverallAnalysisGraph} from './component/analysis/overallAnalysisGraph.jsx';
import {UtilityAnalysisTable} from './component/analysis/utilityAnalysisTable.jsx';
import {OffersStatistics} from './component/statistics.jsx';
import {OfferRequestNotes} from './component/notes.jsx';
import {ProjectFiles} from './component/projectFiles.jsx';
import {OfferSelectedContainer} from './component/offerSelected.jsx';
import {FavoriteButton} from './component/favoriteButton.jsx';
import {OfferFormModal} from './component/offerForm.jsx';
import WithdrawOfferRequestModal from './component/withdrawOfferRequest.jsx';
import {UVGInsuranceInformationContainer} from './component/insurance-type/uvg.jsx';
import {CreditInsuranceInformationContainer} from './component/insurance-type/credit.jsx';
import {DefaultInsuranceInformationContainer} from './component/insurance-type/default.jsx';
import * as DetailsActions from './action/offerRequest';
import ChatActions from './action/chat';
import * as PageActions from './action/page';
import * as OfferActions from './action/offer';
import EditButton from './component/editButton.jsx';
import {STEP_INSURANCE_DATA} from '../create-request/config/wizard';
import RatingModal from './component/analysis/ratingModal.jsx';
import {createOffersWithAnalysis} from '../../common/analysis';
import {updateQueryString} from '../../../helper/querystring';
import {redirectToWithoutDispatch} from '../../../router/action';
import {isCreatedFinishedOffer} from '../../../model/offerRequest';

class OfferRequestDetailsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            scrollTo: null
        }
    }

    componentWillMount() {
        let {match: {params = {}}, actions} = this.props;
        let offerRequestId = params.id;

        // do page init
        actions.init(offerRequestId);

        this.setupScrollTo();
    }

    componentWillUnmount() {
        this.props.actions.cleanup();
    }

    componentWillReceiveProps(nextProps) {
        let {match: {params = {}}, actions} = this.props;
        let nextParams = nextProps.match.params || {};

        if (params.id !== nextParams.id) {
            return actions.init(nextParams.id);
        }

        if (nextProps.publishError && !this.props.publishError) {
            return redirectToWithoutDispatch({
                pathname: URI.EDIT_OFFER_REQUEST.replace(':id', nextParams.id),
                search: updateQueryString(undefined, {validateOnInit: true})
            });
        }

        this.setupScrollTo(nextProps);
    }

    componentDidUpdate() {
        this.doScrollTo()
    };

    setupScrollTo(nextProps) {
        const currentHash = this.props.location.hash;

        if (!nextProps) {
            const scrollTo = currentHash.replace('#', '');
            return this.setState({scrollTo});
        }

        const nextHash = nextProps.location.hash;

        if (nextHash && (currentHash !== nextHash || nextProps.pageState.isL)) {
            const scrollTo = nextHash.replace('#', '');
            return this.setState({scrollTo});
        }
    }

    doScrollTo() {
        const {scrollTo} = this.state;
        const {isLoaded} = this.props.pageState;

        if (!isLoaded || !scrollTo) {
            return;
        }

        setTimeout(() => {
            scroller.scrollTo(scrollTo, {
                duration: 300,
                offset: -20,
                smooth: true
            })
        }, 100);

        this.setState({scrollTo: null})
    }

    render() {
        const {pageState} = this.props;
        const {isLoaded} = pageState;

        return (
            <AppLayout>
                <LoadedPage pageState={this.props.pageState}>
                    <div>{isLoaded ? this.renderContent() : null}</div>
                </LoadedPage>
            </AppLayout>
        );
    }

    renderMessagesBox(user, chat, actions) {
        const {offerRequest} = this.props;

        if (isInStateCreated(offerRequest)) {
            return null;
        }

        function pick(o, ...props) {
            return Object.assign({}, ...props.map(prop => ({[prop]: o[prop]})));
        }

        const chatUser = user ? pick(user, 'id', 'companyId', 'companyType') : null;
        return (
            <Ibox.Container className="chat-view">
                <Element name="messages"/>

                <Ibox.Title>
                    <h5>
                        <i className="fa fa-comments-o"/>&nbsp;
                        {l('Messages')}
                    </h5>
                </Ibox.Title>

                <Ibox.Content>
                    <ThreadedChat
                        chatUser={chatUser}
                        {...chat}
                        actions={actions.chat}
                    />
                </Ibox.Content>
            </Ibox.Container>
        )
    }

    renderAnalysis(requestFromInsurances) {
        const {offerRequest, analysisCriteria, offerList, insurances, statistics, auth, actions} = this.props;
        const isFinishedOfferRequestPreview = isCreatedFinishedOffer(offerRequest);

        if (isInStateCreated(offerRequest) && !isFinishedOfferRequestPreview) {
            return null;
        }

        const analysisGraphActions = {
            onRatingClick: (offer, criteria) => {
                const rating = offer[OfferFields.COVERAGE_ANALYSIS_RATINGS].find((rating) => rating.criteriaId == criteria.id);
                this.refs.ratingModal.showRating(offer, OfferFields.COVERAGE_ANALYSIS_RATINGS, rating, criteria);
            },
            onOfferClick: (offer) => actions.offer.viewOffer(offerRequest, offer)
        };
        const onSaveRatingComment = (offer, analysisField, rating, comment) => {
            return actions.offer.updateOfferRatingComment(offerRequest, offer, analysisField, rating.criteriaId, comment)
        };

        const offersWithAnalysis = createOffersWithAnalysis(offerRequest, offerList, {onlyPublished: !isFinishedOfferRequestPreview})
            .filter(item => item.offer.state !== OfferStates.ABSTAINED);

        return (
            <Ibox.Container>
                <RatingModal ref="ratingModal" insurances={insurances}
                             onSaveComment={onSaveRatingComment}
                             onShowOffer={(offer) => actions.offer.viewOffer(offerRequest, offer)}/>
                <Ibox.Title>
                    <h5><i className="fa fa-area-chart"/>&nbsp;{l('Analysis')}</h5>
                </Ibox.Title>

                <Ibox.Content className="">
                    <h3>{l('Overall Analysis')}</h3>
                    <div className="m-b-lg">
                        <OffersStatistics offerRequest={offerRequest} statistics={statistics} auth={auth}
                                          actions={analysisGraphActions}/>
                    </div>

                    {offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA] && offerRequest[OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA] ? (
                        <div className="m-b-lg">
                            <OverallAnalysisGraph offerRequest={offerRequest} offersWithAnalysis={offersWithAnalysis}
                                                  insurances={insurances} actions={analysisGraphActions}/>
                        </div>
                    ) : null}

                    <h3><i className="fa fa-dollar"/> {l('Premium Analysis')}</h3>
                    <PremiumGraph offerRequest={offerRequest} offers={offerList} insurances={insurances}
                                  auth={auth} actions={analysisGraphActions}/>

                    {offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA] && offerRequest[OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA] ? (
                        <div className="m-t-xl">
                            <h3><i className="fa fa-shield"/> {l('Coverage Analysis')}</h3>
                            <CoverageAnalysisGraph offerRequest={offerRequest} offersWithAnalysis={offersWithAnalysis}
                                                   insurances={insurances} actions={analysisGraphActions}/>
                        </div>
                    ) : null}

                    {isUserOfType(auth, [UserTypes.BROKER])
                    || (isUserOfType(auth, [UserTypes.CUSTOMER])
                        && offerRequest[OfferRequestFields.UTILITY_ANALYSIS_CRITERIA]
                        && offerRequest[OfferRequestFields.UTILITY_ANALYSIS_RATINGS]
                        && offerRequest[OfferRequestFields.UTILITY_ANALYSIS_RATINGS].find((rating) => rating.rating)) ?
                        <div>
                            <br/>
                            <h3><i className="fa fa-heart"/> {l('Utility Analysis')}</h3>
                            <UtilityAnalysisTable
                                availableCriteria={analysisCriteria}
                                criteria={offerRequest[OfferRequestFields.UTILITY_ANALYSIS_CRITERIA] || []}
                                ratings={offerRequest[OfferRequestFields.UTILITY_ANALYSIS_RATINGS] || []}
                                insurances={requestFromInsurances}
                                offerRequest={offerRequest} offers={offerList}
                                onChange={(criteria, ratings) => {
                                    actions.offerRequest.updateUtilityAnalysis(offerRequest, {criteria, ratings});
                                    actions.offerRequest.updateUtilityAnalysisApi(offerRequest, {criteria, ratings});
                                }}
                                disabled={!isUserOfType(auth, UserTypes.BROKER)}/>
                        </div> : null}


                    {offerRequest.files && offerRequest.files.summary ? (
                        <div className="m-t-lg">
                            <h3><i className="fa fa-file-pdf-o"/> {l('Overview')}</h3>
                            <Link
                                to={URI.OFFER_REQUEST_FILE
                                    .replace(':id', offerRequest.id)
                                    .replace(':fileId', offerRequest.files.summary.id)}
                                target="_blank">
                                {offerRequest.files.summary.fileName}
                            </Link>
                        </div>
                    ) : null}
                </Ibox.Content>
            </Ibox.Container>
        );
    }

    renderContent() {
        const {auth, offerList, insurances, contact, chat, insuranceTypes} = this.props;
        let {offerRequest} = this.props;

        const {isDeleting, isWithdrawing, deletingOffers, isPublishing, publishError} = this.props;
        const {actions} = this.props;

        const user = getUser(auth) || {};
        const isInsurance = isUserOfType(auth, UserTypes.INSURANCE);

        const ownOffers = getOwnOffers(offerList, auth);
        const selectedOffer = getSelectedOffer(ownOffers);

        const getInsurance = (insuranceId, insurances) => {
            const insurance = insurances.find(insurance => insurance.id === insuranceId);
            return insurance ? insurance : undefined;
        };

        let requestFromInsurances = (offerRequest.requestFromInsuranceIds
            ? offerRequest.requestFromInsuranceIds.map((insuranceId) => getInsurance(insuranceId, insurances))
            : offerRequest.requestFromInsuranceNames.map((insuranceName, index) => {
                return {
                    id: index,
                    name: insuranceName
                };
            })).filter((insurance) => !!insurance);

        return (
            <div>
                {/* Title and breadcrumbs */}
                <BreadCrumbs title={l('Offer request #:number').replace(':number', offerRequest.id)} icon="file-text-o">
                    <span className="pull-right">
                        <a onClick={() => window.print()}>
                            <i className="fa fa-print"/>&nbsp;
                            {l('Print')}
                        </a>
                    </span>
                </BreadCrumbs>

                {/* Page Content */}
                <div className="wrapper wrapper-content">
                    <Row>
                        <Col sm={6}>
                            <h2 className="m-t-none">{getInsuranceTypeName(insuranceTypes, offerRequest.insuranceTypeId)}</h2>
                            <h3>{l('Insurance holder')}: {offerRequest.customerName}</h3>
                            {offerRequest.offerRoundType
                                ? <h3>{l('Offer round type')}: {l('offerRoundType_' + offerRequest.offerRoundType)}</h3>
                                : null}

                            <CustomerOnly auth={auth}>
                                <div>
                                    {l('Date created')}: <CreationDate offerRequest={offerRequest}/>
                                </div>
                            </CustomerOnly>

                            <div>
                                {l('Date published')}: <PublishDate offerRequest={offerRequest}/>
                            </div>
                        </Col>
                        <Col sm={6} className="text-right">
                            {offerRequest.state == OfferRequestStates.PUBLISHED ?
                                l('daysLeft', {count: moment(offerRequest[OfferRequestFields.OFFER_ROUND_END_DATE]).diff(moment(), 'days')}) : null}

                            &nbsp;&nbsp;&nbsp;

                            <FavoriteButton/>

                            &nbsp;&nbsp;&nbsp;

                            <StatusLabel
                                style={{
                                    padding: '10px 15px',
                                    verticalAlign: 'middle',
                                    fontSize: '20px'
                                }}
                                className="text-uppercase label-btn"
                                icon={(<i className="fa fa-user"/>)}
                                offerRequest={offerRequest}
                            />

                            <br/>

                            {offerRequest.withdrawReason && (
                                <p className="m-t-sm p-w-sm" style={{fontSize: '14px'}}>
                                    <strong>
                                        {l('Withdraw reason')}:
                                    </strong>
                                    &nbsp;
                                    <span className="text-danger">
                                        {offerRequest.withdrawReason}
                                    </span>
                                </p>
                            )}
                        </Col>
                    </Row>

                    <OfferRequestDetailsActions
                        offerRequest={offerRequest}
                        auth={auth}
                        actions={actions.offerRequest}
                        isDeleting={isDeleting}
                        isWithdrawing={isWithdrawing}
                        isPublishing={isPublishing}
                        publishError={publishError}
                    />

                    {selectedOffer && !selectedOffer.officialOfferFileId ? (
                        <InsuranceOnly auth={auth}>
                            <div className="wrapper text-center m-b-lg">
                                <OfferSelectedContainer offer={selectedOffer}/>
                            </div>
                        </InsuranceOnly>
                    ) : null}


                    <div>
                        <Row>
                            <Col sm={8}>
                                {this.renderAnalysis(requestFromInsurances)}

                                {this.renderMessagesBox(user, chat, actions)}

                                <Row>
                                    <Col md={6}>
                                        <SelectedInsurances insurances={requestFromInsurances}/>
                                    </Col>
                                    <Col md={6}>
                                        <OfferRoundDetails {...{offerRequest}} />
                                        <ProjectFiles {...{offerRequest}} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={4}>

                                {/* Used from issued offers but as well from offerRequestActivity */}
                                <OfferFormModal insurances={insurances} offers={offerList}/>

                                <UserTypeOnly type={[UserTypes.BROKER, UserTypes.INSURANCE]} auth={auth}>
                                    <IssuedOffers {...{
                                        offerRequest,
                                        offers: ownOffers,
                                        allOffers: offerList,
                                        actions: actions.offer,
                                        deleteStatus: {deletingOffers},
                                        insurances: insurances,
                                        auth
                                    }} />
                                </UserTypeOnly>

                                <OfferRequestNotes offerRequest={offerRequest}/>

                                <Element name="activity"/>
                                {isInsurance ? null : (
                                    <OfferRequestActivity {...{
                                        offerRequest,
                                        insurances,
                                        actions,
                                        offers: offerList,
                                        showCreation: !isInsurance
                                    }} />
                                )}
                            </Col>
                        </Row>


                        <h2 className="page-header">
                            {l('Insurance information')}
                        </h2>

                        <Row>
                            <Col md={4}>
                                <CustomerDetails customerName={offerRequest[OfferRequestFields.CUSTOMER_NAME]}
                                                 contact={contact}
                                                 businessId={offerRequest[OfferRequestFields.BUSINESS_IDENTIFICATION_NUMBER]}
                                                 customerIndustryClassificationCode={offerRequest[OfferRequestFields.CUSTOMER_INDUSTRY_CLASSIFICATION_CODE]}/>
                            </Col>
                            <Col md={4}>
                                <OfferRequestDetails {...{offerRequest}}/>
                            </Col>
                            <Col md={4}>
                                <CurrentPremium {...{offerRequest, insurances}}/>
                            </Col>
                        </Row>


                        <InsuranceType.Container activeType={offerRequest.insuranceTypeId}>
                            <InsuranceType.Type type={InsuranceTypes.CREDIT}>
                                <CreditInsuranceInformationContainer/>
                            </InsuranceType.Type>

                            <InsuranceType.Type type={InsuranceTypes.UVG}>
                                <UVGInsuranceInformationContainer/>
                            </InsuranceType.Type>

                            <InsuranceType.Default>
                                <DefaultInsuranceInformationContainer/>
                            </InsuranceType.Default>
                        </InsuranceType.Container>

                        {offerRequest.data && offerRequest.data.additionalInformation ? (
                            <Row>
                                <Col md={12}>
                                    <Ibox.Container>
                                        <Ibox.Title>
                                            <h5><i className="fa fa-file-text"/>&nbsp;{l('Additional information')}</h5>
                                            <EditButton step={STEP_INSURANCE_DATA}/>
                                        </Ibox.Title>

                                        <Ibox.Content className="">
                                                <pre>
                                                    {offerRequest.data.additionalInformation}
                                                </pre>
                                        </Ibox.Content>
                                    </Ibox.Container>
                                </Col>
                            </Row>) : null}
                    </div>
                </div>

                <WithdrawOfferRequestModal
                    onSubmit={(reason) => actions.offerRequest.withdrawOfferRequest(offerRequest.id, reason)}
                    onCancel={() => actions.offerRequest.toggleWithdrawModal(false)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        i18n: state.i18n,
        pageState: state.page.offerRequestDetails,
        ...state.page.offerRequestDetails.data,
        isDeleting: state.page.offerRequestDetails.isDeleting,
        isWithdrawing: state.page.offerRequestDetails.isWithdrawing,
        isPublishing: state.page.offerRequestDetails.isPublishing,
        publishError: state.page.offerRequestDetails.publishError,
        deletingOffers: state.page.offerRequestDetails.deletingOffers,
        chat: state.page.offerRequestDetails.chat
    }
}

function mapActionsToProps(dispatch) {
    return {
        actions: {
            init: (offerRequestId) => {
                dispatch(PageActions.init(offerRequestId))
            },
            cleanup: () => {
                dispatch(PageActions.cleanup())
            },
            offer: bindActionCreators({
                createOffer: OfferActions.createOffer,
                editOffer: OfferActions.editOffer,
                viewOffer: OfferActions.viewOffer,
                updateOfferRatingComment: OfferActions.updateOfferRatingComment,
                deleteOffer: DetailsActions.deleteOffer,
                abstain: DetailsActions.abstainFromOffer,
            }, dispatch),
            offerRequest: {
                deleteOfferRequest: (offerRequestId) => {
                    dispatch(DetailsActions.deleteOfferRequest(offerRequestId));
                },
                withdrawOfferRequest: (offerRequestId, reason) => {
                    dispatch(DetailsActions.withdrawOfferRequest(offerRequestId, reason));
                },
                publishOfferRequest: (offerRequest) => {
                    dispatch(DetailsActions.publishOfferRequest(offerRequest));
                },
                toggleWithdrawModal: (show) => dispatch(DetailsActions.updateWithdrawRequestModal({show})),
                updateUtilityAnalysis: (offerRequest, {criteria, ratings}) => {
                    dispatch(DetailsActions.updateUtilityAnalysis(offerRequest, {criteria, ratings}))
                },
                updateUtilityAnalysisApi: debounce((offerRequest, utilityAnalysis) => {
                    dispatch(DetailsActions.updateUtilityAnalysisApi(offerRequest, utilityAnalysis))
                }, 500)
            },
            chat: {
                onThreadSelected: (threadId) => {
                    dispatch(ChatActions.onThreadSelected(threadId))
                },
                onMessageSubmit: (e, text) => {
                    e.preventDefault();
                    dispatch(ChatActions.onMessageSubmit(text));
                }
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(OfferRequestDetailsPage);
