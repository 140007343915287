import {applyMiddleware, compose, createStore} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {createBrowserHistory} from "history";
import {connectRouter, routerMiddleware} from 'connected-react-router';

import createRootReducer from '../reducer.js';

export const history = createBrowserHistory();
const connectRouterHistory = connectRouter(history);

function configureStoreProd(initialState) {
    const reactRouterMiddleware = routerMiddleware(history);
    const middlewares = [
        // Add other middleware on this line...

        // thunk middleware can also accept an extra argument to be passed to each thunk action
        // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
        thunk,
        reactRouterMiddleware,
    ];

    if (process.env.APP_ENV === 'stage') {
        middlewares.push(createLogger())
    }

    return createStore(
        createRootReducer(history), // root reducer with router state
        initialState,
        compose(applyMiddleware(...middlewares))
    );
}

function configureStoreDev(initialState) {
    const reactRouterMiddleware = routerMiddleware(history);
    const middlewares = [
        // Add other middleware on this line...

        // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
        reduxImmutableStateInvariant(),

        // thunk middleware can also accept an extra argument to be passed to each thunk action
        // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
        thunk,
        reactRouterMiddleware,
        createLogger()
    ];

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),

        // window.devToolsExtension ? window.devToolsExtension() : f => f // add support for Redux dev tools
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducer.js', () => {
            const nextRootReducer = require('../reducer.js').default; // eslint-disable-line global-require
            store.replaceReducer(connectRouterHistory(nextRootReducer));
        });
    }

    return store;
}

let configureStore;
if (process.env.APP_ENV === 'production') {
    configureStore = configureStoreProd;
} else if (process.env.APP_ENV === 'stage') {
    configureStore = configureStoreProd; // internally adding logger based on env=stage
} else {
    configureStore = configureStoreDev;
}
export default configureStore;
