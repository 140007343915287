import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {change, formValueSelector, reduxForm, untouch} from 'redux-form';
import Button from '@material-ui/core/Button';

import {l} from '../../../i18n/translator';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx'
import * as Ibox from '../../ui-components/ibox.jsx';
import {initAutoRatingEdit, onInsuranceTypeChanged, saveAutoRating} from './action.js';
import URI from '../../../../config/uri';
import {AutoRatingFields, CRITERIA_ID_PREMIUM, CriteriaFields} from '../../../../config/domain/analysis';
import {AnalysisRatingEditField, renderSelectField, renderTextField} from '../../common/form/renderer';
import {Field} from '../../insurance-offer/create-request/component/redux-form-wrapper';

export class AutoRatingEditPage extends React.Component {

    componentWillMount() {
        let {match: {params = {}}, actions} = this.props;
        let autoRatingId = this.isEdit() ? params.id : null;

        actions.init(autoRatingId);
    }

    isEdit() {
        return this.props.match.path === URI.ANALYSIS_AUTO_RATING_EDIT;
    }

    render() {
        const {actions, insuranceTypeId, analysisCriteriaId, i18n, handleSubmit, submitting, submitSucceeded, pageState} = this.props;
        const {data} = pageState;

        const insuranceTypes = [...(data.insuranceTypes || [])];
        const criteria = (data.criteria || []).filter(c => c[CriteriaFields.ID] !== CRITERIA_ID_PREMIUM);
        const insurances = data.insurances || [];
        const autoRating = data.autoRating || {};
        const selectedCriteria = criteria.find(c => c.id === analysisCriteriaId);

        const insuranceTypeNameById = {};
        insuranceTypes.forEach((insuranceType) => insuranceTypeNameById[insuranceType.id] = insuranceType.name);

        return (
            <InsurTecLayout>
                <BreadCrumbs items={[{label: l('Auto ratings'), uri: URI.ANALYSIS_AUTO_RATINGS}]}
                             title={this.isEdit() ? autoRating.name || l('Auto rating') : l('New auto rating')}
                             icon="bar-chart"/>

                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content">
                        <Row>
                            <Col md={12}>
                                <Ibox.Container>
                                    <Ibox.Title>{l('Auto rating')}</Ibox.Title>

                                    <Ibox.Content className="p-sm">
                                        <form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md={6}>
                                                    <Field name={AutoRatingFields.INSURANCE_TYPE_ID}
                                                           label={l('Insurance type')}
                                                           component={renderSelectField}
                                                           options={insuranceTypes.map(insuranceType => ({
                                                               value: insuranceType.id,
                                                               label: insuranceType.name
                                                           }))}
                                                           onChangeIntercept={value => actions.onInsuranceTypeChanged(value)}
                                                           disabled={submitting} fullWidth/>
                                                    {insuranceTypeId ? <>
                                                        <div className="m-t-md"/>
                                                        <Field name={AutoRatingFields.INSURANCE_ID}
                                                               label={l('Insurance')}
                                                               component={renderSelectField}
                                                               options={insurances.map(c => ({
                                                                   value: c.id,
                                                                   label: c.name
                                                               }))}
                                                               disabled={submitting}
                                                               fullWidth/>
                                                        <div className="m-t-md"/>
                                                        <Field name={AutoRatingFields.ANALYSIS_CRITERIA_ID}
                                                               label={l('Analysis criteria')}
                                                               component={renderSelectField}
                                                               onChangeIntercept={() => actions.onCriteriaChanged()}
                                                               options={criteria.map(c => ({
                                                                   value: c.id,
                                                                   label: c.name
                                                               }))}
                                                               disabled={submitting} fullWidth/>
                                                        <div className="m-t-md"/>
                                                        <Field name={AutoRatingFields.INDUSTRY_CLASSIFICATION_CODE}
                                                               label={l('Industry Classification Code (NOGA)')}
                                                               component={renderTextField} disabled={submitting}
                                                               fullWidth/>
                                                        <div className="m-t-md"/>
                                                        <Field
                                                            name={AutoRatingFields.GENERAL_CONDITIONS_OF_INSURANCE_VERSION}
                                                            label={l('General Conditions of Insurance')}
                                                            component={renderTextField} disabled={submitting}
                                                            fullWidth/>
                                                        <div className="m-t-md"/>
                                                        <Field
                                                            name={AutoRatingFields.SPECIAL_CONDITIONS_OF_INSURANCE_VERSION}
                                                            label={l('Special Conditions of Insurance')}
                                                            component={renderTextField} disabled={submitting}
                                                            fullWidth/>

                                                        <div className="m-t-lg"/>
                                                        <h3>{l('Rating')}</h3>
                                                        {selectedCriteria ? (<>
                                                            <div className="m-t-md"/>
                                                            <Field name={AutoRatingFields.RATING_DATA}
                                                                   criteria={selectedCriteria}
                                                                   component={AnalysisRatingEditField}
                                                                   disabled={submitting}
                                                                   fullWidth/>
                                                        </>) : null}

                                                        <div className="m-t-md"/>
                                                        <Field name={AutoRatingFields.COMMENT_BROKER}
                                                               label={l('Broker comment')}
                                                               component={renderTextField} disabled={submitting}
                                                               fullWidth/>
                                                    </> : null}
                                                </Col>
                                                <Col md={6}>
                                                </Col>
                                            </Row>

                                            <div className="m-t-lg"/>
                                            <Button variant="contained"
                                                    color="primary"
                                                    disabled={submitting}
                                                    onClick={handleSubmit}>
                                                {l('Save')}
                                            </Button>
                                        </form>
                                    </Ibox.Content>
                                </Ibox.Container>
                            </Col>
                        </Row>
                    </div>
                </LoadedPage>
            </InsurTecLayout>
        )
    }
}

export const FORM_NAME = 'auto-rating-edit';
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        insuranceTypeId: selector(state, AutoRatingFields.INSURANCE_TYPE_ID),
        analysisCriteriaId: selector(state, AutoRatingFields.ANALYSIS_CRITERIA_ID),
        pageState: {...state.page.analysisAutoRatingEdit}
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: (autoRatingId) => dispatch(initAutoRatingEdit({autoRatingId})),
            onInsuranceTypeChanged: (insuranceTypeId) => dispatch(onInsuranceTypeChanged(insuranceTypeId)),
            onCriteriaChanged: () => {
                dispatch(change(FORM_NAME, AutoRatingFields.RATING_DATA, null));
                dispatch(untouch(FORM_NAME, AutoRatingFields.RATING_DATA));
            },
        },
        dispatch
    }
}

export default reduxForm({
    form: FORM_NAME,
    onSubmit: (data, dispatch) => saveAutoRating(data, dispatch),
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoRatingEditPage));
