import React from 'react';
import {l} from '../../../../../../i18n/translator.js';
import {defaultFieldKey as dataFieldKey} from '../../../helper';
import {AdditionalInformationForm} from './step/additionalInformation.form.step';

/**
 * Form Component
 */
export const DefaultInsuranceForm = ({WizardActions, WizardContent, offerRequestId, i18n, ...props}) => (
    <div>
        <WizardContent>
            <h3 className="m-b-xl">
                {l('Please enter insurance data for this offer request')}
            </h3>

            <AdditionalInformationForm offerRequestId={offerRequestId}
                                       dataFieldKey={dataFieldKey}
                                       hideAttachments={props.hideAttachments}
                                       i18n={i18n}/>
        </WizardContent>
    </div>
);
