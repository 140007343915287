/**
 * Created by br0wn on 1/13/17.
 */
import {Types} from '../../config/domain/insurance';


export const getInsuranceType = (id) => {
    return Object.keys(Types).reduce((carry, key) => {
        return Types[key] === id ? id : carry;
    }, Types.DEFAULT);
};
