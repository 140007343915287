import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, } from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import {renderTextField} from '../../../common/form/renderer.jsx';
import {l} from '../../../../i18n/translator';
import * as Actions from '../action';
import Error from '../../../common/auth/error.jsx';


export class VerifyMobileComponent extends React.Component {
    componentWillMount() {
        const {initialize, email, token} = this.props;
        initialize({email, token: token});
    }

    render() {
        const {handleSubmit, submitting, submitSucceeded, pristine, error, invalid} = this.props;
        const {mobileTokenEnabled} = this.props;
        return (
            <form className="m-t" onSubmit={handleSubmit}>
                <div>
                    <h3>{l('Verify with your mobile for better security')}</h3>
                </div>
                {!submitSucceeded ? (
                        <div>
                            <Row>
                                <Col md={8} sm={8} xs={8}>
                                    <Field name="mobile" component={renderTextField}
                                           label={l('Mobile Number')}
                                           disabled={submitting || mobileTokenEnabled}
                                           fullWidth={true}/>
                                </Col>
                                <Col md={4} sm={4} xs={4}>
                                    <Field name="mobileToken" component={renderTextField}
                                           label={l('SMS Code')}
                                           disabled={submitting || !mobileTokenEnabled}
                                           fullWidth={true}/>
                                </Col>
                            </Row>
                            <Button
                                color="primary"
                                disabled={submitting || pristine || invalid}
                                onClick={handleSubmit}>{l('Verify Mobile')}</Button>
                        </div>
                    ) : (
                        <div>
                            <i className="fa fa-check-circle-o"
                               style={{color: 'green'}}/> {l('Mobile has been verified')}
                        </div>
                    )}

                <Error error={error}/>
            </form>
        );
    }
}
function mapStateToProps(state) {
    return {
        state,
        mobileTokenEnabled: state.page.verifyUser.mobileTokenEnabled
    };
}

function mapDispatchToProps(dispatch) {
    return {}
}
export default reduxForm({
    form: 'verifyMobile',
    onSubmit: Actions.verifyMobile,
    // validate: validate
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyMobileComponent));
