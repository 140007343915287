import request from 'superagent';
import {AUTHORIZATION_HEADER, AUTHORIZATION_HEADER_SCHEME, BASE_URL, HOST, PROTOCOL} from '../../config/api/endpoint';
import {makeRequest as makeHttpRequest} from '../../lib/http';

/**
 * Creates url for uri based on API configurtaion
 *
 * @param {string} uri
 * @return {string}
 */
export let createUrl = uri => {
    return [PROTOCOL, '://', HOST, BASE_URL, uri].join('');
};

/**
 * Normalizes request configuration
 *
 * @param {object=} config
 * @return {object}
 */
export let normalizeConfig = config => {
    config = config || {};
    config.headers = config.headers || {};
    return config;
};

/**
 * Initiates request towards uri based on API configuration
 *
 * @param {string} uri
 * @param {object=} config
 * @return {Promise}
 */
export let makeRequest = (uri, config) => {
    let url = createUrl(uri);
    return makeHttpRequest(url, config);
};

/**
 * Uploads a file with progress tracking
 *
 * @param {string} uri
 * @param {object=} config
 * @return {Promise}
 */
export const uploadFile = (uri, config = {}) => {
    config = normalizeConfig(config);

    const url = createUrl(uri);
    const {body, onProgress = () => null, headers} = config;

    return new Promise((resolve, reject) => {
        request
            .post(url)
            .on('progress', onProgress)
            .set(headers)
            .send(body)
            .end((err, res) => {
                if (err && (!err.status || !err.response)) {
                    return reject(err);
                }

                // use error response if error happened
                res = err ? err.response : res;

                const {text, header, status, xhr = {}} = res;
                const {statusText} = xhr;

                const headers = new Headers(header);
                const response = new Response(text, {
                    status, headers, statusText
                });

                return resolve(response);
            });
    });
};

/**
 * Returns request configuration for authenticated calls
 *
 * @param {string} token
 * @param {object=} config
 * @return {object}
 */
export let authenticatedRequest = (token, config) => {
    config = normalizeConfig(config);
    config.headers[AUTHORIZATION_HEADER] = `${AUTHORIZATION_HEADER_SCHEME} ${token}`;
    return config;
};

/**
 * Returns request configuration for calls with language
 *
 * @param {string} language
 * @param {object=} config
 * @return {object}
 */
export let languageRequest = (language, config) => {
    config = normalizeConfig(config);
    config.headers['Accept-Language'] = language;
    return config;
};

/**
 * Returns request configuration for GET JSON calls
 *
 * @param {object=} config
 * @return {object}
 */
export let jsonGetRequest = (config) => {
    config = normalizeConfig(config);
    config.headers['Accept'] = 'application/json';
    return config;
};

/**
 * Returns request configuration for POST JSON calls
 *
 * @param {object=} config
 * @return {object}
 */
export let jsonPostRequest = (config) => {
    config = normalizeConfig(config);
    config.method = 'post';
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    return config;
};

/**
 * Returns request configuration for POST calls
 *
 * @param {object=} config
 * @return {object}
 */
export let postRequest = (config) => {
    config = normalizeConfig(config);
    config.method = 'post';
    return config;
};

/**
 * Returns request configuration for DELETE calls
 *
 * @param {object=} config
 * @return {object}
 */
export let deleteRequest = (config) => {
    config = normalizeConfig(config);
    config.method = 'delete';
    return config;
};
