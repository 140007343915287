import {getFormValues, initialize as initializeForm, SubmissionError} from 'redux-form';
import {buildApiRequestData, buildErrors} from '../../../common/form/helper';
import {updateModal} from '../../request-details/action/offer';
import {publishOffer as apiPublish, saveOffer as apiSave, validateOffer as validate} from '../../../../api/sdk-action';
import TYPES from './type';
import {FORM_NAME} from '../../request-details/component/offerForm.jsx';
import {handleCompanyNotSetupError} from '../../../common/errors';

export const initFormWithOffer = (offer) => (dispatch) => {
    // const values = buildFormData(offer); // from common/form/helper
    // TODO maybe when we support data again, we need to use buildFormData again. But not for coverageAnalysis & premiumAnalysis

    const formData = {...offer};

    dispatch(initializeForm(FORM_NAME, formData));
};

export const setOfferRequest = (offerRequest) => ({
    type: TYPES.SET_OFFER_REQUEST, offerRequest
});


export const initForm = (offerRequest, offer = {}) => (dispatch) => {
    dispatch(setOfferRequest(offerRequest));
    dispatch(initFormWithOffer(offer));
};

let buildOfferFormData = function (formData) {
    const offer = buildApiRequestData(formData);
    offer.data = offer.data || {};

    delete offer['ui'];

    return offer;
};

export const save = () => {
    return (dispatch, getState) => {
        // generate request data
        const formData = getFormValues('offerEdit')(getState()) || {};
        const offer = buildOfferFormData(formData);
        const offerRequest = getState().page.createOffer.offerRequest;

        return dispatch(
            apiSave(offerRequest, offer)
        ).then((offer) => {
            dispatch(initFormWithOffer(offer));

            return offer;
        }).catch((error) => {
            // handle company not set
            if (handleCompanyNotSetupError(dispatch, error)) {
                return;
            }

            if (error.validationErrors) {
                throw new SubmissionError(buildErrors(error.validationErrors));
            }

            return Promise.reject(error);
        });
    };
};

export const publish = () => {
    return (dispatch, getState) => {
        const formData = getFormValues('offerEdit')(getState());
        const offer = buildOfferFormData(formData);
        const offerRequest = getState().page.createOffer.offerRequest;

        return dispatch(
            validate(offerRequest, offer)
        ).then(() => dispatch(
            apiSave(offerRequest, offer)
        )).then((offer) => dispatch(
            apiPublish(offer)
        )).catch(error => {
            if (error.validationErrors) {
                const validationErrors = buildErrors(error.validationErrors);
                dispatch(updateModal({show: true, readOnly: false}, false));
                throw new SubmissionError(validationErrors);
            }

            return Promise.reject(error);
        });
    };
};

export const onOfferFileSelected = (file) => {
    return {
        type: TYPES.OFFER_FILE_SELECTED,
        file: file
    };
};
