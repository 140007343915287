/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import classNames from 'classnames';
import {l} from '../../../i18n/translator.js';
import {States} from '../../../../config/domain/offer.js';
import {LocalCurrencyWithSymbol} from '../../common/i18n/number.jsx';
import URI from '../../../../config/uri';
import {fetchOffersList} from '../../../api/sdk-action';
import {BaseWidget} from './widget-base.jsx';
import {redirectToWithoutDispatch} from '../../../router/action';

export class PendingOffersWidget extends BaseWidget {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        const pendingStates = [States.PENDING].join(',');
        const listLength = 10;

        this.props.dispatch(fetchOffersList(
            1, 'state', pendingStates, listLength
        )).then(data => {
            this.props.widgetInitialized(this.getType(), {list: data.items})
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    getType() {
        return 'broker-offers-pending';
    }

    getTitle() {
        return l('Offers - Pending');
    }

    renderContent() {
        const {data} = this.props;
        const list = data.list || [];

        const handleClick = (item) => {
            redirectToWithoutDispatch(URI.OFFER_REQUEST_DETAILS.replace(':id', item.offerRequestId));
        };

        return (
            <div className="no-padding">
                <table className={classNames('table table-link-row table-row-mark table-offer-request table-striped table-hover')}>
                    <thead>
                    <tr>
                        <th>{l('Offer Request #')}</th>
                        <th>{l('Insurance')}</th>
                        <th>{l('Premium')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.map((item, index) => {
                        return (
                            <tr key={item.id} onClick={() => handleClick(item)}>
                                <td><span>#{item.offerRequestId}</span></td>
                                <td><span>{item.insuranceName}</span></td>
                                <td><LocalCurrencyWithSymbol value={item.premiumGross} currency="CHF" /></td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

                {/*<div className="text-center p-h-xs">*/}
                    {/*<Link to={URI.OFFER_REQUEST_LIST} className="btn btn-default">*/}
                        {/*{l('VIEW ALL')}*/}
                    {/*</Link>*/}
                {/*</div>*/}
            </div>
        )
    }
}
