import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import {verifyMobile} from '../action.js';
import {l} from '../../../i18n/translator';
import {renderTextField} from '../../common/form/renderer.jsx';


export class MobileVerifyComponent extends React.Component {
    render() {
        const {className, handleSubmit, pristine, error, invalid, currentMobileNumber} = this.props;
        return (
            <form onSubmit={handleSubmit} className={className}>
                {currentMobileNumber ? (
                    <div>
                        <i className="fa fa-check-circle-o" style={{color: 'green'}}/> {currentMobileNumber}
                    </div>
                ) : null}

                <Row>
                    <Col md={6}>
                        <Field name="mobile" component={renderTextField} label={l('Mobile Number')}
                               fullWidth/>
                    </Col>
                    <Col md={4}>
                        <Field name="mobileToken" component={renderTextField} label={l('SMS Code')} fullWidth/>
                    </Col>
                </Row>

                <div className="m-t-sm">
                    {error}
                    <Button variant="contained"
                            color="primary"
                            disabled={pristine || invalid}
                            onClick={handleSubmit}>
                        {l('Verify Mobile')}
                    </Button>
                </div>
            </form>
        )
    }
}

const validate = values => {
    const errors = {};
    if (values.password != values.passwordConfirm) {
        errors.password = errors.passwordConfirm = l('Passwords do not match');
    }
    return errors;
};

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        currentMobileNumber: state.page.profile.data.user.mobile
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export const MobileVerifyForm = reduxForm({
    form: 'twoFactorAuth',
    onSubmit: verifyMobile,
    validate: validate
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileVerifyComponent));
