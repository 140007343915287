/**
 * Created by br0wn on 11/2/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {Dropdown, Label, MenuItem} from 'react-bootstrap';
import {l} from '../../../i18n/translator.js';
import URI from '../../../../config/uri.js';
import {Types as NotificationTypes} from '../../../../config/domain/notification.js';
import {getNewNotificationsCount, getNotificationsList} from '../../../notification/reducer.js';
import {allNotificationsSeen} from '../../../notification/action';
import {LocalRelative} from '../../common/i18n/datetime.jsx';


const renderDivider = (index) => (
    <MenuItem key={`divider-${index}`} divider/>
);

const renderNotification = (notification, {index, onNotificationClick = (() => alert('click'))}) => {
    const {payload, notificationType} = notification;

    const text = {
        [NotificationTypes.NEW_OFFER]: l('New offer received'),
        [NotificationTypes.NEW_BEST_OFFER]: l('New best offer received'),
        [NotificationTypes.NEW_OFFER_REQUEST]: l('New offer request received'),
        [NotificationTypes.NEW_MESSAGE]: l('New message received'),
        [NotificationTypes.OFFER_REQUEST_INSURANCE_DATA]: l('New insurance data request received'),
        [NotificationTypes.OFFER_REQUEST_VALIDATION_REQUIRED]: l('Offer request requires validation'),
        [NotificationTypes.OFFER_VALIDATION_REQUIRED]: l('Offer requires validation'),
    };

    const linkTo = {
        [NotificationTypes.NEW_OFFER]: `${URI.OFFER_REQUEST_DETAILS}`.replace(':id', payload.offerRequestId),
        [NotificationTypes.NEW_BEST_OFFER]: `${URI.OFFER_REQUEST_DETAILS}`.replace(':id', payload.offerRequestId),
        [NotificationTypes.NEW_OFFER_REQUEST]: URI.OFFER_REQUEST_DETAILS.replace(':id', payload.offerRequestId),
        [NotificationTypes.NEW_MESSAGE]: `${URI.OFFER_REQUEST_DETAILS}#messages`.replace(':id', payload.offerRequestId),
        [NotificationTypes.OFFER_REQUEST_INSURANCE_DATA]: URI.UPDATE_OFFER_REQUEST_DATA.replace(':id', payload.offerRequestId),
        [NotificationTypes.OFFER_REQUEST_VALIDATION_REQUIRED]: URI.OFFER_REQUEST_DETAILS.replace(':id', payload.offerRequestId),
        [NotificationTypes.OFFER_VALIDATION_REQUIRED]: URI.OFFER_REQUEST_DETAILS.replace(':id', payload.offerRequestId),
    };

    const ItemComponent = ({children}) => (
        <Link to={linkTo[notificationType] || "#"}>
            {children}
        </Link>
    );

    return (
        <MenuItem
            key={`notification-${index}`}
            onClick={e => onNotificationClick(notification)}
            componentClass={ItemComponent}
        >
            <div>
                <i className="fa fa-envelope fa-fw"></i>&nbsp;
                {text[notificationType] || l('Unknown notification')}

                <span className="pull-right text-muted small">
                    <LocalRelative value={notification.creationDate}/>
                </span>
            </div>
        </MenuItem>
    );
};

const renderNotifications = (notifications = [], {onNotificationClick}) => {
    return notifications.reduce((carry, notification, index) => [
        // add divider
        ...(index <= 0 ? carry : [...carry, renderDivider(index)]),
        // add notification
        renderNotification(notification, {index, onNotificationClick})
    ], [])
};

export const NotificationsWidget = ({notifications = [], countNew = 0, onDropdownToggle, onNotificationClick}) => {
    const hasNotifications = notifications.length > 0;

    return (
        <Dropdown componentClass="li" id="nav-dropdown-notifications" onToggle={onDropdownToggle}>
            <Dropdown.Toggle componentClass="a" noCaret useAnchor className="count-info">
                <i className="fa fa-bell"/>

                {countNew ? (
                        <Label bsStyle="primary">
                            {countNew}
                        </Label>
                    ) : null}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-alerts nav-notifications-list">

                {!hasNotifications ? (
                        <MenuItem componentClass="div" className="text-center link-block">
                            {l('No notifications at the moment')}
                        </MenuItem>
                    ) :
                    renderNotifications(notifications, {onNotificationClick})
                }

            </Dropdown.Menu>
        </Dropdown>
    )
};

export default connect(
    state => ({
        i18n: state.i18n,
        notifications: getNotificationsList(state),
        countNew: getNewNotificationsCount(state)
    }),
    dispatch => ({
        onDropdownToggle: (isOpen) => {
            if (isOpen) dispatch(allNotificationsSeen());
        }
    })
)
(NotificationsWidget);
