/**
 * Created by br0wn on 11/21/16.
 */
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {bindActionCreators} from 'redux';
import {getPageState, getWidgetState} from '../selector';
import {
    widgetInitializing, widgetInitialized, widgetInitError, widgetError, widgetWorking, widgetUpdate
} from '../action';

import {BiddingRequestsWidget} from './requests-bidding.jsx';
import {FinishedRequestsWidget} from './requests-finished.jsx';
import {PendingRequestsWidget} from './requests-pending.jsx';
import {SelectedRequestsWidget} from './requests-selected.jsx';
import {CustomerRequestsForInsuranceWidget} from './requests-customer-insurance.jsx';
import {CustomerRequestsWidget} from './requests-customer.jsx';
import {PendingOffersWidget} from './offers-pending.jsx';
import {ManualProcessingOffersWidget} from './offers-manual-processing.jsx';
import {NewMessagesWidget} from './messages-new.jsx';


const WidgetActions = {
    widgetInitializing,
    widgetInitialized,
    widgetInitError,
    widgetError,
    widgetWorking,
    widgetUpdate
};


export const BiddingRequests = connect(
    state => getWidgetState(getPageState(state), (new BiddingRequestsWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(BiddingRequestsWidget);


export const PendingRequests = connect(
    state => getWidgetState(getPageState(state), (new PendingRequestsWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(PendingRequestsWidget);


export const FinishedRequests = connect(
    state => getWidgetState(getPageState(state), (new FinishedRequestsWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(FinishedRequestsWidget);


export const SelectedRequests = connect(
    state => getWidgetState(getPageState(state), (new SelectedRequestsWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(SelectedRequestsWidget);


export const CustomerRequestsForInsurance = connect(
    state => getWidgetState(getPageState(state), (new CustomerRequestsForInsuranceWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(CustomerRequestsForInsuranceWidget);


export const CustomerRequests = connect(
    state => getWidgetState(getPageState(state), (new CustomerRequestsWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(CustomerRequestsWidget);


export const PendingOffers = connect(
    state => getWidgetState(getPageState(state), (new PendingOffersWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(withRouter(PendingOffersWidget));

export const ManualProcessingOffers = connect(
    state => getWidgetState(getPageState(state), (new ManualProcessingOffersWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(withRouter(ManualProcessingOffersWidget));

export const NewMessages = connect(
    state => getWidgetState(getPageState(state), (new NewMessagesWidget).getType()),
    dispatch => ({
        ...bindActionCreators(WidgetActions, dispatch),
        dispatch
    })
)(NewMessagesWidget);
