/* eslint-disable import/default */

/*
 * Import styles and static
 */
require('./favicon.ico'); // Tell webpack to load favicon.ico
import './resources/vendor/inspinia/css/bootstrap.css';
import './resources/vendor/inspinia/font-awesome/css/font-awesome.css';
import './resources/vendor/inspinia/css/plugins/iCheck/custom.css';
import './resources/vendor/inspinia/css/plugins/sweetalert/sweetalert.css';
import './resources/vendor/inspinia/css/plugins/dataTables/datatables.min.css';
import './resources/vendor/inspinia/css/animate.css';
import './resources/vendor/inspinia/css/style.css';
import './resources/vendor/gibson_regular_macroman/stylesheet.css';
import 'react-datepicker/dist/react-datepicker.css';
import './resources/style/style.scss';

/*
 * Import js plugins defined as externals
 */
import './resources/vendor/inspinia/js/plugins/sweetalert/sweetalert.min.js';
import 'event-source-polyfill/src/eventsource.min';

import "core-js/stable";
import "regenerator-runtime/runtime";

/**
 * Runs the app
 */

const run = () => {
    require('./app/index');
};

/*
 * Run
 */
import polyfillIntl from './shim/intl/polyfill';

polyfillIntl(run);
