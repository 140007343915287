import React from 'react';
import {l} from  '../../../../i18n/translator';
import Spinner from '../../misc/spiner.jsx';
import {PageNotFoundBox} from '../notFound.jsx';


const LoadedPage = ({pageState: {isLoading, isLoaded, notFound, loadErrors = []}, children}) => {
    if (isLoading) {
        return (
            <div className="middle-box text-center">
                <Spinner />
            </div>
        );
    } else if (loadErrors.length > 0) {
        return (
            <div className="middle-box text-center">
                <h3 className="font-bold">{l('Error while loading data.')}</h3>
                <div className="error-desc">
                    {l('There were some errors while fetching data. Please try to reload the page manually or by clicking on the link bellow.')}
                    <br />
                    <a onClick={()=> window.location.reload()} className="btn btn-primary m-t">
                        {l('Reload the page')}
                    </a>
                </div>
            </div>
        );
    } else if (notFound) {
        return (
            <PageNotFoundBox style={{marginTop: '0px'}}/>
        )
    } else if (isLoaded) {
        return <div>{children}</div>;
    }

    return null;
};


export default LoadedPage;