import * as math from 'mathjs';
import {isEmpty} from './core';


/**
 * Normalizes number as float
 *
 * @param {*} number
 * @return {number}
 */
export let normalizeToFloat = (number) => {
    number = parseFloat(number || 0);

    return isNaN(number) ? 0 : number;
};

/**
 * Sums mixed type numbers
 *
 * @param numbers
 * @return {number}
 */
export let sumNumbers = (...numbers) => {
    return numbers.reduce((result, number) => {
        number = normalizeToFloat(number);

        if (null === result) {
            return number;
        }

        return result + number;
    }, null);
};

/**
 * Multiplies mixed type numbers
 *
 * @param numbers
 * @return {number}
 */
export let multiplyNumbers = (...numbers) => {
    return numbers.reduce((result, number) => {
        number = normalizeToFloat(number);

        if (null === result) {
            return number;
        }

        return result * number;
    }, null);
};

/**
 * Takes percentile value and converts it to decimal
 *
 * @param {string|number} value
 * @return {string}
 */
export const percentileToDecimal = (value) => {
    if (isEmpty(value)) {
        return value;
    }

    value = math.bignumber(value);
    value = math.divide(value, math.bignumber(1000));

    return math.string(value);
};

/**
 * Takes decimal value and converts it to percentile
 *
 * @param {string|number} value
 * @return {string}
 */
export const decimalToPercentile = (value) => {
    if (isEmpty(value)) {
        return value;
    }

    value = math.bignumber(value);
    value = math.multiply(value, math.bignumber(1000));

    return math.string(value);
};
