import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {updatePassword} from '../action.js';
import {l} from '../../../i18n/translator';
import {renderTextField} from '../../common/form/renderer.jsx';


export class PasswordFormComponent extends React.Component {
    render() {
        const {className, handleSubmit, pristine, error, invalid} = this.props;
        return (
            <form onSubmit={handleSubmit} className={className}>
                <div>
                    <Field name="oldPassword" component={renderTextField} type='password'
                           fullWidth
                           label={l('Old Password')}/>
                </div>
                <div className="m-t-sm">
                    <Field name="password" component={renderTextField} type='password'
                           fullWidth
                           label={l('New Password')}/>
                </div>
                <div className="m-t-sm">
                    <Field name="passwordConfirm" component={renderTextField} type='password'
                           fullWidth
                           label={l('Confirm New Password')}/>
                </div>
                <div className="m-t-md">
                    {error}
                    <Button variant="contained"
                            color="primary"
                            disabled={pristine || invalid}
                            onClick={handleSubmit}>
                        {l('Change Password')}
                    </Button>
                </div>
            </form>
        )
    }
}

const validate = values => {
    const errors = {};
    if (values.password != values.passwordConfirm) {
        errors.password = errors.passwordConfirm = l('Passwords do not match');
    }
    return errors;
};

function mapStateToProps(state) {
    return {
        i18n: state.i18n
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export const PasswordForm = reduxForm({
    form: 'passwordUpdate',
    onSubmit: updatePassword,
    validate: validate
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordFormComponent));
