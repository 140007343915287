import React from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, isPristine, reduxForm, submit} from 'redux-form';
import {ButtonToolbar, Col, Grid, Row, ToggleButton} from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import {mapUserToFormValues} from '../../../helper/mapping/user.js';
import {l} from '../../../i18n/translator';
import {renderSelectField, renderTextField, renderToggleButtonGroup} from '../form/renderer.jsx';

const SimpleForm = (props) => {
    const {isNewUser, roleValue, handleSubmit, pristine, reset, submitting} = props;
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md={6}>
                    <div>
                        <Field name="email" component={renderTextField} label={l('E-Mail')}
                               disabled={!isNewUser}
                               fullWidth/>
                    </div>
                </Col>
                <Col md={6}>
                    <div>
                        <Field name="role" component={renderSelectField} label={l('Role')} isInDialog fullWidth>
                            <MenuItem value={'admin'} primaryText={l('admin')}/>
                            <MenuItem value={'user'} primaryText={l('user')}/>
                        </Field>
                    </div>
                </Col>
            </Row>

            {roleValue === 'user' ? (
                <div>
                    <Row>
                        <Col md={12}>
                            <h2>{l('Permissions')}</h2>
                        </Col>
                    </Row>
                    {[...props.insuranceTypes]
                        .sort((i1, i2) => {
                            if (i1.name < i2.name) return -1;
                            if (i1.name > i2.name) return 1;
                            return 0;
                        })
                        .map((insuranceType) => (
                            <Grid fluid={true} key={insuranceType.id}>
                                <Row className="m-b-lg">
                                    <Col md={6}>
                                        <div>
                                            {insuranceType.name}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <ButtonToolbar>
                                            <Field name={`permissions.insuranceTypes.${insuranceType.id}`}
                                                   component={renderToggleButtonGroup}>
                                                <ToggleButton value="" color={'red'}>{l('None')}</ToggleButton>
                                                <ToggleButton value="READ">{l('Read only')}</ToggleButton>
                                                <ToggleButton value="WRITE">{l('Write')}</ToggleButton>
                                            </Field>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </Grid>
                        ))}
                </div>
            ) : null}
        </form>
    )
};

const selector = formValueSelector('userEdit');
export const UserEditForm = connect(
    state => {
        return {
            i18n: state.i18n,
            isNewUser: !selector(state, 'id'),
            roleValue: selector(state, 'role'),
            selectedInsuranceType: selector(state, 'insuranceType'),
        };
    }
)(reduxForm({
    form: 'userEdit',
    onSubmit: (data, dispatch, props) => props.onSubmitUser(data, dispatch),
})(SimpleForm));

export class UserEditModalComponent extends React.Component {
    render() {
        const {show, user = {}, insuranceTypes, onSubmitUser, onClose, pristine} = this.props;
        const {actions} = this.props;
        return (
            <Dialog open={show} onBackdropClick={onClose}>
                <DialogTitle>{user.id ? l('Edit User') : l('Create user')}</DialogTitle>
                <DialogContent>
                    <div style={{minWidth: '400px'}}>
                        <UserEditForm
                            initialValues={mapUserToFormValues(user)}
                            insuranceTypes={insuranceTypes}
                            onSubmitUser={onSubmitUser}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}>
                        {l('Close')}
                    </Button>
                    <Button
                        color="primary"
                        disabled={pristine}
                        onClick={actions.submit}>
                        {user.id ? l('Save') : l('Create')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        form: {...state.form.userEdit},
        pristine: isPristine('userEdit')(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            submit: () => dispatch(submit('userEdit'))
        }
    }
}

export const UserEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditModalComponent);
