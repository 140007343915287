import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';

import {Fields, FORM_NAME} from '../../config/form';
import {OfferRoundTypes} from '../../../../../../config/domain/offerRequest';
import {AuctionForm} from './offer-round/auction.form.jsx';
import {FirstShotForm} from './offer-round/firstShot.form.jsx';

const OfferRoundDataForm = ({offerRoundType, ...props}) => {
    if (offerRoundType === OfferRoundTypes.AUCTION) {
        return (<AuctionForm/>);
    } else if (offerRoundType === OfferRoundTypes.FIRST_SHOT) {
        return (<FirstShotForm/>);
    }
    return (<div/>);
};

// Decorate with connect to read form values
const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        offerRoundType: selector(state, Fields.OFFER_ROUND_TYPE),
    })
)(OfferRoundDataForm);
