import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';

import {Field} from '../redux-form-wrapper.jsx';
import RadioButton from '../../../../common/form/RadioButton.jsx';

import {l} from '../../../../../i18n/translator';
import {Fields, FORM_NAME} from '../../config/form';
import {
    renderCheckbox,
    renderCurrencyField,
    renderDatePicker,
    renderRadioGroup,
    renderSelectField
} from '../../../../common/form/renderer.jsx';
import BrokerOnly from '../../../../common/auth/acl/brokerOnly.jsx';
import {generateTargetPremium} from '../../action/form.js';


class ContractDataForm extends React.Component {

    render() {
        const {i18n, actions, currentInsurancePremiumGross, targetPremium} = this.props;

        return (
            <div className="m-b-xl">
                <h3 className="m-b-md">
                    {l('How would you like this request setup?')}
                </h3>


                <Row>
                    <Col md={6}>
                        <Field
                            name={Fields.INSURANCE_START}
                            label={l('Desired start date')}
                            component={renderDatePicker}
                            fullWidth={true}/>
                    </Col>
                    <Col md={6}>
                        <Field name={Fields.INSURANCE_PERIOD} label={l('Insurance period')}
                               component={renderSelectField} fullWidth={true}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) =>
                                <MenuItem key={number} value={number}
                                          primaryText={l('{{count}} years', {count: number})}/>
                            )}
                        </Field>
                    </Col>
                </Row>

                <p className="font-bold m-t-md">
                    {l('Billing frequency')}
                </p>

                <div className="p-w-xs">
                    <Field name={Fields.INSURANCE_BILLING_FREQUENCY} component={renderRadioGroup} isValueInteger={true}
                           row>
                        <RadioButton value={'3'} label={l('billing_frequency_3')}/>
                        <RadioButton value={'6'} label={l('billing_frequency_6')}/>
                        <RadioButton value={'12'} label={l('billing_frequency_12')}/>
                    </Field>
                </div>

                <Field name={Fields.INSURANCE_YEARLY_TERMINATION} component={renderCheckbox}
                       label={l('Yearly right of termination')} className="m-l-sm m-t-sm"/>

                {currentInsurancePremiumGross ?
                    <BrokerOnly auth={this.props.auth}>
                        <Row className="text-left">
                            <Col md={6}>
                                <div className="m-b-md m-t-sm">
                                    <Field name={Fields.TARGET_PREMIUM} component={renderCurrencyField}
                                           label={l('Target premium')} fullWidth={true}/>
                                    {!targetPremium ?
                                        <div>
                                            <span className="label label-default">{l('No target premium')}</span>
                                        </div> : null}
                                    <button className="btn btn-default m-t-sm"
                                            onClick={actions.generateTargetPremium}>
                                        {l('Generate')}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </BrokerOnly> : null}
            </div>
        );
    }
}

const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        currentInsurancePremiumGross: selector(state, Fields.CURRENT_INSURANCE_PREMIUM_GROSS),
        targetPremium: selector(state, Fields.TARGET_PREMIUM),
    }),
    (dispatch) => ({
        actions: bindActionCreators({
            generateTargetPremium: generateTargetPremium,
        }, dispatch)
    })
)(ContractDataForm);
