/**
 * Created by br0wn on 11/22/16.
 */
import {WIDGET_STATE} from './reducer';


export const getPageState = state => ({
    i18n: state.i18n,
    ...state.page.dashboard
});


export const getKpiList = pageState => pageState.kpiList || [];


export const getWidgetState = (pageState, widgetType) => ({
    i18n: pageState.i18n,
    ...( pageState.widgets[widgetType] || WIDGET_STATE)
});