/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import {l} from '../../../i18n/translator.js';
import {fetchSelectedOfferRequests} from '../../../api/sdk-action';
import {BaseWidget} from './widget-base.jsx';
import {
    Column as OfferRequestColumn,
    favoriteValueFormat,
    PremiumValueComponent,
    Table as OfferRequestTable
} from '../../insurance-offer/request-list/component/table.jsx';


export class SelectedRequestsWidget extends BaseWidget {

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        this.props.dispatch(
            fetchSelectedOfferRequests()
        ).then(data => {
            this.props.widgetInitialized(this.getType(), {list: data})
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    getType() {
        return 'insurance-requests-selected';
    }

    getTitle() {
        return l('Selected by Customer');
    }

    renderContent() {
        const {data} = this.props;
        const list = data.list || [];

        return (
            <div className="no-padding">
                {list.length > 0 ? (
                    <OfferRequestTable items={list} className="m-b-none table-hover table-striped">
                        <OfferRequestColumn name={l('Customer name')} field="customerName"/>
                        <OfferRequestColumn name={l('Offer')} field="offerPremiumGrossMin"
                                            valueComponent={PremiumValueComponent}/>
                        <OfferRequestColumn name={l('Rank')} field="rank"/>
                        <OfferRequestColumn field="favorite" valueFormat={favoriteValueFormat}/>
                    </OfferRequestTable>
                ) : <div className="no-data p-m">{l('None')}</div>}
            </div>
        )
    }
}
