//import URI from '../../../../config/uri';
import API from  '../../../../config/api/routes';
import {warn} from '../../../../lib/logger';
import {handleErrorResponse, isBadRequest} from '../../../../lib/http';
import {l} from '../../../i18n/translator';
import {makeRequest, jsonPostRequest} from '../../../api/request';
import {buildErrors} from '../../common/form/helper';


export const TYPES = {
    SUBMIT_START: 'page.register.register_start',
    SUBMIT_SUCCESS: 'page.register.register_success',
    SUBMIT_FAILED: 'page.register.register_failed'
};


export const submit = (user, password, repeatPassword) => {
    return (dispatch) => {
        if (!user) {
            return dispatch(submitError({
                email: l('Email address required.')
            }));
        }

        if (password !== repeatPassword) {
            return dispatch(submitError({
                password: true,
                repeatPassword: l('Passwords do not match.')
            }));
        }

        if (!password) {
            return dispatch(submitError({
                password: l('Password cannot be empty')
            }));
        }


        // dispatch login start
        dispatch({
            type: TYPES.SUBMIT_START
        });

        // make login request
        const request = makeRequest(API.AUTH.REGISTER, jsonPostRequest({
            body: JSON.stringify({email: user, password})
        }));

        // validate response
        request
            .then(() => dispatch({
                type: TYPES.SUBMIT_SUCCESS
            }))
            .catch(e => handleErrorResponse(e, response => {
                if (!isBadRequest(response)) {
                    throw e;
                }

                return response.json().then(data => {
                    dispatch(submitError(buildErrors(data.errors)));
                });
            }))
            .catch(e => {
                // log error
                warn("Fetch error", e);

                // show error message
                dispatch(submitError({
                    message: l('Something went wrong, please reload the page and try again.'),
                    error: e
                }));
            });
    };
};

export const submitError = (error) => {
    return (dispatch) => {
        // error
        dispatch({
            type: TYPES.SUBMIT_FAILED,
            errors: {...error}
        });
    };
};
