import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import {Element} from 'react-scroll';
import {LocalRelative, LocalDate, LocalTime} from '../../../i18n/datetime.jsx';
import {Icons} from '../../../../../../config/icons.js';


const CompanyNameLabel = ({message}) => (
    <span className="label label-info">
        {message.creatorCompanyName}
    </span>
);


const Message = ({message, chatUser}) => {
    const isMessageByUserCompany = message.creatorCompanyId == chatUser.companyId;
    return (
        <div className={classNames("chat-message", {
            'right': isMessageByUserCompany,
            'left': !isMessageByUserCompany,
            'new': !message.read
        })}>
            <Element name={`message-${message.id}`}/>

            <span className={classNames({
                'pull-left': !isMessageByUserCompany,
                'pull-right': isMessageByUserCompany
            })}>
                <i className={`fa ${Icons.CompanyType[message.creatorCompanyType.toUpperCase()]} fa-2x`}></i>
            </span>

            <div className={classNames("message", {
                'text-right': isMessageByUserCompany
            })}>
                <span className="message-author">{message.creatorName}</span>
                <span className="message-date">
                    <LocalTime value={message.creationDate}/>
                    &nbsp;-&nbsp;
                    <LocalDate value={message.creationDate}/>
                    &nbsp;|&nbsp;
                    <LocalRelative value={message.creationDate}/>
                </span>
                <span className="message-content">{message.content}</span>
            </div>
        </div>

    );
};

Message.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        creatorId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        creatorCompanyId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        creatorCompanyType: PropTypes.string,
        creatorCompanyName: PropTypes.string,
        content: PropTypes.string,
        creatorName: PropTypes.string,
        creationDate: PropTypes.string
    }),

    chatUser: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        companyId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        companyType: PropTypes.string
    })
};

export default Message;
