/**
 * Created by br0wn on 12/6/16.
 */
import React from 'react';


export const renderChildren = (children) => {
    if (React.Children.count(children) > 1 || !React.isValidElement(children)) {
        return (<span>{children}</span>);
    }

    return React.Children.only(children);
};