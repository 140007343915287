import {replace} from 'connected-react-router';
import {change, clearFields} from 'redux-form';

import {l} from '../../../../i18n/translator';
import {warn} from '../../../../../lib/logger';
import {ACTIONS} from '../../../../../config/domain/entity';

import {CompanyTypes} from '../../../../../config/domain/user';
import {getUserCompany, isActionPermitted, isUserOfType} from '../../../../auth/helper';
import {
    fetchAnalysisCriteriaList,
    fetchBrokers,
    fetchCustomers,
    fetchInsurances,
    fetchInsuranceTypes,
    fetchOfferRequest,
    fetchOfferRequestContact
} from '../../../../api/sdk-action';
import {setFormApiData, setInitialFormData} from './form';
import TYPES from './type';
import URI from '../../../../../config/uri.js';
import {Fields, FORM_NAME} from '../config/form';
import {isCreatedFinishedOffer} from '../../../../model/offerRequest';

export const init = (offerRequestId) => {
    return (dispatch, getState) => {
        const userCompany = getUserCompany(getState().auth);
        const isBroker = isUserOfType(getState().auth, CompanyTypes.BROKER);
        const userContact = userCompany.contact ? userCompany.contact : {};

        dispatch({type: TYPES.PAGE_LOAD_START});

        dispatch(
            loadOfferRequest(offerRequestId)
        ).then(offerRequest => {
            if (offerRequest && isCreatedFinishedOffer(offerRequest)) {
                return dispatch(replace(URI.CREATE_OFFER_REQUEST_FINISHED_EDIT.replace(':id', offerRequest.id)));
            }

            return Promise.all([
                dispatch(fetchInsuranceTypes()),
                dispatch(fetchBrokers()),
                isBroker ? dispatch(fetchCustomers()) : [],
                offerRequest && offerRequest.insuranceTypeId ? dispatch(fetchAnalysisCriteriaList({insuranceType: offerRequest.insuranceTypeId})) : [],
                dispatch(loadContact(offerRequest, userContact))
            ]).then(([insuranceTypes, brokers, customers, analysisCriteria, contact]) => {
                dispatch(dataLoaded('insuranceTypes', insuranceTypes));
                dispatch(dataLoaded('brokers', brokers));
                dispatch(dataLoaded('customers', customers));
                dispatch(dataLoaded('analysisCriteria', analysisCriteria));

                if (null === offerRequest || null !== offerRequest.contact || offerRequest.companyId == offerRequest.creatorCompanyId) {
                    dispatch(dataLoaded('contact', contact));
                } else {
                    // if offer request is created by broker, set the company of the company of the offerRequest
                    const contact = customers.find(customer => customer.id == offerRequest.companyId).contact;
                    dispatch(dataLoaded('contact', contact ? {...contact, id: null} : contact));
                }

                if (null !== offerRequest) {
                    dispatch(setFormApiData(offerRequest));
                } else {
                    dispatch(setInitialFormData());
                }

                dispatch(pageLoadEnd({isLoaded: true}));

            }).catch(error => {
                warn("Error while fetching offer resources!", error);
                let errors = [];

                if (error.requestError) {
                    errors.push(l("Unknown response received from server"));
                } else {
                    errors.push(l("Unknown error occurred"));
                }

                dispatch(pageLoadEnd({errors: errors}));
            });

        }).catch(error => {
            warn("Error while loading offer!", error);

            if (error.notFound || error.forbidden) {
                return dispatch(pageLoadEnd({notFound: true}));
            }

            let errors = [];

            if (error.requestError) {
                errors.push(l("Unknown response received from server"));
            } else {
                errors.push(l("Unknown error occurred"));
            }

            dispatch(pageLoadEnd({errors}));
        });
    };
};

export const onInsuranceTypeChanged = (insuranceType) => {
    return (dispatch) => {
        dispatch(change(FORM_NAME, Fields.INSURANCE_ID, []));
        dispatch(clearFields(FORM_NAME, false, false,
            Fields.CURRENT_INSURANCE_ID,
            Fields.PREMIUM_ANALYSIS_CRITERIA,
            Fields.CURRENT_INSURANCE_PREMIUM_ANALYSIS_RATINGS,
            Fields.COVERAGE_ANALYSIS_CRITERIA,
            Fields.CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS,
            Fields.UTILITY_ANALYSIS_CRITERIA,
            Fields.UTILITY_ANALYSIS_RATINGS));
        dispatch(loadInsurances(insuranceType));
        dispatch(loadAnalysisCriteria(insuranceType));
    };
};

export const loadInsurances = (insuranceType) => {
    return (dispatch) => dispatch(
        fetchInsurances(insuranceType)
    ).then(insurances =>
        dispatch(dataLoaded('insurances', insurances))
    ).catch(error => {
        if (error.requestError) {
            warn(`Loading insurances for type ${insuranceType} failed`, error);
            return dispatch(dataLoaded('insurances', []));
        }

        throw error;
    });
};

export const loadAnalysisCriteria = (insuranceType) => {
    return (dispatch) => dispatch(
        fetchAnalysisCriteriaList({insuranceType: insuranceType})
    ).then(analysisCriteria =>
        dispatch(dataLoaded('analysisCriteria', analysisCriteria))
    ).catch(error => {
        if (error.requestError) {
            warn(`Loading analysisCriteria for type ${insuranceType} failed`, error);
            return dispatch(dataLoaded('analysisCriteria', []));
        }

        throw error;
    });
};

export const loadContact = (offerRequest, userContact) => {
    return (dispatch) => {
        // return user contact if no offer request or offer request has custom contact
        if (null === offerRequest || offerRequest.contact !== null) {
            return Promise.resolve(userContact);
        }

        return dispatch(
            fetchOfferRequestContact(offerRequest.id)
        );
    };
};

export const loadOfferRequest = (offerRequestId) => {
    return (dispatch) => {
        if (!offerRequestId) {
            return Promise.resolve(null);
        }

        return dispatch(
            fetchOfferRequest(offerRequestId)
        ).then(offerRequest => {
            if (!isActionPermitted(ACTIONS.EDIT, offerRequest.actions)) {
                return Promise.reject({notFound: true});
            }

            return Promise.resolve(offerRequest);
        });
    };
};

export const dataLoaded = (name, values) => {
    return {
        type: TYPES.DATA_LOADED,
        data: {[name]: values}
    };
};

export const pageLoadEnd = (params = {}) => ({
    type: TYPES.PAGE_LOAD_END,
    ...params
});

export const cleanup = () => {
    return {
        type: TYPES.PAGE_RESET
    };
};
