/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import classNames from 'classnames';
import {l} from '../../../i18n/translator.js';
import {TYPES as KPI_TYPES} from '../../../../config/domain/kpi.js';
import {LocalNumber} from '../../common/i18n/number.jsx';
import * as Ibox from '../../ui-components/ibox.jsx';


const KPI_TYPE_SETTINGS = {
    // shared
    [KPI_TYPES.NEW_MESSAGES]: {
        name: 'New messages',
        image: require('../../../../resources/images/kpi/kpi-new-messages.png'),
        className: 'kpi-item-lazur'
    },
    // customer
    [KPI_TYPES.TOTAL_SAVINGS]: {
        name: 'Total Savings',
        append: ' CHF',
        image: require('../../../../resources/images/kpi/kpi-total-savings.png'),
        className: 'kpi-item-red'
    },
    [KPI_TYPES.NEW_BIDS]: {
        name: 'New Bids',
        image: require('../../../../resources/images/kpi/kpi-new-bids.png'),
        className: 'kpi-item-yellow'
    },
    [KPI_TYPES.OFFICIAL_OFFERS]: {
        name: 'Official Offers',
        image: require('../../../../resources/images/kpi/kpi-official-offers.png'),
        className: 'kpi-item-navy'
    },
    // broker & insurance
    [KPI_TYPES.PREMIUM_VOLUME]: {
        name: 'Premium Volume',
        append: ' CHF',
        image: require('../../../../resources/images/kpi/kpi-official-offers.png'),
        className: 'kpi-item-red'
    },
    [KPI_TYPES.SIGN_OFFS]: {
        name: 'Sign Offs',
        image: require('../../../../resources/images/kpi/kpi-new-bids.png'),
        className: 'kpi-item-navy'
    },
    // broker
    [KPI_TYPES.TASKS_COMPLETED]: {
        name: 'Tasks Completed',
        icon: 'check-square-o',
        className: 'kpi-item-yellow'
    },
    [KPI_TYPES.REQUESTS_FINISHED]: {
        name: 'Requests Finished',
        icon: 'file-text-o',
        className: 'kpi-item-navy'
    },
    // insurance
    [KPI_TYPES.ENDING_SOON]: {
        name: 'Ending Soon',
        icon: 'clock-o',
        className: 'kpi-item-yellow'
    },
    [KPI_TYPES.REQUESTS_SELECTED]: {
        name: 'Requests Selected',
        icon: 'file-text-o',
        className: 'kpi-item-navy'
    }
};


export const isSupported = (kpiType) => !!KPI_TYPE_SETTINGS[kpiType];


export const KpiItem = ({kpi, ...props}) => {

    if (!isSupported(kpi.type)) {
        return null;
    }

    const {type, value} = kpi;
    const {icon, image, name, append, className} = KPI_TYPE_SETTINGS[type];
    const splitStringInTwo = (text) => {
        let center = text.length /2;
        for (let i = 0; i < center; i++) {
            if (text.charAt(center - i) == ' ') {
                return [text.substring(0, center - i), text.substring(center - i + 1)];
            } else if (text.charAt(center + i) == ' ') {
                return [text.substring(0, center + i), text.substring(center + i + 1)];
            }
        }
        return [text];
    };
    const lines = splitStringInTwo(l(name));

    const containerClass = classNames(
        'kpi-item', 'widget', className, props.className
    );

    return (
        <Ibox.Container>
            <Ibox.Content className="p-sm">
                <div className="inline kpi-value">
                    <h2 className="font-bold number">
                        <LocalNumber value={value}/>
                    </h2>
                    {append ? <h2 className="font-bold">&nbsp;{append}</h2> : null}
                </div>
                <div className="inline icon">
                    <div className="kpi-icon text-center">
                        {image ? <img className="image" src={image}/> : <i className={classNames('fa', `fa-${icon}`)}></i>}
                    </div>
                </div>
                <div className={classNames('inline', 'kpi-name', `lines${lines.length}`)}>
                    {lines.length > 1 ? (
                        <span>{lines[0]}<br/>{lines[1]}</span>
                    ) : <span>{lines[0]}</span>}
                </div>
            </Ibox.Content>
        </Ibox.Container>
    )
};
