import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {change, formValueSelector, reduxForm} from 'redux-form';
import {Link} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import URI from '../../../../config/uri';
import {l} from '../../../i18n/translator';
import AppLayout from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx';
import OfferRoundSelectField from '../../common/form/offer-request/offer-round-select.jsx';
import * as PageActions from './action/page';
import * as Ibox from '../../ui-components/ibox.jsx';
import {Field, FieldArray} from '../create-request/component/redux-form-wrapper.jsx';
import {Fields} from '../../../../config/domain/offerRequest.js';
import {
    renderCheckbox,
    renderCurrencyField,
    renderSelectField,
    renderSingleFileUpload,
    renderTextField
} from '../../common/form/renderer.jsx';
import CheckboxButton from '../../common/form/CheckboxButton';

const OfferFieldArray = ({i18n, fields, submitting, insurances, offers}) => (
    <Ibox.Container>
        <Ibox.Title>
            <h5><i className="fa fa-file-text"/>&nbsp;{l('Offers')}</h5>
        </Ibox.Title>
        <Ibox.Content>
            {!fields || fields.length === 0 ? (
                <div className="m-b" style={{color: 'red'}}>{l('Please add at least one offer.')}</div>
            ) : null}

            {
                fields.map((field, index) => (
                    <OfferRow key={index} i18n={i18n} field={field} value={offers[index]} submitting={submitting}
                              insurances={insurances} onRemove={() => fields.remove(index)}/>
                ))
            }
            <Button variant="contained"
                    disabled={submitting}
                    onClick={() => fields.push({abstained: false})}>{l('+ Add offer')}</Button>
        </Ibox.Content>
    </Ibox.Container>
);

const CurrentInsuranceField = ({i18n, input: {name: fieldName, value}, submitting, insurances, meta: {touched, error}, ...props}) => {
    return (
        <Ibox.Container>
            <Ibox.Title>
                <h5><i className="fa fa-life-ring"/>&nbsp;{l('Current insurance')}</h5>
            </Ibox.Title>
            <Ibox.Content>
                <CheckboxButton
                    label={l('Has current insurance')}
                    checked={!!value}
                    onChange={() => props.onToggleInsurance && props.onToggleInsurance(!value)}
                />
                {value ?
                    <OfferRow i18n={i18n} field={fieldName} value={value} submitting={submitting}
                              insurances={insurances} isCurrentInsurance={true}/>
                    : null}
                {error && typeof error === 'string' ? (
                    <div className="m-t" style={{color: 'red'}}>{error}</div>
                ) : null}
            </Ibox.Content>
        </Ibox.Container>
    );
};

const OfferRow = ({i18n, key, field, insurances, value, submitting, isCurrentInsurance, onRemove}) => (
    <Row key={key}>
        <Col sm={4}>
            <Field
                name={`${field}.insuranceId`}
                component={renderSelectField}
                label={l('Insurance')}
                disabled={submitting}
                fullWidth={true}
            >
                {(insurances || []).map(insurance => (
                    <MenuItem key={insurance.id}
                              value={insurance.id}
                              primaryText={insurance.name}/>
                ))}
            </Field>
        </Col>
        <Col sm={3}>
            <Field
                name={`${field}.premium`}
                component={renderCurrencyField}
                InputLabelProps={{shrink: true}}
                label={l('Premium')}
                disabled={submitting || value.abstained}
                fullWidth={true}
            />
            {!isCurrentInsurance ? <Field
                name={`${field}.abstained`}
                component={renderCheckbox}
                label={l('abstained')}
                disabled={submitting}
            /> : null}
        </Col>
        <Col sm={2}>
            <Field
                name={`${field}.coverageRating`}
                component={renderSelectField}
                label={l('Coverage rating')}
                disabled={submitting || value.abstained}
                fullWidth={true}
            >
                {(Array(11).fill().map((v, i) => i)).map(value => (
                    <MenuItem key={value} value={value}
                              primaryText={`${value}`}/>
                ))}
            </Field>
        </Col>
        {!isCurrentInsurance ? <>
            <Col sm={2}>
                {l('Official offer file')}
                <Field
                    name={`${field}.officialOfferFile`}
                    component={renderSingleFileUpload}
                    disabled={submitting || value.abstained}
                    i18n={i18n}
                />
            </Col>
            <Col sm={1} className='m-t-lg'>
                <Button onClick={onRemove}>
                    <i className="fa fa-trash red"/>
                </Button>
            </Col>
        </> : null}
    </Row>
);

const CustomerField = ({i18n, input: {name: fieldName}, submitting, meta: {touched, error}}) => (
    <Ibox.Container>
        <Ibox.Title>
            <h5><i className="fa fa-building"/>&nbsp;{l('Customer')}</h5>
        </Ibox.Title>
        <Ibox.Content>
            <Row>
                <Col sm={6}>
                    <Field
                        name={`${fieldName}.companyName`}
                        component={renderTextField}
                        label={l('Company name')}
                        fullWidth={true}
                        disabled={submitting}
                    />
                </Col>
                <Col sm={6}>
                    <Field
                        name={`${fieldName}.userEmail`}
                        component={renderTextField}
                        label={l('User email')}
                        fullWidth={true}
                        disabled={submitting}
                    />
                </Col>
            </Row>
            {error && typeof error === 'string' ? (
                <div className="m-t" style={{color: 'red'}}>{error}</div>
            ) : null}
        </Ibox.Content>
    </Ibox.Container>
);

/**
 * CreateFinishedOfferRequestPage component
 *
 * @param {object} state The state of the application
 * @param {object} actions Actions for this page
 */

export class CreateFinishedOfferRequestPage extends React.Component {

    componentWillMount() {
        let {match: {params = {}}, actions} = this.props;
        let offerRequestId = params.id;

        // do page init
        actions.init(offerRequestId);
    }

    componentWillUnmount() {
        // this.props.actions.cleanup();
    }

    componentWillReceiveProps(nextProps) {
        // let {params = {}, actions} = this.props;
        // let nextParams = nextProps.params || {};
        //
        // if (params.id && params.id !== nextParams.id) {
        //     actions.cleanup();
        //     actions.init(nextParams.id);
        // }
    }

    render() {
        const {handleSubmit, submitting} = this.props;
        const {pageState, customers, insuranceTypes, insurances, customerId, offers, i18n, actions} = this.props;

        const customerOptions = (customers || []).map(customer => ({value: customer.id, label: customer.name}));
        customerOptions.unshift({value: -1, label: l('New customer')});

        // const {location, params, route, router, routeParams} = this.props;
        // const routerProps = {location, params, route, router, routeParams};
        return (
            <AppLayout>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-12">
                        <h2>
                            {l('Request insurance offer')}

                            <div className="pull-right ssl">
                                <i className="fa fa-lock"/>
                                <span>{l('SSL')}</span>
                            </div>
                        </h2>

                        <ol className="breadcrumb">
                            <li>
                                <Link to={URI.DASHBOARD}>{l('Dashboard')}</Link>
                            </li>
                            <li className="active">
                                <strong>{l('Request insurance offer')}</strong>
                            </li>
                        </ol>
                    </div>
                </div>

                {/* Page content */}
                <LoadedPage pageState={pageState}>
                    <form onSubmit={handleSubmit}>
                        <div className="wrapper wrapper-content">
                            <Row>
                                <Col sm={12}>
                                    <Ibox.Container>
                                        <Ibox.Title>
                                            <h5><i className="fa fa-file"/>&nbsp;{l('Offer overview')}</h5>
                                        </Ibox.Title>
                                        <Ibox.Content>
                                            <Field
                                                className='m-b-md'
                                                name={'customerId'}
                                                component={renderSelectField}
                                                label={l('Customer')}
                                                options={customerOptions}
                                                disabled={submitting}
                                                fullWidth={true}/>

                                            <Field
                                                className='m-b-md'
                                                name={Fields.INSURANCE_TYPE_ID}
                                                component={renderSelectField}
                                                label={l('Insurance Type')}
                                                options={(insuranceTypes || []).map(insuranceType => ({
                                                    value: insuranceType.id,
                                                    label: insuranceType.name
                                                }))}
                                                disabled={submitting}
                                                onChangeIntercept={(value) => actions.loadInsurancesForInsuranceType(value)}
                                                fullWidth={true}/>

                                            <OfferRoundSelectField
                                                className='m-b-md'
                                                name={Fields.OFFER_ROUND_TYPE}
                                                fullWidth={true}
                                                disabled={submitting}/>

                                            <div className='m-b-lg'>
                                                <Field
                                                    name="comment"
                                                    component={renderTextField}
                                                    label={l('Comment')}
                                                    fullWidth={true}
                                                    multiline
                                                    rows={3}
                                                    rowsMax={100}
                                                    disabled={submitting}
                                                />
                                            </div>

                                            <h3>{l('Overview')}</h3>
                                            <Field
                                                name={Fields.SUMMARY_FILE}
                                                component={renderSingleFileUpload}
                                                disabled={submitting}
                                                i18n={i18n}
                                            />
                                        </Ibox.Content>
                                    </Ibox.Container>

                                    {
                                        customerId === -1 ? (
                                            <Field
                                                name="customer"
                                                component={CustomerField}
                                                fullWidth={true}
                                                disabled={submitting}
                                            />
                                        ) : null
                                    }

                                    {
                                        insurances && insurances.length > 0
                                            ? (
                                                <>
                                                    <Field
                                                        name="currentInsurance"
                                                        component={CurrentInsuranceField}
                                                        insurances={insurances}
                                                        i18n={i18n}
                                                        onToggleInsurance={(value) => this.props.dispatch(change(FORM_NAME, 'currentInsurance', value ? {} : null))}
                                                    />
                                                    <FieldArray
                                                        name="offers"
                                                        component={OfferFieldArray}
                                                        insurances={insurances}
                                                        offers={offers}
                                                        i18n={i18n}
                                                    />
                                                </>
                                            ) : null
                                    }

                                    <Button variant="contained" color="default"
                                            onClick={handleSubmit(PageActions.save)}>{l('Save')}</Button>
                                    <span className='m-l-md'/>
                                    <Button variant="contained" color="primary"
                                            onClick={handleSubmit(PageActions.publish)}>{l('Publish')}</Button>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </LoadedPage>
            </AppLayout>
        );
    }
}

export const FORM_NAME = 'offer-request-finished-form';
export const formSelector = formValueSelector(FORM_NAME);
export const CreateFinishedOfferRequestPageForm = reduxForm({
    form: FORM_NAME, // a unique name for this form,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true, // somehow needed, otherwise it always only sends the initial values
})(CreateFinishedOfferRequestPage);

export default connect(
    (state) => {
        const {
            isPublished, isLoading, isLoaded, notFound, forbidden, loadErrors, data: {offerRequest}
        } = state.page.createFinishedOfferRequest;

        return {
            auth: state.auth,
            i18n: state.i18n,
            customers: state.page.createFinishedOfferRequest.data.customers,
            insuranceTypes: state.page.createFinishedOfferRequest.data.insuranceTypes,
            insurances: state.page.createFinishedOfferRequest.data.insurances,

            customerId: formSelector(state, 'customerId'),
            offers: formSelector(state, 'offers'),

            pageState: {
                isPublished, isLoading, isLoaded, notFound, forbidden, loadErrors, offerRequest
            }
        };
    },
    (dispatch) => ({
        dispatch,
        actions: bindActionCreators({
            // TODO: setup saving through api!! check how it looks!
            init: PageActions.init,
            save: PageActions.save,
            loadInsurancesForInsuranceType: PageActions.loadInsurancesForInsuranceType,
            // cleanup: PageActions.cleanup
        }, dispatch)
    })
)(CreateFinishedOfferRequestPageForm);
