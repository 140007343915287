import API from '../../../../config/api/routes';
import {handleErrorResponse, isBadRequest} from '../../../../lib/http';
import {jsonGetRequest, jsonPostRequest} from '../../../api/request';
import {makeAuthApiRequest} from '../../../api/action';
import {buildApiRequestData, buildFormErrors} from '../helper';

export const fetchCompany = () => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.COMPANY, jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        return Promise.reject({requestError: response});
    }));
};

export const companyLookup = (businessId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.COMPANY_LOOKUP.replace(':id', businessId), jsonGetRequest())
    ).then(response => {
        if (response.status == 204) {
            return Promise.reject(response);
        }

        return response.json();
    }).catch(e => handleErrorResponse(e, response => {
        return Promise.reject({requestError: response});
    }));
};

export const validate = (formData) => {
    return (dispatch) => {
        // generate request data
        let requestData = buildApiRequestData(formData);

        return dispatch(makeAuthApiRequest(
            API.COMPANY_VALIDATE, jsonPostRequest({
                body: JSON.stringify(requestData)
            })
        )).catch(e => handleErrorResponse(e, response => {
            // if response is not expected reject with error
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }

            // process validation errors
            return response.json().then(data => {
                // build errors
                let formErrors = buildFormErrors(data.errors);

                // reject promise
                return Promise.reject({validationErrors: formErrors});
            });
        }));
    };
};

export const save = (formData) => {
    return (dispatch) => {
        // generate request data
        let requestData = buildApiRequestData(formData);

        return dispatch(makeAuthApiRequest(
            API.COMPANY, jsonPostRequest({
                body: JSON.stringify(requestData)
            }))
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            return Promise.reject({requestError: response});
        }));
    };
};
