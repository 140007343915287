import React from 'react';
import {connect} from 'react-redux';
import {change, formValueSelector} from 'redux-form';
import {Button, Col, Row} from 'react-bootstrap';
import moment from 'moment';

import {l} from '../../../../../i18n/translator';
import {getDateByOffset} from '../../../../../helper/date.js';
import {Fields, FORM_NAME} from '../../config/form';
import {Field} from '../redux-form-wrapper.jsx'
import {renderDatePicker} from '../../../../common/form/renderer.jsx';
import OfferRoundSelectField from '../../../../common/form/offer-request/offer-round-select';
import OfferRoundDataForm from './offerRoundData.form.jsx';
// import {Fields as OfferRoundFields} from '../../../../../../config/domain/offerRequest/offer-round/offer-round';
// import {renderCheckbox} from '../../../../common/form/renderer';

class OfferDataForm extends React.Component {

    render() {
        const {i18n, submitting, fieldChange} = this.props;

        return (
            <div>
                <h3 className="m-b-xl">
                    {l('What conditions would you like?')}
                </h3>

                <Row>
                    <Col md={12}>
                        <Row className="m-b-lg">
                            <Col md={6}>
                                <OfferRoundSelectField
                                    name={Fields.OFFER_ROUND_TYPE}
                                    fullWidth={true}
                                    disabled={submitting}/>
                            </Col>
                        </Row>

                        <p className="font-bold">
                            {l('End of offer round')}
                        </p>

                        <Row className="m-b-lg">
                            <Col md={6}>
                                <Field
                                    name={Fields.OFFER_ROUND_END_DATE}
                                    component={renderDatePicker}
                                    label={l('End Date for Insurance Companies')}
                                    fullWidth={true}/>
                            </Col>

                            <Col md={6}>
                                <div className="hidden-sm hidden-xs" style={{paddingTop: '10px'}}/>

                                <Button
                                    onClick={(e) => fieldChange(Fields.OFFER_ROUND_END_DATE, moment(getDateByOffset(2, 'weeks')))}>
                                    {l('In 2 weeks')}
                                </Button>
                                &nbsp;
                                <Button
                                    onClick={(e) => fieldChange(Fields.OFFER_ROUND_END_DATE, moment(getDateByOffset(1, 'months')))}>
                                    {l('In 1 month')}
                                </Button>
                                &nbsp;
                                <Button
                                    onClick={(e) => fieldChange(Fields.OFFER_ROUND_END_DATE, moment(getDateByOffset(2, 'months')))}>
                                    {l('In 2 months')}
                                </Button>
                            </Col>
                        </Row>

                        {/*For now hidden because not finished on server. Not defined correctly yet.*/}
                        {/*<Field name={`${Fields.OFFER_ROUND_DATA}.${OfferRoundFields.HIDE_CURRENT_INSURANCE}`} component={renderCheckbox}*/}
                        {/*       label={l('Hide current insurance')}*/}
                        {/*       defaultValue={false}/>*/}

                        <OfferRoundDataForm/>
                    </Col>
                </Row>
            </div>
        )
    }
}

// Decorate with connect to read form values
const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({}),
    dispatch => ({
        fieldChange: (field, value) => dispatch(change(FORM_NAME, field, value))
    })
)(OfferDataForm);
