import React from 'react'
import {Prompt} from 'react-router-dom'
import {redirectToWithoutDispatch} from '../../../router/action';

// adjusted version from https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
// https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
// https://stackoverflow.com/questions/52437195/reactrouter-v4-prompt-override-default-alert/52437325
// https://github.com/ZacharyRSmith/react-router-navigation-prompt

export class RouteLeavingGuard extends React.Component {
    state = {
        confirmedNavigation: false,
    };

    showModal = (location) => {
        swal({
            title: this.props.title,
            text: this.props.message,
            type: 'warning',
            showCancelButton: true
        }, (isSuccess) => {
            if (isSuccess) {
                // need to set confirmedNavigation because otherwise navigation will still be blocked!
                this.setState({
                    confirmedNavigation: true
                }, () => {
                    redirectToWithoutDispatch(location);
                });
            }
        });
    };

    handleBlockedNavigation = (nextLocation) => {
        const {confirmedNavigation} = this.state;
        const {when} = this.props;
        if (!confirmedNavigation && when(nextLocation)) {
            this.showModal(nextLocation);
            return false;
        }

        return true;
    };

    render() {
        return (
            <Prompt
                when={true}
                message={this.handleBlockedNavigation}/>
        );
    }
}

export default RouteLeavingGuard;
