import React from 'react';
import {l} from '../../../../../i18n/translator';


const MessageForm = ({isSending, isSent, onSubmit}) => {
    let messageBox;

    return (
        <form role="form" onSubmit={(e) => onSubmit(e, messageBox.value)}>
            <div>
                <button disabled={isSending} type="submit" className="btn btn-sm btn-primary">
                    {isSending ? (
                        <div><i className="fa fa-cog"></i></div>
                    ) : null}

                    {isSent ? (
                        <div><i className="fa fa-check"></i></div>
                    ) : null}

                    <strong>
                        {l('Send message')}
                    </strong>
                </button>

                <div className="chat-message-holder">
                    <textarea
                        className="form-control"
                        placeholder="Message"
                        ref={(node) => {
                            // set reference to textarea
                            messageBox = node;
                            // clear if message has been sent
                            if (isSent && node) node.value = '';
                        }}
                    ></textarea>
                </div>
            </div>
        </form>
    )
};

export default MessageForm;