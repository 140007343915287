import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import {l} from '../../../i18n/translator';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import * as Ibox from '../../ui-components/ibox.jsx';
import {initCustomerUsersList} from './action.js';
import {UserList} from '../../common/user/userList.jsx';
import {UserEditModal} from '../../common/user/userEditModal.jsx';
import {closeUserEditForm, enableUser, resendEmail, showUserEditForm, submitUser, updateUsersList} from './action';

export class CustomerUsersPage extends React.Component {

    getCustomerId() {
        let {match: {params = {}}, actions} = this.props;
        return params.id;
    }

    componentWillMount() {
        let {actions} = this.props;
        const customerId = this.getCustomerId();

        // do page init
        if (customerId) {
            actions.init(customerId);
        }
    }

    render() {
        const {auth, actions, pageState} = this.props;
        const {data, userEditModal} = pageState || {};
        const {users, insuranceTypes} = data || {};
        const customerId = this.getCustomerId();

        return (
            <InsurTecLayout>
                <BreadCrumbs title={l('Customer users')} items={[l('Customers')]} icon="user"/>

                <div className="wrapper wrapper-content">
                    <Row>
                        <Col md={12}>
                            <Ibox.Container>
                                <Ibox.Title>{l('Users')}</Ibox.Title>
                                <Ibox.Content className="p-sm">
                                    <UserList
                                        auth={auth}
                                        users={users}
                                        updating={[]}
                                        showToggle={true}
                                        onUpdateUsers={(showDisabled) => actions.updateUsersList(customerId, showDisabled)}
                                        onUserClick={actions.onUserClick}
                                        onDeleteUser={(user) => actions.onDeleteUser(customerId, user)}
                                        onEnableUser={(user) => actions.onEnableUser(customerId, user)}
                                        onResendEmail={(user) => actions.resendEmail(customerId, user)}/>
                                    <UserEditModal
                                        insuranceTypes={insuranceTypes}
                                        onSubmitUser={(data, dispatch) => submitUser(customerId, data, dispatch)}
                                        onClose={actions.closeEditUser}
                                        show={userEditModal.show}
                                        user={userEditModal.user}/>

                                    <Button variant="contained"
                                            color="primary"
                                            onClick={() => actions.showCreateUserDialog()}>
                                        {l('Create user')}
                                    </Button>
                                </Ibox.Content>
                            </Ibox.Container>
                        </Col>
                    </Row>
                </div>
            </InsurTecLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        auth: state.auth,
        user: state.auth.user,
        pageState: {...state.page.customerUsers},
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            showCreateUserDialog: () => dispatch(showUserEditForm()),
            onUserClick: (user) => dispatch(showUserEditForm(user)),
            onDeleteUser: (customerId, user) => dispatch(enableUser(customerId, user, false)),
            onEnableUser: (customerId, user) => dispatch(enableUser(customerId, user, true)),
            resendEmail: (customerId, user) => dispatch(resendEmail(customerId, user)),
            closeEditUser: (user) => dispatch(closeUserEditForm(user)),
            init: (customerId) => dispatch(initCustomerUsersList(customerId)),
            updateUsersList: (customerId, showDisabled) => dispatch(updateUsersList(customerId, showDisabled))
        },
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerUsersPage);
