import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {redirectTo} from '../../router/action';
import AdminOnly from '../common/auth/acl/adminOnly.jsx';
import {getUserCompany, isAdmin, isUserLoggedIn} from '../../auth/helper.js';
import {l} from '../../i18n/translator';
import {BreadCrumbs, InsurTecLayout} from '../layout/app-layout.jsx';
import * as Ibox from '../ui-components/ibox.jsx';
import URI from '../../../config/uri';
import {UserEditModal} from '../common/user/userEditModal.jsx';
import {ApiKeyModal} from './component/apiKeyModal.jsx';
import {PasswordForm} from './component/password.jsx';
import {MobileVerifyForm} from './component/mobileVerify.jsx';
import {
    closeUserEditForm,
    createUser,
    deleteApiKey,
    deleteUser,
    editUser,
    enableUser,
    fetchUsers,
    generateNewApiKey,
    initProfilePage,
    showApiKeyForm,
    submitUser,
    usersLoaded,
} from './action.js';
import {UserList as UserListComponent} from '../common/user/userList.jsx';

const useTitleButtonStyles = makeStyles(theme => ({
    margin: {margin: theme.spacing(-0.5, 0, 0.5, 0)},
}));

export const BoxTitle = ({icon, title, editUri, auth, children, ...props}) => {
    const {actions} = props;
    const classes = useTitleButtonStyles();
    return (
        <Ibox.Title>
            <h5>
                <i className={'fa ' + icon}/>&nbsp;
                {title}
            </h5>

            <AdminOnly auth={auth}>
                {editUri ? (
                    <div className="m-t-n-xs">
                        <Button size="small" className={"pull-right " + classes.margin}
                                onClick={() => actions.open(editUri)}>
                            <i className="fa fa-pencil"/>&nbsp;
                            {l('Edit')}
                        </Button>
                    </div>
                ) : null}
            </AdminOnly>

            {children}
        </Ibox.Title>
    );
};

export const PersonInfo = ({person, ...props}) => {
    const {actions} = props;
    return person ? (
        <Ibox.Content className="p-sm">
            <div><strong>{person.firstName} {person.lastName}</strong></div>
            <div>{person.function}</div>
            <div><i className="fa fa-envelope"/> {person.email}</div>
            <div><i className="fa fa-phone"/> {person.telephone}</div>
            <div><i className="fa fa-mobile"/> {person.mobile}</div>
            <br/>
            <strong>{l('Address')}</strong>
            <div>{person.addressLine1}</div>
            <div>{person.addressLine2}</div>
            <div>{person.zip} {person.town}</div>
            <div>{person.country}</div>
        </Ibox.Content>
    ) : (
        <Ibox.Content className="p-sm">
            <i>{l('Same as contact')}</i>
        </Ibox.Content>
    );
};

export const UsersList = ({users, auth, ...props}) => {
    const onUpdateUsers = (showDisabled) => {
        return props.dispatch(fetchUsers(showDisabled))
            .then(users => props.dispatch(usersLoaded(users)));
    };
    const actions = props.actions;

    return (
        <Ibox.Content className="p-sm">
            <UserListComponent
                users={users}
                auth={auth}
                updating={[]}
                onEnableUser={actions.enableUser}
                onDeleteUser={actions.deleteUser}
                onUserClick={actions.editUser}
                onUpdateUsers={onUpdateUsers}
                showToggle={true}
            />
            <Button variant="contained"
                    color="primary"
                    onClick={() => actions.createUser()}>
                {l('Create user')}
            </Button>
        </Ibox.Content>
    );
};

export const ApiKeysList = ({apiKeys, auth, actions, ...props}) => {
    return (
        <Ibox.Content className="p-sm">
            {
                apiKeys && apiKeys.length > 0 ? (
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>{l('Age')}</th>
                            <th>{l('Name')}</th>
                            <th>{l('IP Range')}</th>
                            <th>{l('Key (shortened)')}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            apiKeys.map((apiKey, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{moment(apiKey.creationDate).fromNow()}</td>
                                        <td>{apiKey.name} <a
                                            onClick={(event) => actions.editApiKey(apiKey, event)}
                                            style={{color: 'green'}}
                                        >
                                            <i className="fa fa-pencil" title={l('Edit')}/>
                                        </a></td>
                                        <td>{apiKey.ipRange}</td>
                                        <td>{apiKey.apiKeyPrefix.substr(0, 12)}</td>
                                        <td>
                                            <a
                                                onClick={(event) => actions.deleteApiKey(apiKey, event)}
                                                style={{color: 'red'}}
                                            >
                                                <i className="fa fa-trash" title={l('Delete')}/> {l('Delete')}
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                ) : (<div className="m-b-md">{l('No Api Keys yet')}</div>)
            }
            <Button variant="contained"
                    color="primary"
                    onClick={() => actions.generateNewApiKey()}>
                {l('Generate New Api Key')}
            </Button>
        </Ibox.Content>
    );
};

/**
 * ProfilePage component
 */
export class ProfilePage extends React.Component {

    componentWillMount() {
        this.props.actions.init({isAdmin: isAdmin(this.props.auth), userId: this.props.auth.user.id});
    }

    render() {
        const {auth, insuranceTypes, profile, userEditModal, actions, dispatch} = this.props;

        if (!isUserLoggedIn(auth)) {
            return null;
        }

        const company = getUserCompany(auth);

        return (
            <InsurTecLayout>
                <BreadCrumbs title={l('Profile')} icon="user"/>

                {/* Page Content */}
                <div className="wrapper wrapper-content">
                    <Row>
                        <Col md={3}>
                            <Ibox.Container>
                                <BoxTitle title={l('Company')} icon="fa-building" editUri={URI.ADMINISTRATION}
                                          actions={actions} auth={auth}/>

                                <Ibox.Content className="p-sm">
                                    <div>{l('BIN')}: {company.businessIdentificationNumber}</div>
                                    <div>{l('Name')}: {company.name}</div>
                                    <div>{l('Area')}: {company.areaOfOperations}</div>
                                    <div>{l('Employees')}: {company.employees}<i className="fa fa-user"/></div>
                                </Ibox.Content>
                            </Ibox.Container>
                        </Col>
                        <Col md={3}>
                            <Ibox.Container>
                                <BoxTitle title={l('Contact')} icon="fa-user" editUri={URI.ADMINISTRATION}
                                          actions={actions} auth={auth}/>
                                <PersonInfo person={company.contact}/>
                            </Ibox.Container>
                        </Col>
                        <Col md={3}>
                            <Ibox.Container>
                                <BoxTitle title={l('Correspondence')} icon="fa-user" editUri={URI.ADMINISTRATION}
                                          actions={actions} auth={auth}/>
                                <PersonInfo person={company.correspondence}/>
                            </Ibox.Container>
                        </Col>
                        <Col md={3}>
                            <Ibox.Container>
                                <BoxTitle title={l('Billing')} icon="fa-user" editUri={URI.ADMINISTRATION}
                                          actions={actions} auth={auth}/>
                                <PersonInfo person={company.billing}/>
                            </Ibox.Container>
                        </Col>
                    </Row>

                    <Row>
                        <AdminOnly auth={auth}>
                            <Col md={6}>
                                <Ibox.Container>
                                    <BoxTitle title={l('Users')} icon="fa-users" actions={actions} auth={auth}/>
                                    <UserEditModal insuranceTypes={insuranceTypes}
                                                   onSubmitUser={submitUser}
                                                   onClose={actions.closeEditUser}
                                                   show={userEditModal.show}
                                                   user={userEditModal.user}/>
                                    <UsersList users={profile.data.users}
                                               actions={actions}
                                               auth={auth}
                                               dispatch={dispatch}/>
                                </Ibox.Container>
                            </Col>
                        </AdminOnly>

                        <Col md={3}>
                            <Ibox.Container>
                                <BoxTitle title={l('Password')} icon="fa-lock" actions={actions} auth={auth}/>
                                <Ibox.Content>
                                    <PasswordForm className="m-t-n-sm"/>
                                </Ibox.Content>
                            </Ibox.Container>
                        </Col>

                        <Col md={3}>
                            <Ibox.Container>
                                <BoxTitle title={l('Mobile Verification')} icon="fa-mobile" actions={actions}
                                          auth={auth}/>
                                <Ibox.Content>
                                    <MobileVerifyForm className="m-t-n-sm"/>
                                </Ibox.Content>
                            </Ibox.Container>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Ibox.Container>
                                <BoxTitle title={l('Api Keys')} icon="fa-key" actions={actions} auth={auth}/>
                                <ApiKeyModal/>
                                <ApiKeysList apiKeys={profile.data.apiKeys} actions={actions} auth={auth}/>
                            </Ibox.Container>
                        </Col>
                        <Col md={6}>
                            <Ibox.Container>
                                <BoxTitle title={l('Channels')} icon="fa-send" actions={actions} auth={auth}/>
                                <Ibox.Content>
                                    <table className="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th>{l('Insurance Type')}</th>
                                            <th>{l('E-Mail')}</th>
                                            <th>{l('Gateway')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {profile && profile.data && profile.data.insuranceTypes
                                            ? profile.data.insuranceTypes.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
                                                if (item.channels) {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>{item.channels.email ? (
                                                                <i className="fa fa-check green"></i>) : (
                                                                <i className="fa fa-times red"></i>)}</td>
                                                            <td>{item.channels.gateway ? (
                                                                <i className="fa fa-check green"></i>) : (
                                                                <i className="fa fa-times red"></i>)}</td>
                                                        </tr>
                                                    );
                                                } else return null;
                                            }) : null}
                                        </tbody>
                                    </table>
                                </Ibox.Content>
                            </Ibox.Container>
                        </Col>
                    </Row>
                </div>
            </InsurTecLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        auth: state.auth,
        profile: {...state.page.profile},
        insuranceTypes: [...state.page.profile.data.insuranceTypes],
        userEditModal: {...state.page.profile.userEditModal},
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: {
            createUser: () => dispatch(createUser()),
            editUser: (user) => dispatch(editUser(user)),
            closeEditUser: () => dispatch(closeUserEditForm()),
            deleteUser: (user) => dispatch(deleteUser(user)),
            enableUser: (user) => dispatch(enableUser(user)),
            deleteApiKey: (apiKey) => dispatch(deleteApiKey(apiKey)),
            editApiKey: (apiKey) => dispatch(showApiKeyForm(true, apiKey)),
            generateNewApiKey: () => dispatch(generateNewApiKey()),
            init: ({isAdmin, userId}) => dispatch(initProfilePage({isAdmin, userId})),
            open: (uri) => dispatch(redirectTo(uri)),
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);
