/**
 * Created by br0wn on 10/30/16.
 */
import {createAction} from '../../../../action';

export const Types = {
    LOAD_START: 'loader.load_start',
    LOAD_END: 'loader.load_end',
    LOAD_CLEAR: 'loader.load_clear',
    LOAD_PROGRESS: 'loader.load_progress'
};


export const loadStart = (loaderName) => createAction(
    Types.LOAD_START, {loaderName}
);

export const loadEnd = (loaderName, isLoaded, clearTimeout = 1000) => (dispatch) => {
    dispatch(createAction(
        Types.LOAD_END, {loaderName, isLoaded}
    ));

    if (isLoaded) {
        setTimeout(()=> {
            dispatch(clear(loaderName));
        }, clearTimeout);
    }
};

export const loadProgress = (loaderName, progress) => createAction(
    Types.LOAD_PROGRESS, {loaderName, progress}
);

export const clear = (loaderName) => createAction(
    Types.LOAD_CLEAR, {loaderName}
);
