/* eslint-disable no-console */



export const log = (...args) => {
    if (console) {
        console.log.apply(console, args);
    }
};

export const warn = (...args) => {
    if (console) {
        console.warn.apply(console, args);
    }
};

export const error = (...args) => {
    if (console) {
        console.error.apply(console, args);
    }
};
