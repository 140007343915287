import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {
    fetchAnalysisAutoRatings,
    fetchAnalysisCriteriaList,
    fetchInsurances,
    fetchInsuranceTypes
} from '../../../api/sdk-action';

export const ACTION_TYPES = createPageActionTypes('page.analysis_auto_ratings_list.');

export const initAutoRatingsList = createPageLoader(
    ACTION_TYPES,
    (dispatch) => {
        return Promise.all([
            dispatch(fetchAnalysisAutoRatings()),
            dispatch(fetchAnalysisCriteriaList()),
            dispatch(fetchInsuranceTypes()),
            dispatch(fetchInsurances()),
        ]).then(([autoRatings, criteria, insuranceTypes, insurances]) => {
            return {autoRatings, criteria, insuranceTypes, insurances};
        });
    }
);
