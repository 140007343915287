import React from 'react';
import PropTypes from "prop-types";
import {CompanyTypes as UserTypes} from '../../../../../config/domain/user';
import UserTypeOnly from './userTypeOnly.jsx';

/**
 * Shows content only for user type insurance
 *
 * @param {object} auth
 * @param {*} children
 * @return {*}
 */
const InsuranceOnly = ({auth, children}) => (
    <UserTypeOnly type={UserTypes.INSURANCE} auth={auth}>
        {children}
    </UserTypeOnly>
);

InsuranceOnly.propTypes = {
    'auth': PropTypes.object.isRequired
};

export default InsuranceOnly;
