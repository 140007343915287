import React from 'react';
import classNames from 'classnames';
import {Types as StatusTypes} from '../../config/status';


const StatusBar = ({status}) => {
    let hasStatus = !!status;
    status = status || {};

    return (
        <div className={classNames("ibox-heading text-center", {
            'hidden': !hasStatus,
            'status-default': true,
            'status-danger': status.type === StatusTypes.ERROR,
            'status-warning': status.type === StatusTypes.WARNING,
            'status-info': status.type === StatusTypes.INFO,
            'status-success': status.type === StatusTypes.SUCCESS
        })}>
            {status.message}
        </div>
    );
};

export default StatusBar;