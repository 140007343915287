/**
 * Created by br0wn on 11/30/16.
 */
import {createAction} from '../../../action';

const INITIAL_STATE = {
    values: {},
    errors: {}
};

export const ACTIONS = {
    FIELD_CHANGE: 'form.FIELD_CHANGE',
    FIELD_ERROR_CLEAR: 'form.FIELD_ERROR_CLEAR',
    VALUES_UPDATE: 'form.VALUES_UPDATE',
    ERRORS_UPDATE: 'form.ERRORS_UPDATE'
};


export const formReducer = (state = INITIAL_STATE, action, ACTION_TYPES = ACTIONS) => {
    let {values, errors} = state;

    switch (action.type) {
        case ACTION_TYPES.FIELD_CHANGE: {
            values = {...values, [action.field]: action.value};

            errors = {...errors};
            delete errors[action.field];

            return {...state, values, errors};
        }
        case ACTION_TYPES.FIELD_ERROR_CLEAR: {
            errors = {...errors};
            delete errors[action.field];

            return {...state, errors};
        }
        case ACTION_TYPES.VALUES_UPDATE: {
            values = action.replace ? action.values : {...values, ...action.values};

            return {...state, values};
        }
        case ACTION_TYPES.ERRORS_UPDATE: {
            errors = action.replace ? action.errors : {...errors, ...action.errors};

            return {...state, errors};
        }
    }

    return state;
};


export const formActionCreators = (ACTION_TYPES = ACTIONS) => {

    const fieldChange = (field, value) => createAction(
        ACTION_TYPES.FIELD_CHANGE, {field, value}
    );

    const fieldErrorClear = (field) => createAction(
        ACTION_TYPES.FIELD_ERROR_CLEAR, {field}
    );

    const valuesUpdate = (values, replace) => createAction(
        ACTION_TYPES.VALUES_UPDATE, {values, replace}
    );

    const errorsUpdate = (errors, replace) => createAction(
        ACTION_TYPES.ERRORS_UPDATE, {errors, replace}
    );

    return {fieldChange, fieldErrorClear, valuesUpdate, errorsUpdate};
};