import DEFAULT_TYPES from './type';

/**
 * @class
 * @abstract
 */
class ChatActions {

    constructor(ACTION_TYPES = DEFAULT_TYPES) {
        /**
         *
         * @type {{THREAD_LIST_LOAD_START: string, THREAD_LIST_LOAD_END: string, THREAD_SELECTED: string, THREAD_MESSAGES_LOAD_START: string, THREAD_MESSAGES_LOAD_END: string, MESSAGE_SUBMIT_START: string, MESSAGE_SUBMIT_END: string}}
         */
        this.ACTION_TYPES = ACTION_TYPES;
    }

    /**
     * Loads list of threads
     *
     * @return {function}
     */
    loadThreadList() {
        return (dispatch) => {
            const ACTION_TYPES = this.ACTION_TYPES;

            dispatch({
                type: ACTION_TYPES.THREAD_LIST_LOAD_START
            });

            return dispatch(
                this.fetchThreadList()
            ).then(threadList => dispatch({
                    type: ACTION_TYPES.THREAD_LIST_LOAD_END,
                    threads: threadList
                })
            ).catch(
                error => dispatch({
                    type: ACTION_TYPES.THREAD_LIST_LOAD_END,
                    error
                })
            );
        };
    }

    /**
     * Loads thread messages
     *
     * @param {*} threadId
     * @return {function}
     */
    loadThreadMessages(threadId) {
        return (dispatch) => {
            const ACTION_TYPES = this.ACTION_TYPES;

            dispatch({
                type: ACTION_TYPES.THREAD_MESSAGES_LOAD_START,
                threadId
            });

            return dispatch(
                this.fetchThreadMessages(threadId)
            ).then(
                messages => dispatch({
                    type: ACTION_TYPES.THREAD_MESSAGES_LOAD_END,
                    messages,
                    threadId
                })
            ).catch(
                error => dispatch({
                    type: ACTION_TYPES.THREAD_MESSAGES_LOAD_END,
                    threadId,
                    error
                })
            );
        };
    }

    /**
     * Submits message and updates state
     *
     * @param {string} message
     * @param {*} threadId
     * @return {function}
     */
    submitMessage(message, threadId = null) {
        return (dispatch) => {
            const ACTION_TYPES = this.ACTION_TYPES;
            const clearMessageSentFlag = this.clearMessageSentFlag.bind(this);

            dispatch({
                type: ACTION_TYPES.MESSAGE_SUBMIT_START,
                threadId
            });

            return dispatch(
                this.postMessage(message, threadId)
            ).then(
                message => {
                    setTimeout(() => {
                        dispatch(clearMessageSentFlag(threadId));
                    }, 1000);

                    return dispatch({
                        type: ACTION_TYPES.MESSAGE_SUBMIT_END,
                        threadId,
                        message
                    });
                }
            ).catch(
                error => dispatch({
                    type: ACTION_TYPES.MESSAGE_SUBMIT_END,
                    threadId,
                    error
                })
            );
        };
    }

    /**
     * Clears message sent flag
     *
     * @param {*} threadId
     * @return {{type: string, threadId: *}}
     */
    clearMessageSentFlag(threadId) {
        return {
            type: this.ACTION_TYPES.CLEAR_MESSAGE_SENT,
            threadId
        };
    }

    /**
     * Updates state when thread is selected
     *
     * @param threadId
     * @return {function(*)}
     */
    onThreadSelected(threadId) {
        return (dispatch) => {
            const ACTION_TYPES = this.ACTION_TYPES;

            dispatch({
                type: ACTION_TYPES.THREAD_SELECTED,
                threadId: threadId
            });

            return dispatch(this.loadThreadMessages(threadId));
        };
    }

    /**
     * Updates state when message is submitted
     *
     * @param {string} message
     * @return {function}
     */
    onMessageSubmit(message) {
        return (dispatch, getState) => {
            const chatState = this.getChatState(getState);
            const threadId = chatState.selectedThread;

            return dispatch(this.submitMessage(message, threadId));
        };
    }

    /* eslint-disable no-unused-vars */

    /**
     * Returns chats state from global state provided by `getState`
     *
     * @param getState
     * @return {object}
     *
     * @abstract
     * @protected
     */
    getChatState(getState) {
        throw new Error("Must be implemented in child class.");
    }

    /**
     * Fetches thread messages. Returns a thunk that will return a promise that is a result of the fetch.
     *
     * @param {*} threadId
     * @return {function}
     *
     * @abstract
     */
    fetchThreadMessages(threadId) {
        throw new Error("Must be implemented in child class.");
    }

    /**
     * Fetches list of threads. Returns a thunk that will return a promise that is a result of the fetch.
     *
     * @return {function}
     *
     * @abstract
     */
    fetchThreadList() {
        throw new Error("Must be implemented in child class.");
    }

    /**
     * Posts message to a thread. Returns a thunk that will return a promise that is a result of the post.
     *
     * @param {string} message
     * @param {*} threadId
     * @return {function}
     *
     *  @abstract
     */
    postMessage(message, threadId) {
        throw new Error("Must be implemented in child class.");
    }

    /* eslint-enable no-unused-vars */
}

export default ChatActions;
