/**
 * Created by br0wn on 11/22/16.
 */

export const TYPES = {
    // shared
    NEW_MESSAGES: 'newMessages',

    // customer
    TOTAL_SAVINGS: 'totalSavings',
    NEW_BIDS: 'newBids',
    OFFICIAL_OFFERS: 'officialOffers',

    // broker & insurance
    PREMIUM_VOLUME: 'premiumVolume',
    SIGN_OFFS: 'signOffs',

    // broker
    TASKS_COMPLETED: 'tasksCompleted',
    REQUESTS_FINISHED: 'requestsFinished',

    // insurance
    ENDING_SOON: 'endingSoon',
    REQUESTS_SELECTED: 'requestsSelected'
};
