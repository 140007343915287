/**
 * Created by br0wn on 11/24/16.
 */
import React from 'react';
import {injectIntl} from 'react-intl';
import {Bar} from 'react-chartjs-2';
import {l} from '../../../../i18n/translator';
import {States as OfferStates} from '../../../../../config/domain/offer';
import {CurrentInsuranceFields, Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';
import {sortOfferList} from '../../helper';
import {isCreatedFinishedOffer} from '../../../../model/offerRequest';

class PremiumGraphComponent extends React.Component {

    structureGraphData(offerRequest, offerList, insurances, userCompanyId) {
        const {actions} = this.props;
        const insurancesMap = {};
        insurances.forEach((insurance) => insurancesMap[insurance.id] = insurance);

        const graphData = [];

        const brokerageAverage = this.getBrokerageAverage(offerRequest.insuranceTypeId);
        const hasBrokerage = brokerageAverage > 0;
        const currentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE] || {};
        const hasCurrentInsurance = !!offerRequest[OfferRequestFields.CURRENT_INSURANCE];
        const showCurrentPremium = hasCurrentInsurance && currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS];

        const addGraphItem = ({insuranceId, publishDate, premiumGross, offer, isCurrentInsurance}) => {
            if (isCurrentInsurance === null || isCurrentInsurance === undefined) {
                isCurrentInsurance = userCompanyId === insuranceId || currentInsurance[CurrentInsuranceFields.INSURANCE_ID] === insuranceId;
            }
            const diffGrossNet = hasBrokerage ? premiumGross * brokerageAverage : premiumGross;
            graphData.push({
                insuranceId, publishDate, offer, isCurrentInsurance,
                premiumGross: diffGrossNet,
                premiumNet: hasBrokerage ? premiumGross - diffGrossNet : 0,
                isCurrentInsurancePremium: offer.isCurrent,
            });
        };

        // if (showCurrentPremium) {
        //     addGraphItem({
        //         insuranceId: currentInsurance[CurrentInsuranceFields.INSURANCE_ID],
        //         publishDate: offerRequest.publishDate,
        //         premiumGross: offerRequest.currentInsurancePremiumGross,
        //         isCurrentInsurancePremium: true,
        //         isCurrentInsurance: false // here we do not want to highlight it as currentInsurance
        //     });
        // }

        offerList.sort((a, b) => {
            if (a.isCurrent !== b.isCurrent) {
                if (a.isCurrent) return -1;
                else return 1;
            }
            if (a.premiumGross < b.premiumGross) {
                return 1;
            }
            if (a.premiumGross > b.premiumGross) {
                return -1;
            }
            return 0;
        });

        offerList.forEach(offer => {
            addGraphItem({
                insuranceId: offer.insuranceId,
                publishDate: offer.publishDate,
                premiumGross: offer.premiumGross,
                offer: offer
            });
        });

        const dataGross = [];
        const dataNet = [];
        const dataInsurances = [];
        const dataSets = [];
        const dataGrossColors = [];
        const dataNetColors = [];
        for (let i = 0; i < graphData.length; i++) {
            const item = graphData[i];

            dataGross.push(parseFloat(item.premiumGross));
            dataNet.push(parseFloat(item.premiumNet));
            dataInsurances.push(insurancesMap[item.insuranceId]);

            if (item.isCurrentInsurancePremium) {
                dataGrossColors.push("rgba(229,225,0,1)");
                dataNetColors.push("rgba(75,192,192,1)");
            } else if (item.isCurrentInsurance) {
                dataGrossColors.push("rgba(180,180,0,0.75)");
                dataNetColors.push("rgba(48,130,130,0.75)");
            } else {
                dataGrossColors.push("rgba(229,225,0,0.5)");
                dataNetColors.push("rgba(75,192,192,0.5)");
            }
        }

        const addSingleLineDataSet = (value, label, color) => {
            const data = [];
            for (let i = 0; i < dataGross.length; i++) {
                data.push(value);
            }
            dataSets.push({
                type: 'line',
                label: label,
                fill: false,
                borderColor: color,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: color,
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                borderWidth: 2,
                backgroundColor: color,
                data: data
            });
        };
        if (offerRequest.targetInsurancePremium) {
            addSingleLineDataSet(parseFloat(offerRequest.targetInsurancePremium), l('Target premium'), "rgba(0,0,0,1)");
        }

        const dataTrend = dataGross.map((item, index) => item + dataNet[index]);

        dataSets.push({
            type: 'line',
            label: l('Trend'),
            data: dataTrend,
            fill: false,
            borderColor: '#DD8860',
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: '#DD8860',
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            borderWidth: 1,
            backgroundColor: '#DD8860',
            yAxisID: "y-axis-1"
        });
        if (hasBrokerage) {
            dataSets.push({
                stack: 'Stack 0',
                key: 'premiumNet', // for identifying
                type: 'bar',
                label: l('Net premium'),
                data: dataNet,
                borderColor: "rgba(75,192,192,1)",
                backgroundColor: dataNetColors
            });
        }
        dataSets.push({
            stack: 'Stack 0',
            key: 'premiumGross', // for identifying
            type: 'bar',
            label: l('Gross premium'),
            data: dataGross,
            borderColor: "rgba(229, 225, 0, 1)",
            backgroundColor: dataGrossColors
        });

        const labels = [];
        for (let i = 0; i < dataGross.length; i++) {
            if (showCurrentPremium) {
                if (i === 0) {
                    labels.push(l('Current'));
                } else {
                    // labels.push('#' + i);
                    labels.push('');
                }
            } else {
                // labels.push('#' + (i + 1));
                labels.push('');
            }
        }

        const {intl} = this.props;

        const maxValue = (() => {
            let max = 0;
            // we only check first two, because first is currentInsurancePremium and second is highest of offers
            if (dataNet.length > 0) {
                max = Math.max(max, dataNet[0] + dataGross[0]);
            }
            if (dataNet.length > 1) {
                max = Math.max(max, dataNet[1] + dataGross[1]);
            }
            // this could be higher than any offer in case that we have no currentInsurancePremium and all offers are lower
            if (offerRequest.targetInsurancePremium) {
                max = Math.max(max, offerRequest.targetInsurancePremium);
            }
            return max;
        })();

        return {
            type: 'line',
            data: {
                labels: labels,
                datasets: dataSets
            },
            options: {
                scales: {
                    yAxes: [{
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'CHF'
                        },
                        ticks: {
                            min: 0,
                            suggestedMax: maxValue,
                            callback: function (value, index, values) {
                                return intl.formatNumber(value, {
                                    style: 'decimal',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                });
                            }
                        }
                    }, {
                        stacked: false,
                        display: false,
                        ticks: {
                            min: 0,
                            suggestedMax: maxValue,
                        },
                        id: "y-axis-1"
                    }]
                },
                legend: {
                    position: 'bottom'
                },
                tooltips: {
                    callbacks: {
                        title: (tooltipItem, data) => {
                            let dataSet = data.datasets[tooltipItem[0].datasetIndex];
                            if (tooltipItem[0].xLabel) {
                                return tooltipItem[0].xLabel + ': ' + dataSet.label;
                            } else {
                                return dataSet.label;
                            }
                        },
                        label: (tooltipItem, data) => {
                            let key = data.datasets[tooltipItem.datasetIndex].key;
                            if (hasBrokerage && key === 'premiumNet') { // net premium
                                return null;
                            }

                            let dataSet = data.datasets[tooltipItem.datasetIndex];
                            let premium = dataSet.data[tooltipItem.index];
                            let insurance = dataInsurances[tooltipItem.index];

                            // if gross premium, add the net premium since gross bar is only difference between the two
                            if (key === 'premiumGross') {
                                premium += dataNet[tooltipItem.index];
                            }

                            if (!insurance) {
                                insurance = {name: '?'};
                            }
                            return `${insurance.name}: ${intl.formatNumber(premium, {
                                style: 'decimal',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })} CHF`;
                        },
                    }
                }
            },
            plugins: [{
                afterEvent: function (chart, event) {
                    const x = event.x;
                    const y = event.y;

                    if (event.type === 'click') {
                        const element = chart.getElementAtEvent(event);
                        if (element && element.length > 0) {
                            // click on criteria rating
                            const dataIndex = element[0]._index;

                            const data = graphData[dataIndex];
                            if (data.offer && actions && actions.onOfferClick) {
                                actions.onOfferClick(data.offer)
                            }
                        }
                    }
                }
            }]
        };
    };

    getBrokerageAverage(insuranceTypeId) {
        switch (insuranceTypeId) {
            case 'sick_pay':
                return 0.075; //ktg
            case 'uvg':
                return 0.03;
            case 'uvgz':
                return 0.15;
            case 'bvg':
            case 'bvg_semiautonomous':
                return 0.02;
            case 'liability_insurance_for_self_propelled_land_vehicles':
                return 0.04;
            case 'car_insurance':
                return 0.10;
            default:
                return 0.15;
        }
    }

    render() {
        let {auth, insurances, offers, offerRequest, showCreated} = this.props;
        offers = sortOfferList(offers, -1);

        let offersFilterCriteria = isCreatedFinishedOffer(offerRequest)
            ? offer => offer.state === OfferStates.PUBLISHED || offer.state === OfferStates.CREATED
            : offer => offer.state === OfferStates.PUBLISHED;
        let visibleOffers = offers.filter(offersFilterCriteria);
        let hasPublishedOffers = visibleOffers.length > 0;

        if (!hasPublishedOffers) {
            return null;
        }

        let graphData = this.structureGraphData(offerRequest, visibleOffers, insurances, auth && auth.user ? auth.user.companyId : null);

        return (
            <div>
                <Bar {...graphData} />
            </div>
        );
    }
}


export const PremiumGraph = injectIntl(PremiumGraphComponent);
