import {States as OfferStates} from '../../config/domain/offer';

export const hasPublishedOffer = (offers, insuranceId) => {
    return !!offers.find((offer) => {
        return offer.insuranceId === insuranceId
            && (offer.state === OfferStates.PUBLISHED || offer.state === OfferStates.ABSTAINED);
    });
};

export const hasAbstainedOffer = (offers, insuranceId) => {
    return !!offers.find((offer) => {
        return offer.insuranceId === insuranceId && offer.state === OfferStates.ABSTAINED;
    });
};
