/**
 * Created by br0wn on 10/24/16.
 */
import React from 'react';
import PropTypes from "prop-types";
import {isActionPermitted, isAnyActionPermitted} from '../../../../auth/helper.js';
import {renderChildren} from '../../../../helper/react.js';


export const EntityActionOnly = ({action, allowedActions = [], NotAllowedComponent = (() => null), children}) => {
    if (!isActionPermitted(action, allowedActions)) {
        return (<NotAllowedComponent />);
    }

    return renderChildren(children);
};

EntityActionOnly.propTypes = {
    action: PropTypes.string.isRequired,
    allowedActions: PropTypes.array,
    NotAllowedComponent: PropTypes.element
};

export const EntityAnyActionOnly = ({actions, allowedActions = [], NotAllowedComponent = (() => null), children}) => {
    if (!isAnyActionPermitted(actions, allowedActions)) {
        return (<NotAllowedComponent />);
    }

    return renderChildren(children);
};

EntityAnyActionOnly.propTypes = {
    actions: PropTypes.array.isRequired,
    allowedActions: PropTypes.array,
    NotAllowedComponent: PropTypes.element
};


export const WrapWhenActionAllowed = ({action, allowedActions = [], WrapperComponent, children}) => {
    if (!isActionPermitted(action, allowedActions)) {
        return renderChildren(children);
    }

    return (
        <WrapperComponent>
            {children}
        </WrapperComponent>
    );
};

WrapWhenActionAllowed.propTypes = {
    WrapperComponent: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func
    ]).isRequired,
    action: PropTypes.string.isRequired,
    allowedActions: PropTypes.array
};
