import React from 'react';
import * as Ibox from '../../ui-components/ibox.jsx';

const AuthPage = ({children, icon='lock', title}) => {
    return (
        <div id="wrapper" className="login" style={{height: '100%'}}>
            <div className="middle-box loginscreen animated fadeInDown">
                <div className="text-center m-b-md">
                    <img className="logo" src={require('../../../../resources/images/logo.png')}/>
                </div>


                <Ibox.Container>
                    <Ibox.Title>
                        <h5><i className={`fa fa-${icon}`}/>&nbsp;{title}</h5>
                    </Ibox.Title>

                    <Ibox.Content className="">
                        {children}
                    </Ibox.Content>
                </Ibox.Container>

                <div className="m-t text-center">
                    <img className="ssl" src={require('../../../../resources/images/ssl.png')}/>
                </div>
            </div>

        </div>
    );
};


export default AuthPage;
