import {TYPES as ACTION_TYPES} from './action';

const INITIAL_STATE = {
    locale: 'en',
    browserLocale: 'en'
};

/**
 *
 * @param state
 * @param action
 * @return {object}
 */
let i18nReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_LOCALE: {
            return {
                ...state,
                locale: action.locale
            };
        }
        case ACTION_TYPES.SET_BROWSER_LOCALE: {
            return {
                ...state,
                browserLocale: action.locale
            };
        }
    }

    return state;
};

export default i18nReducer;