import {ACTION_TYPES, PAGE_LOADER_ACTION_TYPES} from './action';
import loadedPageReducer from '../../common/page/loaded-page/reducer';
import {updateUserInList} from '../../common/user/userEditModal-reducer';

const INITIAL_STATE = {
    isCreating: false,
    isCreated: false,
    customer: {},

    userEditModal: {
        show: false,
        user: {}
    },
};

let customerUsersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // case ACTION_TYPES.CREATE_USER_START: {
        //     return {
        //         ...INITIAL_STATE,
        //         isCreating: true
        //     };
        // }
        // case ACTION_TYPES.CREATE_USER_END: {
        //     return {
        //         isCreating: false,
        //         isCreated: true,
        //         customer: action.data
        //     };
        // }
        case ACTION_TYPES.UPDATE_USER_EDIT_MODAL: {
            return {
                ...state,
                userEditModal: {
                    show: action.show,
                    user: action.user
                }
            };
        }

        case ACTION_TYPES.DATA_UPDATE_SINGLE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    users: updateUserInList(state.data.users, action.user)
                }
            };
        }
        case ACTION_TYPES.ENABLE_USER_START: {
            const users = [...state.data.users];
            const foundUserIndex = users.findIndex((user) => user.id == action.id);
            if (!action.enable && foundUserIndex >= 0) {
                users.splice(foundUserIndex, 1);
            } else if (action.enable && foundUserIndex >= 0) {
                users[foundUserIndex] = {
                    ...users[foundUserIndex],
                    enabled: true,
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    users: users
                }
            };
        }
        case ACTION_TYPES.ENABLE_USER_END: {
            if (action.error) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        users: updateUserInList(state.data.users, action.user)
                    }
                };
            }
            break;
        }
    }
    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_LOADER_ACTION_TYPES)
    };
};

export default customerUsersReducer;
