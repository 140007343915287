import React from 'react';
import {l} from '../../../../i18n/translator';
import {Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {STEP_CONTRACT_DATA} from '../../create-request/config/wizard';
import EditButton from './editButton.jsx';

const SelectedInsurances = ({insurances = [], offerRequest}) => {
    const selectedInsurancesNames = getSelectedInsuranceNames(insurances, offerRequest);
    return (
        <Ibox.Container>
            <Ibox.Title>
                <h5>
                    <i className="fa fa-user"/>&nbsp;
                    {l('Selected Insurances')}
                </h5>

                <EditButton step={STEP_CONTRACT_DATA}/>
            </Ibox.Title>

            {selectedInsurancesNames && selectedInsurancesNames.length ? (
                <Ibox.Content>
                    <ul className="todo-list small-list max-height-200-scroll scrollbars-always">
                        {selectedInsurancesNames
                            .sort((i1, i2) => i1.localeCompare(i2))
                            .map((insuranceName, index) => {
                                return insuranceName ? (

                                    <li key={index}>
                                        {insuranceName}
                                    </li>

                                ) : null;
                            })}
                    </ul>
                </Ibox.Content>
            ) : (
                <Ibox.Content className="no-data">
                    {l('None selected')}
                </Ibox.Content>
            )}

        </Ibox.Container>
    );
};

const getSelectedInsuranceNames = (insurances, offerRequest) => {
    if (insurances && !offerRequest) {
        return insurances.map((insurance) => insurance.name);
    }
    if (offerRequest) {
        if (offerRequest[OfferRequestFields.REQUEST_FROM_INSURANCE_IDS]) {
            const getInsuranceName = (insuranceId) => {
                const insurance = insurances.find(insurance => insurance.id === insuranceId);
                return insurance ? insurance.name : undefined;
            };
            return offerRequest[OfferRequestFields.REQUEST_FROM_INSURANCE_IDS]
                .map((insuranceId) => getInsuranceName(insuranceId, insurances));
        } else if (offerRequest && offerRequest[OfferRequestFields.REQUEST_FROM_INSURANCE_NAMES]) {
            return offerRequest[OfferRequestFields.REQUEST_FROM_INSURANCE_NAMES];
        }
    }
    return [];
};

export default SelectedInsurances;
