/**
 * Created by br0wn on 12/15/16.
 */
import React from 'react';
import classNames from 'classnames';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {l} from '../../../../i18n/translator.js';
import {PremiumValue} from '../../common/i18n/premium.jsx';
import {LocalDate} from '../../../common/i18n/datetime.jsx';
import {isDefined, isFunction, objectProp} from '../../../../helper/core.js';
import {getOfferRequestSavings, isOfferRequestEndingIn} from '../../helper.js';
import {Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';
import {OfferRequestLink} from './link.jsx';

const ItemCell = ({item, children, ...props}) => (
    <td {...props}>
        <OfferRequestLink offerRequest={item}>
            {children}
        </OfferRequestLink>
    </td>
);

const createBoundCell = (item) => ({children, ...props}) => (
    <ItemCell {...props} item={item}>
        {children}
    </ItemCell>
);


export const Column = ({item, field, name, cellComponent, valueComponent, valueFormat, attributes, ...props}) => {
    const Cell = createBoundCell(item);

    /*
     * Render cellComponent if defined
     */
    if (cellComponent) {
        const Component = cellComponent;
        return (
            <Component {...{item, Cell}}/>
        )
    }

    /*
     * Update cell value
     */
    const rawValue = objectProp(field, item);
    let itemValue = rawValue;

    if (valueFormat && isFunction(valueFormat)) {
        itemValue = valueFormat(itemValue);
    }
    if (valueComponent) {
        const Component = valueComponent;
        itemValue = (<Component value={itemValue} item={item}/>);
    }

    /*
     * Update attributes
     */
    if (attributes && isFunction(attributes)) {
        attributes = attributes(rawValue, item);
    }

    return (
        <Cell {...props} {...attributes}>
            {itemValue}
        </Cell>
    )
};

export const EndsSoonTooltip = () => (
    <span>
        {l('This offer request ends soon.')}
    </span>
);

export const Table = ({items, children, tableRef, ...props}) => {
    return (
        <table {...props}
               className={classNames('table table-link-row table-row-mark table-offer-request', props.className)}
               ref={tableRef}>
            <thead>
            <tr>
                {React.Children.map(children, child => {
                    return isDefined(child) ? (
                        <th className={classNames(child.props.className)}>
                            {child.props.name}
                        </th>
                    ) : null;
                })}
            </tr>
            </thead>
            <tbody>
            {items.map((item, index) => {
                const endsIn2Days = isOfferRequestEndingIn(item);
                const endsIn7Days = !endsIn2Days && isOfferRequestEndingIn(item, [7, 'days']);

                return (
                    <tr key={index} className={classNames({
                        'marked': endsIn2Days || endsIn7Days,
                        'mark-danger': endsIn2Days,
                        'mark-warning': endsIn7Days
                    })}>
                        {
                            React.Children.map(children, (child, index) => {
                                return isDefined(child) ? React.cloneElement(child, {key: index, item}) : null;
                            })
                        }
                    </tr>
                );
            })}
            {items.length === 0 ? (
                <tr>
                    <td colSpan={children.length} align='center'>
                        <div className="p-xs">{l('data_table_emptyTable')}</div>
                    </td>
                </tr>
            ) : null}
            </tbody>
        </table>
    )
};

/*
 * Common format, column and value components
 */

export const PremiumValueComponent = ({value}) => {
    if (!isDefined(value)) {
        return null;
    }

    return (
        <span>
            <PremiumValue value={value}/>
        </span>
    );
};

export const EndDateCellComponent = ({item, Cell}) => {
    const value = item[OfferRequestFields.OFFER_ROUND_END_DATE];

    if (!isDefined(value)) {
        return <Cell/>
    }

    const endsIn2Days = isOfferRequestEndingIn(item);
    const endsIn7Days = !endsIn2Days && isOfferRequestEndingIn(item, [7, 'days']);
    const endsSoon = endsIn2Days || endsIn7Days;

    const tooltip = (
        <Tooltip id={`offer-request-table-cell-hover--end-date`}>
            <EndsSoonTooltip/>
        </Tooltip>
    );

    return (
        <Cell data-sort={value} className="field--end-date">
            <LocalDate value={value}/>

            {endsSoon && (
                <OverlayTrigger delayShow={500} placement="bottom" overlay={tooltip}>
                    <i className="fa fa-clock-o"/>
                </OverlayTrigger>
            )}
        </Cell>
    );
};

export const SavingsCellComponent = ({item, Cell}) => {
    const savings = getOfferRequestSavings(item);
    return (
        <Cell>
            {savings !== null ? savings + '%' : null}
        </Cell>
    )
};

export const favoriteValueFormat = value => value ? (<i className="fa fa-flag"/>) : null;
