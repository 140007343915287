
import {l} from '../../../i18n/translator';
import {warn} from '../../../../lib/logger';
import {fetchCompany} from './api';
import {setFormData} from './form';
import TYPES from './type';

export const init = () => {
    return (dispatch) => {
        dispatch({
            type: TYPES.PAGE_LOAD_START
        });

        Promise.all([
            dispatch(fetchCompany())

        ]).then(([company]) => {
            dispatch(setFormData(company));
            dispatch(pageLoadEnd({isLoaded: true}));

        }).catch(error => {
            warn("Error while fetching offer resources!", error);
            let errors = [];

            if (error.requestError) {
                errors.push(l("Unknown response received from server"));
            } else {
                errors.push(l("Unknown error occurred"));
            }

            dispatch(pageLoadEnd({errors: errors}));
        });
    };
};

export const pageLoadEnd = (params = {}) => ({
    type: TYPES.PAGE_LOAD_END,
    ...params
});