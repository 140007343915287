const TYPES = {
    OFFER_FILE_UPLOAD_START: 'page.create_offer.form.offer_file.upload_start',
    OFFER_FILE_UPLOAD_END: 'page.create_offer.form.offer_file.upload_end',
    OFFER_FILE_UPLOAD_PROGRESS: 'page.create_offer.form.offer_file.upload_progress',
    OFFER_FILE_SELECTED: 'page.create_offer.form.offer_file_selected',
    OFFER_FILE_REMOVE_START: 'page.create_offer.form.offer_file_remove_start',
    OFFER_FILE_REMOVE_END: 'page.create_offer.form.offer_file_remove_end',

    FIELD_CHANGE: 'page.create_offer.form.FIELD_CHANGE',
    FIELD_ERROR_CLEAR: 'page.create_offer.form.FIELD_ERROR_CLEAR',
    VALUES_UPDATE: 'page.create_offer.form.VALUES_UPDATE',
    ERRORS_UPDATE: 'page.create_offer.form.ERRORS_UPDATE',

    SET_OFFER_REQUEST: 'page.create_offer.SET_OFFER_REQUEST'
};

export const FILE_UPLOAD_ACTIONS = {
    UPLOAD_START: TYPES.OFFER_FILE_UPLOAD_START,
    UPLOAD_END: TYPES.OFFER_FILE_UPLOAD_END,
    UPLOAD_PROGRESS: TYPES.OFFER_FILE_UPLOAD_PROGRESS,
    REMOVE_FILE_START: TYPES.OFFER_FILE_REMOVE_START,
    REMOVE_FILE_END: TYPES.OFFER_FILE_REMOVE_END,
    FILE_SELECTED: TYPES.OFFER_FILE_SELECTED
};

export default TYPES;