import React from 'react';
import PropTypes from "prop-types";

const TabPane = (props) => {
  return (
    <div className={'tab-pane' + (props.isActive(props.name) ? ' active' : '')}>
      {props.children}
    </div>
  );
};

TabPane.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.func
};

export default TabPane;
