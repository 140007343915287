import React from 'react';
import classNames from 'classnames';
import {l} from '../../../../../i18n/translator';


const LoadingIndicator = ({isLoading}) => (

    <div className={classNames("text-center", "loading", {
        'hidden': !isLoading
    })}>
        <i className="fa fa-cog"></i> {l('Loading...')}
    </div>
);

export default LoadingIndicator;