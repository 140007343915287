import React from 'react';
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import URI from '../../../../../config/uri';
import {ACTIONS} from '../../../../../config/domain/entity.js';
import {isActionPermitted} from '../../../../auth/helper.js';
import {NumberStringShape} from '../../../shape.js';


const LinkShape = {
    title: PropTypes.string
};

const OfferRequestShape = {
    offerRequest: PropTypes.shape({
        id: NumberStringShape.isRequired,
        state: PropTypes.string.isRequired
    }).isRequired
};


export const getEditURI = (offerRequest) => {
    return URI.EDIT_OFFER_REQUEST.replace(':id', offerRequest.id);
};

export const getDetailsURI = (offerRequest) => {
    return URI.OFFER_REQUEST_DETAILS.replace(':id', offerRequest.id);
};

export const getUpdateDataURI = (offerRequest) => {
    return URI.UPDATE_OFFER_REQUEST_DATA.replace(':id', offerRequest.id);
};

export const getLinkComponent = (props) => {
    return props.onClick ? OnClickLink : Link
};


export const OfferRequestLink = ({offerRequest, children, ...props}) => {
    const render = (LinkComponent) => (
        <LinkComponent {...props} offerRequest={offerRequest} Component={getLinkComponent(props)}>
            {children}
        </LinkComponent>
    );

    // we always want to go to the view page first
    // if (isActionPermitted(ACTIONS.EDIT, offerRequest.actions)) {
    //     return render(EditLink);
    // }

    if (isActionPermitted(ACTIONS.VIEW, offerRequest.actions)) {
        return render(DetailsLink);
    }

    if (isActionPermitted(ACTIONS.EDIT_INSURANCE_DATA, offerRequest.actions)) {
        return render(UpdateDataLink);
    }

    if (isActionPermitted(ACTIONS.VIEW_INSURANCE_DATA, offerRequest.actions)) {
        return render(UpdateDataLink);
    }

    if (React.isValidElement(children)) {
        return children;
    }

    return (<span>{children}</span>);
};

OfferRequestLink.propTypes = {
    ...LinkShape,
    ...OfferRequestShape,
    onClick: PropTypes.func
};


export const OnClickLink = ({onClick, to, children}) => {
    const uri = to;
    const child = React.Children.only(children);

    const linkProps = {
        onClick: (e) => onClick(e, uri),
        style: {cursor: 'pointer'}
    };

    return React.cloneElement(child, linkProps);
};

OnClickLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    to: PropTypes.string.isRequired
};


export const DetailsLink = ({offerRequest, title, children, Component = Link, ...props}) => (
    <Component {...props} to={getDetailsURI(offerRequest)}>
        {children}
    </Component>
);

DetailsLink.propTypes = {
    ...LinkShape,
    ...OfferRequestShape
};


export const EditLink = ({offerRequest, title, children, Component = Link, ...props}) => (
    <Component {...props} to={getEditURI(offerRequest)}>
        {children}
    </Component>
);

EditLink.propTypes = {
    ...LinkShape,
    ...OfferRequestShape
};


export const UpdateDataLink = ({offerRequest, title, children, Component = Link, ...props}) => (
    <Component {...props} to={getUpdateDataURI(offerRequest)}>
        {children}
    </Component>
);

UpdateDataLink.propTypes = {
    ...LinkShape,
    ...OfferRequestShape
};
