/**
 * Created by br0wn on 11/22/16.
 */
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {KpiItem} from './kpi-item.jsx';
import {getPageState, getKpiList} from '../selector';
import {fetchKpiList} from '../action';


export const KpiList = ({kpiList = [], ...props}) => {

    const containerClass = classNames(
        'kpi-list', 'row', props.className
    );

    return (
        <div className={containerClass}>
            {kpiList.map((kpi, index) => (
                <div key={index} className="col-md-3 col-sm-4 col-xs-6">
                    <KpiItem kpi={kpi} className={props.itemClass}/>
                </div>
            ))}
        </div>
    );
};


export class DashboardKpiList extends React.Component {

    init() {
        this.props.fetchKpiList();
    }

    componentWillMount() {
        this.init();
    }

    render() {
        const {kpiList} = this.props;

        return (
            <KpiList kpiList={kpiList} className="dashboard--kpi"/>
        )
    }
}


export const DashboardKpiListContainer = connect(
    state => ({
        i18n: state.i18n,
        kpiList: getKpiList(getPageState(state))
    }),
    dispatch => bindActionCreators({
        fetchKpiList
    }, dispatch)
)(DashboardKpiList);
