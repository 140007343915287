import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {fetchCustomers} from '../../../api/sdk-action';

export const ACTION_TYPES = createPageActionTypes('page.customers_list.');

export const initCustomersList = createPageLoader(
    ACTION_TYPES,
    (dispatch) => {
        return Promise.all([
            dispatch(fetchCustomers())
        ]).then(([customers]) => {
            return {customers};
        });
    }
);
