/**
 * Created by br0wn on 12/5/16.
 */
import React from 'react';
import PropTypes from "prop-types";
import {renderChildren} from '../../../helper/react.js';


export const Default = ({children}) => renderChildren(children);


export const Type = ({children}) => renderChildren(children);

Type.propTypes = {
    type: PropTypes.any.isRequired
};


export const Container = ({children, activeType}) => {
    let activeChild, defaultChild;

    React.Children.forEach(children, child => {
        const childComponent = child.type;

        if (childComponent === Type) {
            const {type} = child.props;

            if (type === activeType) {
                activeChild = child;
            }
        }
        else if (childComponent === Default) {
            defaultChild = child;
        }
    });

    if (!activeChild && !defaultChild) {
        return null;
    }

    return activeChild || defaultChild;
};


export const InsuranceType = {Type, Default, Container};
