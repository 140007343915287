/**
 * Created by br0wn on 10/24/16.
 */

export const ACTIONS = {
    VIEW: "view",
    EDIT: "edit",
    PUBLISH: "publish",
    REJECT: "reject",
    WITHDRAW: "withdraw",
    DELETE: "delete",
    CREATE_OFFER: "create_offer",
    ABSTAIN: "abstain",
    VIEW_INSURANCE_DATA: "view_insurance_data",
    EDIT_INSURANCE_DATA: "edit_insurance_data",
    REQUEST_OFFICIAL_OFFER: "request_official_offer",
    UPLOAD_OFFICIAL_OFFER: "upload_official_offer",
    EDIT_NOTES: 'edit_notes',
    OFFER_ANALYSIS_UPDATE_COMMENT: 'analysis_comment'
};
