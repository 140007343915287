export const TYPES = {
    DATA_LOADED: 'page.create_finished_offer_request.data_loaded',

    PAGE_LOAD_START: 'page.create_finished_offer_request.load_start',
    PAGE_LOAD_END: 'page.create_finished_offer_request.load_end',
    PAGE_RESET: 'page.create_finished_offer_request.reset'
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    DATA_LOADED: TYPES.DATA_LOADED,
    PAGE_RESET: TYPES.PAGE_RESET
};
