import {initialize, SubmissionError} from 'redux-form';

import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {
    apiCreateAutoRating,
    apiUpdateAutoRating,
    fetchAnalysisAutoRating,
    fetchAnalysisCriteriaList,
    fetchInsurances,
    fetchInsuranceTypes
} from '../../../api/sdk-action';
import {AutoRatingFields} from '../../../../config/domain/analysis';
import URI from '../../../../config/uri.js';
import {buildErrors} from '../../common/form/helper';
import {FORM_NAME} from './page.jsx';
import {redirectTo} from '../../../router/action';

export const ACTION_TYPES = {
    UPDATE_AUTO_RATING: 'page.analysis_auto_rating_edit.update_auto_rating',
    UPDATE_DATA: 'page.analysis_auto_rating_edit.update_data',
    ...createPageActionTypes('page.analysis_auto_rating_edit.')
};

export const initAutoRatingEdit = createPageLoader(
    ACTION_TYPES,
    (dispatch, {autoRatingId}) => {
        return Promise.all([
            autoRatingId !== null ? dispatch(fetchAnalysisAutoRating(autoRatingId)) : {},
            dispatch(fetchInsuranceTypes()),
            // dispatch(fetchAnalysisCriteriaList()), // TODO based on insuranceType of autoRating
            // dispatch(fetchInsurances()),// TODO only insurances for insurance type
        ]).then(([autoRating, insuranceTypes, criteria, insurances]) => {
            dispatch(initialize(FORM_NAME, autoRating));
            if (autoRating[AutoRatingFields.INSURANCE_TYPE_ID]) {
                dispatch(onInsuranceTypeChanged(autoRating[AutoRatingFields.INSURANCE_TYPE_ID]));
            }
            return {autoRating, criteria, insuranceTypes, insurances};
        });
    }
);

export const saveAutoRating = (data, dispatch) => {
    data = {...data};
    return dispatch(
        data[AutoRatingFields.ID]
            ? apiUpdateAutoRating(data[AutoRatingFields.ID], data)
            : apiCreateAutoRating(data)
    ).then(autoRating => {
        if (!data.id) {
            dispatch({type: ACTION_TYPES.DATA_LOADED, data:{autoRating}});
            dispatch(redirectTo(URI.ANALYSIS_AUTO_RATING_EDIT.replace(':id', autoRating.id)));
        }
    }).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};

export const onInsuranceTypeChanged = insuranceTypeId => dispatch => {
    return Promise.all([
        dispatch(fetchAnalysisCriteriaList({insuranceType: insuranceTypeId})),
        dispatch(fetchInsurances(insuranceTypeId)),
    ]).then(([criteria, insurances]) => {
        return dispatch({type: ACTION_TYPES.DATA_LOADED, data: {criteria, insurances}});
    });
};
