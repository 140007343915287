import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Link} from 'react-router-dom';
import URI from '../../../../config/uri';
import {l} from '../../../i18n/translator';
import * as Actions from './action';
import {renderTextField} from '../../common/form/renderer.jsx';
import AuthPage from '../../common/auth/authPage.jsx';

/**
 * ForgotPasswordPage component
 */

export class ForgotPasswordPage extends React.Component {
    render() {
        const {handleSubmit, submitting, submitSucceeded, error} = this.props;
        return (
            <AuthPage icon="lock" title={l('Forgot your password?')}>
                {submitSucceeded ? (
                    <div>
                        <h3>{l('Your password has been reset!')}</h3>

                        <p>{l('Please check your email for password reset link.')}</p>
                    </div>
                ) : (
                    <div>
                        <form onSubmit={handleSubmit}>
                            <Field name="email" component={renderTextField}
                                   label={l('Enter your email')}
                                   disabled={submitting}
                                   autoFocus={true}/>

                            <p className="text-left text-danger m-t-sm">
                                {error}
                            </p>

                            <button disabled={submitting} type="submit" className="btn btn-danger block m-b">
                                {!submitting ? (
                                    l('Reset password')
                                ) : (
                                    <span>
                                    <i className="fa fa-cog m-r-sm"></i> {l('Working, please wait')}
                                </span>
                                )}
                            </button>
                        </form>
                    </div>
                )}

                <div>
                    <Link to={URI.LOGIN}>
                        <small>
                            <i className="fa fa-arrow-left m-r-sm"></i> {l('Back to login')}
                        </small>
                    </Link>
                </div>
            </AuthPage>
        );
    }
};

function mapStateToProps(state) {
    return {state};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default reduxForm({
    form: 'passwordReset',
    onSubmit: Actions.doPasswordReset,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordPage));

