/* eslint-disable import/default */

import React from 'react';
import {render} from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import 'isomorphic-fetch';

import configureStore, {history} from './store/configureStore';

import {addLocaleData} from 'react-intl';
import i_en from 'react-intl/locale-data/en';
import i_de from 'react-intl/locale-data/de';

import l_en from '../config/translation/en';
import l_de from '../config/translation/de';
import Translator, {DEFAULT_NS} from './i18n/translator';
import {loadBrowserLocale, loadLocaleFromStorage} from './i18n/action';

import {loadUserFromStorage} from './auth/action';
import {bootstrap as bootstrapNotificationService} from './notification/service';
/*eslint-disable import/no-named-as-default*/
/*eslint-enable import/no-named-as-default*/
import ReactGA from 'react-ga';
// Required for material-ui components: http://www.material-ui.com/#/get-started/installation

import Root from './root.js';

const store = configureStore();

// load localizations
addLocaleData([...i_en, ...i_de]);

// load translations
Translator.addResources('en', DEFAULT_NS, l_en);
Translator.addResources('de', DEFAULT_NS, l_de);

// subscribe to state changes
store.subscribe(() => {
    if (store.getState().i18n) {
        Translator.changeLanguage(store.getState().i18n.locale);
    }
});

// load locale
store.dispatch(loadLocaleFromStorage());
store.dispatch(loadBrowserLocale());


// load user
store.dispatch(loadUserFromStorage());


bootstrapNotificationService(store);

ReactGA.initialize('UA-124441710-2');
ReactGA.pageview(window.location.pathname + window.location.search);
history.listen(function (location) {
    ReactGA.pageview(location.pathname + location.search);
});

render(
    <AppContainer>
        <Root store={store} history={history} />
    </AppContainer>
    ,
    document.getElementById('insurTecAppRoot')
);

if (module.hot) {
    module.hot.accept('./root.js', () => {
        const NewRoot = require('./root').default;
        render(
            <AppContainer>
                <NewRoot store={store} history={history} />
            </AppContainer>,
            document.getElementById('insurTecAppRoot')
        );
    });
}
