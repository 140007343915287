import moment from 'moment';

import {Fields as OfferRequestFields} from '../../../../config/domain/offerRequest';
import {Fields as CreditOfferRequestFields} from '../../../../config/domain/offerRequest/credit';
import {Types as InsuranceTypes} from '../../../../config/domain/insurance';
import {getInsuranceType} from '../../../model/insurance';
import {
    isArray,
    isDefined,
    objectMerge,
    objectPickByKeys,
    objectProp,
    objectPropSet,
    objectPropUnset
} from '../../../helper/core';
import {dataFieldKey} from '../../common/form/field.jsx';
import {translateError} from '../../common/form/helper';
import {
    ContractDataFields,
    CurrentInsurerFields,
    Fields,
    MandatoryFields,
    OfferRoundDataFields,
    SummaryFields
} from './config/form';
import * as Steps from './config/wizard';
import {Fields as OfferRoundFields} from '../../../../config/domain/offerRequest/offer-round/offer-round';


export const FieldErrorParsers = (() => {
    const transformArrayFieldError = value => {
        if (isArray(value)) return value;

        return value ? {_error: value} : value;
    };

    return {
        [dataFieldKey(CreditOfferRequestFields.SALES_VOLUME_LASTTHREEYEARS)]: transformArrayFieldError,
        [dataFieldKey(CreditOfferRequestFields.SALES_SALESSTRUCTURE)]: transformArrayFieldError,
        [dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_DEVELOPMENT)]: transformArrayFieldError,
        [dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_STRUCTURE)]: transformArrayFieldError,
        [dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_BIGGESTCUSTOMERS)]: transformArrayFieldError,
        [dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_BADDEPTS)]: value => {
            if (!isArray(value)) {
                return transformArrayFieldError(value);
            }

            return value.map(errors => {
                errors.biggestCases = transformArrayFieldError(errors.biggestCases);
                return errors;
            });
        }
    };
})();


const filterDataByFields = (data, fields, excludeFields = []) => {
    const fieldsToPick = Object.keys(fields).map(key => fields[key])
        .filter(field => excludeFields.indexOf(field) === -1);

    return objectPickByKeys(data, fieldsToPick);
};

/**
 * Builds offer request API request object from form data
 *
 * @param {object} values
 * @return {object}
 */
export const buildApiRequestData = ({values}) => {
    let apiData = objectMerge({}, values);

    const sameAsContact = objectProp(Fields.SAME_AS_CONTACT, values);
    const hasCurrentInsurance = objectProp(Fields.HAS_CURRENT_INSURANCE, values);
    const insuranceTypeId = objectProp(Fields.INSURANCE_TYPE_ID, values);
    const insuranceType = getInsuranceType(insuranceTypeId);

    const offerRoundEndDate = objectProp(Fields.OFFER_ROUND_END_DATE, values);
    if (offerRoundEndDate) {
        apiData = objectPropSet(OfferRequestFields.OFFER_ROUND_END_DATE, moment(offerRoundEndDate).endOf('day'), apiData);
    }
    apiData = objectPropSet(OfferRequestFields.OFFER_ROUND_DATA,
        objectProp(OfferRequestFields.OFFER_ROUND_DATA, values) || {[OfferRoundFields.HIDE_CURRENT_INSURANCE]: false}, apiData);

    if (sameAsContact) {
        apiData = objectPropSet(OfferRequestFields.CONTACT, null, apiData);
    }

    if (hasCurrentInsurance === "yes") {
        const currentInsurance = apiData[OfferRequestFields.CURRENT_INSURANCE] || {};
        apiData = objectPropSet(OfferRequestFields.CURRENT_INSURANCE, currentInsurance, apiData);
    } else {
        apiData = objectPropUnset(OfferRequestFields.CURRENT_INSURANCE, apiData);
        apiData = objectPropUnset(OfferRequestFields.TARGET_INSURANCE_PREMIUM, apiData); // no current insurance => no target premium
    }

    const yearlyTermination = objectProp(OfferRequestFields.INSURANCE_YEARLY_RIGHT_OF_TERMINATION, apiData);

    apiData = objectPropSet(OfferRequestFields.INSURANCE_YEARLY_RIGHT_OF_TERMINATION, !!yearlyTermination, apiData);

    apiData = objectPropUnset(Fields.SAME_AS_CONTACT, apiData);
    apiData = objectPropUnset(Fields.HAS_CURRENT_INSURANCE, apiData);
    apiData = objectPropUnset(Fields.TERMS_AND_AGREEMENT, apiData);

    // update data structure
    const data = objectProp(OfferRequestFields.DATA + '.' + insuranceType, apiData);
    apiData = objectPropSet(OfferRequestFields.DATA, data || {}, apiData);

    return apiData;
};

/**
 * Builds form data object from offer request API object
 *
 * @param {object} apiData
 * @return {object}
 */
export const buildFormData = (apiData = {}) => {
    let formData = objectMerge({}, apiData);
    formData = filterDataByFields(formData, Fields);

    const contact = objectProp(OfferRequestFields.CONTACT, apiData);
    const currentInsurance = objectProp(OfferRequestFields.CURRENT_INSURANCE, apiData);
    const insuranceTypeId = objectProp(OfferRequestFields.INSURANCE_TYPE_ID, apiData);
    const insuranceType = getInsuranceType(insuranceTypeId);

    formData = objectPropSet(Fields.SAME_AS_CONTACT, contact == null, formData);
    formData = objectPropSet(Fields.HAS_CURRENT_INSURANCE, (currentInsurance ? "yes" : "no"), formData);

    // update data structure
    const data = objectProp(Fields.DATA, formData);
    formData = objectPropSet(Fields.DATA, {}, formData);
    formData = objectPropSet(Fields.DATA + '.' + insuranceType, data || {}, formData);

    return formData;
};

/**
 * Builds form errors object from API validation errors
 *
 * @param validationErrors
 * @param values
 * @return {object}
 */
export const buildFormErrors = (validationErrors, {values}) => {
    if (!Array.isArray(validationErrors)) {
        return {};
    }

    const formData = values;
    const insuranceTypeId = objectProp(OfferRequestFields.INSURANCE_TYPE_ID, formData);
    const insuranceType = getInsuranceType(insuranceTypeId);

    // build errors structure
    let errors = validationErrors.reduce((carry, {path, ...data}) => {
        let message = translateError(data.key);
        return objectPropSet(path, message, carry);
    }, {});

    // apply parsers
    Object.keys(FieldErrorParsers).forEach(path => {
        const parser = FieldErrorParsers[path];
        const value = objectProp(path, errors);

        if (isDefined(value)) {
            const newValue = parser(value);
            objectPropSet(path, newValue, errors);
        }
    });

    // update data errors structure
    const dataErrors = objectProp(OfferRequestFields.DATA, errors);

    if (isDefined(dataErrors)) {
        errors = objectPropSet(OfferRequestFields.DATA, {}, errors);
        errors = objectPropSet(OfferRequestFields.DATA + '.' + insuranceType, dataErrors, errors);
    }

    return errors;
};

/**
 * Extracts errors for list of fields
 *
 * @param {Array} fields
 * @param {object} errors
 * @return {object}
 */
export const extractErrorsForFields = (fields, errors) => {
    return fields.reduce((carry, field) => {
        const error = objectProp(field, errors);
        return error ? objectPropSet(field, error, carry) : carry;
    }, {});
};

/**
 * Extract form errors based on wizard step
 *
 * @param {object} errors
 * @param {int|string} step
 * @return {object}
 */
export const extractStepErrors = (errors, step) => {
    switch (step) {
        case Steps.STEP_BASIC_DATA:
            return extractErrorsForFields(MandatoryFields, errors);
        case Steps.STEP_CONTRACT_DATA:
            return extractErrorsForFields(ContractDataFields, errors);
        case Steps.STEP_CURRENT_INSURER:
            return extractErrorsForFields(CurrentInsurerFields, errors);
        case Steps.STEP_INSURANCE_DATA:
            return extractErrorsForFields([OfferRequestFields.DATA], errors);
        case Steps.STEP_OFFER_DATA:
            return extractErrorsForFields(OfferRoundDataFields, errors);
        case Steps.STEP_SUMMARY:
            return extractErrorsForFields(SummaryFields, errors);
        default:
            return {};
    }
};

/**
 * Returns true if step has errors
 *
 * @param {object} errors
 * @param {string} step
 * @return {boolean}
 */
export const hasStepErrors = (errors, step) => {
    let stepErrors = extractStepErrors(errors, step);
    return Object.keys(stepErrors).length > 0;
};


/**
 * Creates prefix function
 *
 * @param prefix
 * @return {function(*)}
 */
export const createDataFieldPrefixer = (prefix) => {
    return (fieldKey) => dataFieldKey(`${prefix}.${fieldKey}`);
};


export const creditFieldKey = createDataFieldPrefixer(InsuranceTypes.CREDIT);

export const uvgFieldKey = createDataFieldPrefixer(InsuranceTypes.UVG);

export const defaultFieldKey = createDataFieldPrefixer(InsuranceTypes.DEFAULT);
