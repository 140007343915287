import React from 'react';
import PropTypes from "prop-types";
import {hasUserRole} from '../../../../auth/helper.js';
import {renderChildren} from '../../../../helper/react.js';
import {AuthShape} from '../shape.js';

/**
 * Shows content only if user is of certain role
 *
 * @param {Array|string}role
 * @param {object} auth
 * @param {*} children
 * @return {null|*}
 */
const UserRoleOnly = ({role, auth, children}) => {
    // if current user is not of required role
    if (!hasUserRole(auth, role)) {
        return null;
    }

    // show children
    return renderChildren(children);
};

UserRoleOnly.propTypes = {
    'role': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]).isRequired,

    'auth': AuthShape.isRequired
};

export default UserRoleOnly;
