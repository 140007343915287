import React from 'react';
import {PageNotFoundBox} from '../common/page/notFound.jsx';


/**
 * NotFoundPage
 *
 */
let NotFoundPage = () => (
    <div id="wrapper" className="gray-bg" style={{height: '100%'}}>
        <PageNotFoundBox/>
    </div>
);

export default NotFoundPage;