/**
 * Created by br0wn on 10/25/16.
 */
import API from '../../../../config/api/routes';
import {isNotFound, isForbidden, handleErrorResponse, isBadRequest} from '../../../../lib/http';
import {jsonGetRequest, jsonPostRequest, deleteRequest} from '../../../api/request';
import {makeAuthApiRequest} from '../../../api/action';


export const fetchUsers = () => (dispatch) => dispatch(
    makeAuthApiRequest(API.USERS, jsonGetRequest())
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    return Promise.reject({requestError: response});
}));


export const fetchUser = (id) => (dispatch) => dispatch(
    makeAuthApiRequest(API.USER.replace(':id', id), jsonGetRequest())
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    if (isNotFound(response)) {
        return Promise.reject({notFound: true});
    }
    if (isForbidden(response)) {
        return Promise.reject({forbidden: true});
    }

    return Promise.reject({requestError: response});
}));


export const createUser = (user) => (dispatch) => dispatch(
    makeAuthApiRequest(API.USERS, jsonPostRequest({
        body: JSON.stringify(user)
    }))
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    // if response is not expected reject with error
    if (!isBadRequest(response)) {
        return Promise.reject({requestError: response});
    }

    // process validation errors
    return response.json().then(data => {
        return Promise.reject({validationErrors: data.errors});
    });
}));


export const updateUser = (user) => (dispatch) => dispatch(
    makeAuthApiRequest(API.USER.replace(':id', user.id), jsonPostRequest({
        body: JSON.stringify(user)
    }))
).then(
    response => response.json()
).catch(e => handleErrorResponse(e, response => {
    // if response is not expected reject with error
    if (!isBadRequest(response)) {
        return Promise.reject({requestError: response});
    }

    // process validation errors
    return response.json().then(data => {
        return Promise.reject({validationErrors: data.errors});
    });
}));


export const deleteUser = (id) => (dispatch) => dispatch(
    makeAuthApiRequest(
        API.USER_ENABLE.replace(':id', id), deleteRequest()
    )
).catch(e => handleErrorResponse(e, response => {
    if (isNotFound(response)) {
        return Promise.reject({notFound: true});
    }

    if (isForbidden(response)) {
        return Promise.reject({forbidden: true});
    }

    return Promise.reject({requestError: response});
}));
