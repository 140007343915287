import {history} from '../store/configureStore';

export const redirectTo = (uri) => {
    return () => {
        history.push(uri);
    };
};

export const redirectToWithoutDispatch = (uri)=> {
    history.push(uri);
};

export const redirectToURL = (url) => {
    return () => {
        window.location.href = url;
    };
};

export const replaceUri = (uri) => {
    return () => {
        history.replace(uri);
    };
};
