import React from 'react'
import PropTypes from "prop-types";
import StandardChat from '../standard/container.jsx';
import LoadingIndicator from '../standard/loadingIndicator.jsx'
import StatusBar from '../standard/statusBar.jsx'
import ThreadList from './threadList.jsx';
import {l} from '../../../../../i18n/translator';


class ThreadedChat extends React.Component {

    getComponents() {
        return {
            StatusBar,
            LoadingIndicator,
            StandardChat,
            ThreadList
        }
    }

    render() {
        const {threads, status, chatUser, selectedThread, threadStates} = this.props;
        const {isLoadingThreadList, isThreadListLoaded} = this.props;
        const {actions: {onThreadSelected, onMessageSubmit}} = this.props;
        const selectedThreadState = selectedThread ? threadStates[selectedThread] : null;
        const {
            StatusBar,
            LoadingIndicator,
            StandardChat,
            ThreadList
        } = this.getComponents();

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <StatusBar {...status} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <LoadingIndicator
                            isLoading={isLoadingThreadList}
                        />
                        <div className="chat-users">
                            <ThreadList
                                className="users-list"
                                threads={[...threads]}
                                selectedThread={selectedThread}
                                onThreadSelect={onThreadSelected}
                                isLoaded={isThreadListLoaded}
                                isLoading={isLoadingThreadList}
                            />
                        </div>
                    </div>
                    <div className="col-md-9">
                        {selectedThreadState ? (
                            <StandardChat
                                {...selectedThreadState}
                                chatUser={chatUser}
                                actions={{onMessageSubmit}}
                                className="thread-container"
                            />
                        ) : (
                            <p className="text-center m-lg">
                                {l('No chat selected.')}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        )
    }

}

ThreadedChat.propTypes = {
    chatUser: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        companyId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        companyType: PropTypes.string
    }).isRequired,

    status: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string
    }),

    isLoadingThreadList: PropTypes.bool.isRequired,
    isThreadListLoaded: PropTypes.bool.isRequired,

    threads: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ]).isRequired,

            name: PropTypes.string.isRequired
        })
    ).isRequired,

    selectedThread: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    threadStates: PropTypes.objectOf(
        PropTypes.shape({
            /*
             * standard chat state without actions and chat user id
             */
            ...StandardChat.propTypes,
            actions: undefined,
            chatUser: undefined
        })
    ).isRequired,

    actions: PropTypes.shape({
        'onMessageSubmit': PropTypes.func.isRequired,
        'onThreadSelected': PropTypes.func.isRequired,
    }).isRequired
};

export default ThreadedChat;
