import React from 'react';
//import classNames from 'classnames';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import URI from '../../../config/uri';
import {l} from '../../i18n/translator';
import {getUserCompany} from '../../auth/helper.js';
import AppLayout from '../layout/app-layout.jsx';
import TabsContainer from '../common/page/tabnav/TabsContainer';
import Tabs from '../common/page/tabnav/Tabs';
import Tab from '../common/page/tabnav/Tab';
import TabContent from '../common/page/tabnav/TabContent';
import TabPane from '../common/page/tabnav/TabPane';
import GeneralDataForm from './component/form/generalData.form.jsx';
import LoadedPage from '../common/page/loaded-page/component.jsx';
import PersonDataForm from '../common/form/person-data/component.jsx';
import {ContactDataFields, CorrespondenceDataFields, BillingDataFields, UiFields} from './config/formFields';
import {buildContactFormData} from './helper';
import * as PageActions from './action/page';
import * as FormActions from './action/form';
import tabs from './config/tabs';
import {hasTabErrors} from './helper';


const TabWarningLabel = () => (
    <span className="label label-danger m-l-xs">
        <i className="fa fa-warning"/>
    </span>
);


/**
 * AdministrationPage component
 */
export class AdministrationPage extends React.Component {

    componentWillMount() {
        this.props.actions.init();
    }

    render() {
        const {state, actions} = this.props;

        const {onValueChange, lookupCompany} = actions.form;
        const pageState = state.page.administration;

        const userCompany = getUserCompany(state.auth);
        const contact = userCompany ? userCompany.contact : {};

        const formData = {...pageState.form};
        const {isSaving, isSaved}  = pageState;

        if (!formData.values[UiFields.HAS_CORRESPONDENCE]) {
            formData.values = {
                ...formData.values,
                ...buildContactFormData('correspondence', {correspondence: contact})
            }
        }

        if (!formData.values[UiFields.HAS_BILLING]) {
            formData.values = {
                ...formData.values,
                ...buildContactFormData('billing', {billing: contact})
            }
        }

        return (
            <AppLayout>

                {/* Title and breadcrumbs */}
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2>{l('Administration')}</h2>

                        <ol className="breadcrumb">
                            <li>
                                <Link to={URI.DASHBOARD}>{l('Dashboard')}</Link>
                            </li>
                            <li className="active">
                                <strong>{l('Administration')}</strong>
                            </li>
                        </ol>
                    </div>
                </div>

                {/* Page content */}
                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <TabsContainer namespace="administration"
                                       defaultActive={tabs.GENERAL_DATA}>
                            <Tabs>
                                <Tab name={tabs.GENERAL_DATA}>
                                    {l('General')}

                                    {hasTabErrors(formData.errors, tabs.GENERAL_DATA) ? (
                                            <TabWarningLabel />
                                        ) : null}
                                </Tab>
                                <Tab name={tabs.CONTACT_DATA}>
                                    {l('Contact')}

                                    {hasTabErrors(formData.errors, tabs.CONTACT_DATA) ? (
                                            <TabWarningLabel />
                                        ) : null}
                                </Tab>
                                <Tab name={tabs.CORRESPONDENCE_DATA}>
                                    {l('Correspondence')}

                                    {hasTabErrors(formData.errors, tabs.CORRESPONDENCE_DATA) ? (
                                            <TabWarningLabel />
                                        ) : null}
                                </Tab>
                                <Tab name={tabs.BILLING_DATA}>
                                    {l('Billing')}

                                    {hasTabErrors(formData.errors, tabs.BILLING_DATA) ? (
                                            <TabWarningLabel />
                                        ) : null}
                                </Tab>
                            </Tabs>
                            <TabContent>
                                <TabPane name={tabs.GENERAL_DATA}>
                                    <div className="panel-body">
                                        <GeneralDataForm {...{
                                            formData,
                                            onValueChange,
                                            lookupCompany,
                                            locale: state.i18n.locale,
                                        }}/>
                                    </div>
                                </TabPane>
                                <TabPane name={tabs.CONTACT_DATA}>
                                    <div className="panel-body">
                                        <h3 className="m-b-lg">{l('Contact Address')}</h3>

                                        <PersonDataForm {...{formData, onValueChange, formFields: ContactDataFields}}/>
                                    </div>
                                </TabPane>
                                <TabPane name={tabs.CORRESPONDENCE_DATA}>
                                    <div className="panel-body">
                                        <h3 className="m-b-lg">{l('Correspondence Address')}</h3>

                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <input type="checkbox"
                                                           checked={!formData.values[UiFields.HAS_CORRESPONDENCE]}
                                                           onChange={(e) => onValueChange(UiFields.HAS_CORRESPONDENCE, !e.target.checked)}
                                                    />
                                                    &nbsp;
                                                    <strong>
                                                        {l('Same as Contact Address')}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>

                                        <PersonDataForm {...{
                                            formData,
                                            onValueChange,
                                            formFields: CorrespondenceDataFields,
                                            disabled: !formData.values[UiFields.HAS_CORRESPONDENCE]
                                        }}/>
                                    </div>
                                </TabPane>
                                <TabPane name={tabs.BILLING_DATA}>
                                    <div className="panel-body">
                                        <h3 className="m-b-lg">{l('Billing Address')}</h3>

                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <input type="checkbox"
                                                           checked={!formData.values[UiFields.HAS_BILLING]}
                                                           onChange={(e) => onValueChange(UiFields.HAS_BILLING, !e.target.checked)}
                                                    />
                                                    &nbsp;
                                                    <strong>
                                                        {l('Same as Contact Address')}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>

                                        <PersonDataForm {...{
                                            formData, onValueChange, formFields: BillingDataFields,
                                            disabled: !formData.values[UiFields.HAS_BILLING]
                                        }}/>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </TabsContainer>

                        <div className="m-t-lg text-right">
                            <a disabled={isSaving} className="btn btn-info" onClick={() => actions.save()}>
                                {l('Save')}

                                {isSaving ? (
                                        <i className="fa fa-cog m-l-md"></i>
                                    ) : null}

                                {isSaved ? (
                                        <i className="fa fa-check m-l-md"></i>
                                    ) : null}
                            </a>
                        </div>
                    </div>
                </LoadedPage>
            </AppLayout>
        )
    }
}

function mapStateToProps(state) {
    return {state};
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: () => {
                dispatch(PageActions.init());
            },
            save: function () {
                dispatch(FormActions.save());
            },
            form: {
                onValueChange: (field, value) => {
                    dispatch(FormActions.onValueChange(field, value))
                },
                lookupCompany: (...args) => dispatch(FormActions.lookupCompany(...args))
            }
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministrationPage);
