import {TYPES as ACTION_TYPES} from './action';

const INITIAL_STATE = {
    form: {
        errors: {}
    },
    isFetching: false
};

/**
 * Main App reducer
 *
 * @param state
 * @param action
 *
 * @return {object}
 */
let loginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_MOBILE_TOKEN_REQUIRED: {
            return {
                ...state,
                form: {
                    ...state.form,
                    mobileTokenRequired: true
                },
                isFetching: true
            };
        }
        case ACTION_TYPES.LOGIN_SUCCESS: {
            return {
                ...INITIAL_STATE
            };
        }
    }

    return state;
};

export default loginReducer;
