export default {
    DASHBOARD: '/',
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    REGISTER: '/auth/register',
    VERIFY_USER: '/auth/verify',

    CREATE_OFFER: '/offer/create/:offerRequestId',
    EDIT_OFFER: '/offer/edit/:id',
    OFFERS_HISTORY: '/offers/history',
    OFFICIAL_OFFER_FILE: '/offer/:id/files/official-offer',

    WIZARD_STEP: '/:stepId',
    CREATE_OFFER_REQUEST: '/offer-request/create',
    CREATE_OFFER_REQUEST_FINISHED: '/offer-request/create-finished',
    CREATE_OFFER_REQUEST_FINISHED_EDIT: '/offer-request/create-finished/:id',
    EDIT_OFFER_REQUEST: '/offer-request/edit/:id',
    OFFER_REQUEST_LIST: '/offer-request/list',

    OFFER_REQUEST_DETAILS: '/offer-request/:id',
    OFFER_REQUEST_FILE: '/offer-request/:id/files/:fileId',
    OFFER_REQUEST_FILES_ADVISORY_MANDATE: '/offer-request/:id/files/advisory-mandate',
    OFFER_REQUEST_FILES_ADVISORY_MANDATE_TEMPLATE: '/offer-request/files/advisory-mandate-template',

    OFFER_DETAILS: '/offer-request/:offerRequestId/offer/:id',

    OFFER_REQUEST_INSURANCE_FILES: '/offer-request/:id/insurance/files',
    OFFER_REQUEST_INSURANCE_FILE: '/offer-request/:id/insurance/files/:fileId',
    OFFER_REQUEST_INSURANCE_ADVISORY_MANDATE: '/offer-request/:id/insurance/files/advisory-mandate',

    UPDATE_OFFER_REQUEST_DATA: '/offer-request/:id/update-data',

    FILE: '/files/:id',

    CUSTOMERS: '/customers',
    CUSTOMERS_CREATE: '/customers/create',
    CUSTOMERS_EDIT: '/customers/edit/:id',
    CUSTOMERS_USERS: '/customers/:id/users',

    ANALYSIS: '/analysis',
    ANALYSIS_AUTO_RATINGS: '/analysis/auto-ratings',
    ANALYSIS_AUTO_RATING_EDIT: '/analysis/auto-ratings/:id',
    ANALYSIS_AUTO_RATING_CREATE: '/analysis/auto-ratings/create',
    ANALYSIS_CRITERIA: '/analysis/criteria',
    ANALYSIS_CRITERIA_EDIT: '/analysis/criteria/:id',
    ANALYSIS_CRITERIA_CREATE: '/analysis/criteria/create',

    PROFILE: '/profile',

    ADMINISTRATION: '/administration',

    USER_LIST: '/users',
    USER_EDIT: '/users/:id',
    USER_CREATE: '/users/create',

    ACCESS_FORBIDDEN: '/403',
    NOT_FOUND: '/404'
};
