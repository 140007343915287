/**
 * Created by br0wn on 10/26/16.
 */
import {l} from '../../../i18n/translator';
import {warn} from '../../../../lib/logger';
import loadedPageReducer, {PAGE_INITIAL_STATE} from '../../common/page/loaded-page/reducer';
import {TYPES, PAGE_ACTIONS} from '../action/list';


export const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    data: {
        userList: []
    },
    deleting: []
};


const userListPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPES.DELETE_USER_START: {
            return {
                ...state,
                deleting: [...state.deleting, action.id]
            };
        }
        case TYPES.DELETE_USER_END: {
            let items = [...(state.data.userList || [])];
            let deleting = [...state.deleting];

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            } else {
                let item = items.find(item => item.id == action.id);
                let itemIndex = items.indexOf(item);

                if (itemIndex !== -1) {
                    items = [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)];
                }
            }

            let deletingIndex = deleting.indexOf(action.id);
            deleting = [...deleting.slice(0, deletingIndex), ...deleting.slice(deletingIndex + 1)];

            return {
                ...state,
                deleting: deleting,
                data: {
                    ...state.data,
                    userList: items
                }
            };
        }
    }

    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS)
    };
};

export default userListPageReducer;
