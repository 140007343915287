import React from 'react';
import PropTypes from "prop-types";
import {Col, Row} from 'react-bootstrap';
import {Field as RfField} from 'redux-form';
import {l} from '../../../../i18n/translator';
import {renderTextField} from '../../../common/form/renderer.jsx';

const PersonDataForm = ({formFields, fieldProps, fieldComponent: Field = RfField}) => {

    const newFieldProps = fieldProps || {};
    if (!newFieldProps.component) {
        newFieldProps.component = renderTextField;
    }

    return (
        <div>
            <Row>
                <Col md={6}>
                    <Field name={formFields.FIRST_NAME} {...newFieldProps} label={l('First Name')}/>
                    <Field name={formFields.LAST_NAME} {...newFieldProps} label={l('Last Name')}/>
                    <Field name={formFields.FUNCTION} {...newFieldProps} label={l('Function')}/>
                    <Field name={formFields.TELEPHONE} {...newFieldProps} label={l('Telephone')}/>
                    <Field name={formFields.MOBILE} {...newFieldProps} label={l('Mobile')}/>
                    <Field name={formFields.EMAIL} {...newFieldProps} label={l('E-mail')}/>
                </Col>
                <Col md={6}>
                    <Field name={formFields.ADDRESS_1} {...newFieldProps} label={l('Address line 1')}/>
                    <Field name={formFields.ADDRESS_2} {...newFieldProps} label={l('Address line 2')}/>
                    <Field name={formFields.ZIP} {...newFieldProps} label={l('Zip Code')}/>
                    <Field name={formFields.TOWN} {...newFieldProps} label={l('City')}/>
                    <Field name={formFields.COUNTRY} {...newFieldProps} label={l('Country')}/>
                </Col>
            </Row>
        </div>
    );
};

PersonDataForm.propTypes = {
    formFields: PropTypes.object,
    fieldProps: PropTypes.object
};

export default PersonDataForm;
