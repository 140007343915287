import swal from 'sweetalert'; // eslint-disable-line
import {l} from '../../../../i18n/translator';
import TYPES from './type';
import {
    deleteOfferRequest as apiDeleteOfferRequest,
    fetchOfferRequestList as apiFetchOfferRequestList
} from '../../../../api/sdk-action';

export const filterList = (filters) => {
    return (dispatch) => {
        dispatch({
            type: TYPES.FILTER_LIST,
            filters: filters
        });

        dispatch(updateList());
    };
};

export const setPage = (page) => {
    return (dispatch) => {
        dispatch({
            type: TYPES.SET_PAGE,
            page: page
        });

        dispatch(updateList());
    };
};

export const updateList = () => {
    return (dispatch, getState) => {
        let {offerRequests: {page}, filters} = getState().page.offerRequestList;

        let filterBy = null;
        let filterValue = null;

        if (filters.length) {
            filterBy = 'state';
            filterValue = filters.join(',');
        }

        dispatch({
            type: TYPES.LIST_LOAD_START
        });

        return dispatch(
            apiFetchOfferRequestList(page, filterBy, filterValue, 9999)
        ).then(
            offerRequests => dispatch({
                type: TYPES.LIST_LOAD_END,
                offerRequests
            })
        ).catch(
            error => dispatch({
                type: TYPES.LIST_LOAD_END,
                error
            })
        );
    };
};

export const listLoadEnd = (offerRequests) => {
    return {
        type: TYPES.LIST_LOAD_END,
        offerRequests
    };
};

export const deleteOfferRequest = (offerRequestId) => {
    return (dispatch) => {
        swal({
            title: l('Are you sure?'),
            type: 'warning',
            showCancelButton: true
        }, () => {
            dispatch({
                type: TYPES.DELETE_OFFER_REQUEST_START,
                id: offerRequestId
            });

            return dispatch(
                apiDeleteOfferRequest(offerRequestId)
            ).then(
                () => dispatch({
                    type: TYPES.DELETE_OFFER_REQUEST_END,
                    id: offerRequestId
                })
            ).catch(
                error => dispatch({
                    type: TYPES.DELETE_OFFER_REQUEST_END,
                    id: offerRequestId,
                    error
                })
            );
        });
    };
};