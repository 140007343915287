import {redirectTo} from '../router/action';
import URI from '../../config/uri';
import API from '../../config/api/routes';
import {log} from '../../lib/logger';
import {handleErrorResponse} from '../../lib/http';
import {jsonGetRequest} from '../api/request';
import {makeAuthApiRequest} from '../api/action';
import {getUser} from './helper';

export const TYPES = {
    USER_AUTHENTICATED: 'auth.user_authenticated',
    USER_LOGGED_OUT: 'auth.user_logged_out',
    USER_DATA_LOAD_START: 'auth.user_data.load_start',
    USER_COMPANY_LOAD_SUCCESS: 'auth.user_data.load_success',
    USER_DATA_LOAD_FAIL: 'auth.user_data.load_fail'
};

export let userAuthenticated = (user) => {
    return (dispatch) => {
        // update storage
        localStorage.setItem('auth.user', JSON.stringify(user));

        // update state
        dispatch({type: TYPES.USER_AUTHENTICATED, user: user});

        // load user data
        dispatch(loadAuthenticatedUserData());
    };
};

export let doLogout = () => {
    return (dispatch) => {
        // update storage
        localStorage.removeItem('auth.user');

        // update state
        dispatch({type: TYPES.USER_LOGGED_OUT});

        // redirect
        dispatch(redirectTo(URI.LOGIN));
    };
};

export let sessionExpired = () => {
    return (dispatch) => {
        // alert
        alert("Your session has expired! You will be logged out.");

        // do logout
        dispatch(doLogout());
    };
};

export let loadAuthenticatedUserData = () => {
    return (dispatch) => {
        // update state for load start
        dispatch({
            type: TYPES.USER_DATA_LOAD_START
        });

        // load user data
        dispatch(makeAuthApiRequest(
            API.COMPANY, jsonGetRequest()
        )).then(response => response.json().then(
            // update state for data loaded
            userData => dispatch(userCompanyLoaded(userData))
        )).catch(e => handleErrorResponse(e, response => {
            // log response
            log(response);

            // dispatch fail action
            dispatch({
                type: TYPES.USER_DATA_LOAD_FAIL
            });
        })).catch(error => {
            // log response
            log(error);

            // dispatch fail action
            dispatch({
                type: TYPES.USER_DATA_LOAD_FAIL
            });
        });
    };
};

export let userCompanyLoaded = (company) => {
    return (dispatch, getState) => {
        // update state
        dispatch({
            type: TYPES.USER_COMPANY_LOAD_SUCCESS,
            company
        });

        // after dispatch, user should've been updated
        const user = getUser(getState().auth);

        // persist user data
        localStorage.setItem('auth.user', JSON.stringify(user));
    };
};

export let loadUserFromStorage = () => {
    return (dispatch) => {
        let user = localStorage.getItem('auth.user') || null;

        try {
            user = JSON.parse(user);
        } catch (e) {
            user = null;
        }

        if (null === user) {
            // nothing
        } else {
            dispatch(userAuthenticated(user));
        }
    };
};
