/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {l} from '../../../i18n/translator.js';
import Spinner from '../../common/misc/spiner.jsx';
import * as Ibox from '../../ui-components/ibox.jsx';


export class BaseWidget extends React.Component {
    constructor(props) {
        super(props)
    }

    getType() {
        throw Error("Implement in child class!");
    }

    getTitle() {
        return '';
    }

    renderContent() {
        throw Error("Implement in child class!");
    }


    isInitialized() {
        return this.props.isInitialized;
    }

    isInitializing() {
        return this.props.isInitializing;
    }

    isWorking() {
        return this.props.isWorking;
    }

    hasInitError() {
        return !!this.props.initError;
    }

    hasError() {
        return !!this.props.error;
    }

    renderTitle() {
        return (
            <Ibox.Title>
                <h5>{this.getTitle()}</h5>

                {this.renderErrorNotification()}
                {this.renderWorkingNotification()}
            </Ibox.Title>
        )
    }

    renderWorkingNotification() {
        if (!this.isWorking()) {
            return null;
        }

        return (
            <div className="pull-right">
                <i className="fa fa-cog"/>
            </div>
        )
    }

    renderErrorNotification() {
        let error;

        if (!this.hasInitError() && !this.hasError()) {
            return null;
        }

        if (this.hasInitError()) {
            error = l('An error occurred while loading widget.');
        } else {
            error = l('An error occurred while widget was working.');
        }

        const tooltip = (
            <Tooltip id={`widget-${this.getType()}-error`}>
                {error}
            </Tooltip>
        );

        return (
            <div className="pull-right">
                <OverlayTrigger overlay={tooltip} placement="bottom">
                    <i className="fa fa-warning"/>
                </OverlayTrigger>
            </div>
        )
    }

    renderInitErrorContent() {
        return null;
    }

    renderLoader() {
        return (
            <div className="p-md">
                <Spinner/>
            </div>
        )
    }

    renderLoadedContent() {
        if (this.isInitializing()) {
            return this.renderLoader();
        }

        if (this.hasInitError()) {
            return this.renderInitErrorContent();
        }

        return this.renderContent();
    }

    render() {
        return (
            <Ibox.Container className={this.props.className}>
                {this.renderTitle()}

                <Ibox.Content className="no-padding">
                    {this.renderLoadedContent()}
                </Ibox.Content>

            </Ibox.Container>
        )
    }
}
