import {TYPES as ACTION_TYPES} from './actions';


export default function tabsContainerState(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.TABS_CONTAINER_SELECT_TAB: {
      const newState = {...state};
      newState[action.namespace] = {activeTab : action.tab};
      return newState;
    }

    case ACTION_TYPES.TABS_CONTAINER_DESTROY:
      if(!state[action.namespace]) {
        return state;
      } else {
        const newState = {...state};
        delete newState[action.namespace];
        return newState;
      }

    default:
      return state;
  }
}
