/**
 * Created by br0wn on 10/25/16.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import {FormGroup, ControlLabel, FormControl, HelpBlock, Button, Col} from 'react-bootstrap';
import {Fields as UserFields, Roles as UserRoles} from '../../../../config/domain/user.js';
import {Types as InsuranceTypes} from '../../../../config/domain/insurance.js';
import {l} from '../../../i18n/translator.js';
import {FormField} from '../../common/form/field.jsx';
import {FormComponentShape} from '../../common/form/shape.js';


export const UserForm = ({isEdit = null, formData, onSubmit, onValueChange, ...props}) => {
    const isEditMode = isEdit;
    const {isSubmitting} = formData;

    return (
        <form
            {...props}
            className={props.className}
            onSubmit={onSubmit}
        >
            <div className="form">
                <FormField field={UserFields.USERNAME} {...{formData, onValueChange}}>
                    {({field, value, error, onChange}) => (
                        <FormGroup
                            controlId={field}
                            validationState={error ? 'error' : null}
                        >
                            <ControlLabel>
                                {l('Username')}
                            </ControlLabel>

                            <FormControl
                                disabled={isEditMode}
                                type="text"
                                value={value || ''}
                                onChange={(event) => onChange(event.target.value)}
                            />
                            <FormControl.Feedback />

                            {error ? (
                                <HelpBlock>{error}</HelpBlock>
                            ) : null}
                        </FormGroup>
                    )}
                </FormField>

                <FormField field={UserFields.EMAIL} {...{formData, onValueChange}}>
                    {({field, value, error, onChange}) => (
                        <FormGroup
                            controlId={field}
                            validationState={error ? 'error' : null}
                        >
                            <ControlLabel>
                                {l('Email')}
                            </ControlLabel>

                            <FormControl
                                disabled={isEditMode}
                                type="email"
                                value={value || ''}
                                onChange={(event) => onChange(event.target.value)}
                            />
                            <FormControl.Feedback />

                            {error ? (
                                <HelpBlock>{error}</HelpBlock>
                            ) : null}
                        </FormGroup>
                    )}
                </FormField>

                {!isEditMode ? (
                    <FormField field={UserFields.PASSWORD} {...{formData, onValueChange}}>
                        {({field, value, error, onChange}) => (
                            <FormGroup
                                controlId={field}
                                validationState={error ? 'error' : null}
                            >
                                <ControlLabel>
                                    {l('Password')}
                                </ControlLabel>

                                <FormControl
                                    type="password"
                                    value={value || ''}
                                    onChange={(event) => onChange(event.target.value)}
                                />
                                <FormControl.Feedback />

                                {error ? (
                                    <HelpBlock>{error}</HelpBlock>
                                ) : null}
                            </FormGroup>
                        )}
                    </FormField>
                ) : null}

                <FormField field={UserFields.ROLE} {...{formData, onValueChange}}>
                    {({field, value, error, onChange}) => (
                        <FormGroup
                            controlId={field}
                            validationState={error ? 'error' : null}
                        >
                            <ControlLabel>
                                {l('Role')}
                            </ControlLabel>

                            <FormControl
                                disabled={isEditMode}
                                componentClass="select"
                                value={value || ''}
                                onChange={(event) => onChange(event.target.value)}
                            >
                                <option value="">{l('Please select')}</option>

                                {Object.keys(UserRoles).map(key => (
                                    <option key={key} value={UserRoles[key]}>{UserRoles[key]}</option>
                                ))}
                            </FormControl>

                            <FormControl.Feedback />

                            {error ? (
                                <HelpBlock>{error}</HelpBlock>
                            ) : null}
                        </FormGroup>
                    )}
                </FormField>
            </div>

            <div className={classNames('m-t-lg form-horizontal', {
                hidden: formData.values[UserFields.ROLE] == UserRoles.ADMIN
            })}>
                <div className="widget gray-bg">
                    <h4 className="m-b-md">{l('Insurance type permissions')}</h4>

                    {Object.keys(InsuranceTypes).map(key => {
                        const insuranceType = InsuranceTypes[key];
                        const field = `${UserFields.PERMISSIONS}.insuranceTypes.${insuranceType}`;

                        return (
                            <FormField key={key} field={field} {...{formData, onValueChange}}>
                                {({field, value, error, onChange}) => (
                                    <FormGroup
                                        controlId={field}
                                        validationState={error ? 'error' : null}
                                    >
                                        <Col md={2} componentClass={ControlLabel}>
                                            {l(key)}
                                        </Col>

                                        <Col md={10}>
                                            <FormControl
                                                componentClass="select"
                                                value={value || ''}
                                                onChange={(event) => onChange(event.target.value)}
                                            >
                                                <option value="">{l('None')}</option>
                                                <option value="READ">{l('Read')}</option>
                                                <option value="WRITE">{l('Write')}</option>
                                            </FormControl>

                                            <FormControl.Feedback />

                                            {error ? (
                                                <HelpBlock>{error}</HelpBlock>
                                            ) : null}
                                        </Col>
                                    </FormGroup>
                                )}
                            </FormField>
                        )
                    })}
                </div>
            </div>


            <div style={{height: '30px'}}></div>

            <Button bsStyle="primary" type="submit" disabled={isSubmitting}>
                Submit <i className={classNames('fa fa-cog', {hidden: !isSubmitting})}></i>
            </Button>
        </form>
    )
};

UserForm.propTypes = {
    ...FormComponentShape,
    onSubmit: PropTypes.func.isRequired,
    user: PropTypes.object
};
