import React from 'react';
import PropTypes from "prop-types";

const Tabs = (props) => {
  const {children, ...childProps} = props;
  const tabChildren = React.Children.map(children,
    (child) => React.cloneElement(child, {...childProps})
  );
  return (
    <ul className="nav nav-tabs">
      {tabChildren}
    </ul>
  );
};

Tabs.componentName = "Tabs";

Tabs.propTypes = {
  componentName: PropTypes.oneOf([Tabs.componentName]),
  children: PropTypes.node.isRequired,
  isActive: PropTypes.func,
  onSelect: PropTypes.func
};

Tabs.defaultProps = {
  componentName: Tabs.componentName
};

export default Tabs;
