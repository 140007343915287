/**
 * Created by br0wn on 10/25/16.
 */
import React from 'react';
import PropTypes from "prop-types";
import {objectProp, objectPropSet, objectPropUnset, isUndefined} from '../../../helper/core.js';


export const FormField = ({field, formData, onValueChange, initialValue, children}) => {
    const {values, errors} = formData;
    const boundOnValueChange = (value) => onValueChange(field, value);

    return children && children.call ? children({
            field: field,
            value: fieldValue(field, values) || initialValue,
            error: fieldValue(field, errors),
            onChange: boundOnValueChange
        }) : null;
};

FormField.propTypes = {
    field: PropTypes.string.isRequired,
    formData: PropTypes.shape({
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
    }).isRequired,
    onValueChange: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
};


export const FormArrayField = ({field, formData, onValueChange, children}) => {
    const arrayValue = fieldValue(field, formData.values) || [];

    const items = arrayValue.map((item, index) => `${field}[${index}]`);

    const add = (item) => onValueChange(field, [...arrayValue, item]);

    const remove = (index) => {
        if (isUndefined(arrayValue[index])) {
            return;
        }

        onValueChange(field, [
            ...arrayValue.slice(0, index),
            ...arrayValue.slice(index + 1)
        ]);
    };

    const value = (index) => arrayValue[index];

    return children && children.call ? children({items, add, remove, value}) : null;
};

FormArrayField.propTypes = {
    field: PropTypes.string.isRequired,
    formData: PropTypes.shape({
        values: PropTypes.object.isRequired
    }).isRequired,
    onValueChange: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
};


export const dataFieldKey = (fieldKey) => `data.${fieldKey}`;

export const fieldValue = (field, values) => objectProp(field, values);

export const fieldValueSet = (field, value, values) => objectPropSet(field, value, values);

export const fieldValueUnset = (field, values) => objectPropUnset(field, values);
