import {Fields as PersonFields} from '../../../../../../config/domain/person';

export const prefixedPersonDataFormFields = (prefix) => {
    return {
        FIRST_NAME: `${prefix}${PersonFields.FIRST_NAME}`,
        LAST_NAME: `${prefix}${PersonFields.LAST_NAME}`,
        FUNCTION: `${prefix}${PersonFields.FUNCTION}`,
        ADDRESS_1: `${prefix}${PersonFields.ADDRESS_LINE1}`,
        ADDRESS_2: `${prefix}${PersonFields.ADDRESS_LINE2}`,
        ZIP: `${prefix}${PersonFields.ZIP}`,
        TOWN: `${prefix}${PersonFields.TOWN}`,
        COUNTRY: `${prefix}${PersonFields.COUNTRY}`,
        TELEPHONE: `${prefix}${PersonFields.TELEPHONE}`,
        MOBILE: `${prefix}${PersonFields.MOBILE}`,
        EMAIL: `${prefix}${PersonFields.EMAIL}`
    };
};
