
const TYPES = {
    DATA_LOADED: 'page.offer_request_list.data_loaded',

    PAGE_LOAD_START: 'page.offer_request_list.load_start',
    PAGE_LOAD_END: 'page.offer_request_list.load_end',
    PAGE_RESET: 'page.offer_request_list.reset',

    FILTER_LIST: 'page.offer_request_list.filter_list',

    LIST_LOAD_START: 'page.offer_request_list.list_load_start',
    LIST_LOAD_END: 'page.offer_request_list.list_load_end',

    DELETE_OFFER_REQUEST_START: 'page.offer_request_list.offer_request.delete_start',
    DELETE_OFFER_REQUEST_END: 'page.offer_request_list.offer_request.delete_end',

    WITHDRAW_OFFER_REQUEST_START: 'page.offer_request_list.offer_request.withdraw_start',
    WITHDRAW_OFFER_REQUEST_END: 'page.offer_request_list.offer_request.withdraw_end',

    SET_PAGE: 'page.offer_request_list.set_page'
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    DATA_LOADED: TYPES.DATA_LOADED,
    PAGE_RESET: TYPES.PAGE_RESET
};

export default TYPES;
