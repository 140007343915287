import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import {Button, HelpBlock} from 'react-bootstrap';
import {l} from '../../../../i18n/translator';
import {GeneralDataFields} from '../../config/formFields';

/**
 * BasicDataForm
 *
 * @param {{values: object, errors: object}} formData
 * @param {function} onValueChange
 * @param {function} lookupCompany
 */
const GeneralDataForm = ({formData, locale, onValueChange, lookupCompany}) => {
    return (
        <div className="basic-data">
            <div className="form-horizontal">
                <div className={classNames('form-group', {'has-error': formData.errors[GeneralDataFields.BUSINESS_IDENTIFICATION_NUMBER]})}>
                    <label className="col-md-2 control-label">
                        {l('Business Identification Number')}
                    </label>
                    <div className="col-md-4">
                        <input type="text" className="form-control"
                               value={formData.values[GeneralDataFields.BUSINESS_IDENTIFICATION_NUMBER] || ''}
                               onChange={(e) => onValueChange(GeneralDataFields.BUSINESS_IDENTIFICATION_NUMBER, e.target.value)}
                        />
                        <div className="help-block m-b-none">
                            {formData.errors[GeneralDataFields.BUSINESS_IDENTIFICATION_NUMBER]}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <Button
                            bsStyle="primary"
                            onClick={() => lookupCompany(formData.values[GeneralDataFields.BUSINESS_IDENTIFICATION_NUMBER])}
                            disabled={!formData.values[GeneralDataFields.BUSINESS_IDENTIFICATION_NUMBER]}
                        >
                            {l('Lookup')}
                        </Button>
                    </div>
                </div>

                <div className="hr-line-dashed"/>

                <div className={classNames('form-group', {'has-error': formData.errors[GeneralDataFields.NAME]})}>
                    <label className="col-md-2 control-label">
                        {l('Company name')}
                    </label>
                    <div className="col-md-4">
                        <input type="text" className="form-control" value={formData.values[GeneralDataFields.NAME] || ''}
                               onChange={(e) => onValueChange(GeneralDataFields.NAME, e.target.value)}/>
                        <div className="help-block m-b-none">
                            {formData.errors[GeneralDataFields.NAME]}
                        </div>
                    </div>
                </div>

                <div className="hr-line-dashed"/>

                <div
                    className={classNames('form-group', {'has-error': formData.errors[GeneralDataFields.INDUSTRY_CLASSIFICATION_CODE]})}>
                    <label className="col-md-2 control-label">
                        {l('Industry Classification Code (NOGA)')}
                    </label>
                    <div className="col-md-4">
                        <input type="text" className="form-control"
                               value={formData.values[GeneralDataFields.INDUSTRY_CLASSIFICATION_CODE] || ''}
                               onChange={(e) => onValueChange(GeneralDataFields.INDUSTRY_CLASSIFICATION_CODE, e.target.value)}/>
                        <HelpBlock>
                            <a href={`https://www.kubb-tool.bfs.admin.ch/${locale.substr(0, 2)}`} target="_blank">
                                {l('Find your NOGA code')}
                            </a>
                        </HelpBlock>
                        <div className="help-block m-b-none">
                            {formData.errors[GeneralDataFields.INDUSTRY_CLASSIFICATION_CODE]}
                        </div>
                    </div>
                </div>

                <div className={classNames('form-group', {'has-error': formData.errors[GeneralDataFields.AREA_OF_OPERATIONS]})}>
                    <label className="col-md-2 control-label">
                        {l('Area of Operations')}
                    </label>
                    <div className="col-md-4">
                        <input type="text" className="form-control" value={formData.values[GeneralDataFields.AREA_OF_OPERATIONS] || ''}
                               onChange={(e) => onValueChange(GeneralDataFields.AREA_OF_OPERATIONS, e.target.value)}/>
                        <div className="help-block m-b-none">
                            {formData.errors[GeneralDataFields.AREA_OF_OPERATIONS]}
                        </div>
                    </div>
                </div>

                <div className="hr-line-dashed"/>

                <div className={classNames('form-group', {'has-error': formData.errors[GeneralDataFields.EMPLOYEES]})}>
                    <label className="col-md-2 control-label">
                        {l('Number of Employees')}
                    </label>
                    <div className="col-md-4">
                        <input type="number" className="form-control" value={formData.values[GeneralDataFields.EMPLOYEES] || ''}
                               onChange={(e) => onValueChange(GeneralDataFields.EMPLOYEES, e.target.value)}/>
                        <div className="help-block m-b-none">
                            {formData.errors[GeneralDataFields.EMPLOYEES]}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

GeneralDataForm.propTypes = {
    formData: PropTypes.shape({
        values: PropTypes.object,
        errors: PropTypes.object
    }).isRequired,
    onValueChange: PropTypes.func.isRequired
};

export default GeneralDataForm;
