/**
 * Created by br0wn on 10/30/16.
 */
import {Types} from './action';


export const INITIAL_STATE = {};

export const DEFAULT_LOADER_STATE = {
    isLoading: false,
    isLoaded: false,
    progress: null
};


export const getLoaderState = (state, loaderName) => state[loaderName] || DEFAULT_LOADER_STATE;


const loaderStateReducer = (state = INITIAL_STATE, action) => {
    const {loaderName} = action;

    if (!loaderName) {
        return state;
    }

    switch (action.type) {
        case Types.LOAD_START: {
            return {
                ...state,
                [loaderName]: {
                    ...getLoaderState(state, loaderName),
                    isLoading: true
                }
            };
        }
        case Types.LOAD_END: {
            return {
                ...state,
                [loaderName]: {
                    ...getLoaderState(state, loaderName),
                    isLoading: false,
                    isLoaded: action.isLoaded
                }
            };
        }
        case Types.LOAD_PROGRESS: {
            return {
                ...state,
                [loaderName]: {
                    ...getLoaderState(state, loaderName),
                    progress: action.progress
                }
            };
        }
        case Types.LOAD_CLEAR: {
            let newState = {...state};
            delete newState[loaderName];

            return newState;
        }
    }

    return state;
};

export default loaderStateReducer;
