import DEFAULT_TYPES from '../action/type';

export const INITIAL_STATE = {
    isLoading: false,
    isLoaded: false,
    messages: [],
    isSendingMessage: false,
    isMessageSent: false,
    status: null
};


const updateMessages = (messages = [], message) => {
    const index = messages.findIndex(t => t.id == message.id);

    if (index === -1) {
        return [...messages, message];
    }

    return [
        ...messages.slice(0, index),
        {...messages[index], ...message},
        ...messages.slice(index + 1)
    ];
};


const standardChatReducer = (state = INITIAL_STATE, action, ACTION_TYPES = DEFAULT_TYPES) => {
    switch (action.type) {
        case ACTION_TYPES.THREAD_MESSAGES_LOAD_START: {
            return {
                ...state,
                isLoading: true
            };
        }
        case ACTION_TYPES.THREAD_MESSAGES_LOAD_END: {
            let messages = [...state.messages];

            if (action.error) {
                // handle error
            } else {
                messages = action.messages;
            }

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                messages
            };
        }
        case ACTION_TYPES.MESSAGE_SUBMIT_START: {
            return {
                ...state,
                isSendingMessage: true,
                isMessageSent: false
            };
        }
        case ACTION_TYPES.MESSAGE_SUBMIT_END: {
            let messages = state.messages;

            if (action.error) {
                // handle error
            } else {
                messages = updateMessages(state.messages, action.message);
            }

            return {
                ...state,
                isSendingMessage: false,
                isMessageSent: true,
                messages
            };
        }
        case ACTION_TYPES.CLEAR_MESSAGE_SENT: {
            return {
                ...state,
                isMessageSent: false
            };
        }
        case ACTION_TYPES.MESSAGE_RECEIVED: {
            return {
                ...state,
                messages: updateMessages(state.messages, action.message)
            };
        }
        default: {
            return state;
        }
    }
};

export default standardChatReducer;