// import {TYPES as ACTION_TYPES} from './action';

const INITIAL_STATE = {
};

/**
 * Main App reducer
 *
 * @param state
 * @param action
 *
 * @return {object}
 */
let forgotPasswordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default: return state;
    }
};

export default forgotPasswordReducer;
