
import API from '../../../../../config/api/routes';
import {isBadRequest, isNotFound, isForbidden, handleErrorResponse} from '../../../../../lib/http';
import {jsonGetRequest, jsonPostRequest} from '../../../../api/request';
import {makeAuthApiRequest} from '../../../../api/action';


export const fetchOfferRequestMessagesOverview = (offerRequestId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST_MESSAGE_THREADS.replace(':id', offerRequestId), jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        if (isNotFound(response)) {
            return Promise.reject({notFound: true});
        }
        if (isForbidden(response)) {
            return Promise.reject({forbidden: true});
        }

        return Promise.reject({requestError: response});
    }));
};


export const fetchOfferRequestMessages = (offerRequestId, threadId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_REQUEST_THREAD_MESSAGES.replace(':id', offerRequestId).replace(':threadId', threadId),
            jsonGetRequest()
        )
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        if (isNotFound(response)) {
            return Promise.reject({notFound: true});
        }
        if (isForbidden(response)) {
            return Promise.reject({forbidden: true});
        }

        return Promise.reject({requestError: response});
    }));
};


export const postOfferRequestMessage = (offerRequestId, threadId, message) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_REQUEST_THREAD_MESSAGES.replace(':id', offerRequestId).replace(':threadId', threadId),
            jsonPostRequest({
                body: JSON.stringify(message)
            })
        )
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        if (isNotFound(response)) {
            return Promise.reject({notFound: true});
        }
        if (isForbidden(response)) {
            return Promise.reject({forbidden: true});
        }

        // process validation errors
        if (isBadRequest(response)) {
            return response.json().then(data => {
                return Promise.reject({validationErrors: data.errors});
            });
        }

        return Promise.reject({requestError: response});
    }));
};

