import React from 'react';
import PropTypes from "prop-types";
import {l} from '../../../../i18n/translator';
import classNames from 'classnames';
import {States as OfferRequestStates} from '../../../../../config/domain/offerRequest';
import {isInStateAbstained} from '../../../../../app/model/offerRequest';
import {AuthShape} from '../../../common/auth/shape';
import {LocalDate} from '../../../common/i18n/datetime.jsx';
import CustomerOnly from '../../../common/auth/acl/customerOnly.jsx';

export const StatusLabel = ({offerRequest, icon = '', ...props}) => (
    <span {...props} className={classNames("label", {
        'label-primary': offerRequest.state === OfferRequestStates.PUBLISHED,
        'label-danger': offerRequest.state === OfferRequestStates.EXPIRED || offerRequest.state === OfferRequestStates.WITHDRAWN,
        'label-warning': offerRequest.state === OfferRequestStates.CREATED || offerRequest.abstained
    }, props.className)}>

        {icon ? (
            <span>{icon}&nbsp;</span>
        ) : null}

        {isInStateAbstained(offerRequest) ?
            l('state_abstained') :
            offerRequest.rank ?
                l(`state_${offerRequest.state}`) + ': ' + l('Rank %num%').replace('%num%', offerRequest.rank) :
                l(`state_${offerRequest.state}`)
        }
    </span>
);

StatusLabel.propTypes = {
    offerRequest: PropTypes.shape({
        state: PropTypes.string
    }).isRequired
};


export const CreationDate = ({offerRequest}) => (
    <LocalDate value={offerRequest.creationDate}/>
);

CreationDate.propTypes = {
    offerRequest: PropTypes.shape({
        creationDate: PropTypes.string
    }).isRequired
};


export const PublishDate = ({offerRequest}) => (
    <LocalDate value={offerRequest.publishDate}/>
);

PublishDate.propTypes = {
    offerRequest: PropTypes.shape({
        publishDate: PropTypes.string
    }).isRequired
};


export const OfferRequestStatus = ({offerRequest, auth}) => (
    <div className="form-horizontal">

        <CustomerOnly auth={auth}>
            <div className="form-group m-b-none">
                <label className="control-label col-sm-4">
                    {l('Created on')}:
                </label>
                <div className="col-sm-8">
                    <p className="form-control-static" style={{fontSize: '16px'}}>
                        <CreationDate offerRequest={offerRequest}/>
                    </p>
                </div>
            </div>
        </CustomerOnly>

        {offerRequest.publishDate ? (
            <div className="form-group m-b-none">
                <label className="control-label col-sm-4">
                    {l('Published on')}:
                </label>
                <div className="col-sm-8">
                    <p className="form-control-static" style={{fontSize: '16px'}}>
                        <PublishDate offerRequest={offerRequest}/>
                    </p>
                </div>
            </div>
        ) : null}

        <div className="form-group">
            <label className="control-label col-sm-4">
                {l('Status')}:
            </label>
            <div className="col-sm-8">
                <p className="form-control-static" style={{fontSize: '12px'}}>
                    <StatusLabel offerRequest={offerRequest}/>
                </p>
            </div>
        </div>
    </div>
);

OfferRequestStatus.propTypes = {
    offerRequest: PropTypes.object.isRequired,
    auth: AuthShape.isRequired
};
