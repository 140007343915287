export const mapUserToFormValues = (user) => {
    let newUser = {...user};
    if (!newUser.permissions) {
        newUser.permissions = {
            insuranceTypes: {}
        };
    }
    if (!newUser.permissions.insuranceTypes) {
        newUser.permissions.insuranceTypes = {};
    }
    return newUser;
};

export const mapFormValuesToUser = (formValues) => {
    const user = {...formValues};
    if (user.permissions && user.permissions.insuranceTypes) {
        user.permissions = {...formValues.permissions};
    }

    // in the form this is a helper form field for adding insurance type permissions
    user.insuranceType = undefined;
    return user;
};
