import API from '../../config/api/routes';
import {AppEventSource} from '../../lib/eventsource';
import {error} from '../../lib/logger';
import {Types as NotificationTypes} from '../../config/domain/notification';
import {createUrl} from '../api/request';
import {getUserToken, isUserLoggedIn} from '../auth/helper';
import {notificationReceived, loadNotifications} from './action';
import {AUTHORIZATION_QUERY_STRING} from '../../config/api/endpoint';

/**
 * @type {AppEventSource}
 */
let _notificationService = null;
let _token = null;


const getUrl = (token) => {
    return createUrl(API.NOTIFICATIONS_LIVE + `?${AUTHORIZATION_QUERY_STRING}=${token}`);
};

const initialize = (store, token) => {
    if (_notificationService) {
        _notificationService.close();
    }

    if (!token) {
        return;
    }

    const url = getUrl(token);
    _notificationService = new AppEventSource({url});
    _notificationService.addEventListener('message', event => handleEvent(store, event));
};

const parseNotificationEvent = (event) => {
    try {
        return JSON.parse(event.data);
    } catch (e) {
        error("Error reading notification event\n" + e);
        return null;
    }
};

const handleEvent = (store, event) => {
    const notification = parseNotificationEvent(event);

    if (null === notification || notification.notificationType == NotificationTypes.HEARTBEAT) {
        return;
    }

    store.dispatch(notificationReceived(notification));
};


export const bootstrap = (store) => {
    store.subscribe(() => {
        const auth = store.getState().auth;
        if (!auth) return;

        const newToken = getUserToken(auth);
        const hasTokenChanged = newToken !== _token;

        if (hasTokenChanged) {
            initialize(store, newToken);
        }

        if (hasTokenChanged && isUserLoggedIn(auth)) {
            store.dispatch(loadNotifications());
        }

        _token = newToken;
    });
};
