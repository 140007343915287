/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {l} from '../../../i18n/translator.js';
import URI from '../../../../config/uri.js';
import * as Feed from '../../ui-components/feed.jsx';
import {LocalDate, LocalRelative, LocalTime} from '../../common/i18n/datetime.jsx';
import {fetchMessages} from '../action.js';
import {BaseWidget} from './widget-base.jsx';


export class NewMessagesWidget extends BaseWidget {

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        this.props.dispatch(
            fetchMessages()
        ).then(messages => {
            this.props.widgetInitialized(this.getType(), {messages})
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    getType() {
        return 'messages-new';
    }

    getTitle() {
        return (
            <span>
                <i className="fa fa-comment fa-lg"/>&nbsp;&nbsp;
                {l('New messages')}
            </span>
        )
    }

    renderMessage(message, index) {
        let {content = ''} = message;
        const maxLength = 100;

        if (content.length > maxLength) {
            content = content.substr(0, maxLength) + '...';
        }

        return (
            <Feed.Element key={index}>
                <Link to={`${URI.OFFER_REQUEST_DETAILS}#messages`.replace(':id', message.offerRequestId)}>
                    <div className="p-w-sm">

                        <div className="m-b-xs">
                            <span className="label label-info">
                                {message.creatorCompanyName}
                            </span>
                            &nbsp;&nbsp;

                            <small className="pull-right">
                                <LocalRelative value={message.creationDate}/>
                            </small>
                        </div>

                        <div className="m-b-xs">
                            <h4>
                                {message.creatorName}
                            </h4>
                        </div>

                        <div className="m-b-xs">
                            {content}
                        </div>

                        <small className="text-muted">
                            <LocalTime value={message.creationDate}/>
                            &nbsp;-&nbsp;
                            <LocalDate value={message.creationDate}/>
                        </small>
                    </div>
                </Link>
            </Feed.Element>
        )
    }

    renderContent() {
        let {messages = []} = this.props.data;

        if (messages.length <= 0) {
            return (
                <div className="ibox-content no-data">
                    <i className="fa fa-envelope-o"></i>&nbsp;&nbsp;
                    {l('No messages at the moment.')}
                </div>
            )
        }

        return (
            <Feed.ActivityList>
                <div className="p-t">
                    {messages.map(this.renderMessage.bind(this))}
                </div>
            </Feed.ActivityList>
        )
    }
}
