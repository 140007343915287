import {authenticatedRequest, languageRequest, makeRequest, uploadFile} from '../api/request';
import {getUserToken, isUserLoggedIn} from '../auth/helper';
import {handleNotAuthorizedResponse} from '../auth/request/middleware';
import {sessionExpired} from '../auth/action';

export const makeAuthApiRequest = (uri, config, executeRequest = makeRequest) => {
    return (dispatch, getState) => {
        const state = getState();
        const token = getUserToken(state.auth);
        if (state.i18n && state.i18n.locale) {
            config = languageRequest(state.i18n.locale, config);
        }

        // make request
        let request = executeRequest(uri, authenticatedRequest(token, config));

        // handle response
        return new Promise((resolve, reject) => {
            request
                .then(response => resolve(response))
                // handle auth error
                .catch(e => handleNotAuthorizedResponse(e, () => {
                    // invalidate session on 401
                    if (isUserLoggedIn(getState().auth)) {
                        dispatch(sessionExpired());
                    }
                }))
                // handle error
                .catch(e => reject(e));
        });
    };
};

export const makeApiRequest = (uri, config, executeRequest = makeRequest) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.i18n && state.i18n.locale) {
            config = languageRequest(state.i18n.locale, config);
        }
        return executeRequest(uri, config);
    };
};

export const authApiUploadFile = (uri, config) => makeAuthApiRequest(uri, config, uploadFile);

export const apiUploadFile = (uri, config) => makeApiRequest(uri, config, uploadFile);
