import PropTypes from "prop-types";

export const FormComponentShape = {
    formData: PropTypes.shape({
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired
    }).isRequired,

    onValueChange: PropTypes.func.isRequired
};
