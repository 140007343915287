/**
 * Created by br0wn on 12/5/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Row, Col} from 'react-bootstrap';
import {Fields} from '../../../../../../config/domain/offerRequest/credit.js';
import {objectProp, isDefined} from '../../../../../helper/core.js';
import {renderChildren} from '../../../../../helper/react.js';
import {LocalNumber} from '../../../../common/i18n/number.jsx';
import {PremiumValue} from '../../../common/i18n/premium.jsx';
import {l} from '../../../../../i18n/translator.js';
import * as Ibox from '../../../../ui-components/ibox.jsx';
import {
    STEP_INSURANCE_DATA,
    STEP_DETAILS_OF_SALES,
    STEP_ACCOUNTS_RECEIVABLE,
    STEP_MANAGEMENT_RECEIVABLE,
    STEP_REMINDERS_COLLECTIONS
} from '../../../create-request/config/wizard';
import EditButton from '../editButton.jsx';


const insuranceDataStep = (step) => `${STEP_INSURANCE_DATA}:${step}`;


const Widget = ({title, noPadding, children, step}) => {
    return (
        <Ibox.Container>
            <Ibox.Title>
                {title}

                <EditButton step={insuranceDataStep(step)}/>
            </Ibox.Title>

            <Ibox.Content className={classNames({
                'no-padding': noPadding
            })}>
                {children}
            </Ibox.Content>
        </Ibox.Container>
    );
};


const UndefinedValue = () => <span>-</span>;

const IfValueDefined = ({value, UndefinedComponent = UndefinedValue, children}) => {
    if (isDefined(value)) {
        return renderChildren(children);
    }

    return <UndefinedComponent />
};


export const CreditInsuranceInformation = ({offerRequest = {}}) => {
    let {data} = offerRequest;

    if (!data || Object.keys(data).length <= 0) {
        return null;
    }

    const currency = objectProp(Fields.SALES_CURRENCY, data, 'CHF');

    return (
        <Row>
            <Col md={4}>
                <Widget
                    title={(<h5>{l('Accounts receivable')}</h5>)}
                    step={STEP_ACCOUNTS_RECEIVABLE}
                >
                    <Row>
                        <Col md={6}>
                            <strong>{l('Development per quarter')}</strong>
                            {(development => (
                                <div>
                                    <Row>
                                        <Col xs={3}>
                                            {l('1st')}:
                                        </Col>
                                        <Col xs={9}>
                                            <IfValueDefined value={development[0]}>
                                                <PremiumValue value={development[0]} currency={currency}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            {l('2nd')}:
                                        </Col>
                                        <Col xs={9}>
                                            <IfValueDefined value={development[1]}>
                                                <PremiumValue value={development[1]} currency={currency}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            {l('3rd')}:
                                        </Col>
                                        <Col xs={9}>
                                            <IfValueDefined value={development[2]}>
                                                <PremiumValue value={development[2]} currency={currency}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            {l('4th')}:
                                        </Col>
                                        <Col xs={9}>
                                            <IfValueDefined value={development[3]}>
                                                <PremiumValue value={development[3]} currency={currency}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                </div>
                            ))(objectProp(Fields.ACCOUNTSRECEIVABLE_DEVELOPMENT, data, []))}
                        </Col>
                        <Col md={6}>
                            <strong>{l('Structure')}</strong>
                            {(structure => (
                                <div>
                                    <Row>
                                        <Col xs={6}>
                                            {l('Up to 5')}:
                                        </Col>
                                        <Col xs={6}>
                                            <IfValueDefined value={structure[0]}>
                                                <LocalNumber value={structure[0]}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            {l('5 to 20')}:
                                        </Col>
                                        <Col xs={6}>
                                            <IfValueDefined value={structure[1]}>
                                                <LocalNumber value={structure[1]}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            {l('20 to 100')}:
                                        </Col>
                                        <Col xs={6}>
                                            <IfValueDefined value={structure[2]}>
                                                <LocalNumber value={structure[2]}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            {l('100 to 300')}:
                                        </Col>
                                        <Col xs={6}>
                                            <IfValueDefined value={structure[3]}>
                                                <LocalNumber value={structure[3]}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            {l('> 300')}:
                                        </Col>
                                        <Col xs={6}>
                                            <IfValueDefined value={structure[4]}>
                                                <LocalNumber value={structure[4]}/>
                                            </IfValueDefined>
                                        </Col>
                                    </Row>
                                </div>
                            ))(objectProp(Fields.ACCOUNTSRECEIVABLE_STRUCTURE, data, []))}
                        </Col>
                    </Row>
                </Widget>

                <Widget
                    title={(<h5>{l('Buyer Credit Risk Requests')}</h5>)}
                    step={STEP_MANAGEMENT_RECEIVABLE}
                >
                    <Row className="m-b-sm">
                        <Col md={6}>
                            <strong>{l('For new buyers')}</strong>
                            {((frequency, excess) => (
                                <div>
                                    <IfValueDefined value={frequency}>
                                        {frequency == 'never' && l('Never')}
                                        {frequency == 'occasionally' && l('Occasionally')}
                                        {frequency == 'systematically' && l('Systematically')}
                                        {frequency == 'inExcess' && l('Orders in excess of: %excess%').replace('%excess%', excess) + ' ' + currency}
                                    </IfValueDefined>
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_ASSESSMENT_NEWBUYERS_FREQUENCY, data),
                                objectProp(Fields.MANAGEMENT_ASSESSMENT_NEWBUYERS_EXCESS, data)
                            )}
                        </Col>
                        <Col md={6}>
                            <strong>{l('For existing buyers')}</strong>
                            {((frequency, excess) => (
                                <div>
                                    <IfValueDefined value={frequency}>
                                        {frequency == 'never' && l('Never')}
                                        {frequency == 'occasionally' && l('Occasionally')}
                                        {frequency == 'systematically' && l('Systematically')}
                                        {frequency == 'inExcess' && l('Orders in excess of: %excess%').replace('%excess%', excess)}
                                    </IfValueDefined>
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_ASSESSMENT_EXISTINGBUYERS_FREQUENCY, data),
                                objectProp(Fields.MANAGEMENT_ASSESSMENT_EXISTINGBUYERS_EXCESS, data)
                            )}
                        </Col>
                    </Row>

                    <Row className="m-b-sm">
                        <Col md={6}>
                            <strong>{l('Financial statements from our customers')}</strong>

                            {((isRequested, cases) => (
                                <div>
                                    <IfValueDefined value={isRequested}>
                                        {isRequested == 'never' && l('Never')}
                                        {isRequested == 'sometimes' ? (
                                                <div>
                                                    {l('Sometimes, in the following cases:')}

                                                    <ul className="unstyled">
                                                        {cases.map((caseItem, index) => (
                                                            <li key={index}>{caseItem}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : null}
                                    </IfValueDefined>
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_ANNUALFINANCIALSTATEMENTS_ISREQUESTED, data),
                                objectProp(Fields.MANAGEMENT_ANNUALFINANCIALSTATEMENTS_CASES, data, [])
                            )}
                        </Col>
                        <Col md={6}>
                            <strong>{l('Our sources')}</strong>

                            {((informationBureaus, debtCollectionOffices, other) => (
                                <div>
                                    <div>{informationBureaus && l('Information Bureaus')}</div>
                                    <div>{debtCollectionOffices && l('Debt collection offices')}</div>
                                    {other ? (
                                            <div>{l('Other:')} {other}</div>
                                        ) : null}

                                    {(!isDefined(informationBureaus) && !isDefined(debtCollectionOffices) && !isDefined(other)) ? (
                                            <UndefinedValue />
                                        ) : null}
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_ASSESSMENT_SOURCES_INFORMATIONBUREAUS, data),
                                objectProp(Fields.MANAGEMENT_ASSESSMENT_SOURCES_DEBTCOLLECTIONOFFICES, data),
                                objectProp(Fields.MANAGEMENT_ASSESSMENT_SOURCES_OTHER, data)
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <strong>
                                {l('# of credit/financial reports obtained annually')}
                            </strong>
                            <div>
                                {(item => (
                                    <IfValueDefined value={item}>
                                        {item}
                                    </IfValueDefined>
                                ))(
                                    objectProp(Fields.MANAGEMENT_ASSESSMENT_FINANCIALREPORTSOBTAINEDANNUALLY, data)
                                )}
                            </div>
                        </Col>
                    </Row>
                </Widget>

                <Widget
                    noPadding
                    title={(<h5>{l('Reminder procedure')}</h5>)}
                    step={STEP_REMINDERS_COLLECTIONS}
                >
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    {l('Procedure').toUpperCase()}
                                </th>
                                <th className="text-center">
                                    {l('Days after original due date')}
                                </th>
                            </tr>
                        </thead>
                        {((first, second, third, fourth, callOrVisit, ceaseDelivering, beginLegal) => (
                            <tbody>
                                {first && (
                                    <tr>
                                        <td>
                                            {l('1st reminder')}
                                        </td>
                                        <td className="text-center">
                                            {first}
                                        </td>
                                    </tr>
                                )}
                                {second && (
                                    <tr>
                                        <td>
                                            {l('2nd reminder')}
                                        </td>
                                        <td className="text-center">
                                            {second}
                                        </td>
                                    </tr>
                                )}
                                {third && (
                                    <tr>
                                        <td>
                                            {l('3rd reminder')}
                                        </td>
                                        <td className="text-center">
                                            {third}
                                        </td>
                                    </tr>
                                )}
                                {fourth && (
                                    <tr>
                                        <td>
                                            {l('4th reminder')}
                                        </td>
                                        <td className="text-center">
                                            {fourth}
                                        </td>
                                    </tr>
                                )}
                                {callOrVisit && (
                                    <tr>
                                        <td>
                                            {l('Call/visit buyer')}
                                        </td>
                                        <td className="text-center">
                                            {callOrVisit}
                                        </td>
                                    </tr>
                                )}
                                {ceaseDelivering && (
                                    <tr>
                                        <td>
                                            {l('Cease delivering')}
                                        </td>
                                        <td className="text-center">
                                            {ceaseDelivering}
                                        </td>
                                    </tr>
                                )}
                                {beginLegal && (
                                    <tr>
                                        <td>
                                            {l('Begin legal proceedings')}
                                        </td>
                                        <td className="text-center">
                                            {beginLegal}
                                        </td>
                                    </tr>
                                )}
                                {(!first && !second && !third && !fourth && !callOrVisit && !ceaseDelivering && !beginLegal) ? (
                                        <tr>
                                            <td colSpan="2">
                                                <span className="m-l-md">
                                                    {l('Not specified')}
                                                </span>
                                            </td>
                                        </tr>
                                    ) : null}
                            </tbody>
                        ))(
                            objectProp(Fields.SCHEDULE_REMINDER_FIRST, data),
                            objectProp(Fields.SCHEDULE_REMINDER_SECOND, data),
                            objectProp(Fields.SCHEDULE_REMINDER_THIRD, data),
                            objectProp(Fields.SCHEDULE_REMINDER_FOURTH, data),
                            objectProp(Fields.SCHEDULE_REMINDER_CALLORVISIT, data),
                            objectProp(Fields.SCHEDULE_REMINDER_CEASEDELIVERING, data),
                            objectProp(Fields.SCHEDULE_REMINDER_BEGINLEGALPROCEEDINGS, data)
                        )}
                    </table>
                </Widget>

                <Widget
                    title={(<h5>{l('Credit Limits and Receivables Security')}</h5>)}
                    step={STEP_MANAGEMENT_RECEIVABLE}
                >
                    <Row className="m-b-sm">
                        <Col md={6}>
                            <strong>{l('We set credit limits for our buyers')}</strong>

                            {((creditLimits) => (
                                <div>
                                    <IfValueDefined value={creditLimits}>
                                        {creditLimits ? l('Yes') : l('No')}
                                    </IfValueDefined>
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_CREDITLIMITS, data)
                            )}
                        </Col>
                        <Col md={6}>
                            <strong>{l('Credit limits are decided by')}</strong>

                            {((creditLimitsDecision) => (
                                <div>
                                    {creditLimitsDecision.map(decision => (
                                        <div key={decision}>
                                            {decision == 'salesDepartment' && l('Sales Department')}
                                            {decision == 'financeDepartment' && l('Finance Department')}
                                            {decision == 'seniorManagement' && l('Senior Management')}
                                        </div>
                                    ))}

                                    {(!isDefined(creditLimitsDecision) || creditLimitsDecision.length <= 0) ? (
                                            <UndefinedValue />
                                        ) : null}
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_CREDITLIMITSDECISION, data, [])
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={10}>
                            <strong>
                                {l('We secure our receivables through a...')}
                            </strong>
                            <div>
                                {l('Retention of title, in the following cases:')}
                            </div>
                            {((items) => (
                                <div className="m-b-sm">
                                    {items.map((item, index) => (
                                        <div key={index}>
                                            {item}
                                        </div>
                                    ))}

                                    {items.length <= 0 && (
                                        <UndefinedValue />
                                    )}
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_SECURERECEIVABLES_RETENTIONCASES, data, [])
                            )}

                            <div>
                                {l('Bank guarantee, in following cases:')}
                            </div>
                            {((items) => (
                                <div className="m-b-sm">
                                    {items.map((item, index) => (
                                        <div key={index}>
                                            {item}
                                        </div>
                                    ))}

                                    {items.length <= 0 && (
                                        <UndefinedValue />
                                    )}
                                </div>
                            ))(
                                objectProp(Fields.MANAGEMENT_SECURERECEIVABLES_BANKGUARANTEECASES, data, [])
                            )}
                        </Col>
                    </Row>
                </Widget>

                <Widget
                    title={(<h5>{l('Debt Collection and Credit Insurance')}</h5>)}
                    step={STEP_REMINDERS_COLLECTIONS}
                >
                    <strong>{l('We enforce dept collection')}</strong>

                    {((ourself, thirdParty) => (
                        <div className="m-b-sm">
                            {ourself.length > 0 && (
                                <div>
                                    <span>
                                        {l('Ourself in:')}
                                    </span>

                                    {ourself.map(item => (
                                        <span key={item} className="p-w-xs">
                                            {item == 'switzerland' && l('Switzerland')}
                                            {item == 'abroad' && l('Abroad')}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {thirdParty.length > 0 && (
                                <div>
                                    <span>
                                        {l('By 3rd party in:')}
                                    </span>

                                    {thirdParty.map(item => (
                                        <span key={item} className="p-w-xs">
                                            {item == 'switzerland' && l('Switzerland')}
                                            {item == 'abroad' && l('Abroad')}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {(ourself.length <= 0 && thirdParty.length <= 0) ? (
                                    <UndefinedValue />
                                ) : null}
                        </div>
                    ))(
                        objectProp(Fields.SCHEDULE_ENFORCEDDEBTCOLLECTION_SELF, data, []),
                        objectProp(Fields.SCHEDULE_ENFORCEDDEBTCOLLECTION_THIRDPARTY, data, [])
                    )}

                    <div>
                        <strong>{l('Number of collections initiated in last 12 months')}</strong>
                    </div>
                    {((item) => (
                        <div className="m-b-sm">
                            <IfValueDefined value={item}>
                                {item}
                            </IfValueDefined>
                        </div>
                    ))(
                        objectProp(Fields.SCHEDULE_ENFORCEDDEBTCOLLECTION_OCCURRENCESLASTTWELVEMONTHS, data)
                    )}
                </Widget>
            </Col>

            <Col md={8}>
                <Widget
                    noPadding
                    title={(<h5>{l('Sales')}</h5>)}
                    step={STEP_DETAILS_OF_SALES}
                >
                    <div className="p-xs">
                        <strong>{l('Sales structure and payment terms')}</strong>
                    </div>

                    <div className="">
                        <table className="table table-striped border-bottom m-b-sm">
                            <thead>
                                <tr>
                                    <th>
                                        {l('Country')}
                                    </th>
                                    <th className="text-right">
                                        {l('Sales previous year')}
                                    </th>
                                    <th className="text-center">
                                        {l('Payment terms (days)')}
                                    </th>
                                    <th className="text-center">
                                        {l('# of Buyers')}
                                    </th>
                                </tr>
                            </thead>

                            {((items) => (
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.region}
                                            </td>
                                            <td className="text-right">
                                                <IfValueDefined value={item.salesPreviousYear}>
                                                    <PremiumValue value={item.salesPreviousYear} currency={currency}/>
                                                </IfValueDefined>
                                            </td>
                                            <td className="text-center">
                                                <div>
                                                    <span>
                                                        {l('Standard')}:&nbsp;
                                                    </span>
                                                    <IfValueDefined value={item.paymentTermDaysStandard}>
                                                        <LocalNumber value={item.paymentTermDaysStandard}/>
                                                    </IfValueDefined>
                                                </div>
                                                <div>
                                                    <span>
                                                        {l('Maximum')}:&nbsp;
                                                    </span>
                                                    <IfValueDefined value={item.paymentTermDaysMaximum}>
                                                        <LocalNumber value={item.paymentTermDaysMaximum}/>
                                                    </IfValueDefined>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <LocalNumber value={item.numberOfBuyers}/>
                                            </td>
                                        </tr>
                                    ))}

                                    {items.length <= 0 && (
                                        <tr>
                                            <td colSpan="4">
                                                {l('Not specified')}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            ))(
                                objectProp(Fields.SALES_SALESSTRUCTURE, data, [])
                            )}
                        </table>
                    </div>

                    <div className="p-xs">
                        <Row>
                            <Col md={5}>
                                <strong>{l('Sales volume in 000s')}</strong>

                                <table className="full-width">
                                    {((nextYear, thisYear, lastYears) => (
                                        <tbody>
                                            {isDefined(nextYear) && (
                                                <tr>
                                                    <td>
                                                        {l('Forecast Next year:')}
                                                    </td>
                                                    <td className="w-150">
                                                        <PremiumValue value={nextYear} currency={currency}/>
                                                    </td>
                                                </tr>
                                            )}

                                            {isDefined(thisYear) && (
                                                <tr>
                                                    <td>
                                                        {l('Forecast Current year:')}
                                                    </td>
                                                    <td>
                                                        <PremiumValue value={thisYear} currency={currency}/>
                                                    </td>
                                                </tr>
                                            )}

                                            {isDefined(lastYears) && lastYears.length ? (
                                                    <tr>
                                                        <td style={{verticalAlign: 'top'}}>
                                                            {l('Over last 3 years:')}
                                                        </td>
                                                        <td>
                                                            {lastYears.map((value, index) => (
                                                                <div key={index}>
                                                                    <PremiumValue value={value} currency={currency}/>
                                                                </div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                ) : null}

                                            {(!isDefined(nextYear) && !isDefined(thisYear) && (!isDefined(lastYears) || lastYears.length <= 0)) ? (
                                                    <tr>
                                                        <td colSpan="2">
                                                            <UndefinedValue/>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                        </tbody>
                                    ))(
                                        objectProp(Fields.SALES_VOLUME_FORECASTNEXTYEAR, data),
                                        objectProp(Fields.SALES_VOLUME_FORECASTCURRENTYEAR, data),
                                        objectProp(Fields.SALES_VOLUME_LASTTHREEYEARS, data, []),
                                    )}
                                </table>
                            </Col>
                            <Col md={7}>
                                <strong>{l('Non-insurable sales')}</strong>

                                {((item) => (
                                    <div className="m-b-sm">
                                        <IfValueDefined value={item}>
                                            <strong>
                                                {item}%
                                            </strong>
                                            &nbsp;-&nbsp;
                                            {l('overall sales generated trough business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).')}
                                        </IfValueDefined>
                                    </div>
                                ))(
                                    objectProp(Fields.SALES_NONINSURABLESALESPERCENTAGE, data)
                                )}
                            </Col>
                        </Row>
                    </div>
                </Widget>

                <Widget
                    noPadding
                    title={(<h5>{l('Biggest Customers')}</h5>)}
                    step={STEP_ACCOUNTS_RECEIVABLE}
                >
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    {l('Name')}
                                </th>
                                <th className="text-right">
                                    {l('Biggest open position')}
                                </th>
                                <th className="text-right">
                                    {l('Revenue')}
                                </th>
                            </tr>
                        </thead>
                        {((items) => (
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td className="text-right">
                                            <IfValueDefined value={item.maximumOutstandingDept}>
                                                <PremiumValue value={item.maximumOutstandingDept} currency={currency}/>
                                            </IfValueDefined>
                                        </td>
                                        <td className="text-right">
                                            <IfValueDefined value={item.annualTurnover}>
                                                <PremiumValue value={item.annualTurnover} currency={currency}/>
                                            </IfValueDefined>
                                        </td>
                                    </tr>
                                ))}

                                {items.length <= 0 && (
                                    <tr>
                                        <td colSpan="4">
                                            {l('Not specified')}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        ))(
                            objectProp(Fields.ACCOUNTSRECEIVABLE_BIGGESTCUSTOMERS, data, [])
                        )}
                    </table>
                </Widget>

                <Widget
                    noPadding
                    title={(<h5>{l('Bad Debts and Distress Claims')}</h5>)}
                    step={STEP_ACCOUNTS_RECEIVABLE}
                >
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    {l('Year')}
                                </th>
                                <th className="text-center">
                                    {l('Total #')}
                                </th>
                                <th className="text-right">
                                    {l('Amount in (000s)')}
                                </th>
                                <th className="text-right">
                                    {l('2 Biggest Customers')}
                                </th>
                            </tr>
                        </thead>

                        {((items) => (
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            {item.year}
                                        </td>
                                        <td className="text-center">
                                            <IfValueDefined value={item.total}>
                                                <LocalNumber value={item.total}/>
                                            </IfValueDefined>
                                        </td>
                                        <td className="text-right">
                                            <IfValueDefined value={item.totalAmount}>
                                                <PremiumValue value={item.totalAmount} currency={currency}/>
                                            </IfValueDefined>
                                        </td>
                                        <td className="text-right">
                                            <IfValueDefined value={item.biggestCases}>
                                                {objectProp('biggestCases', item, []).map((item, index) => (
                                                    <div key={index}>
                                                        {item.customer}
                                                    </div>
                                                ))}
                                            </IfValueDefined>
                                        </td>
                                    </tr>
                                ))}

                                {items.length <= 0 && (
                                    <tr>
                                        <td colSpan="4">
                                            {l('Not specified')}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        ))(
                            objectProp(Fields.ACCOUNTSRECEIVABLE_BADDEPTS, data, [])
                        )}
                    </table>
                </Widget>
            </Col>
        </Row>
    );
};


export const CreditInsuranceInformationContainer = connect(
    state => ({
        i18n: state.i18n,
        offerRequest: state.page.offerRequestDetails.data.offerRequest
    })
)(CreditInsuranceInformation);
