import API from '../../../../../config/api/routes';
import API_ERRORS from '../../../../../config/api/errors';
import {isBadRequest, isForbidden, isNotFound, isNotAuthorized, handleErrorResponse} from '../../../../../lib/http';
import {updateQueryParameter} from '../../../../../lib/url';
import {jsonGetRequest, jsonPostRequest, postRequest} from '../../../../api/request';
import {makeApiRequest, makeAuthApiRequest, apiUploadFile, authApiUploadFile} from '../../../../api/action';
import {getToken} from '../reducer';


export const makeApiRequestWithToken = (uri, config, actions = []) => {
    const [tokenAction = makeApiRequest, authAction = makeAuthApiRequest] = actions;

    return (dispatch, getState) => {
        // get token
        const token = getToken(getState());

        if (token) {
            // update uri
            uri = updateQueryParameter(uri, 'token', token);

            return dispatch(
                tokenAction(uri, config)
            );
        }

        return dispatch(
            authAction(uri, config)
        );
    };
};

export const fetchOfferRequest = (offerRequestId) => {
    return (dispatch) => dispatch(
        makeApiRequestWithToken(API.OFFER_REQUEST_INSURANCE_DATA.replace(':id', offerRequestId), jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        if (isNotFound(response)) {
            return Promise.reject({notFound: true});
        }
        if (isForbidden(response) || isNotAuthorized(response)) {
            return Promise.reject({forbidden: true});
        }

        return Promise.reject({requestError: response});
    }));
};

export const publish = (offerRequest) => {
    return (dispatch) => {
        return dispatch(makeApiRequestWithToken(
            API.OFFER_REQUEST_INSURANCE_DATA.replace(':id', offerRequest.id), jsonPostRequest({
                body: JSON.stringify(offerRequest)
            }))
        ).catch(e => handleErrorResponse(e, response => {
            // process validation errors
            if (isBadRequest(response)) {
                return response.json().then(data => {
                    return Promise.reject({validationErrors: data.errors});
                });
            }
            // process forbidden errors
            if (isForbidden(response)) {
                return response.json().then(data => {
                    if (data.error == API_ERRORS.COMPANY_NOT_SET_UP) {
                        return Promise.reject({companyError: API_ERRORS.COMPANY_NOT_SET_UP});
                    } else {
                        return Promise.reject({requestError: response});
                    }
                });
            }

            return Promise.reject({requestError: response});
        }));
    };
};

export const uploadFile = (offerRequestId, file, onProgress) => {
    return (dispatch) => {
        // set form data
        let formData = new FormData();
        formData.append('file', file);

        return dispatch(
            makeApiRequestWithToken(
                API.OFFER_REQUEST_INSURANCE_FILES.replace(':id', offerRequestId), postRequest({
                    body: formData,
                    onProgress
                }),
                [apiUploadFile, authApiUploadFile]
            )
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            // if response is not expected reject with error
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }

            // process validation errors
            return response.json().then(data => {
                // reject promise
                return Promise.reject({validationErrors: data});
            });
        }));
    };
};

export const deleteFile = (offerRequestId, fileId) => {
    return () => Promise.resolve({offerRequestId, fileId});
};
