import {l} from '../../../i18n/translator';
import {warn} from '../../../../lib/logger';
import loadedPageReducer from '../../common/page/loaded-page/reducer';
import chatReducer, {INITIAL_STATE as CHAT_INITIAL_STATE} from '../../common/chat/reducer/threaded';
import {OFFER_UPLOAD_ACTIONS} from '../offer-details/action/type';
import {removeOffer, updateOffer, updateOfferRatingComment} from './reducer/offer';
import {addOfferRequestFile, removeOfferRequestFile} from './reducer/offerRequest';
import ACTION_TYPES, {CHAT_ACTIONS, PAGE_ACTIONS} from './action/type';
import {Types as NOTIFICATION_ACTIONS} from '../../../notification/action';
import {getThreadId} from './action/chat';

const INITIAL_STATE = {
    isLoading: false,
    isLoaded: false,
    notFound: false,
    loadErrors: [],
    data: {
        offerRequest: null,
        insuranceTypes: [],
        offerList: [],
        insurances: [],
        statistics: []
    },
    isDeleting: false,
    isAbstaining: false,
    isPublishing: false,
    publishError: null,
    deletingOffers: [],
    chat: {...CHAT_INITIAL_STATE},
    offerModal: {
        show: false,
        readOnly: false
    },
    withdrawRequestModal: {
        show: false
    }
};


/**
 * Root reducer for CreateOfferRequest page
 *
 * @param state
 * @param action
 */
let offerRequestDetailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_OFFER_EDIT_MODAL: {
            return {
                ...state,
                offerModal: {
                    ...( action.replace ? INITIAL_STATE.offerModal : state.offerModal),
                    ...action.data
                }
            };
        }
        case ACTION_TYPES.UPDATE_WITHDRAW_REQUEST_MODAL: {
            return {
                ...state,
                withdrawRequestModal: {
                    ...( action.replace ? INITIAL_STATE.offerModal : state.offerModal),
                    ...action.data
                }
            };
        }
        case ACTION_TYPES.DELETE_OFFER_REQUEST_START: {
            return {
                ...state,
                isDeleting: true
            };
        }
        case ACTION_TYPES.DELETE_OFFER_REQUEST_END: {
            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            }

            return {
                ...state,
                isDeleting: false
            };
        }
        case ACTION_TYPES.PUBLISH_OFFER_REQUEST_START: {
            return {
                ...state,
                isPublishing: true,
                publishError: null
            };
        }
        case ACTION_TYPES.PUBLISH_OFFER_REQUEST_END: {
            let offerRequest = state.data.offerRequest;
            let publishError = state.publishError;

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError, validationErrors}} = action;

                if (validationErrors) {
                    publishError = l('Offer request is not complete, please edit the request and add missing information.');
                } else if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            } else {
                offerRequest = {...action.offerRequest};
            }

            return {
                ...state,
                isPublishing: false,
                publishError,
                data: {
                    ...state.data,
                    offerRequest
                }
            };
        }
        case ACTION_TYPES.WITHDRAW_OFFER_REQUEST_START: {
            return {
                ...state,
                isWithdrawing: true
            };
        }
        case ACTION_TYPES.WITHDRAW_OFFER_REQUEST_END: {
            let offerRequest = state.data.offerRequest;
            let withdrawRequestModal = state.withdrawRequestModal;

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            } else {
                offerRequest = {...action.offerRequest};
                withdrawRequestModal = {...INITIAL_STATE.withdrawRequestModal};
            }

            return {
                ...state,
                isWithdrawing: false,
                data: {
                    ...state.data,
                    offerRequest
                },
                withdrawRequestModal
            };
        }
        case ACTION_TYPES.DELETE_OFFER_START: {
            return {
                ...state,
                deletingOffers: [...state.deletingOffers, action.id]
            };
        }
        case ACTION_TYPES.DELETE_OFFER_END: {
            let items = [...state.data.offerList];
            let deletingOffers = [...state.deletingOffers];

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer or offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            } else {
                let item = items.find(item => item.id == action.id);
                let itemIndex = items.indexOf(item);

                if (itemIndex !== -1) {
                    items = [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)];
                }
            }

            let deletingIndex = deletingOffers.indexOf(action.id);
            deletingOffers = [...deletingOffers.slice(0, deletingIndex), ...deletingOffers.slice(deletingIndex + 1)];

            return {
                ...state,
                deletingOffers: deletingOffers,
                data: {
                    ...state.data,
                    offerList: items
                }
            };
        }
        case ACTION_TYPES.ABSTAIN_OFFER_START: {
            return {
                ...state,
                isAbstaining: true
            };
        }
        case ACTION_TYPES.ABSTAIN_OFFER_END: {
            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified offer request.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else {
                    alert(l("Unknown error occurred"));
                }
            }

            return {
                ...state,
                isAbstaining: false
            };
        }
        case OFFER_UPLOAD_ACTIONS.UPLOAD_END: {
            // skip if there was an error
            if (action.error) {
                break;
            }

            const {offer, file} = action;

            return updateOffer(state, {...offer, officialOfferFileId: file.id});
        }
        case ACTION_TYPES.UPDATE_OFFER: {
            // skip if there was an error
            if (action.error) {
                break;
            }

            return updateOffer(state, action.offer);
        }
        case ACTION_TYPES.REMOVE_OFFER: {
            // skip if there was an error
            if (action.error) {
                break;
            }

            return removeOffer(state, action.offer);
        }
        case ACTION_TYPES.UPDATE_OFFER_COMMENT: {
            // skip if there was an error
            if (action.error) {
                break;
            }

            return updateOfferRatingComment(state, action.offer, action.analysisField, action.criteriaId, action.comment);
        }
        case ACTION_TYPES.FILE_REMOVED: {
            if (action.error) {
                break;
            }
            return removeOfferRequestFile(state, action.file);
        }
        case ACTION_TYPES.FILE_UPLOADED: {
            return addOfferRequestFile(state, action.file);
        }
        case ACTION_TYPES.UTILITY_ANALYSIS_UPDATED: {
            return {
                ...state,
                data: {
                    ...state.data,
                    offerRequest: {
                        ...state.data.offerRequest,
                        utilityAnalysisCriteria: action.criteria,
                        utilityAnalysisRatings: action.ratings
                    }
                }
            };
        }
        case ACTION_TYPES.PAGE_RESET: {
            return {
                ...INITIAL_STATE,
                ...loadedPageReducer(state, action, PAGE_ACTIONS)
            };
        }
        case NOTIFICATION_ACTIONS.OFFER_RECEIVED: {
            const {offerRequest = {}} = (state.data || {});
            const {offer, offerRequestId} = action;

            if (offerRequest.id != offerRequestId) {
                break;
            }

            return updateOffer(state, offer);
        }
        case NOTIFICATION_ACTIONS.MESSAGE_RECEIVED: {
            const {message} = action;
            const {channel, insuranceId} = message;

            const chatAction = {
                type: CHAT_ACTIONS.MESSAGE_RECEIVED,
                threadId: getThreadId({channel, insuranceId}),
                message
            };

            return {
                ...state,
                chat: chatReducer(state.chat, chatAction, CHAT_ACTIONS),
            };
        }
    }

    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS),
        chat: chatReducer(state.chat, action, CHAT_ACTIONS)
    };
};

export default offerRequestDetailsReducer;
