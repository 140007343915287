import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import {l} from '../../../i18n/translator';
import {redirectTo} from '../../../router/action.js';
import URI from '../../../../config/uri';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx'
import * as Ibox from '../../ui-components/ibox.jsx';
import {initCustomersList} from './action.js';

export class CustomersListPage extends React.Component {

    componentWillMount() {
        this.props.actions.init();
    }

    render() {
        const {dispatch, auth, pageState} = this.props;
        const {data} = pageState;
        const customers = [...(data.customers || [])];
        customers.sort((a, b) => a.name.localeCompare(b.name));

        // if (!isUserLoggedIn(auth)) {
        //     return null;
        // }

        return (
            <InsurTecLayout>
                <BreadCrumbs title={l('Customers')} icon="user"/>


                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content">
                        <Row>
                            <Col md={12}>
                                <Ibox.Container>
                                    <Ibox.Title>{l('Customers')}</Ibox.Title>

                                    <Ibox.Content className="p-sm">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th className="col-md-2">{l('Id')}</th>
                                                <th>{l('Name')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {customers.map((customer, index) => {
                                                return (
                                                    <tr key={customer.id} style={{cursor: 'pointer'}}
                                                        onClick={() => dispatch(redirectTo(URI.CUSTOMERS_EDIT.replace(':id', customer.id)))}>
                                                        <td className="col-md-2">{customer.id}</td>
                                                        <td>{customer.name}</td>
                                                        <td>
                                                            <Button variant="contained"
                                                                    onClick={(event) => {
                                                                        dispatch(redirectTo(URI.CUSTOMERS_USERS.replace(':id', customer.id)));
                                                                        event.stopPropagation();
                                                                    }}>
                                                                <i className="fa fa-users red"/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>

                                        <Button variant="contained"
                                                color="primary"
                                                onClick={() => dispatch(redirectTo(URI.CUSTOMERS_CREATE))}>
                                            {l('Create customer')}
                                        </Button>
                                    </Ibox.Content>
                                </Ibox.Container>
                            </Col>
                        </Row>
                    </div>
                </LoadedPage>
            </InsurTecLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        auth: state.auth,
        user: state.auth.user,
        pageState: {...state.page.customersList}
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: () => dispatch(initCustomersList())
        },
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersListPage);
