import React from 'react';
import PropTypes from "prop-types";
import {Row, Col} from 'react-bootstrap';
import classNames from 'classnames';
import {l} from '../../../../i18n/translator';

/**
 * PersonDataForm
 *
 * @param {{values: object, errors: object}} formData
 * @param {function} onValueChange
 * @param {object} formFields
 * @param {boolean} disabled
 * @param {object} props
 */
const PersonDataForm = ({formData, onValueChange, formFields, disabled, ...props}) => {
    return (
        <div {...props} className={classNames('form-horizontal label-left', props.className)}>
            <Row>
                <Col md={6}>
                    <div className={classNames('row', {'has-error': formData.errors[formFields.FIRST_NAME]})}>
                        <label className="col-md-4 control-label">
                            {l('First Name')}
                        </label>
                        <div className="col-md-8">
                            <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.FIRST_NAME] || ''}
                                   onChange={(e) => onValueChange(formFields.FIRST_NAME, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.FIRST_NAME]}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={classNames('row', {'has-error': formData.errors[formFields.LAST_NAME]})}>
                        <label className="col-md-4 control-label">
                            {l('Last Name')}
                        </label>
                        <div className="col-md-8">
                            <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.LAST_NAME] || ''}
                                   onChange={(e) => onValueChange(formFields.LAST_NAME, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.LAST_NAME]}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <div className={classNames('row', {'has-error': formData.errors[formFields.FUNCTION]})}>
                                <label className="col-md-4 control-label">
                                    {l('Function')}
                                </label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.FUNCTION] || ''}
                                           onChange={(e) => onValueChange(formFields.FUNCTION, e.target.value)}/>
                                    <div className="help-block m-b-none">
                                        {formData.errors[formFields.FUNCTION]}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={classNames('row', {'has-error': formData.errors[formFields.TELEPHONE]})}>
                                <label className="col-md-4 control-label">
                                    {l('Telephone')}
                                </label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.TELEPHONE] || ''}
                                           onChange={(e) => onValueChange(formFields.TELEPHONE, e.target.value)}/>
                                    <div className="help-block m-b-none">
                                        {formData.errors[formFields.TELEPHONE]}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <div className={classNames('row', {
                        'has-error': formData.errors[formFields.ADDRESS_1] || formData.errors[formFields.ADDRESS_2]
                    })}>
                        <label className="col-md-4 control-label">
                            {l('Address')}
                        </label>
                        <div className="col-md-8">
                            <input className="form-control" disabled={disabled} value={formData.values[formFields.ADDRESS_1] || ''}
                                   onChange={(e) => onValueChange(formFields.ADDRESS_1, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.ADDRESS_1]}
                            </div>

                            <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.ADDRESS_2] || ''}
                                   onChange={(e) => onValueChange(formFields.ADDRESS_2, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.ADDRESS_2]}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className={classNames('row', {'has-error': formData.errors[formFields.MOBILE]})}>
                        <label className="col-md-4 control-label">
                            {l('Mobile')}
                        </label>
                        <div className="col-md-8">
                            <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.MOBILE] || ''}
                                   onChange={(e) => onValueChange(formFields.MOBILE, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.MOBILE]}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={classNames('row', {'has-error': formData.errors[formFields.ZIP]})}>
                        <label className="col-md-4 control-label">
                            {l('Zip Code')}
                        </label>
                        <div className="col-md-8">
                            <input type="number" className="form-control" disabled={disabled} value={formData.values[formFields.ZIP] || ''}
                                   onChange={(e) => onValueChange(formFields.ZIP, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.ZIP]}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className={classNames('row', {'has-error': formData.errors[formFields.EMAIL]})}>
                        <label className="col-md-4 control-label">
                            {l('E-mail')}
                        </label>
                        <div className="col-md-8">
                            <input type="email" className="form-control" disabled={disabled} value={formData.values[formFields.EMAIL] || ''}
                                   onChange={(e) => onValueChange(formFields.EMAIL, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.EMAIL]}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={classNames('row', {'has-error': formData.errors[formFields.TOWN]})}>
                        <label className="col-md-4 control-label">
                            {l('City')}
                        </label>
                        <div className="col-md-8">
                            <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.TOWN] || ''}
                                   onChange={(e) => onValueChange(formFields.TOWN, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.TOWN]}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                </Col>
                <Col md={6}>
                    <div className={classNames('row', {'has-error': formData.errors[formFields.COUNTRY]})}>
                        <label className="col-md-4 control-label">
                            {l('Country')}
                        </label>
                        <div className="col-md-8">
                            <input type="text" className="form-control" disabled={disabled} value={formData.values[formFields.COUNTRY] || ''}
                                   onChange={(e) => onValueChange(formFields.COUNTRY, e.target.value)}/>
                            <div className="help-block m-b-none">
                                {formData.errors[formFields.COUNTRY]}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

PersonDataForm.propTypes = {
    formData: PropTypes.shape({
        values: PropTypes.object,
        errors: PropTypes.object
    }).isRequired,
    onValueChange: PropTypes.func.isRequired,
    formFields: PropTypes.object
};

export default PersonDataForm;
