import {change, getFormValues} from 'redux-form';
import {FORM_NAME} from '../config/form';
import API from '../../../../../config/api/routes';
import {makeAuthApiRequest} from '../../../../api/action';
import {deleteRequest} from '../../../../api/request';

export const removeAdvisoryMandate = () => {
    return (dispatch, getState) => {
        const formValues = getFormValues(FORM_NAME)(getState());
        const offerRequestId = formValues.id;
        const fileId = formValues.files && formValues.files.advisoryMandate
            ? formValues.files.advisoryMandate.id : null;

        if (fileId) {
            const url = offerRequestId
                ? API.OFFER_REQUEST_FILE.replace(':id', this.props.offerRequestId).replace(':fileId', fileId)
                : API.FILE.replace(':id', fileId);

            dispatch(makeAuthApiRequest(url, deleteRequest()))
                .then(() => {
                    dispatch(change(FORM_NAME, 'files.advisoryMandate', null));
                });
        }
    };
};
