/**
 * Created by br0wn on 10/26/16.
 */
import {warn} from '../../../../lib/logger';
import {l} from '../../../i18n/translator';
import loadedPageReducer, {PAGE_INITIAL_STATE} from '../../common/page/loaded-page/reducer';
import {TYPES, PAGE_ACTIONS} from '../action/edit';


export const INITIAL_STATE = {
    ...PAGE_INITIAL_STATE,
    data: {
        user: null
    },
    form: {
        values: {},
        errors: {},
        isSubmitting: false
    }
};


const userEditPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPES.SET_FORM_DATA: {
            let values = state.form.values;

            values = action.update == true ? {
                ...values,
                ...(action.formData || {})
            } : (
                action.formData || {}
            );

            return {
                ...state,
                form: {
                    ...state.form,
                    values: values,
                    errors: {}
                }
            };
        }
        case TYPES.SUBMIT_START: {
            return {
                ...state,
                form: {
                    ...state.form,
                    isSubmitting: true,
                    errors: {}
                }
            };
        }
        case TYPES.SUBMIT_END: {
            let errors = {...state.form.errors};

            if (action.error) {
                warn(action.error);
                let {error: {forbidden, notFound, requestError, validationErrors}} = action;

                if (forbidden) {
                    alert(l('Action not allowed for current user.'));
                } else if (notFound) {
                    alert(l('Could not find specified user.'));
                } else if (requestError) {
                    alert(l('Unknown response received from server'));
                } else if (validationErrors) {
                    errors = {...validationErrors};
                } else {
                    alert(l("Unknown error occurred"));
                }
            }

            return {
                ...state,
                form: {
                    ...state.form,
                    isSubmitting: false,
                    errors: errors
                }
            };
        }
        case TYPES.FORM_FIELD_CHANGE: {
            const {field, value} = action;

            const values = {
                ...state.form.values,
                [field]: value
            };

            let errors = {...state.form.errors};
            delete errors[field];

            return {
                ...state,
                form: {
                    ...state.form, values, errors
                }
            };
        }
    }

    return {
        ...state,
        ...loadedPageReducer(state, action, PAGE_ACTIONS)
    };
};

export default userEditPageReducer;
