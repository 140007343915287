/**
 * Created by br0wn on 10/26/16.
 */
import swal from 'sweetalert'; // eslint-disable-line
import {warn} from '../../../../lib/logger';
import {l} from '../../../i18n/translator';
import {createAction} from '../../../action';
import {fetchUsers, deleteUser as apiDeleteUser} from './api';

/*
 * ACTION TYPES
 */
export const TYPES = {
    DATA_LOADED: 'page.users.list.data_loaded',

    PAGE_LOAD_START: 'page.users.list.load_start',
    PAGE_LOAD_END: 'page.users.list.load_end',
    PAGE_RESET: 'page.users.list.reset',

    LIST_LOAD_START: 'page.users.list.list_load_start',
    LIST_LOAD_END: 'page.users.list.list_load_end',

    DELETE_USER_START: 'page.users.list.delete_start',
    DELETE_USER_END: 'page.users.list.delete_end'
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    DATA_LOADED: TYPES.DATA_LOADED,
    PAGE_RESET: TYPES.PAGE_RESET
};


/*
 * ACTIONS
 */

export const init = () => (dispatch) => {
    dispatch(createAction(TYPES.PAGE_LOAD_START));

    Promise.all([
        dispatch(fetchUsers())
    ]).then(([userList]) => {
        dispatch(createAction(TYPES.DATA_LOADED, {data: {userList}}));
        dispatch(createAction(TYPES.PAGE_LOAD_END, {isLoaded: true}));

    }).catch(error => {
        warn("Error while fetching offer resources!", error);
        let errors = [];

        if (error.requestError) {
            errors.push(l("Unknown response received from server"));
        } else {
            errors.push(l("Unknown error occurred"));
        }

        dispatch(createAction(TYPES.PAGE_LOAD_END, {errors: errors}));
    });
};


export const deleteUser = (user) => (dispatch) => {
    swal({
        title: l('Are you sure?'),
        type: 'warning',
        showCancelButton: true
    }, () => {
        dispatch(createAction(TYPES.DELETE_USER_START, {id: user.id}));

        return dispatch(
            apiDeleteUser(user.id)
        ).then(
            () => dispatch(createAction(TYPES.DELETE_USER_END, {id: user.id}))
        ).catch(
            error => dispatch(createAction(TYPES.DELETE_USER_END, {
                id: user.id,
                error
            }))
        );
    });
};
