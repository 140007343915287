export const States = {
    CREATED: 'created',
    PENDING: 'pending',
    REJECTED: 'rejected',
    WITHDRAWN: 'withdrawn',
    PUBLISHED: 'published',
    ABSTAINED: 'abstained'
};

export const Fields = {
    ID: 'id',
    CREATION_DATE: 'creationDate',
    PUBLISH_DATE: 'publishDate',
    CREATOR_ID: 'creatorId',
    CREATOR_COMPANY_ID: 'creatorCompanyId',
    OFFER_REQUEST_ID: 'offerRequestId',
    INSURANCE_ID: 'insuranceId',
    INSURANCE_NAME: 'insuranceName',
    IS_CURRENT: 'isCurrent',
    STATE: 'state',
    OFFICIAL_OFFER_REQUESTED: 'officialOfferRequested',
    OFFICIAL_OFFER_FILE: 'officialOfferFile',
    GENERAL_CONDITIONS_OF_INSURANCE_VERSION: 'generalConditionsOfInsuranceVersion',
    SPECIAL_CONDITIONS_OF_INSURANCE_VERSION: 'specialConditionsOfInsuranceVersion',
    DATA: 'data',
    ADDITIONAL_INFORMATION: 'data.additionalInformation',
    PREMIUM_NET: 'premiumNet',
    PREMIUM_GROSS: 'premiumGross',
    REJECT_REASON: 'rejectReason',
    WITHDRAW_REASON: 'withdrawReason',

    REQUIRES_MANUAL_PROCESSING: 'requiresManualProcessing',

    PREMIUM_ANALYSIS_CRITERIA: 'premiumAnalysisCriteria',
    PREMIUM_ANALYSIS_RATINGS: 'premiumAnalysisRatings',
    COVERAGE_ANALYSIS_CRITERIA: 'coverageAnalysisCriteria',
    COVERAGE_ANALYSIS_RATINGS: 'coverageAnalysisRatings',

    ACTIONS: 'actions',
};
