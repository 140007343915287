export const Fields = {
    ID: 'id',
    KEY: 'key',
    NAME: 'name',
    BUSINESS_IDENTIFICATION_NUMBER: 'businessIdentificationNumber',
    INDUSTRY_CLASSIFICATION_CODE: 'industryClassificationCode',
    AREA_OF_OPERATIONS: 'areaOfOperations',
    EMPLOYEES: 'employees',
    CONTACT: 'contact',
};
