/**
 * Created by br0wn on 12/1/16.
 */
import {initForm, save} from '../../create-offer/action/form';
import {
    deleteOffer as apiDeleteOffer,
    updateOfferAnalysisRatingComment as apiUpdateOfferAnalysisRatingComment
} from '../../../../api/sdk-action';
import {removeOffer, updateOffer} from './offerRequest';
import TYPES from './type';
import {cleanup, init} from './page';


export const updateModal = (data = {}, replace = false) => ({
    type: TYPES.UPDATE_OFFER_EDIT_MODAL, data, replace
});


export const updateOfferWithAction = (action, ...args) => (dispatch) => {
    return dispatch(
        action(...args)
    ).then(
        offer => offer && offer.id && dispatch(updateOffer(offer))
    );
};

export const actionAndReload = (action, ...args) => (dispatch) => {
    return dispatch(
        action(...args)
    ).then(offer => {
            // reset page
            dispatch(cleanup());
            dispatch(init(offer.offerRequestId));
        }
    );
};

export const saveOffer = () => (dispatch) => {
    return dispatch(
        save()
    ).then(offer => {
        dispatch(updateOffer(offer));
        dispatch(updateModal({offer}));
    });
};

export const deleteOffer = (offer) => (dispatch) => {
    return dispatch(
        apiDeleteOffer(offer.id)
    ).then(
        () => dispatch(removeOffer(offer))
    );
};

export const updateOfferRatingComment = (offerRequest, offer, analysisField, criteriaId, comment) => (dispatch) => {
    return dispatch(
        apiUpdateOfferAnalysisRatingComment(offer.id, analysisField, criteriaId, comment)
    ).then(
        () => dispatch({type: TYPES.UPDATE_OFFER_COMMENT, offer, analysisField, criteriaId, comment})
        // () => dispatch(updateOfferComment(offer, criteriaId, comment))
    );
};

export const createOffer = (offerRequest) => (dispatch) => {
    dispatch(initForm(offerRequest));
    dispatch(updateModal({show: true}, true));
};

export const editOffer = (offerRequest, offer) => (dispatch) => {
    dispatch(initForm(offerRequest, offer));
    dispatch(updateModal({show: true, offer}, true));
};

export const viewOffer = (offerRequest, offer) => (dispatch) => {
    dispatch(initForm(offerRequest, offer));
    dispatch(updateModal({show: true, readOnly: true, offer}, true));
};

export const closeOfferModal = () => (dispatch) => {
    dispatch(updateModal({show: false}));
};
