import {l} from '../../i18n/translator';
import {redirectTo} from '../../router/action';
import URI from '../../../config/uri';
import {isUserOfType} from '../../auth/helper';
import {CompanyTypes} from '../../../config/domain/user';

export const handleCompanyNotSetupError = (dispatch, error, companyId) => {
    if (error.companyError) {
        alert(l('You have not setup company information yet.'));
        dispatch((dispatch, getState) => {
            const state = getState();
            const isBroker = isUserOfType(state.auth, CompanyTypes.BROKER);

            if (isBroker && parseInt(state.auth.user.companyId) !== companyId) {
                dispatch(redirectTo(URI.CUSTOMERS_EDIT.replace(':id', companyId)));
            } else {
                dispatch(redirectTo(URI.ADMINISTRATION));
            }
        });
        return true;
    }
    return false;
};
