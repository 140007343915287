import singleFileUploadReducer, {INITIAL_STATE as UPLOAD_INITIAL_STATE} from '../../common/form/file-upload/reducer/singleFileUploadReducer';
import {formReducer} from '../../common/form/reducer';
import ACTION_TYPES, {FILE_UPLOAD_ACTIONS} from './action/type';


const INITIAL_STATE = {
    form: {
        values: {},
        errors: {}
    },
    offerFileUpload: {...UPLOAD_INITIAL_STATE},
    offerRequest: undefined
};


/**
 * Root reducer for CreateOfferRequest page
 *
 * @param state
 * @param action
 */
let createOfferReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_OFFER_REQUEST: {
            return {
                ...INITIAL_STATE,
                offerRequest: action.offerRequest
            };
        }
    }

    return {
        ...state,
        form: formReducer(state.form, action, ACTION_TYPES),
        offerFileUpload: singleFileUploadReducer(state.offerFileUpload, action, FILE_UPLOAD_ACTIONS)
    };
};

export default createOfferReducer;
