import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {l} from '../../../i18n/translator.js';
import {redirectTo} from '../../../router/action.js';
import Spiner from '../../common/misc/spiner.jsx';
import {DashboardKpiListContainer} from '../kpi/kpi-list.jsx';
import {CustomerRequests, NewMessages} from '../widget/index';
import {getPageState} from '../selector';
import {initCustomerDashboard} from '../action';
import URI from '../../../../config/uri';


export class DashboardPage extends React.Component {

    componentWillMount() {
        this.props.initCustomerDashboard(this.props.auth);
    }

    render() {
        const {auth, isLoading, showWelcomeMessage, dispatch} = this.props;

        return (
            <div className="dashboard--broker">
                {isLoading ? (
                    <div>
                        <div className="row wrapper wrapper-content animated fadeInRight">
                            <Spiner/>
                        </div>
                    </div>
                ) : (
                    showWelcomeMessage ? (
                        <div className="row wrapper wrapper-content animated fadeInRight">
                            <div className="col-md-12">
                                <h1>
                                    {l('Welcome to InsurTec! Create your first offer request within just a few clicks!')}
                                </h1>

                                <p>
                                    <Button variant="contained"
                                            color="primary"
                                            icon={<i className="fa fa-file-o text-white"/>}
                                            onClick={() => dispatch(redirectTo(URI.CREATE_OFFER_REQUEST))}>
                                        {l('Create Offer Request')}
                                    </Button>
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {/* TOP WIDGETS */}
                            <div className="row wrapper m-t-md">
                                <div className="col-md-12">

                                    {/* KPI LIST*/}
                                    <DashboardKpiListContainer/>

                                </div>
                            </div>
                            <div className="row wrapper wrapper-content animated fadeInRight">
                                <div className="col-md-8">
                                    <CustomerRequests/>
                                </div>
                                <div className="col-md-4">
                                    <NewMessages/>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        )
    }
}

export default connect(
    state => {
        const pageState = getPageState(state);
        const {isLoading, showWelcomeMessage} = pageState;

        return {isLoading, showWelcomeMessage, i18n: state.i18n, auth: state.auth};
    },
    dispatch => ({
        ...bindActionCreators({initCustomerDashboard}, dispatch),
        dispatch
    })
)(DashboardPage);
