/**
 * Created by br0wn on 1/25/17.
 */
import React from 'react';
import classNames from 'classnames';
import {ScrollElement} from 'react-scroll';
import {Field as RfField, FieldArray as RfFieldArray} from 'redux-form';


export const FieldScrollElement = ScrollElement(
    ({parentBindings, ...props}) => (<div {...props} />)
);

export const FieldComponentWrapper = ({fieldName, fieldComponent: Component, ...props}) => {
    const {meta: {touched, error}} = props;

    return (
        <FieldScrollElement
            className={classNames('field', {'has-error': touched && error})}
            name={fieldName}>
            <Component {...props}/>
        </FieldScrollElement>
    );
};

export const Field = ({component, ...props}) => (
    <RfField {...props} component={FieldComponentWrapper} fieldComponent={component} fieldName={props.name}/>
);

export const FieldArray = ({component, parentBindings, ...props}) => (
    <RfFieldArray {...props} component={FieldComponentWrapper} fieldComponent={component} fieldName={props.name}/>
);
