import React from 'react';
import {connect} from 'react-redux';
import {Col, HelpBlock, Row} from 'react-bootstrap';
import {Field, getFormValues, reduxForm, submit} from 'redux-form';
import Button from '@material-ui/core/Button';
import {l} from '../../../i18n/translator';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import * as Ibox from '../../ui-components/ibox.jsx';
import PersonDataFrom from '../../common/form/person-data/componentReduxForm.jsx';
import {prefixedPersonDataFormFields} from '../../common/form/person-data/config/formFields.js';
import {renderTextField} from '../../common/form/renderer.jsx';
import {createCustomer, loadCustomer} from './action.js';
import {Fields} from '../../../../config/domain/company';
import {lookupCompany} from './action';

export class CustomerCreatePage extends React.Component {

    componentWillMount() {
        let {match: {params = {}}, actions} = this.props;
        let customerId = params.id;

        // do page init
        if (customerId) {
            actions.init(customerId);
        }
    }

    render() {
        const {i18n, handleSubmit, submitting, submitSucceeded, actions, dispatch, values} = this.props;

        const isEdit = values && values[Fields.ID];
        return (
            <InsurTecLayout>
                <BreadCrumbs title={isEdit ? l('Edit customer') : l('Create customer')}
                             items={[l('Customers')]} icon="user"/>

                <div className="wrapper wrapper-content">
                    <Row>
                        <Col md={12}>
                            <Ibox.Container>
                                <Ibox.Title>{isEdit ? l('Edit customer') : l('Create customer')}</Ibox.Title>

                                <Ibox.Content className="p-sm">
                                    {submitSucceeded ? (
                                        <div>{l('Company was successfully created')}</div>
                                    ) : (
                                        <form onSubmit={handleSubmit}>
                                            <h3>{l('Company')}</h3>
                                            <Row>
                                                <Col md={6}>
                                                    <Field name={Fields.BUSINESS_IDENTIFICATION_NUMBER} label={l('BIN')}
                                                           component={renderTextField} disabled={submitting} fullWidth/>
                                                    <Button variant="contained"
                                                            className="m-l-lg"
                                                            disabled={
                                                                submitting || !values || !values[Fields.BUSINESS_IDENTIFICATION_NUMBER]
                                                                || !/^CHE-[0-9]{3}\.[0-9]{3}\.[0-9]{3}$/.test(values[Fields.BUSINESS_IDENTIFICATION_NUMBER])
                                                            }
                                                            color="primary"
                                                            onClick={actions.lookupCompany}>
                                                        {l('Lookup')}
                                                    </Button>

                                                    <br/>
                                                    <Field name={Fields.NAME} label={l('Company name')}
                                                           component={renderTextField}
                                                           disabled={submitting} fullWidth/>

                                                    <br/>
                                                    <Field name={Fields.INDUSTRY_CLASSIFICATION_CODE}
                                                           label={l('NOGA-Code')}
                                                           component={renderTextField} fullWidth={false}
                                                           disabled={submitting} fullWidth/>
                                                    <HelpBlock className="m-b-lg">
                                                        <a href={`https://www.kubb-tool.bfs.admin.ch/${i18n.locale.substr(0, 2)}`}
                                                           target="_blank">
                                                            {l('Find your NOGA code')}
                                                        </a>
                                                    </HelpBlock>
                                                </Col>
                                            </Row>

                                            <h3>{l('Contact')}</h3>
                                            <PersonDataFrom formFields={prefixedPersonDataFormFields('contact.')}
                                                            fieldProps={{fullWidth: true, disabled: submitting}}/>

                                            <div className="m-t-lg"/>
                                            <Button variant="contained"
                                                    color="primary"
                                                    disabled={submitting}
                                                    onClick={actions.submit}>
                                                {isEdit ? l('Save') : l('Create customer')}
                                            </Button>
                                        </form>
                                    )}
                                </Ibox.Content>
                            </Ibox.Container>
                        </Col>
                    </Row>
                </div>
            </InsurTecLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        auth: state.auth,
        user: state.auth.user,
        pageState: {...state.page.customersList},
        values: getFormValues(FORM_NAME)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            submit: () => dispatch(submit(FORM_NAME)),
            lookupCompany: () => dispatch(lookupCompany()),
            init: (customerId) => dispatch(loadCustomer(customerId)),
        },
        dispatch
    }
}

export const FORM_NAME = 'customer-create';
export default reduxForm({
    form: FORM_NAME,
    onSubmit: createCustomer
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerCreatePage));
