import React from 'react';

let Spinner = () => (
    <div className="sk-spinner sk-spinner-three-bounce">
        <div className="sk-bounce1"></div>
        <div className="sk-bounce2"></div>
        <div className="sk-bounce3"></div>
    </div>
);

export default Spinner;