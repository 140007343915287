import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {ACTIONS} from '../../../../../config/domain/entity.js';
import {l} from '../../../../i18n/translator';
import {Fields as OfferFields, States as OfferStates} from '../../../../../config/domain/offer';
import URI from '../../../../../config/uri';
import {sortOfferList} from '../../helper';
import {LocalDate, LocalTime} from '../../../common/i18n/datetime.jsx';
import {PremiumValue} from '../../common/i18n/premium.jsx';
import {EntityActionOnly} from '../../../common/auth/acl/entityAction.jsx';

const OfferRequestActivity = ({offerRequest, offers, insurances, actions, showCreation = false}) => {
    offers = sortOfferList(offers, -1);

    const downcaseFirstLetter = (string) => {
        return string.charAt(0).toLowerCase() + string.slice(1);
    };

    return (
        <div className="ibox">
            <div className="ibox-title">
                <h5>
                    <i className="fa fa-history"/>&nbsp;
                    {l('Activity')}
                </h5>
            </div>
            <div className="ibox-content inspinia-timeline max-height-1000-scroll scrollbars-always">
                {offers.map((offer, index) => {
                        const isCurrent = offer[OfferFields.IS_CURRENT];
                        return (
                            <div key={index} className={classNames('timeline-item')}>
                                <div className="row">

                                    <div className={classNames('col-xs-3', 'date', !isCurrent ? offer.state : '')}>

                                        {offer[OfferFields.OFFICIAL_OFFER_FILE] ? (
                                            <i className="fa fa-file-text-o"></i>
                                        ) : (
                                            <i className="fa fa-file-o"></i>
                                        )}

                                        <LocalDate value={(offer.publishDate || offer.creationDate)}/>
                                        <br/>

                                        <small className="text-navy">
                                            <LocalTime value={(offer.publishDate || offer.creationDate)}/>
                                        </small>
                                    </div>

                                    <div className={classNames('col-xs-8 content')}>
                                        <p className="m-b-xs">
                                            <strong>
                                                {!isCurrent
                                                    ? l('Offer {{state}}', {state: downcaseFirstLetter(l(`state_${offer.state}`))})
                                                    : l('Current insurance policy')}
                                            </strong>

                                            {offer[OfferFields.WITHDRAW_REASON] && (
                                                <span className="m-l-xs">({offer[OfferFields.WITHDRAW_REASON]})</span>
                                            )}
                                            {offer[OfferFields.REJECT_REASON] && (
                                                <span className="m-l-xs">({offer[OfferFields.REJECT_REASON]})</span>
                                            )}
                                        </p>

                                        <p>
                                            {(insurance => (
                                                <span>{insurance ? insurance.name : '?'}</span>
                                            ))(offer.insuranceId ? insurances.find(
                                                i => i.id == offer.insuranceId) : {name: offer.insuranceName})}
                                            <br/>

                                            {offer.state != OfferStates.ABSTAINED ? (
                                                <strong className="text-navy">
                                                    <PremiumValue value={offer.premiumNet}/>
                                                </strong>
                                            ) : null}
                                        </p>

                                        <p>
                                            <EntityActionOnly action={ACTIONS.VIEW} allowedActions={offer.actions}>
                                        <span className="btn btn-xs btn-success"
                                              onClick={() => actions.offer.viewOffer(offerRequest, offer)}>
                                            <i className="fa fa-search m-r-xs"></i>
                                            {!isCurrent ? l('View offer') : l('Show')}
                                        </span>

                                                &nbsp;&nbsp;
                                            </EntityActionOnly>

                                            {offer.officialOfferFileId ? (
                                                <Link to={URI.OFFICIAL_OFFER_FILE.replace(':id', offer.id)} target="_blank">
                                                <span className="btn btn-xs btn-info">
                                                    <i className="fa fa-download"></i>
                                                    &nbsp;
                                                    {l('Official offer file')}
                                                </span>
                                                </Link>
                                            ) : null}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        );
                    }
                )}

                {offerRequest.publishDate ? (
                    <div className={classNames('timeline-item')}>
                        <div className="row">
                            <div className="col-xs-3 date">
                                <i className="fa fa-star"></i>

                                <LocalDate value={offerRequest.publishDate}/>
                                <br/>

                                <small className="text-navy">
                                    <LocalTime value={offerRequest.publishDate}/>
                                </small>
                            </div>
                            <div className={classNames('col-xs-8 content', {
                                'no-top-border': !offerRequest.publishDate && !offers.length
                            })}>
                                <p className="m-b-xs">
                                    <strong>
                                        {l('Offer request published')}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                ) : null}

                {showCreation && offerRequest.creationDate ? (
                    <div className={classNames('timeline-item')}>
                        <div className="row">
                            <div className="col-xs-3 date">
                                <i className="fa fa-star-half-o"></i>
                                <LocalDate value={offerRequest.creationDate}/>
                                <br/>
                                <small className="text-navy">
                                    <LocalTime value={offerRequest.creationDate}/>
                                </small>
                            </div>
                            <div className={classNames('col-xs-8 content', {
                                'no-top-border': !offerRequest.publishDate && !offers.length
                            })}>
                                <p className="m-b-xs">
                                    <strong>
                                        {l('Offer request created')}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default OfferRequestActivity;
