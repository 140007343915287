import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import classNames from 'classnames';
import * as queryString from 'query-string';

import URI from '../../../../config/uri';
import {l} from '../../../i18n/translator';
import * as Actions from './action';
import {renderTextField} from '../../common/form/renderer.jsx';
import AuthPage from '../../common/auth/authPage.jsx';

/**
 * LoginPage component
 */

export class LoginPage extends React.Component {
    componentWillMount() {
        const {initialize, location} = this.props;

        if (location && location.search) {
            const query = queryString.parse(location.search);
            if (query.ref) {
                initialize({redirect: query.ref});
            }
        }
    }

    render() {
        const {handleSubmit, submitting, error} = this.props;
        const {mobileTokenRequired} = this.props;

        return (
            <AuthPage icon="lock" title={l('Login')}>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className={classNames("form-group", {
                            'hidden': mobileTokenRequired
                        })}>
                            <Field name="user" component={renderTextField}
                                   label={l('E-Mail')}
                                   disabled={submitting}
                                   fullWidth={true}
                                   autoFocus={true}/>
                            <div className="m-t-sm">
                                <Field name="password" component={renderTextField}
                                       label={l('Password')}
                                       type='password'
                                       fullWidth={true}
                                       disabled={submitting}/>
                            </div>
                            <div className="m-t-sm">
                                <Link to={URI.FORGOT_PASSWORD}>
                                    <small>{l('Forgot password?')}</small>
                                </Link>
                            </div>
                        </div>

                        <Field name="mobileToken" component={renderTextField}
                               label={l('Mobile Token')}
                               disabled={submitting}
                               className={classNames("form-group", {
                                   'hidden': !mobileTokenRequired
                               })}/>
                    </div>

                    <p className="text-left text-danger m-t-sm">
                        {error}
                    </p>

                    <button disabled={submitting} type="submit"
                            className="btn btn-danger block m-b">
                        {!submitting ? (
                            l('Login')
                        ) : (
                            <span>
                                <i className="fa fa-cog m-r-sm"></i> {l('Logging in, please wait')}
                            </span>
                        )}
                    </button>
                </form>
            </AuthPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
        mobileTokenRequired: state.page.login.form.mobileTokenRequired
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default reduxForm({
    form: 'login',
    onSubmit: Actions.doLogin,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage));

