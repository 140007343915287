/**
 * Created by br0wn on 12/5/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {l} from '../../../../../i18n/translator.js';
import {sumNumbers} from '../../../../../helper/number.js';
import {normalizeSalariesData} from '../../../../../model/offerRequest/uvg.js';
import {PremiumValue} from '../../../common/i18n/premium.jsx';
import * as Ibox from '../../../../ui-components/ibox.jsx';
import {STEP_INSURANCE_DATA} from '../../../create-request/config/wizard';
import EditButton from '../editButton.jsx';


export const UVGInsuranceInformation = ({offerRequest = {}}) => {
    offerRequest = {
        ...offerRequest,
        data: normalizeSalariesData(offerRequest.data)
    };

    return (
        <Row>
            <Col mdOffset={3} md={6}>
                <Ibox.Container>
                    <Ibox.Title>
                        <h5>
                            <i className="fa fa-file"/>&nbsp;

                            {l('UVG Salaries')}
                        </h5>

                        <EditButton step={STEP_INSURANCE_DATA}/>
                    </Ibox.Title>

                    <Ibox.Content>
                        <div className="row m-t-xs m-b-xs">
                            <div className="col-xs-6">
                                <h4 className="m-b-s">{l('Salaries Job Accidents')}</h4>
                                <small className="pull-left">{l('Total')}</small>
                                <h1 className="m-xs m-l-xl">
                                    <PremiumValue value={sumNumbers(
                                        offerRequest.data.salarySumsJobAccidents.men,
                                        offerRequest.data.salarySumsJobAccidents.women
                                    )}/>
                                </h1>
                            </div>

                            <div className="col-xs-6">
                                <h4 className="m-b-s">{l('Salaries Non-Job Accidents')}</h4>
                                <small className="pull-left">{l('Total')}</small>
                                <h1 className="m-xs m-l-xl">
                                    <PremiumValue value={sumNumbers(
                                        offerRequest.data.salarySumsNonJobAccidents.men,
                                        offerRequest.data.salarySumsNonJobAccidents.women
                                    )}/>
                                </h1>
                            </div>
                        </div>

                        <table className="table m-t-sm">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong className="">{l('Men')}</strong>
                                        <span className="pull-right fa-lg">
                                            {offerRequest.data.salarySumsJobAccidents.men ? (
                                                <PremiumValue value={offerRequest.data.salarySumsJobAccidents.men}/>
                                            ) : '-'}
                                        </span>
                                    </td>
                                    <td>
                                        <strong className="">{l('Men')}</strong>
                                        <span className="pull-right fa-lg">
                                            {offerRequest.data.salarySumsNonJobAccidents.men ? (
                                                <PremiumValue value={offerRequest.data.salarySumsNonJobAccidents.men}/>
                                            ) : '-'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong className="">{l('Women')}</strong>
                                        <span className="pull-right fa-lg">
                                            {offerRequest.data.salarySumsJobAccidents.women ? (
                                                <PremiumValue value={offerRequest.data.salarySumsJobAccidents.women}/>
                                            ) : '-'}
                                        </span>
                                    </td>
                                    <td>
                                        <strong className="">{l('Women')}</strong>
                                        <span className="pull-right fa-lg">
                                            {offerRequest.data.salarySumsNonJobAccidents.women ? (
                                                <PremiumValue value={offerRequest.data.salarySumsNonJobAccidents.women}/>
                                            ) : '-'}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Ibox.Content>
                </Ibox.Container>
            </Col>
        </Row>
    );
};


export const UVGInsuranceInformationContainer = connect(
    state => ({
        i18n: state.i18n,
        offerRequest: state.page.offerRequestDetails.data.offerRequest
    })
)(UVGInsuranceInformation);
