/**
 * Created by br0wn on 11/24/16.
 */
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import URI from '../../../../../config/uri.js';
import {ACTIONS} from '../../../../../config/domain/entity';
import {EntityActionOnly} from '../../../common/auth/acl/entityAction.jsx';
import {l} from '../../../../i18n/translator.js';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {LocalDate, LocalTime} from '../../../common/i18n/datetime.jsx';
import FileUploadForm from '../../../common/form/file-upload/component/singleFileUpload.jsx';
import {onFileUploaded, removeFile} from '../action/offerRequest';
import {AttachmentTypes} from '../../../../../config/domain/file';

export class ProjectFilesComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {editMode: false, isRequestInProgress: false}
    }

    doRequest(requestAction, ...args) {
        this.setState({isRequestInProgress: true});

        return requestAction(...args).then(
            () => this.setState({isRequestInProgress: false})
        ).catch(
            () => this.setState({isRequestInProgress: false})
        )
    }


    renderEdit({show}) {
        const {offerRequest} = this.props;
        const {onFileUploaded} = this.props;

        return (
            <div className={show ? '' : 'hidden'}>
            <Ibox.Content>
                <FileUploadForm
                    attachmentType={AttachmentTypes.ATTACHMENT}
                    offerRequestId={offerRequest.id}
                    actions={{
                        onUploaded: (file) => {
                            onFileUploaded(file);
                            this.setState({editMode: false});
                        }
                    }}/>
            </Ibox.Content>
            </div>
        )
    }

    render() {
        const {offerRequest} = this.props;
        const {removeFile} = this.props;
        const {editMode, isRequestInProgress} = this.state;

        return (
            <Ibox.Container>
                <Ibox.Title>
                    <h5>
                        <i className="fa fa-file"/>&nbsp;
                        {l('Offer request files')}
                    </h5>

                    {isRequestInProgress ? (
                        <span className="pull-right">
                            <i className="fa fa-cog"/>
                        </span>
                    ) : null}

                    <EntityActionOnly action={ACTIONS.EDIT} allowedActions={offerRequest.actions}>
                        {editMode ? (
                            <Button bsSize="sm" className="pull-right m-t-n-xs"
                                    onClick={() => this.setState({editMode: false})}>
                                <i className="fa fa-document"/>&nbsp;
                                {l('Cancel')}
                            </Button>
                        ) : (
                            <Button bsSize="sm" className="pull-right m-t-n-xs"
                                    onClick={() => this.setState({editMode: true})}>
                                <i className="fa fa-document"/>&nbsp;
                                {l('Add new')}
                            </Button>
                        )}
                    </EntityActionOnly>
                </Ibox.Title>

                {offerRequest.files.attachments && offerRequest.files.attachments.length > 0 ? (
                    <Ibox.Content className="no-padding">
                        <table className="table table-striped">
                            <tbody>
                            {offerRequest.files.attachments.map((attachment, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={
                                            URI.OFFER_REQUEST_FILE
                                                .replace(':id', offerRequest.id)
                                                .replace(':fileId', attachment.id)
                                        } target="_blank">
                                            <div>
                                                {attachment.fileName}
                                            </div>
                                        </Link>
                                        <div>
                                            <small>
                                                <LocalDate value={attachment.creationDate}/>
                                                &nbsp;
                                                <LocalTime value={attachment.creationDate}/>
                                            </small>
                                        </div>
                                    </td>

                                    <EntityActionOnly action={ACTIONS.EDIT} allowedActions={offerRequest.actions}>
                                        <td className="text-right">
                                            <Button
                                                bsStyle="link"
                                                disabled={isRequestInProgress}
                                                onClick={() => this.doRequest(removeFile, offerRequest, attachment)}
                                            >
                                                <i className="fa fa-times"/>&nbsp;
                                                {l('Remove')}
                                            </Button>
                                        </td>
                                    </EntityActionOnly>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Ibox.Content>
                ) : (
                    <Ibox.Content className="no-data">
                        {l('No files attached')}
                    </Ibox.Content>
                )}

                {this.renderEdit({show: editMode})}
            </Ibox.Container>
        );
    }
}

export const ProjectFiles = connect(
    state => ({
        i18n: state.i18n
    }),
    dispatch => bindActionCreators({onFileUploaded, removeFile}, dispatch)
)(ProjectFilesComponent);
