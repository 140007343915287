import {CompanyTypes as UserTypes} from '../../../../../config/domain/user';
import {States as OfferRequestStates} from '../../../../../config/domain/offerRequest';
import {l} from '../../../../i18n/translator';
import {isUserOfType} from '../../../../auth/helper';
import ChatActions from '../../../common/chat/action/chat';
import {CHAT_ACTIONS} from './type';
import {fetchOfferRequestMessages, fetchOfferRequestMessagesOverview, postOfferRequestMessage} from './api';


const THREAD_TYPE = {
    BROKER: 'broker',
    INSURANCE: 'insurance',
    CUSTOMER: 'customer',
    INSURANCE_BROADCAST: 'insurance_broadcast'
};

const INSURANCE_BROADCAST_THREAD = {
    type: THREAD_TYPE.INSURANCE_BROADCAST,
    name: l('All insurances')
};


/**
 * Builds chat thread from api thread data
 *
 * @param {object} apiThread
 * @return {object}
 */
export const buildThread = (apiThread) => {
    const {type, name, messagesCount, unreadMessagesCount, insuranceId} = apiThread;

    // set base thread data
    let thread = {type, name, messagesCount, unreadMessagesCount};
    let threadId = getThreadId({channel: type, insuranceId});

    return {
        ...thread,
        id: threadId
    };
};

export const getThreadId = ({channel, insuranceId}) => {
    switch (channel) {
        case THREAD_TYPE.INSURANCE_BROADCAST: {
            return 'insurances';
        }
        case THREAD_TYPE.INSURANCE: {
            return `insurances/${insuranceId}`;
        }
        default : {
            return channel;
        }
    }
};

export const buildInsuranceBroadcastMessage = () => {
    const date = (new Date()).toISOString();

    return {
        "id": `'insurtec-chatbot-broadcast-${date}`,
        "creatorId": 'insurtec-chatbot',
        "creatorCompanyType": "insurtec-chatbot",
        "creatorName": "InsurTec Chat",
        "creationDate": date,
        "read": true,
        "content": l('This is a chanel for broadcasting message to all insurances. No other messages will be displayed here')
    };
};


class RequestDetailsChatActions extends ChatActions {

    /**
     * Updates state when thread is selected
     *
     * @param threadId
     * @return {function(*)}
     */
    onThreadSelected(threadId) {
        return (dispatch, getState) => {
            const ACTION_TYPES = this.ACTION_TYPES;

            dispatch({
                type: ACTION_TYPES.THREAD_SELECTED,
                threadId: threadId
            });

            const chatState = this.getChatState(getState);

            /*
             * Load messages if first load or already loaded
             */
            if (!chatState.threadStates[threadId] || chatState.threadStates[threadId].isLoaded) {
                dispatch(this.loadThreadMessages(threadId));
            }
        };
    }

    /**
     * @inheritDoc
     */
    getChatState(getState) {
        return getState().page.offerRequestDetails.chat;
    }

    /**
     * Returns offer request object from the state
     * @param getState
     * @return {object}
     */
    getOfferRequest(getState) {
        return getState().page.offerRequestDetails.data.offerRequest || {};
    }

    /**
     * Returns user object from global state provided by `getState`
     *
     * @param {function} getState
     * @return {null|object}
     */
    getAuth(getState) {
        return getState().auth;
    }

    /**
     * Returns thread info from chat state
     *
     * @param {function} getState
     * @param {string} threadId
     * @return {null|object}
     */
    getThreadInfo(getState, threadId) {
        const chatState = this.getChatState(getState);
        const thread = chatState.threads.find(t => t.id === threadId);

        return thread ? thread : null;
    }

    /**
     * @inheritDoc
     */
    fetchThreadMessages(threadId) {
        return (dispatch, getState) => {
            const offerRequest = this.getOfferRequest(getState);
            const threadInfo = this.getThreadInfo(getState, threadId) || {};

            if (threadInfo.type === THREAD_TYPE.INSURANCE_BROADCAST) {
                return Promise.resolve([
                    buildInsuranceBroadcastMessage()
                ]);
            }

            return dispatch(
                fetchOfferRequestMessages(offerRequest.id, threadId)
            );
        };
    }

    /**
     * @inheritDoc
     */
    fetchThreadList() {
        return (dispatch, getState) => {
            const auth = this.getAuth(getState);
            const offerRequest = this.getOfferRequest(getState);

            return dispatch(
                fetchOfferRequestMessagesOverview(offerRequest.id)
            ).then(
                threadList => {
                    // transform api data structure to chat data structure
                    let threads = threadList.map(apiThread => buildThread(apiThread));
                    // add insurance broadcast thread
                    if (isUserOfType(auth, UserTypes.BROKER)
                        && (offerRequest.state === OfferRequestStates.PUBLISHED
                        || offerRequest.state === OfferRequestStates.EXPIRED
                        || offerRequest.state === OfferRequestStates.WITHDRAWN)) {
                        threads = [...threads, buildThread(INSURANCE_BROADCAST_THREAD)];
                    }
                    return threads;
                }
            );
        };
    }

    /**
     * @inheritDoc
     */
    postMessage(message, threadId) {
        return (dispatch, getState) => {
            const offerRequest = this.getOfferRequest(getState);
            const messageObject = {
                "content": message
            };

            return dispatch(
                postOfferRequestMessage(offerRequest.id, threadId, messageObject)
            ).then(data => {
                const threadInfo = this.getThreadInfo(getState, threadId) || {};

                if (threadInfo.type === THREAD_TYPE.INSURANCE_BROADCAST) {
                    return data[0];
                }

                return data;
            });
        };
    }
}

export default new RequestDetailsChatActions(CHAT_ACTIONS);
