const TYPES = {
    FORM_FIELD_CHANGED: 'page.administration.form.field_changed',

    SAVE_START: 'page.administration.save_start',
    SAVE_END: 'page.administration.save_end',
    CLEAR_SAVED_FLAG: 'page.administration.clear_saved_flag',

    SET_FORM_DATA: 'page.administration.form.set_data',

    DATA_LOADED: 'page.administration.data_loaded',

    PAGE_LOAD_START: 'page.administration.load_start',
    PAGE_LOAD_END: 'page.administration.load_end',
    PAGE_RESET: 'page.administration.reset',

    SET_ACTIVE_TAB: 'page.administration.set_active_tab'
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    DATA_LOADED: TYPES.DATA_LOADED,
    PAGE_RESET: TYPES.PAGE_RESET
};

export default TYPES;
