const TYPES = {
    OFFER_FILE_UPLOAD_START: 'page.offer_details.form.offer_file.upload_start',
    OFFER_FILE_UPLOAD_END: 'page.offer_details.form.offer_file.upload_end',
    OFFER_FILE_UPLOAD_PROGRESS: 'page.offer_details.form.offer_file.upload_progress',
    OFFER_FILE_SELECTED: 'page.offer_details.form.offer_file_selected',
    OFFER_FILE_REMOVE_START: 'page.offer_details.form.offer_file_remove_start',
    OFFER_FILE_REMOVE_END: 'page.offer_details.form.offer_file_remove_end'
};

export const OFFER_UPLOAD_ACTIONS = {
    UPLOAD_START: TYPES.OFFER_FILE_UPLOAD_START,
    UPLOAD_END: TYPES.OFFER_FILE_UPLOAD_END,
    UPLOAD_PROGRESS: TYPES.OFFER_FILE_UPLOAD_PROGRESS,
    REMOVE_FILE_START: TYPES.OFFER_FILE_REMOVE_START,
    REMOVE_FILE_END: TYPES.OFFER_FILE_REMOVE_END,
    FILE_SELECTED: TYPES.OFFER_FILE_SELECTED
};

export default TYPES;