import DEFAULT_TYPES from '../action/type';

export const INITIAL_STATE = {
    selectedFile: null,
    progress: null,
    isUploading: false,
    isRemoving: false,
    uploadedFile: null,
    error: null
};

let singleFileUploadReducer = (state = INITIAL_STATE, action, ACTION_TYPES = DEFAULT_TYPES) => {
    switch (action.type) {
        case ACTION_TYPES.UPLOAD_START: {
            return {
                ...state,
                isUploading: true,
                progress: 0,
                error: null
            };
        }
        case ACTION_TYPES.UPLOAD_END: {
            return action.error ? {
                ...state,
                isUploading: false,
                progress: null,
                error: action.error
            } : {
                ...INITIAL_STATE,
                uploadedFile: {
                    ...action.file
                }
            };
        }
        case ACTION_TYPES.UPLOAD_PROGRESS: {
            return {
                ...state,
                progress: action.progress
            };
        }
        case ACTION_TYPES.REMOVE_FILE_START: {
            return {
                ...state,
                isRemoving: true,
                error: null
            };
        }
        case ACTION_TYPES.REMOVE_FILE_END: {
            return {
                ...state,
                isRemoving: false,
                uploadedFile: action.error ? state.uploadedFile : null,
                error: action.error || null
            };
        }
        case ACTION_TYPES.FILE_SELECTED: {
            return {
                ...state,
                selectedFile: action.file,
                error: null
            };
        }
    }

    return state;
};

export default singleFileUploadReducer;
