import {SubmissionError} from 'redux-form';
import {l} from '../../../i18n/translator';
import API from  '../../../../config/api/routes';
import API_ERRORS from  '../../../../config/api/errors';
import {handleApiValidationErrorResponse} from '../../../../lib/http';
import {jsonPostRequest} from '../../../api/request';
import {makeApiRequest} from '../../../api/action';
import {buildErrors} from '../../common/form/helper';


export const TYPES = {
};


export const apiPasswordReset = (data) => {
    const {email} = data;
    return (dispatch) => {
        return dispatch(makeApiRequest(API.AUTH.RESET_PASSWORD, jsonPostRequest({
                body: JSON.stringify({email: email})
            }))
        ).catch(handleApiValidationErrorResponse);
    };
};

export let doPasswordReset = (data, dispatch) => {
    return dispatch(
        apiPasswordReset(data)
    ).catch((error) => {
        if (error.error) {
            const errorMessage = (() => {
                switch (error.error) {
                    case API_ERRORS.AUTH.RESET_PASSWORD.INVALID_USER: {
                        return l('User not found.');
                    }
                    default: {
                        return l('Unknown response received from server.');
                    }
                }
            })();
            throw new SubmissionError({_error: errorMessage});
        }
        if (error.validationErrors) {
            const validationErrors = buildErrors(error.validationErrors);
            throw new SubmissionError(validationErrors);
        }
        throw new SubmissionError({_error: l('Something went wrong, please reload the page and try again.')});
    });
};

