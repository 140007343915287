
import {l} from '../../../../i18n/translator';
import {warn} from '../../../../../lib/logger';
import {fetchOfferRequestList, fetchInsuranceTypes} from '../../../../api/sdk-action';
import {listLoadEnd} from './list';
import TYPES from './type';


export const init = () => {
    return (dispatch) => {
        dispatch({
            type: TYPES.PAGE_LOAD_START
        });

        Promise.all([
            dispatch(fetchInsuranceTypes()),
            dispatch(fetchOfferRequestList())

        ]).then(([insuranceTypes, offerRequestList]) => {
            dispatch(dataLoaded('insuranceTypes', insuranceTypes));
            dispatch(listLoadEnd(offerRequestList));
            dispatch(pageLoadEnd({isLoaded: true}));

        }).catch(error => {
            warn("Error while fetching offer resources!", error);
            let errors = [];

            if (error.requestError) {
                errors.push(l("Unknown response received from server"));
            } else {
                errors.push(l("Unknown error occurred"));
            }

            dispatch(pageLoadEnd({errors: errors}));
        });
    };
};

export const dataLoaded = (name, values) => {
    return {
        type: TYPES.DATA_LOADED,
        data: {[name]: values}
    };
};

export const pageLoadEnd = (params = {}) => ({
    type: TYPES.PAGE_LOAD_END,
    ...params
});