export const TradingVolumeFields = {
    EXPECTATIONS_NEXT_YEAR: 'expectationsNextYear',
    EXPECTATIONS_CURRENT_YEAR: 'expectationsCurrentYear',
    LAST_YEAR: 'lastYear',
    TWO_YEARS_AGO: 'twoYearsAgo',
    THREE_YEARS_AGO: 'threeYearsAgo'
};


export const Fields = {
    CURRENCY: 'data.currency',
    TRADING_VOLUME: 'data.tradingVolume',
    TRADING_VOLUME_EXPECTATIONS_NEXT_YEAR: `data.tradingVolume.${TradingVolumeFields.EXPECTATIONS_NEXT_YEAR}`,
    TRADING_VOLUME_EXPECTATIONS_CURRENT_YEAR: `data.tradingVolume.${TradingVolumeFields.EXPECTATIONS_CURRENT_YEAR}`,
    TRADING_VOLUME_LAST_YEAR: `data.tradingVolume.${TradingVolumeFields.LAST_YEAR}`,
    TRADING_VOLUME_TWO_YEARS_AGO: `data.tradingVolume.${TradingVolumeFields.TWO_YEARS_AGO}`,
    TRADING_VOLUME_THREE_YEARS_AGO: `data.tradingVolume.${TradingVolumeFields.THREE_YEARS_AGO}`,

    SALES: 'sales',
    ACCOUNTSRECEIVABLE: 'accountsReceivable',
    MANAGEMENT: 'management',
    SCHEDULE: 'schedule',
    SALES_CURRENCY: 'sales.currency',
    SALES_VOLUME_FORECASTNEXTYEAR: 'sales.volume.forecastNextYear',
    SALES_VOLUME_FORECASTCURRENTYEAR: 'sales.volume.forecastCurrentYear',
    SALES_VOLUME_LASTTHREEYEARS: 'sales.volume.lastThreeYears',
    SALES_SALESSTRUCTURE: 'sales.salesStructure',
    SALES_NONINSURABLESALESPERCENTAGE: 'sales.nonInsurableSalesPercentage',
    ACCOUNTSRECEIVABLE_DEVELOPMENT: 'accountsReceivable.development',
    ACCOUNTSRECEIVABLE_STRUCTURE: 'accountsReceivable.structure',
    ACCOUNTSRECEIVABLE_BIGGESTCUSTOMERS: 'accountsReceivable.biggestCustomers',
    ACCOUNTSRECEIVABLE_BADDEPTS: 'accountsReceivable.badDepts',
    MANAGEMENT_ASSESSMENT_NEWBUYERS_FREQUENCY: 'management.assessment.newBuyers.frequency',
    MANAGEMENT_ASSESSMENT_NEWBUYERS_EXCESS: 'management.assessment.newBuyers.excess',
    MANAGEMENT_ASSESSMENT_EXISTINGBUYERS_FREQUENCY: 'management.assessment.existingBuyers.frequency',
    MANAGEMENT_ASSESSMENT_EXISTINGBUYERS_EXCESS: 'management.assessment.existingBuyers.excess',
    MANAGEMENT_ASSESSMENT_SOURCES_INFORMATIONBUREAUS: 'management.assessment.sources.informationBureaus',
    MANAGEMENT_ASSESSMENT_SOURCES_DEBTCOLLECTIONOFFICES: 'management.assessment.sources.debtCollectionOffices',
    MANAGEMENT_ASSESSMENT_SOURCES_OTHER: 'management.assessment.sources.other',
    MANAGEMENT_ASSESSMENT_FINANCIALREPORTSOBTAINEDANNUALLY: 'management.assessment.financialReportsObtainedAnnually',
    MANAGEMENT_ANNUALFINANCIALSTATEMENTS_ISREQUESTED: 'management.annualFinancialStatements.isRequested',
    MANAGEMENT_ANNUALFINANCIALSTATEMENTS_CASES: 'management.annualFinancialStatements.cases',
    MANAGEMENT_CREDITLIMITS: 'management.creditLimits',
    MANAGEMENT_CREDITLIMITSDECISION: 'management.creditLimitsDecision',
    MANAGEMENT_SECURERECEIVABLES_RETENTIONCASES: 'management.secureReceivables.retentionCases',
    MANAGEMENT_SECURERECEIVABLES_BANKGUARANTEECASES: 'management.secureReceivables.bankGuaranteeCases',
    SCHEDULE_REMINDER_FIRST: 'schedule.reminder.first',
    SCHEDULE_REMINDER_SECOND: 'schedule.reminder.second',
    SCHEDULE_REMINDER_THIRD: 'schedule.reminder.third',
    SCHEDULE_REMINDER_FOURTH: 'schedule.reminder.fourth',
    SCHEDULE_REMINDER_CALLORVISIT: 'schedule.reminder.callOrVisit',
    SCHEDULE_REMINDER_CEASEDELIVERING: 'schedule.reminder.ceaseDelivering',
    SCHEDULE_REMINDER_BEGINLEGALPROCEEDINGS: 'schedule.reminder.beginLegalProceedings',
    SCHEDULE_ENFORCEDDEBTCOLLECTION_SELF: 'schedule.enforcedDebtCollection.self',
    SCHEDULE_ENFORCEDDEBTCOLLECTION_THIRDPARTY: 'schedule.enforcedDebtCollection.thirdParty',
    SCHEDULE_ENFORCEDDEBTCOLLECTION_OCCURRENCESLASTTWELVEMONTHS: 'schedule.enforcedDebtCollection.occurrencesLastTwelveMonths'
};

export const DefaultData = {
    sales: {
        volume: {
            lastThreeYears: []
        },
        salesStructure: []
    },
    accountsReceivable: {
        development: [],
        structure: [],
        biggestCustomers: [],
        badDepts: []
    },
    management: {
        annualFinancialStatements: {
            cases: []
        },
        creditLimitsDecision: [],
        secureReceivables: {
            retentionCases: [],
            bankGuaranteeCases: []
        }
    },
    schedule: {
        enforcedDebtCollection: {
            self: [],
            thirdParty: []
        }
    }
};
