/**
 * Created by br0wn on 11/24/16.
 */
import React from 'react';


export class ScrollArea extends React.Component {

    componentDidMount() {
        const {options = {}} = this.props;

        if (options.start === 'bottom') {
            this.container.scrollTop = this.container.scrollHeight;
        }
    }

    componentDidUpdate(prevProps) {
        const {options = {}} = this.props;
        // This could probably be optimized further by having an option that says if this should happen or not. And as
        // well to have a kind of contentId on the ScrollArea so that if the content changes but has same amount of
        // children, that it scrolls as well to the bottom.
        if (options.start === 'bottom' &&
            (prevProps.children == null
                || this.props.children == null
                || prevProps.children !== this.props.children
                || prevProps.children.length !== this.props.children.length)) {
            this.container.scrollTop = this.container.scrollHeight;
        }
    }

    render() {
        const {props} = this;

        return (
            <div className={`${this.props.className} full-height-scroll`} ref={node => this.container = node}>
                {props.children}
            </div>
        )
    }
}
