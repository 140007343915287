

/**
 * Loads Intl extension polyfill if it is not present in global scope. Calls callback when polyfill is loaded.
 *
 * @param callback
 */
export default (callback) => {
    if (!global.Intl) {
        // TODO maybe we need to do what this says: https://webpack.js.org/guides/migrating/#code-splitting-with-es2015
        require.ensure([
            'intl',
            'intl/locale-data/jsonp/en.js',
            'intl/locale-data/jsonp/de.js',
            'intl/locale-data/jsonp/de-CH.js'
        ], function (require) {
            require('intl');
            require('intl/locale-data/jsonp/en.js');
            require('intl/locale-data/jsonp/de.js');
            require('intl/locale-data/jsonp/de-CH.js');

            callback();
        });
    } else {
        callback();
    }
};
