/**
 * Created by br0wn on 11/21/16.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {l} from '../../../i18n/translator.js';
import URI from '../../../../config/uri.js';
import {States} from '../../../../config/domain/offerRequest.js';
import {fetchOfferRequestList} from '../../../api/sdk-action'
import {BaseWidget} from './widget-base.jsx';
import {
    Table as OfferRequestTable, Column as OfferRequestColumn, favoriteValueFormat, EndDateCellComponent
} from '../../insurance-offer/request-list/component/table.jsx';


export class BiddingRequestsWidget extends BaseWidget {

    componentWillMount() {
        this.props.widgetInitializing(this.getType());

        const pendingStates = States.PUBLISHED;
        const listLength = 10;

        this.props.dispatch(fetchOfferRequestList(
            1, 'state', pendingStates, listLength
        )).then(data => {
            this.props.widgetInitialized(this.getType(), {list: data.items})
        }).catch(error => {
            this.props.widgetInitError(this.getType(), error);
        })
    }

    getType() {
        return 'broker-requests-bidding';
    }

    getTitle() {
        return l('Requests - Bidding');
    }

    renderContent() {
        const {data} = this.props;
        const list = data.list || [];

        return (
            <div className="no-padding">
                <OfferRequestTable items={list} className="m-b-none table-hover table-striped">
                    <OfferRequestColumn name={l('Customer name')} field="customerName"/>
                    <OfferRequestColumn name={l('Offer round end')} cellComponent={EndDateCellComponent}/>
                    <OfferRequestColumn name={l('# Offers')} field="offerCount"/>
                    <OfferRequestColumn field="favorite" valueFormat={favoriteValueFormat}/>
                </OfferRequestTable>

                <div className="text-center p-h-xs">
                    <Link to={URI.OFFER_REQUEST_LIST} className="btn btn-default">
                        {l('VIEW ALL')}
                    </Link>
                </div>
            </div>
        )
    }
}
