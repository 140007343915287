/**
 * Returns parameter `name` from `url`
 *
 * @param {string} name
 * @param {string} url
 * @return {*}
 */
export function getQueryParameter(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&"); // eslint-disable-line no-useless-escape

    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Updates query parameter value in url
 * If parameter does not exist, it will be added to the end of query.
 * If parameter value is set to null|undefined or omitted, parameter will be removed
 *
 * @param {string} url
 * @param {string} name
 * @param {null|string} value
 * @return {*}
 */
export function updateQueryParameter(url, name, value) {
    // escape name
    name = name.replace(/[\[\]]/g, "\\$&"); // eslint-disable-line no-useless-escape
    // set default value to null
    value = typeof value === "undefined" ? null : value;

    let encodedValue = encodeURIComponent(value),
        regex = new RegExp("([?&]" + name + ")(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

    if (results && value !== null) {
        // update param
        return url.replace(regex, '$1=' + encodedValue);
    } else if (value !== null) {
        // add param to end
        return url + (/\?/.test(url) ? '&' : '?') + `${name}=${encodedValue}`;
    } else {
        // remove param from url
        return url.replace(regex, '');
    }
}


/**
 * Generates uri by replacing params in path
 *
 * @param {string} path
 * @param {object} params Object where keys are params to be replaced with corresponding value
 * @return {*}
 */
export const generateUri = (path, params) => {
    Object.keys(params).forEach(param => {
        const value = params[param];
        path = path.replace(param, value);
    });

    return path;
};
