import React from 'react';
import classNames from 'classnames';
import {l} from '../../../../../i18n/translator';
import Message from './message.jsx';
import {ScrollArea} from '../../../../ui-components/scroll.jsx';


class MessageList extends React.Component {

    getComponents() {
        return {
            Message
        }
    }

    render() {
        const {chatUser, isLoaded, messages = []} = this.props;
        const hasMessages = messages.length > 0;
        const {
            Message
        } = this.getComponents();

        return (
            <div className={classNames("chat-discussion-wrapper")}>
                <ScrollArea options={{start: 'bottom'}} className="chat-discussion">
                    {hasMessages ?
                        messages.map((message, index) => (
                            <Message key={index} message={message} chatUser={chatUser}/>
                        ))
                        : // else
                        !hasMessages && isLoaded ? (
                            <p className="text-center m-lg">
                                {l('There are currently no messages.')}
                            </p>
                        ) : null}


                </ScrollArea>
            </div>
        )
    }
}

export default MessageList;