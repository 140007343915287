/**
 * API URI Routes
 *
 * @type {object}
 */
export default {
    AUTH: {
        LOGIN: '/v1/auth',
        REGISTER: '/v1/auth/register',
        NEW_PASSWORD: '/v1/auth/password',
        RESET_PASSWORD: '/v1/auth/password/reset',
        VERIFY: '/v1/auth/verify',
        VERIFY_MOBILE: '/v1/auth/verify/mobile',

        API_KEYS: '/v1/auth/keys',
        API_KEY: '/v1/auth/keys/:key'
    },

    OFFERS: '/v1/offers',
    OFFER: '/v1/offers/:id',
    OFFER_VALIDATE: '/v1/validate/offer',
    OFFER_OFFICIAL_FILE: '/v1/offers/:id/files/official-offer',
    OFFER_PUBLISH: '/v1/offers/:id/publish',
    OFFER_REJECT: '/v1/offers/:id/reject',
    OFFER_WITHDRAW: '/v1/offers/:id/withdraw',
    OFFER_ACCEPT: '/v1/offers/:id/accept',
    OFFER_REQUEST_OFFICIAL: '/v1/offers/:id/request-official-offer',
    OFFER_ANALYSIS_RATINGS_COMMENT: '/v1/offers/:id/analysis/ratings-comment',

    OFFER_REQUESTS: '/v1/requests',
    OFFER_REQUESTS_FINISHED: '/v1/requests/finished',
    OFFER_REQUEST_FINISHED: '/v1/requests/finished/:id',
    OFFER_REQUESTS_SELECTED: '/v1/requests/selected',
    OFFER_REQUEST: '/v1/requests/:id',
    OFFER_REQUEST_PUBLISH: '/v1/requests/:id/publish',
    OFFER_REQUEST_ABSTAIN: '/v1/requests/:id/abstain',
    OFFER_REQUEST_WITHDRAW: '/v1/requests/:id/withdraw',
    OFFER_REQUEST_OFFERS: '/v1/requests/:id/offers',
    OFFER_REQUEST_CONTACT: '/v1/requests/:id/contact',
    OFFER_REQUEST_FILES: '/v1/requests/:id/files',
    OFFER_REQUEST_FILE: '/v1/requests/:id/files/:fileId',
    OFFER_REQUEST_NOTES: '/v1/requests/:id/notes',
    OFFER_REQUEST_FAVORITE: '/v1/requests/:id/favorite',
    OFFER_REQUEST_UTILITY_ANALYSIS: '/v1/requests/:id/utility-analysis',
    OFFER_REQUEST_VALIDATE: '/v1/validate/request',

    OFFER_REQUEST_MESSAGE_THREADS: '/v1/requests/:id/messages/overview',
    OFFER_REQUEST_THREAD_MESSAGES: '/v1/requests/:id/messages/:threadId',

    OFFER_REQUEST_INSURANCE_DATA: '/v1/requests/:id/insurance/data',
    OFFER_REQUEST_INSURANCE_FILES: '/v1/requests/:id/insurance/files',
    OFFER_REQUEST_INSURANCE_FILE: '/v1/requests/:id/insurance/files/:fileId',
    OFFER_REQUEST_INSURANCE_ADVISORY_MANDATE: '/v1/requests/:id/insurance/files/advisory-mandate',

    OFFER_REQUEST_ADVISORY_MANDATE: '/v1/requests/:id/files/advisory-mandate',
    OFFER_REQUEST_ADVISORY_MANDATE_TEMPLATE: '/v1/requests/files/advisory-mandate-template',

    ANALYSIS_CRITERIA: '/v1/analysis/criteria',
    ANALYSIS_CRITERIA_BY_ID: '/v1/analysis/criteria/:id',

    ANALYSIS_AUTO_RATINGS: '/v1/analysis/auto-ratings',
    ANALYSIS_AUTO_RATING: '/v1/analysis/auto-ratings/:id',

    INSURANCES: '/v1/insurances',
    INSURANCE_TYPES: '/v1/insurances/types',

    BROKERS: '/v1/brokers',

    COMPANY: '/v1/company',
    COMPANY_LOOKUP: '/v1/company/lookup?id=:id',
    COMPANY_VALIDATE: '/v1/validate/company',

    CUSTOMERS: '/v1/customers',
    CUSTOMERS_CREATE: '/v1/customers',
    CUSTOMER: '/v1/customers/:id',
    CUSTOMER_USERS: '/v1/customers/:id/users',
    CUSTOMER_USER: '/v1/customers/:id/users/:userId',
    CUSTOMER_USER_ENABLE: '/v1/customers/:id/users/:userId/enable',
    CUSTOMER_USER_EMAIL: '/v1/customers/:id/users/:userId/email',

    INDUSTRY_CODE_TYPES: '/v1/industries/codes/types',

    FILES: '/v1/files',
    FILE: '/v1/files/:id',
    FILE_INFO: '/v1/files/:id/info',

    USERS: '/v1/users',
    USER: '/v1/users/:id',
    USER_ENABLE: '/v1/users/:id/enable',

    NOTIFICATIONS: '/v1/notifications',
    NOTIFICATIONS_LIVE: '/v1/notifications/live',

    KPI: '/v1/kpi',

    MESSAGES_UNREAD: '/v1/messages/unread'
};
