/**
 * Created by br0wn on 10/26/16.
 */
import {Fields} from '../../../../config/domain/user';
import URI from '../../../../config/uri';
import {warn} from '../../../../lib/logger';
import {l} from '../../../i18n/translator';
import {redirectTo} from '../../../router/action';
import {createAction} from '../../../action';
import {objectPickByKeys} from '../../../helper/core';
import {buildErrors, buildFormData, buildApiRequestData} from '../../common/form/helper';
import {fetchUser, createUser, updateUser} from './api';

/*
 * ACTION TYPES
 */
export const TYPES = {
    DATA_LOADED: 'page.users.edit.data_loaded',

    PAGE_LOAD_START: 'page.users.edit.load_start',
    PAGE_LOAD_END: 'page.users.edit.load_end',
    PAGE_RESET: 'page.users.edit.reset',

    SUBMIT_START: 'page.users.edit.submit_start',
    SUBMIT_END: 'page.users.edit.submit_end',

    SET_FORM_DATA: 'page.users.edit.form.set_data',
    FORM_FIELD_CHANGE: 'page.users.edit.form.field_change'
};

export const PAGE_ACTIONS = {
    PAGE_LOAD_START: TYPES.PAGE_LOAD_START,
    PAGE_LOAD_END: TYPES.PAGE_LOAD_END,
    DATA_LOADED: TYPES.DATA_LOADED,
    PAGE_RESET: TYPES.PAGE_RESET
};


/*
 * ACTIONS
 */

export const init = (userId) => (dispatch) => {
    dispatch(createAction(TYPES.PAGE_LOAD_START));

    Promise.all([
        dispatch(loadUser(userId))
    ]).then(([user]) => {
        dispatch(createAction(TYPES.DATA_LOADED, {data: {user}}));

        if (null !== user) {
            dispatch(setApiFormData(user));
        }

        dispatch(createAction(TYPES.PAGE_LOAD_END, {isLoaded: true}));

    }).catch(error => {
        warn("Error while loading page!", error);
        let errors = [];

        if (error.notFound || error.forbidden) {
            return dispatch(createAction(TYPES.PAGE_LOAD_END, {notFound: true}));
        }

        if (error.requestError) {
            errors.push(l("Unknown response received from server"));
        } else {
            errors.push(l("Unknown error occurred"));
        }

        dispatch(createAction(TYPES.PAGE_LOAD_END, {errors: errors}));
    });
};

export const loadUser = (userId) => (dispatch) => {
    if (!userId) {
        return Promise.resolve(null);
    }

    return dispatch(fetchUser(userId));
};

export const setApiFormData = (user) => {
    const formData = {
        [Fields.ID]: user[Fields.ID],
        [Fields.USERNAME]: user[Fields.USERNAME],
        [Fields.EMAIL]: user[Fields.EMAIL],
        [Fields.ROLE]: user[Fields.ROLE],
        // set permissions
        ...buildFormData(user[Fields.PERMISSIONS], field => `${Fields.PERMISSIONS}.${field}`)
    };

    return createAction(TYPES.SET_FORM_DATA, {formData});
};

export const submitForm = (event) => (dispatch, getState) => {
    event.preventDefault();
    dispatch(createAction(TYPES.SUBMIT_START));

    const formData = getState().page.userEdit.form.values;
    const apiData = {
        [Fields.ID]: formData[Fields.ID] || null,
        [Fields.USERNAME]: formData[Fields.USERNAME],
        [Fields.EMAIL]: formData[Fields.EMAIL],
        [Fields.ROLE]: formData[Fields.ROLE],
        [Fields.PASSWORD]: formData[Fields.PASSWORD] || null,
        [Fields.PERMISSIONS]: buildApiRequestData(
            objectPickByKeys(formData, field => field.indexOf(Fields.PERMISSIONS) == 0),
            field => field.replace(`${Fields.PERMISSIONS}.`, ''),
            value => value || null
        )
    };

    dispatch(
        apiData.id ? updateUser(apiData) : createUser(apiData)
    ).then(user => {
        dispatch(createAction(TYPES.SUBMIT_END, {user}));
        dispatch(redirectTo(URI.USER_LIST));
    }).catch(error => {
        if (error.validationErrors) {
            error.validationErrors = buildErrors(error.validationErrors);
        }

        return dispatch(createAction(TYPES.SUBMIT_END, {error}));
    });
};

export const onValueChange = (field, value) => createAction(
    TYPES.FORM_FIELD_CHANGE, {field, value}
);

export const cleanup = () => createAction(
    TYPES.SET_FORM_DATA, {formData: {}, update: false}
);
