import React from 'react';
import PropTypes from "prop-types";
import MessageList from './messageList.jsx';
import MessageForm from './form.jsx';
import StatusBar from './statusBar.jsx';
import LoadingIndicator from './loadingIndicator.jsx';


class StandardChat extends React.Component {

    getComponents() {
        return {
            MessageList,
            MessageForm,
            StatusBar,
            LoadingIndicator
        }
    }

    render() {
        const props = this.props;
        const {status, messages = [], chatUser} = props;
        const {isLoading, isLoaded} = props;
        const {isSendingMessage, isMessageSent} = props;
        const {actions: {onMessageSubmit}} = props;
        const {
            MessageList,
            MessageForm,
            StatusBar,
            LoadingIndicator
        } = this.getComponents();

        return (
            <div className={props.className}>
                <StatusBar {...status} />

                <MessageList {...{chatUser, isLoaded, messages}} />
                <LoadingIndicator {...{isLoading}} />

                <div className="chat-form">
                    <MessageForm
                        isSending={isSendingMessage}
                        isSent={isMessageSent}
                        onSubmit={onMessageSubmit}
                    />
                </div>
            </div>
        );
    }

}

StandardChat.propTypes = {
    'isLoading': PropTypes.bool.isRequired,
    'isLoaded': PropTypes.bool.isRequired,

    'chatUser': PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        companyId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        companyType: PropTypes.string
    }).isRequired,

    'messages': PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,

    'isSendingMessage': PropTypes.bool.isRequired,
    'isMessageSent': PropTypes.bool.isRequired,

    'status': PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string
    }),

    actions: PropTypes.shape({
        'onMessageSubmit': PropTypes.func.isRequired
    }).isRequired
};

export default StandardChat;
