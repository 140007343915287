import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {Button} from 'react-bootstrap';
import classNames from 'classnames';

import {l} from '../../../../../i18n/translator';
import API from '../../../../../../config/api/routes';
import URI from '../../../../../../config/uri';
import {deleteRequest, postRequest} from '../../../../../api/request';
import {apiUploadFile, authApiUploadFile, makeApiRequest, makeAuthApiRequest} from '../../../../../api/action';
import {updateQueryParameter} from '../../../../../../lib/url';
import {AttachmentTypes} from '../../../../../../config/domain/file';

class FileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadingFiles: {},
            removingFileIds: [],
            nextUploadingFileIndex: 1
        };
    }

    getFileUploadUri() {
        if (this.isCurrentInsuranceForm()) {
            return API.OFFER_REQUEST_INSURANCE_FILES.replace(':id', this.props.offerRequestId);
        }
        if (this.getAttachmentType() == AttachmentTypes.OFFICIAL_OFFER) {
            if (this.props.offerId) {
                return API.OFFER_OFFICIAL_FILE.replace(':id', this.props.offerId);
            }
            return API.FILES;
        }
        if (this.props.offerRequestId) {
            return API.OFFER_REQUEST_FILES.replace(':id', this.props.offerRequestId);
        }
        return API.FILES;
    }

    getFileDeleteUri(fileId) {
        if (this.isCurrentInsuranceForm()) {
            return API.OFFER_REQUEST_INSURANCE_FILE.replace(':id', this.props.offerRequestId).replace(':fileId', fileId);
        }
        if (this.getAttachmentType() == AttachmentTypes.OFFICIAL_OFFER) {
            if (this.props.offerId) {
                return API.OFFER_OFFICIAL_FILE.replace(':id', this.props.offerId);
            }
            return API.FILE.replace(':id', fileId);
        }
        if (this.props.offerRequestId) {
            API.OFFER_REQUEST_FILE.replace(':id', this.props.offerRequestId).replace(':fileId', fileId)
        }
        return API.FILE.replace(':id', fileId);
    }

    getFileDisplayUri(fileId) {
        if (this.isCurrentInsuranceForm()) {
            return URI.OFFER_REQUEST_INSURANCE_FILE.replace(':id', this.props.offerRequestId).replace(':fileId', fileId);
        }
        if (this.getAttachmentType() == AttachmentTypes.OFFICIAL_OFFER) {
            if (this.props.offerId) {
                return URI.OFFICIAL_OFFER_FILE.replace(':id', this.props.offerId);
            }
            return URI.FILE.replace(':id', fileId);
        }
        if (this.props.offerRequestId) {
            return URI.OFFER_REQUEST_FILE.replace(':id', this.props.offerRequestId).replace(':fileId', fileId);
        }
        return URI.FILE.replace(':id', fileId);
    }

    uploadFile(file) {
        const uploadingFileIndex = this.state.nextUploadingFileIndex;
        this.state.uploadingFiles[uploadingFileIndex] = file;
        this.state.uploadingFiles[uploadingFileIndex].progress = 0;
        this.state.nextUploadingFileIndex = uploadingFileIndex + 1;
        this.setState({...this.state});

        this.uploadFileAtIndex(uploadingFileIndex);
    }

    uploadFileAtIndex(index) {
        const file = this.state.uploadingFiles[index];
        delete file.error;
        this.setState({...this.state});

        let formData = new FormData();
        formData.append('file', file);
        formData.append('attachmentType', this.getAttachmentType());

        this.props.dispatch(this.apiUploadFile(
            this.getFileUploadUri(), postRequest({
                body: formData,
                onProgress: (e) => {
                    const newState = {...this.state};
                    newState.uploadingFiles[index].progress = e.percent;
                    this.setState(newState)
                }
            }))
        ).then(response => response.json()).then(
            fileResponse => {
                this.removeUploadingFileAtIndex(index);

                if (this.isSingleFile()) {
                    this.props.input.onChange(fileResponse);
                } else {
                    let newValue = [...this.getValueAsArray()];
                    newValue.push(fileResponse);
                    this.props.input && this.props.input.onChange(newValue);
                }

                if (this.props.actions && this.props.actions.onUploaded) {
                    this.props.actions.onUploaded(fileResponse);
                }
            }
        ).catch((error) => {
            this.state.uploadingFiles[index].error = true;
            this.setState({...this.state});
        });
    }

    apiUploadFile(uri, config) {
        return (dispatch) => {
            return dispatch(this.apiCall(uri, config, [apiUploadFile, authApiUploadFile]));
        };
    }

    apiCall(uri, config, actions = []) {
        const [tokenAction = makeApiRequest, authAction = makeAuthApiRequest] = actions;

        return (dispatch) => {
            if (this.props.currentInsuranceToken) {
                uri = updateQueryParameter(uri, 'token', this.props.currentInsuranceToken);
                return dispatch(tokenAction(uri, config));
            }

            return dispatch(authAction(uri, config));
        };
    }

    removeUploadingFileAtIndex(index) {
        const uploadingFiles = {...this.state.uploadingFiles};
        delete uploadingFiles[index];
        this.setState({...this.state, uploadingFiles});
    }

    removeFile(file) {
        this.setState({...this.state, removingFileIds: [...this.state.removingFileIds, file.id]});

        this.props.dispatch(this.apiCall(this.getFileDeleteUri(file.id), deleteRequest())).then(response => {
            const newState = {...this.state};
            newState.removingFileIds.splice(newState.removingFileIds.indexOf(file.id), 1);
            this.setState(newState);

            if (this.isSingleFile()) {
                this.props.input.onChange(null);
            } else {
                let newValue = [...this.getValueAsArray()];
                newValue.splice(this.props.input.value.findIndex(f => f.id == file.id), 1);
                this.props.input.onChange(newValue);
            }
        });
    }

    isSingleFile() {
        return this.getAttachmentType() == AttachmentTypes.MANDATE
            || this.getAttachmentType() == AttachmentTypes.OFFICIAL_OFFER
            || this.getAttachmentType() == AttachmentTypes.SUMMARY;
    }

    isCurrentInsuranceForm() {
        return !!this.props.currentInsuranceToken;
    }

    getValueAsArray() {
        if (this.props.input && this.props.input.value && this.props.input.value.constructor === Array) {
            return this.props.input.value;
        } else {
            return [];
        }
    }

    getAttachmentType() {
        if (this.props.attachmentType) {
            return this.props.attachmentType;
        }

        const fieldName = this.props.input ? this.props.input.name : null;
        if (fieldName) {
            if (fieldName.endsWith('.advisoryMandate')) {
                return AttachmentTypes.MANDATE;
            }
            if (fieldName.endsWith('.harmHistory')) {
                return AttachmentTypes.HARM_HISTORY;
            }
            if (fieldName.endsWith('.summary')) {
                return AttachmentTypes.SUMMARY;
            }
            if (fieldName.endsWith('.attachments')) {
                return AttachmentTypes.ATTACHMENT;
            }
            if (fieldName.endsWith('officialOfferFile')) {
                return AttachmentTypes.OFFICIAL_OFFER;
            }
        }

        throw new Error('Can not figure out attachmentType');
    }

    isRemoving(file) {
        return this.state.removingFileIds.indexOf(file.id) !== -1;
    }

    getUploadedFiles() {
        const value = this.props.input ? this.props.input.value : null;
        if (typeof value === "undefined" || value === null || value === '') {
            return [];
        } else if (value.id) {
            return [value];
        } else if (value.constructor === Array) {
            return value;
        } else {
            return [];
        }
    }

    uploadFiles(files) {
        if (files) {
            if (this.isSingleFile()) {
                if (files[0]) {
                    this.uploadFile(files[0]);
                }
            } else {
                files.forEach(file => this.uploadFile(file));
            }
        }
    }

    renderUploadedFile() {
        const {disabled} = this.props;

        const files = this.getUploadedFiles();

        return (
            <div>
                {files.map(file => (
                    <p className="form-control-static" key={file.id}>
                        <Link to={this.getFileDisplayUri(file.id)} target="_blank">
                            {file.fileName}
                        </Link>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        {this.isRemoving(file) ? (
                            <span>{l('Removing...')}</span>
                        ) : (
                            <Button bsStyle="link" disabled={disabled} onClick={() => this.removeFile(file)}>
                                {l('Remove')}
                            </Button>
                        )}
                    </p>
                ))}
            </div>
        )
    }

    renderProgress() {
        return (
            <div>
                {Object.keys(this.state.uploadingFiles).map((index) => {
                    const file = this.state.uploadingFiles[index];
                    return (
                        <div className="form-control-static" key={index}>
                            <strong className="m-r-xl">{file.name}</strong>

                            {file.error ? (
                                <div className="inline">
                                    <span className="m-r-lg text-uppercase badge badge-danger">{l("Error")}</span>
                                    <Button bsStyle="link"
                                            onClick={() => this.uploadFileAtIndex(index)}>{l('Retry')}</Button>
                                    <Button bsStyle="link"
                                            onClick={() => this.removeUploadingFileAtIndex(index)}>{l('Cancel')}</Button>
                                </div>
                            ) : (
                                <div className="inline">
                                    {l('Uploading')} {file.progress ? <span>{file.progress}%</span> : null}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        )
    }

    renderUploadForm() {
        const {disabled, selectedFile} = this.props;
        return (
            <div className="file-upload">
                {disabled ? (
                    <div className="well m-b-sm p-md text-muted text-center">
                        {l('Disabled.')}
                    </div>
                ) : (
                    <Dropzone multiple={!this.isSingleFile()} accept="application/pdf,.pdf"
                              onDrop={files => this.uploadFiles(files)}>
                        {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                            <div {...getRootProps()} style={{cursor: 'pointer'}}
                                 className={classNames('well m-b-sm p-md ', {
                                     accept: isDragActive,
                                     reject: isDragReject
                                 })}>
                                <input {...getInputProps()} />
                                <div className="text-center">
                                    <i className="fa fa-upload"/>
                                    {selectedFile ? (
                                        <strong>
                                            {selectedFile.name}
                                        </strong>
                                    ) : (
                                        l('Drop files here or click to open file select dialog.')
                                    )}
                                </div>
                            </div>

                        )}
                    </Dropzone>
                )}
            </div>
        )
    }

    shouldShowUploadForm() {
        const value = this.props.input ? this.props.input.value : null;
        return !(this.isSingleFile() && ((value && value.id) || Object.keys(this.state.uploadingFiles).length > 0));
    }

    render() {
        return (
            <div>
                {this.renderUploadedFile()}
                {this.renderProgress()}
                {this.shouldShowUploadForm() ? this.renderUploadForm() : null}
            </div>
        );
    }
}


export default connect(
    state => ({}),
    dispatch => ({
        dispatch
    })
)(FileUpload);
